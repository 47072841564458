<template>
  <vxe-textarea v-model="value" :disabled="renderOpts.props.disabled" v-bind="renderProps" @input="inputEvent"></vxe-textarea>
</template>

<script>
export default {
  name: "VxeTextArea",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      renderProps: {
        placeholder: "请输入备注",
        showWordCount: true,
        maxlength: 500,
        ...this.renderOpts.props,
      }
    }
  },
  computed: {
    value() {
      let { row, column, data, property } = this.params;
      return this.type == "renderItemContent" ? data && data[property] : row[column.property];
    },
  },
  methods: {
    inputEvent({ value }) {
      let { row, column, data, property } = this.params;
      this.type == "renderItemContent" ? data[property] = value : row[column.property] = value;
    }
  }
}
</script>

<style>

</style>

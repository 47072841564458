<template>
  <div>
    <div class="search">
      <el-autocomplete v-model="keywords" :fetch-suggestions="querySearchAsync" :trigger-on-focus="false" :clearable="true" label="title" placeholder="搜索地址" @select="handleSelect" style="width:100%" />
    </div>
    <div id="tMap" ref="tMap"></div>
    <inforWindow ref="infoWindow" @close="closeEvent" :addressInfo="addressInfo" v-if="JSON.stringify(addressInfo)!='{}'"></inforWindow>
  </div>
</template>

<script>
import Axios from "axios";
import TMap from "@/utils/initMap"
import inforWindow from '@/components/Common/infoWindow.vue'
import { mapActions, mapGetters } from "vuex";
export default {
  name: "VxeMap",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  components: {
    inforWindow
  },
  data() {
    return {
      refrashCount: 0,
      keywords: '',
      map: null,
      markerLayer: null,
      infoWindow: null,
      addressInfo: {},
      longitude: 108.947040, //经度
      latitude: 34.259430, //维度
      city: '', //城市
      province: '', //省
      ...this.renderOpts
    }
  },
  created() {
    const { data,property } = this.params;
    this.keywords = data[property];
    console.log(this.addressInfo)
  },
  mounted() {
    this.initMap();
    this.getLocation();
  },
  computed:{
    ...mapGetters(['tMap'])
  },
  methods: {
    ...mapActions(['getEventService']),
    getLocation() {
      const map_key = process.env.VUE_APP_MAP_TENCENT_MAP_KEY
      var geolocation = new window.qq.maps.Geolocation(map_key, "myapp");
      geolocation.getLocation(this.showPosition, this.errorPosition);
    },
    showPosition(position) {
      this.longitude = position.lng;
      this.latitude = position.lat;
      this.city = position.city;
      this.province = position.province;
    },
    errorPosition() {
      //继续定位
      this.getLocation();
    },
    getPosition() { //点击一次获取一次
      this.getLocation()
    },

    async initMap() {
      TMap.init().then((TMap) => {
        const { data } = this.params;
        const center = new TMap.LatLng(data.lat || this.latitude, data.lng || this.longitude)
        this.map = new TMap.Map(this.$refs.tMap, {
          center, //中心点坐标
          zoom: 16
        })
        
        setTimeout(() => {
          this.$store.commit('getTmap', this.map)
           this.markerLayer  = new TMap.MultiMarker({
            map: this.map,
            geometries: [{
              "id": '1',
              "position":center,
            }, ]
          })
        },800)
      })
    },
    querySearchAsync(keywords, cb) {
      if (!keywords) return;
      Axios.get(`/maps/ws/place/v1/suggestion?keyword=${keywords}&key=O2BBZ-RIIW4-WARU3-FQ5RB-4S7NS-5JFKD`).then(({ status, data }) => {
        if (status == 200) {
          const list = data.data.map(item => {
            item.value = `${item.title}-${item.address}`
            return item
          })
          cb(list)
        }
      })
    },
    handleSelect(value) {
      this.addressInfo = value;
      this.setMark(value);
      const { row, column, data, property } = this.params;
      this.type == 'renderItemContent' ? data[property] = value : row[column.property] = value;
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, value);
        });
      }
    },
    setMark(data) {
      const { lat, lng } = data.location;
      TMap.init().then(TMap => {
        const center = new TMap.LatLng(lat, lng)
        this.map.setCenter(center); //重新设置地图中心点
        // 设置marker标记
        this.markerLayer = new TMap.MultiMarker({
          map: this.map,
          geometries: [{
            "id": '1',
            "position": new TMap.LatLng(lat, lng),
          }, ]
        })
        this.markerLayer.on('click', this.markerClick)
        this.initInfoWidow(TMap, data)
      })
    },
    markerClick() {
      this.infoWindow.open();
    },
    closeWindow() {
      this.infoWindow.close();
    },
    initInfoWidow(TMap, data) { //初始化信息窗口
      const { lat, lng } = data.location;
      this.infoWindow = new TMap.InfoWindow({
        map: this.map,
        enableCustom: true,
        position: new TMap.LatLng(lat, lng),
        offset: { y: -65, x: -5 },
        content: `<div class="refCard"></div>`
      })
      this.$refs.infoWindow.$el.style.display = "block"
      document.querySelector('.refCard').append(this.$refs.infoWindow.$el)
      this.closeWindow();
    },
    closeEvent() {
      this.closeWindow();
    },
  }
}
</script>

<style lang="scss" scoped>
#tMap {
  width: 100%;
  height: 300px;

}
.search{
  position: absolute;
  z-index: 99999;
  margin-top: 10px;
  margin-left: 10px;
  width: 50%;
  min-width: 200px;
}
</style>

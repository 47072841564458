import { createRouter, createWebHashHistory } from "vue-router";
import Store from "@/store/index";
// 集成 XEUtils 工具类
import XEUtils from "xe-utils";
const constRoutes = [{
        path: "/",
        redirect: {
            name: "Login",
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import ("@/views/Pages/Login/Login.vue"),
    },
    {
        path: "/layout",
        name: "Layout",
        component: () =>
            import ("@/views/Layout/Layout.vue"),
        children: [],
    },
    {
        path: "/error",
        name: "NetWorkError",
        meta: {
            title: "系统异常",
        },
        component: () =>
            import ("@/views/Pages/NetworkError/NetworkError.vue"),
    },
    {
        path: "/404",
        name: "404",
        meta: {
            title: "404",
        },
        component: () =>
            import ("@/views/Pages/404/404.vue"),
    },
    {
        path: "/:catchAll(.*)", // 不识别的path自动匹配404
        redirect: "/404",
    },
];

const Router = createRouter({
    history: createWebHashHistory(),
    routes: constRoutes,
});

// 全局路由前置守卫
Router.beforeEach((to, from, next) => {
    const nextRoute = ["Login", "Register"];
    // 获取本地存储的userToken  let token = localStorage.token;
    const token = localStorage.token;
    // 未登录状态；当路由到nextRoute指定页时，跳转至login
    if (!token || to.name == "Login") localStorage.clear();
    if (nextRoute.indexOf(to.name) < 0 && !token) {
        next({ name: "Login" });
        localStorage.clear();
    } else {
        next();
    }
});

export default Router;

//创建一个函数，将用户权限对应的路由添加进来
export function initRoutes() {
    const token = localStorage.token;
    let Layout = constRoutes.find((item) => item.name === "Layout");
    if (token && !Layout.children.length) {
        const Resources = Store.state.menuLists || [];
        Resources.forEach((resource) => {
            let obj = {
                path: `/${resource.code.toLowerCase()}`,
                name: resource.code,
                redirect: {
                    name: resource.children && resource.children.length ?
                        resource.children[0].code : "",
                },
                component: () =>
                    import ("@/views/Layout/Main.vue"),
                children: [],
            };
            Layout.children.push(structureRouter(obj, resource));
        });
        // 动态构建路由数据
        Router.addRoute(Layout);
        let urlParse = XEUtils.parseUrl(location);
        if (!urlParse.hashQuery.linkType) {
            Store.commit("goToPage", Store.state.activeMenu);
        }
    }
}

// 递归构造路由结构
const structureRouter = (menu, resource) => {
    if (resource.children && resource.children.length) {
        resource.children.forEach((item) => {
            if (item.type == 1 && item.children && item.children.length) {
                structureRouter(menu, item);
            }
            if (item.type == 2 && item.linkType == 1) {
                menu.children.push({
                    path: `/${resource.code.toLowerCase()}/${item.code.toLowerCase()}`,
                    name: item.code,
                    meta: {
                        service: item.service,
                        tableName: item.tableName,
                        buttons: item.children,
                    },
                    component: () =>
                        import (`@/views/${item.linkAddress}/Index`),
                });
            }
        });
    }
    return menu;
};
<template>
<el-divider :content-position="props.position">{{props.title}}</el-divider>
</template>

<script>
export default {
  name: "VxeDivider",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      ...this.renderOpts
    }
  }
}
</script>

<style>

</style>

import axios from "axios";
import Axios from "@/utils/axios";

const state = {};
const actions = {
    //通用方法 获取Tree
    getTreeService({}, { service, params, dictTypeCodes }) {
        if (dictTypeCodes && dictTypeCodes.length) {
            return axios
                .all([
                    Axios.post(`${service}/tree`, params),
                    Axios.post(`/sys/sysDict/findEnumByTypeCodes`, dictTypeCodes),
                ])
                .then((data) => {
                    if (data[0].code == 200 && data[1].code == 200) {
                        return Promise.resolve({
                            code: 200,
                            data: data[0].data,
                            dicts: data[1].data,
                        });
                    }
                    return Promise.reject(data[0].message || data[1].message);
                });
        }
        return Axios.post(`${service}/tree`, params);
    },

    //通用方法 获取List
    getListService({}, { service, tablePage, params, dictTypeCodes }) {
        if (dictTypeCodes && dictTypeCodes.length) {
            return axios
                .all([
                    Axios.post(
                        `${service}/list/${tablePage.currentPage}/${tablePage.pageSize}`,
                        params
                    ),
                    Axios.post(`/sys/sysDict/findEnumByTypeCodes`, dictTypeCodes),
                ])
                .then((data) => {
                    if (data[0].code == 200 && data[1].code == 200) {
                        return Promise.resolve({
                            code: 200,
                            data: data[0].data,
                            dicts: data[1].data,
                        });
                    }
                    return Promise.reject(data[0].message || data[1].message);
                });
        }
        return Axios.post(
            `${service}/list/${tablePage.currentPage}/${tablePage.pageSize}`,
            params
        );
    },

    //通用方法 保存、更新功能
    submitService({}, { service, params }) {
        if (params.id) {
            //更改
            return Axios.put(service, params);
        } else {
            //新增
            return Axios.post(service, params);
        }
    },

    //通用方法 保存、更新功能
    submitBatchService({}, { service, params }) {
        if (params.id) {
            //更改
            return Axios.put(service, params);
        } else {
            //新增
            return Axios.post(service, params);
        }
    },

    //通用方法 批量提交/批量审核/批量上报/批量取消上报
    batchOperateService({}, { params }) {
        return Axios.post(`/mealWaste/businessApprove`, params);
    },

    //通用方法 post
    postEventService({}, { service, params }) {
        return Axios.post(service, params);
    },

    //通用方法 get
    getEventService({}, { service, params }) {
        if (params) {
            return Axios.get(`${service}/${params.id}`);
        } else {
            return Axios.get(`${service}`);
        }
    },

    //通用方法 删除功能
    removeEventService({}, { service, params }) {
        return Axios.delete(service, { data: params });
    },

    //通用方法 根据ID查询详情功能
    queryEventService({}, { service, params, dictTypeCodes }) {
        if (dictTypeCodes && dictTypeCodes.length) {
            return axios
                .all([
                    Axios.get(`${service}/${params.id}`),
                    Axios.post(`/sys/sysDict/findEnumByTypeCodes`, dictTypeCodes),
                ])
                .then((data) => {
                    if (data[0].code == 200 && data[1].code == 200) {
                        return Promise.resolve({
                            code: 200,
                            data: data[0].data,
                            dicts: data[1].data,
                        });
                    }
                    return Promise.reject(data[0].message || data[1].message);
                });
        }
        return Axios.get(`${service}/${params.id}`);
    },

    //通用方法 查询详情功能
    findByEventService({}, { service, params }) {
        return Axios.post(`${service}/findBy`, params);
    },

    //通用方法 获取数组字典集合
    getSysDictService({}, { params }) {
        return Axios.post(`/sys/sysDict/findEnumByTypeCodes`, params);
    },

    //通用方法 统计接口
    getStatisticsService({}, { service, params }) {
        return Axios.post(service, params);
    },

    //通用方法 获取List
    auditService({}, { params }) {
        return Axios.post(`/finance/businessAudit/saveInfo`, params);
    },

    //通用方法 批量导入
    importAllEventService({}, { service, params }) {
        const data = new FormData();
        Object.keys(params).forEach((key, index) => {
            data.append(key, params[key]);
        });
        return Axios.post(`${service}/import/excel`, data, {
            headers: {
                "Content-Type": "multipart/form-data;boundary=" + new Date().getTime(),
                Authorization: localStorage.token,
            },
        });
    },

    //通用方法 批量导出
    // exportAllEventService({}, { service, params }) {
    //     let exportColumns = [];
    //     params.exportColumns.forEach((column) => {
    //         exportColumns.push({
    //             property: column.property,
    //             title: column.title,
    //         });
    //     });
    //     this.$Tools.creaElementA(
    //         `${process.env.VUE_APP_BASEURL}/${service}/export?Authorization=${
    //     localStorage.token
    //   }&condition=${this.$Tools.serializeQueryUrl(
    //     params.condition
    //   )}&exportColumns=${this.$Tools.serializeQueryUrl(exportColumns)}`
    //     );
    // },
    exportAllEventService({}, { service, params }) {
        return axios.post(`${service}/export`, params, {
            responseType: "blob", //定义接口响应的格式,很重要
            headers: {
                Authorization: localStorage.token,
            },
        });
    },
};

const mutations = {};
export default {
    state,
    actions,
    mutations,
};
import XEUtils from "xe-utils";
// 集成Axios请求类
import Axios from "@/utils/axios";
import qs from "qs";
//项目中公共方法
const regs = {
    mail: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
    url: /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i,
    phone: /^1[3456789]\d{9}$/,
    telephone: /^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/,
    number: /^[0-9]*$/,
    IDCard: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
    decimals: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
    symbol: /^[0-9a-zA-Z\u0000-\u00FF]+$/, //半角符号
};

// 获取按钮权限
const getBtnPermiss = function(route) {
    let Resources = localStorage.resources ?
        JSON.parse(localStorage.resources) : [];
    let resources = XEUtils.filterTree(
        Resources,
        (item) => item.code == route.name
    );
    return resources && resources.length ? resources[0].children : [];
};

const getSsoToken = function() {
    let userInfo = JSON.parse(localStorage.userInfo);
    if (userInfo.appUserId) {
        return Axios.post(`oauth2/token`, qs.stringify({
            "client_id": "oTNs5v0pCAdT4docQOEV",
            "client_secret": "FDPBPbxJwGWW0z2RE75q",
            "grant_type": "client_credentials"
        })).then(({ data }) => {
            if (data.access_token) {
                return Axios.post(`api/system/logon/ssoAuth2`, qs.stringify({
                    "token": data.access_token,
                    "corpId": "0",
                    "loginType": "0",
                    "uid": userInfo.appUserId
                }))
            }
            return Promise.reject('无法获取access_token');
        })
    }
    return Promise.reject('找不到SSO用户信息');
}

const serializeQueryUrl = function(objData) {
    let querySrt =
        JSON.stringify(objData) != "{}" ? `${JSON.stringify(objData)}&` : "";
    return encodeURI(querySrt);
};

const creaElementA = function(url) {
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

// blob转文件并下载
const downloadFileByBlob = function(file, fileName) {
    let blob = new Blob([file], { type: fileName.split(".")[0] })
    let blobUrl = window.URL.createObjectURL(blob)
    let link = document.createElement('a')
    link.download = fileName
    link.style.display = 'none'
    link.href = blobUrl
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

// 将数据字典和数据进行整合并渲染
const buildDataByDicts = function(columns, dicts, defaultProps = { value: "code", label: "name" }) {
    if (!dicts || !columns) return [];
    columns.map((item) => {
        const render = item.editRender || item.itemRender;
        if (render && render.optionTypeCode) {
            render["options"] = treeDataEvent(
                dicts[render.optionTypeCode],
                defaultProps
            );
        }
        if (item.children && item.children.length) {
            buildDataByDicts(item.children, dicts, defaultProps);
        }
    });
    return columns || [];
};

// 将数据字典和数据进行整合并渲染
const buildDataToGrid = function(columns, data, property, defaultProps) {
    // 设置默认渲染的字段
    if (!defaultProps) defaultProps = { value: "id", label: "name" };
    if (!data || !columns) return [];
    columns.map((item, index) => {
        if (item.field == property) {
            const render = item.editRender || item.itemRender;
            render["options"] = treeDataEvent(data, defaultProps);
        }
        if (item.children && item.children.length) {
            buildDataToGrid(item.children, data, property, defaultProps);
        }
    });
    return columns || [];
};

// 循环组装树形结构的数据
const treeDataEvent = function(lists, props) {
    if (!lists) return [];
    lists.map((item) => {
        item.value = item[props.value];
        item.label = item[props.label];
        if (item.children && item.children.length) {
            treeDataEvent(item.children, props);
        } else {
            item.children = null;
        }
    });
    return lists || [];
};

// 对于vxe-table中的特殊字段禁用或启用
const enabledEvent = function(lists, propertys, bool) {
    propertys.forEach((property) => {
        lists.map((item) => {
            if (item.field == property && item.itemRender.props) {
                item.itemRender.props["disabled"] = bool;
            }
        });
    });
};

const enabledAllEvent = function(lists, bool) {
    lists.map((item) => {
        //  && item.itemRender.props.disabled != true
        if (item.itemRender && item.itemRender.props && !item.itemRender.defaultDisabled) {
            item.itemRender.props["disabled"] = bool;
        }
        if (item.itemRender && item.itemRender.props && item.itemRender.defaultDisabled) {
            item.itemRender.props["disabled"] = true;
        }
        if (item.itemRender && item.itemRender.name == "$buttons" && item.itemRender.defaultDisabled) {
            item.itemRender.children.map(btn => {
                if (btn.props.name != "reset") {
                    btn.props["disabled"] = bool;
                }
            });
        }
    });
};

const convertCurrency = (money) => {
    //汉字的数字
    var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
    );
    //基本单位
    var cnIntRadice = new Array("", "拾", "佰", "仟");
    //对应整数部分扩展单位
    var cnIntUnits = new Array("", "万", "亿", "兆");
    //对应小数部分单位
    var cnDecUnits = new Array("角", "分", "毫", "厘");
    //整数金额时后面跟的字符
    var cnInteger = "整";
    //整型完以后的单位
    var cnIntLast = "元";
    //最大处理的数字
    var maxNum = 999999999999999.9999;
    //金额整数部分
    var integerNum;
    //金额小数部分
    var decimalNum;
    //输出的中文金额字符串
    var chineseStr = "";
    //分离金额后用的数组，预定义
    var parts;
    if (money == "") {
        return "";
    }
    money = parseFloat(money);
    if (money >= maxNum) {
        //超出最大处理数字
        return "";
    }
    if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    //转换为字符串
    money = money.toString();
    if (money.indexOf(".") == -1) {
        integerNum = money;
        decimalNum = "";
    } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
            var n = integerNum.substr(i, 1);
            var p = IntLen - i - 1;
            var q = p / 4;
            var m = p % 4;
            if (n == "0") {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                //归零
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    //小数部分
    if (decimalNum != "") {
        let decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
            let n = decimalNum.substr(i, 1);
            if (n != "0") {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr == "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == "") {
        chineseStr += cnInteger;
    }
    return chineseStr;
};

// 处理表单中字段渲染的问题,如：aa.bb
const getValueByfieldEvent = function(data, property) {
    if (property.indexOf(".") > -1) {
        let obj = data;
        property = property.split(".");
        property.forEach((item) => {
            obj = obj[item];
        });
        if (obj) {
            return obj;
        }
    }
    return data[property];
};


const getPrevQuarterFirstDay = function(date) {
    let year = date.getFullYear();
    var month = date.getMonth();
    if (month < 3) {
        date.setYear(year - 1);
        date.setMonth(9);
    } else if (2 < month && month < 6) {
        date.setMonth(0);
    } else if (5 < month && month < 9) {
        date.setMonth(3);
    } else if (8 < month && month < 11) {
        date.setMonth(6);
    }
    date.setDate(1);
    return XEUtils.toDateString(date, 'yyyy-MM-dd');
}

const setDefaultDataEvent = function(lists) {
    let dictionary = [];
    if (!lists || !lists.length) return;
    lists.forEach(item => {
        dictionary.push({
            projectName: item.code,
            name: item.name,
            start: "",
            end: ""
        })
    });

    return dictionary;
}

const getBatchEvent = function(dateType) {
    let batch = ""
    if (dateType == "year") {
        batch = XEUtils.toDateString(XEUtils.getWhatYear(new Date(), -1), "yyyy");
    } else if (dateType == "quarter") {
        batch = getPrevQuarterFirstDay(new Date())
    } else if (dateType == "month") {
        batch = XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), "yyyy-MM");
    } else {
        batch = XEUtils.toDateString(XEUtils.getWhatYear(new Date(), -1), "yyyy-MM-dd");
    }
    return batch
}
const getBatchLabelFormat = function(dateType) {
    let batch = ""
    if (dateType == "year") {
        batch = "yyyy";
    } else if (dateType == "quarter") {
        batch = "";
    } else if (dateType == "month") {
        batch = "yyyy-MM";
    } else {
        batch = "yyyy-MM-dd";
    }
    return batch
}


// 批量设置全局校验提示
const setValidationPrompt = function(formOptions, fieldLists) {
    let { items, rules } = formOptions;
    if (!items || !items.length) return;
    items.forEach((item) => {
        if (!item.field || item.field == "remark" || XEUtils.includes(item.field) || (fieldLists && fieldLists.includes(item.field))) return;
        rules[item.field] = [{
            required: true,
            message: item.itemRender.props.placeholder,
        }, ];
    });
};

const calculationRowCount = (row, prop, columns, XEUtils) => {
    let aColumns = XEUtils.toTreeArray(columns),
        aProps = [];
    aColumns.forEach((item) => {
        if (
            item.editRender &&
            item.editRender.props &&
            item.editRender.props[prop]
        ) {
            aProps.push(item.field);
        }
    });

    let rowCount = 0;
    aProps.forEach((itemProp) => {
        rowCount = XEUtils.add(rowCount, row[itemProp]);
    });
    return rowCount
}
const calculationColCount = (start, end, property, list, XEUtils) => {
    let colCount = 0;
    list.slice(start, end).forEach(item => {
        colCount = XEUtils.add(colCount, item[property]);
    });

    return colCount
}


const subtotal = function(columns, row, totalKey) {
    let subtotalNum = 0;
    columns.forEach((item) => {
        if (item.calculateValue == totalKey) {
            subtotalNum = XEUtils.add(subtotalNum, row[item["field"]]);
        }
    });
    return subtotalNum
}

const total = function() {

}

const Tools = {
    regs,
    getBtnPermiss,
    getSsoToken,
    serializeQueryUrl,
    creaElementA,
    downloadFileByBlob,
    buildDataToGrid,
    buildDataByDicts,
    treeDataEvent,
    enabledEvent,
    enabledAllEvent,
    convertCurrency,
    getValueByfieldEvent,
    getPrevQuarterFirstDay,
    setDefaultDataEvent,
    getBatchEvent,
    getBatchLabelFormat,
    setValidationPrompt,
    calculationRowCount,
    calculationColCount,
    subtotal,
    total
};

export default Tools;
<template>
<VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :defaultTrigger="false"> </VxeBasicTable>
</template>

<script>
export default {
  name: "VxeOrderNo",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      gridOptions: {
        height: 200,
        toolbarConfig: {
          enabled: false,
        },
        columns: [{
            type: 'seq',
            fixed: 'left',
            minWidth: 60
          },
          {
            field: 'orderNo',
            title: '订单编号',
            width: 150,
            editRender: {
              name: '$input',
              props: {}
            }
          },
          {
            field: '',
            title: '油',
            minWidth: 180,
            children:[
              {
                field: 'weight1',
                title: '重量',
                minWidth: 180,
                editRender: {
                  name: '$input',
                  props: {}
                }
              },
              {
                field: 'total1',
                title: '数量',
                minWidth: 180,
                editRender: {
                  name: '$input',
                  props: {}
                }
              },
            ]
          },
          {
            field: 'machineCode',
            title: '料',
            width: 100,
            children:[
              {
                field: 'weight2',
                title: '重量',
                minWidth: 180,
                editRender: {
                  name: '$input',
                  props: {}
                }
              },
              {
                field: 'total2',
                title: '数量',
                minWidth: 180,
                editRender: {
                  name: '$input',
                  props: {}
                }
              },
            ]
          },
          {
            field: '',
            title: '渣',
            width: 100,
            children:[
              {
                field: 'weight3',
                title: '重量',
                minWidth: 180,
                editRender: {
                  name: '$input',
                  props: {}
                }
              },
              {
                field: 'total3',
                title: '数量',
                minWidth: 180,
                editRender: {
                  name: '$input',
                  props: {}
                }
              },
            ]
          },
        ],
        pagerConfig: {
          enabled: false,
        },
        editConfig: {
          enabled: false
        },
        exportConfig: {},
        importConfig: {}
      },
      ...this.renderOpts
    }
  },
  mounted() {
    let {
      data,property
    } = this.params;
   
    data[property]?.forEach((item,index) => {
      data[property][index] = {
        ...item,
        ...this.getSubmitParams([item])
      }
    })
    this.$refs.xGrid.tableData = data[property]
    
  },
  methods: {
    getSubmitParams(selectRows) {
      let oil = [];
      let material = [];
      let dregs = [];
      let ids = [];
      let orders = [];

      selectRows.forEach(order => {
        ids.push(order.id);
        orders.push(order.orderNo);
        order.businessOrderWasteList.forEach(item => {
          if (item.wasteCode == 1) {
            oil.push(item)
          }
          if (item.wasteCode == 2) {
            material.push(item)
          }
          if (item.wasteCode == 3) {
            dregs.push(item)
          }
        })
      });
      this.formData = {
        ...this.countEvent(oil),
        ...this.countEvent(material),
        ...this.countEvent(dregs),
      }
      // oil.length ? this.formData.businessOrderWasteList.push(this.countEvent1(oil)) : "";
      // material.length ? this.formData.businessOrderWasteList.push(this.countEvent1(material)) : "";
      // dregs.length ? this.formData.businessOrderWasteList.push(this.countEvent1(dregs)) : "";

      return this.formData
    },
     countEvent(lists) {
      let data = {};
      lists.forEach(item => {
        data[`weight${item.wasteCode}`] = this.$XEUtils.add(data[`weight${item.wasteCode}`], item[item.orderWeight ? "retrieveWeight" : "storageWeight"]) || 0;
        data[`total${item.wasteCode}`] = this.$XEUtils.add(data[`total${item.wasteCode}`], item[item.orderCount ? "retrieveCount" : "storageCount"]) || 0;
      });
      return data;
    },
  }
}
</script>

<style lang="scss">
.text {
  height: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0.4em 0.6em;
  border: 1px solid #dcdfe6;
  background-color: #f7f5f6;

  div {
    color: #7391c9;

    &:hover {
      color: orange;
      cursor: pointer;
    }
  }
}
</style>

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import Router from "@/router";
import Login from "./Modules/Login";
import Public from "./Modules/Public";
import WebSocket from "./Modules/WebSocket";

// 根据当前环境和访问地址，动态设置LOGO
import Logos from "../../LogoConfig";
let logo = Logos[0];
if (process.env.NODE_ENV == "production") {
    logo = Logos.filter((item) => item.origin == location.origin)[0] || Logos[0];
}

export default createStore({
    state: {
        logo: logo,
        isCollapse: false,
        tabs: [],
        tabIndex: "0",
        activeMenu: null,
        menuLists: [],
        timer: 300000,
        tMap:null,//腾讯地图实例对象
    },
    getters: {
        tMap(state) {
           return state.tMap
        },
    },
    mutations: {
        // 全局处理路由跳转
        goToPage(state, item) {
            if (!item) return;
            if (item.linkType == 2) {
                let routeUrl = Router.resolve({
                    name: item.code,
                    query:{orderNo:item.orderNo}
                });
                window.open(`${routeUrl.href}?linkType=2`, "_blank");
            } else {
                state.tabIndex = item.index;
                state.activeMenu = item;
                let flag = false;
                if (state.tabs.length) {
                    for (let tab of state.tabs) {
                        if (tab.index == item.index) {
                            flag = true;
                            break;
                        }
                    }
                } else {
                    state.tabs = [];
                }
                if (!flag) state.tabs.push({...item });
                Router.push({
                    name: item.code,
                    query:{orderNo:item.orderNo}
                });
            }
        },

        // 删除页面上的选项卡
        removeTabEvent(state, tabIndex) {
            state.tabs.forEach((tab, index) => {
                if (tab.index === tabIndex) {
                    let nextTab = state.tabs[index + 1] || state.tabs[index - 1];
                    if (nextTab) {
                        state.tabIndex = nextTab.index;
                        state.activeMenu = nextTab;
                        state.tabs.splice(index, 1);
                        Router.replace({
                            name: nextTab.code,
                        });
                    }
                }
            });
        },

        // 删除页面上的选项卡
        removeAllTab(state) {
            let welcome = state.menuLists[0].children[0];
            state.tabIndex = welcome.index;
            state.activeMenu = welcome;
            state.tabs = [welcome];
            Router.replace({
                name: welcome.code,
            });
        },

        goBack() {
            Router.back(-1);
        },
        getTmap(state, data) {
            state.tMap = data;
        },
    },
    actions: {},
    modules: {
        Login,
        Public,
        WebSocket
    },
    plugins: [
        createPersistedState({
            storage: window.localStorage,
            reducer(val) {
                return {
                    // 需要保持数据持久化的内容，只储存state中的部分数据
                    logo: val.logo,
                    isCollapse: val.isCollapse,
                    tabs: val.tabs,
                    tabIndex: val.tabIndex,
                    activeMenu: val.activeMenu,
                    menuLists: val.menuLists,
                };
            },
        }),
    ],
});
import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

// 集成默认样式
import "@/assets/css/animate.min.css";
import "@/assets/css/font-awesome.min.css";
import "@/assets/iconFonts/iconfont.css";
import "@/assets/fonts/led/led.css"
import "@/assets/css/base.scss";

// 初始化VUE
const app = createApp(App);

// 引入echarts
import * as echarts from 'echarts'
// 集成 XEUtils 工具类
import XEUtils from "xe-utils";
// 集成自定义工具类
import Tools from "@/utils/Tools";
// 集成Axios请求类
import Axios from "@/utils/axios";
// 定义全局的方法类
app.config.globalProperties.$XEUtils = XEUtils;
app.config.globalProperties.$Tools = Tools;
app.config.globalProperties.$Axios = Axios;
app.config.globalProperties.$echarts = echarts;

// 默认皮肤
import "@/assets/skins/default.scss";

// 集成i18n 国际化资源
import i18n from "@/i18n";

// 集成 element-plus
import ElementPlus from "element-plus";

// 集成 element-plus-Icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 封装全局公共组件
import VxeCommon from "@/components/Common/index";
VxeCommon(app)

// 集成 VXETable
import VXETable from "@/vxe-table/index";

app.use(store).use(router).use(ElementPlus, {
    zIndex: 3000,
    locale: i18n.global.messages[i18n.global.locale],
}).use(i18n).use(VXETable).mount("#app");
<template>
<!-- <vxe-textarea v-model="value" v-bind="renderProps" @input="inputEvent"></vxe-textarea> -->

<el-date-picker v-model="value" type="daterange" format="YYYY-MM-DD" value-format="YYYY-MM-DD" style="width: calc(100% - 20px)" @change="handleChange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
</el-date-picker>
</template>

<script>
export default {
  name: "VxeDateRange",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      value: '',
      ...this.renderOpts
    }
  },
  created() {
    let { row, column, data, property } = this.params;
    let value = this.type == "renderItemContent" ? data[property] : row[column.property] ? JSON.parse(this.type == "renderItemContent" ? data[property] : row[column.property]) : [];
    if (value) {
      this.value = JSON.parse(value)
    }
   
  },
  methods: {
    handleChange(val) {
      let { row, column, data, property } = this.params;
      this.type == "renderItemContent" ? data[property] = JSON.stringify(val) : row[column.property] = JSON.stringify(val);
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, val);
        });
      }
    }
  }
}
</script>

<style>

</style>

export default [{
		"provinceCode": "110000000",
		"provinceName": "北京市",
		"id": 11,
		"parentId": 0,
		"children": [{
			"cityCode": "110100000",
			"cityName": "市辖区",
			"id": 1101,
			"parentId": 11,
			"children": [{
					"countyCode": "110101000",
					"countyName": "东城区",
					"id": 110101,
					"parentId": 1101
				},
				{
					"countyCode": "110102000",
					"countyName": "西城区",
					"id": 110102,
					"parentId": 1101
				},
				{
					"countyCode": "110105000",
					"countyName": "朝阳区",
					"id": 110105,
					"parentId": 1101
				},
				{
					"countyCode": "110106000",
					"countyName": "丰台区",
					"id": 110106,
					"parentId": 1101
				},
				{
					"countyCode": "110107000",
					"countyName": "石景山区",
					"id": 110107,
					"parentId": 1101
				},
				{
					"countyCode": "110108000",
					"countyName": "海淀区",
					"id": 110108,
					"parentId": 1101
				},
				{
					"countyCode": "110109000",
					"countyName": "门头沟区",
					"id": 110109,
					"parentId": 1101
				},
				{
					"countyCode": "110111000",
					"countyName": "房山区",
					"id": 110111,
					"parentId": 1101
				},
				{
					"countyCode": "110112000",
					"countyName": "通州区",
					"id": 110112,
					"parentId": 1101
				},
				{
					"countyCode": "110113000",
					"countyName": "顺义区",
					"id": 110113,
					"parentId": 1101
				},
				{
					"countyCode": "110114000",
					"countyName": "昌平区",
					"id": 110114,
					"parentId": 1101
				},
				{
					"countyCode": "110115000",
					"countyName": "大兴区",
					"id": 110115,
					"parentId": 1101
				},
				{
					"countyCode": "110116000",
					"countyName": "怀柔区",
					"id": 110116,
					"parentId": 1101
				},
				{
					"countyCode": "110117000",
					"countyName": "平谷区",
					"id": 110117,
					"parentId": 1101
				},
				{
					"countyCode": "110118000",
					"countyName": "密云区",
					"id": 110118,
					"parentId": 1101
				},
				{
					"countyCode": "110119000",
					"countyName": "延庆区",
					"id": 110119,
					"parentId": 1101
				}
			]
		}]
	},
	{
		"provinceCode": "120000000",
		"provinceName": "天津市",
		"id": 12,
		"parentId": 0,
		"children": [{
			"cityCode": "120100000",
			"cityName": "市辖区",
			"id": 1201,
			"parentId": 12,
			"children": [{
					"countyCode": "120101000",
					"countyName": "和平区",
					"id": 120101,
					"parentId": 1201
				},
				{
					"countyCode": "120102000",
					"countyName": "河东区",
					"id": 120102,
					"parentId": 1201
				},
				{
					"countyCode": "120103000",
					"countyName": "河西区",
					"id": 120103,
					"parentId": 1201
				},
				{
					"countyCode": "120104000",
					"countyName": "南开区",
					"id": 120104,
					"parentId": 1201
				},
				{
					"countyCode": "120105000",
					"countyName": "河北区",
					"id": 120105,
					"parentId": 1201
				},
				{
					"countyCode": "120106000",
					"countyName": "红桥区",
					"id": 120106,
					"parentId": 1201
				},
				{
					"countyCode": "120110000",
					"countyName": "东丽区",
					"id": 120110,
					"parentId": 1201
				},
				{
					"countyCode": "120111000",
					"countyName": "西青区",
					"id": 120111,
					"parentId": 1201
				},
				{
					"countyCode": "120112000",
					"countyName": "津南区",
					"id": 120112,
					"parentId": 1201
				},
				{
					"countyCode": "120113000",
					"countyName": "北辰区",
					"id": 120113,
					"parentId": 1201
				},
				{
					"countyCode": "120114000",
					"countyName": "武清区",
					"id": 120114,
					"parentId": 1201
				},
				{
					"countyCode": "120115000",
					"countyName": "宝坻区",
					"id": 120115,
					"parentId": 1201
				},
				{
					"countyCode": "120116000",
					"countyName": "滨海新区",
					"id": 120116,
					"parentId": 1201
				},
				{
					"countyCode": "120117000",
					"countyName": "宁河区",
					"id": 120117,
					"parentId": 1201
				},
				{
					"countyCode": "120118000",
					"countyName": "静海区",
					"id": 120118,
					"parentId": 1201
				},
				{
					"countyCode": "120119000",
					"countyName": "蓟州区",
					"id": 120119,
					"parentId": 1201
				}
			]
		}]
	},
	{
		"provinceCode": "130000000",
		"provinceName": "河北省",
		"id": 13,
		"parentId": 0,
		"children": [{
				"cityCode": "130100000",
				"cityName": "石家庄市",
				"id": 1301,
				"parentId": 13,
				"children": [{
						"countyCode": "130102000",
						"countyName": "长安区",
						"id": 130102,
						"parentId": 1301
					},
					{
						"countyCode": "130104000",
						"countyName": "桥西区",
						"id": 130104,
						"parentId": 1301
					},
					{
						"countyCode": "130105000",
						"countyName": "新华区",
						"id": 130105,
						"parentId": 1301
					},
					{
						"countyCode": "130107000",
						"countyName": "井陉矿区",
						"id": 130107,
						"parentId": 1301
					},
					{
						"countyCode": "130108000",
						"countyName": "裕华区",
						"id": 130108,
						"parentId": 1301
					},
					{
						"countyCode": "130109000",
						"countyName": "藁城区",
						"id": 130109,
						"parentId": 1301
					},
					{
						"countyCode": "130110000",
						"countyName": "鹿泉区",
						"id": 130110,
						"parentId": 1301
					},
					{
						"countyCode": "130111000",
						"countyName": "栾城区",
						"id": 130111,
						"parentId": 1301
					},
					{
						"countyCode": "130121000",
						"countyName": "井陉县",
						"id": 130121,
						"parentId": 1301
					},
					{
						"countyCode": "130123000",
						"countyName": "正定县",
						"id": 130123,
						"parentId": 1301
					},
					{
						"countyCode": "130125000",
						"countyName": "行唐县",
						"id": 130125,
						"parentId": 1301
					},
					{
						"countyCode": "130126000",
						"countyName": "灵寿县",
						"id": 130126,
						"parentId": 1301
					},
					{
						"countyCode": "130127000",
						"countyName": "高邑县",
						"id": 130127,
						"parentId": 1301
					},
					{
						"countyCode": "130128000",
						"countyName": "深泽县",
						"id": 130128,
						"parentId": 1301
					},
					{
						"countyCode": "130129000",
						"countyName": "赞皇县",
						"id": 130129,
						"parentId": 1301
					},
					{
						"countyCode": "130130000",
						"countyName": "无极县",
						"id": 130130,
						"parentId": 1301
					},
					{
						"countyCode": "130131000",
						"countyName": "平山县",
						"id": 130131,
						"parentId": 1301
					},
					{
						"countyCode": "130132000",
						"countyName": "元氏县",
						"id": 130132,
						"parentId": 1301
					},
					{
						"countyCode": "130133000",
						"countyName": "赵县",
						"id": 130133,
						"parentId": 1301
					},
					{
						"countyCode": "130181000",
						"countyName": "辛集市",
						"id": 130181,
						"parentId": 1301
					},
					{
						"countyCode": "130183000",
						"countyName": "晋州市",
						"id": 130183,
						"parentId": 1301
					},
					{
						"countyCode": "130184000",
						"countyName": "新乐市",
						"id": 130184,
						"parentId": 1301
					}
				]
			},
			{
				"cityCode": "130200000",
				"cityName": "唐山市",
				"id": 1302,
				"parentId": 13,
				"children": [{
						"countyCode": "130202000",
						"countyName": "路南区",
						"id": 130202,
						"parentId": 1302
					},
					{
						"countyCode": "130203000",
						"countyName": "路北区",
						"id": 130203,
						"parentId": 1302
					},
					{
						"countyCode": "130204000",
						"countyName": "古冶区",
						"id": 130204,
						"parentId": 1302
					},
					{
						"countyCode": "130205000",
						"countyName": "开平区",
						"id": 130205,
						"parentId": 1302
					},
					{
						"countyCode": "130207000",
						"countyName": "丰南区",
						"id": 130207,
						"parentId": 1302
					},
					{
						"countyCode": "130208000",
						"countyName": "丰润区",
						"id": 130208,
						"parentId": 1302
					},
					{
						"countyCode": "130209000",
						"countyName": "曹妃甸区",
						"id": 130209,
						"parentId": 1302
					},
					{
						"countyCode": "130224000",
						"countyName": "滦南县",
						"id": 130224,
						"parentId": 1302
					},
					{
						"countyCode": "130225000",
						"countyName": "乐亭县",
						"id": 130225,
						"parentId": 1302
					},
					{
						"countyCode": "130227000",
						"countyName": "迁西县",
						"id": 130227,
						"parentId": 1302
					},
					{
						"countyCode": "130229000",
						"countyName": "玉田县",
						"id": 130229,
						"parentId": 1302
					},
					{
						"countyCode": "130281000",
						"countyName": "遵化市",
						"id": 130281,
						"parentId": 1302
					},
					{
						"countyCode": "130283000",
						"countyName": "迁安市",
						"id": 130283,
						"parentId": 1302
					},
					{
						"countyCode": "130284000",
						"countyName": "滦州市",
						"id": 130284,
						"parentId": 1302
					}
				]
			},
			{
				"cityCode": "130300000",
				"cityName": "秦皇岛市",
				"id": 1303,
				"parentId": 13,
				"children": [{
						"countyCode": "130302000",
						"countyName": "海港区",
						"id": 130302,
						"parentId": 1303
					},
					{
						"countyCode": "130303000",
						"countyName": "山海关区",
						"id": 130303,
						"parentId": 1303
					},
					{
						"countyCode": "130304000",
						"countyName": "北戴河区",
						"id": 130304,
						"parentId": 1303
					},
					{
						"countyCode": "130306000",
						"countyName": "抚宁区",
						"id": 130306,
						"parentId": 1303
					},
					{
						"countyCode": "130321000",
						"countyName": "青龙满族自治县",
						"id": 130321,
						"parentId": 1303
					},
					{
						"countyCode": "130322000",
						"countyName": "昌黎县",
						"id": 130322,
						"parentId": 1303
					},
					{
						"countyCode": "130324000",
						"countyName": "卢龙县",
						"id": 130324,
						"parentId": 1303
					}
				]
			},
			{
				"cityCode": "130400000",
				"cityName": "邯郸市",
				"id": 1304,
				"parentId": 13,
				"children": [{
						"countyCode": "130402000",
						"countyName": "邯山区",
						"id": 130402,
						"parentId": 1304
					},
					{
						"countyCode": "130403000",
						"countyName": "丛台区",
						"id": 130403,
						"parentId": 1304
					},
					{
						"countyCode": "130404000",
						"countyName": "复兴区",
						"id": 130404,
						"parentId": 1304
					},
					{
						"countyCode": "130406000",
						"countyName": "峰峰矿区",
						"id": 130406,
						"parentId": 1304
					},
					{
						"countyCode": "130407000",
						"countyName": "肥乡区",
						"id": 130407,
						"parentId": 1304
					},
					{
						"countyCode": "130408000",
						"countyName": "永年区",
						"id": 130408,
						"parentId": 1304
					},
					{
						"countyCode": "130423000",
						"countyName": "临漳县",
						"id": 130423,
						"parentId": 1304
					},
					{
						"countyCode": "130424000",
						"countyName": "成安县",
						"id": 130424,
						"parentId": 1304
					},
					{
						"countyCode": "130425000",
						"countyName": "大名县",
						"id": 130425,
						"parentId": 1304
					},
					{
						"countyCode": "130426000",
						"countyName": "涉县",
						"id": 130426,
						"parentId": 1304
					},
					{
						"countyCode": "130427000",
						"countyName": "磁县",
						"id": 130427,
						"parentId": 1304
					},
					{
						"countyCode": "130430000",
						"countyName": "邱县",
						"id": 130430,
						"parentId": 1304
					},
					{
						"countyCode": "130431000",
						"countyName": "鸡泽县",
						"id": 130431,
						"parentId": 1304
					},
					{
						"countyCode": "130432000",
						"countyName": "广平县",
						"id": 130432,
						"parentId": 1304
					},
					{
						"countyCode": "130433000",
						"countyName": "馆陶县",
						"id": 130433,
						"parentId": 1304
					},
					{
						"countyCode": "130434000",
						"countyName": "魏县",
						"id": 130434,
						"parentId": 1304
					},
					{
						"countyCode": "130435000",
						"countyName": "曲周县",
						"id": 130435,
						"parentId": 1304
					},
					{
						"countyCode": "130481000",
						"countyName": "武安市",
						"id": 130481,
						"parentId": 1304
					}
				]
			},
			{
				"cityCode": "130500000",
				"cityName": "邢台市",
				"id": 1305,
				"parentId": 13,
				"children": [{
						"countyCode": "130502000",
						"countyName": "桥东区",
						"id": 130502,
						"parentId": 1305
					},
					{
						"countyCode": "130503000",
						"countyName": "桥西区",
						"id": 130503,
						"parentId": 1305
					},
					{
						"countyCode": "130521000",
						"countyName": "邢台县",
						"id": 130521,
						"parentId": 1305
					},
					{
						"countyCode": "130522000",
						"countyName": "临城县",
						"id": 130522,
						"parentId": 1305
					},
					{
						"countyCode": "130523000",
						"countyName": "内丘县",
						"id": 130523,
						"parentId": 1305
					},
					{
						"countyCode": "130524000",
						"countyName": "柏乡县",
						"id": 130524,
						"parentId": 1305
					},
					{
						"countyCode": "130525000",
						"countyName": "隆尧县",
						"id": 130525,
						"parentId": 1305
					},
					{
						"countyCode": "130526000",
						"countyName": "任县",
						"id": 130526,
						"parentId": 1305
					},
					{
						"countyCode": "130527000",
						"countyName": "南和县",
						"id": 130527,
						"parentId": 1305
					},
					{
						"countyCode": "130528000",
						"countyName": "宁晋县",
						"id": 130528,
						"parentId": 1305
					},
					{
						"countyCode": "130529000",
						"countyName": "巨鹿县",
						"id": 130529,
						"parentId": 1305
					},
					{
						"countyCode": "130530000",
						"countyName": "新河县",
						"id": 130530,
						"parentId": 1305
					},
					{
						"countyCode": "130531000",
						"countyName": "广宗县",
						"id": 130531,
						"parentId": 1305
					},
					{
						"countyCode": "130532000",
						"countyName": "平乡县",
						"id": 130532,
						"parentId": 1305
					},
					{
						"countyCode": "130533000",
						"countyName": "威县",
						"id": 130533,
						"parentId": 1305
					},
					{
						"countyCode": "130534000",
						"countyName": "清河县",
						"id": 130534,
						"parentId": 1305
					},
					{
						"countyCode": "130535000",
						"countyName": "临西县",
						"id": 130535,
						"parentId": 1305
					},
					{
						"countyCode": "130581000",
						"countyName": "南宫市",
						"id": 130581,
						"parentId": 1305
					},
					{
						"countyCode": "130582000",
						"countyName": "沙河市",
						"id": 130582,
						"parentId": 1305
					}
				]
			},
			{
				"cityCode": "130600000",
				"cityName": "保定市",
				"id": 1306,
				"parentId": 13,
				"children": [{
						"countyCode": "130602000",
						"countyName": "竞秀区",
						"id": 130602,
						"parentId": 1306
					},
					{
						"countyCode": "130606000",
						"countyName": "莲池区",
						"id": 130606,
						"parentId": 1306
					},
					{
						"countyCode": "130607000",
						"countyName": "满城区",
						"id": 130607,
						"parentId": 1306
					},
					{
						"countyCode": "130608000",
						"countyName": "清苑区",
						"id": 130608,
						"parentId": 1306
					},
					{
						"countyCode": "130609000",
						"countyName": "徐水区",
						"id": 130609,
						"parentId": 1306
					},
					{
						"countyCode": "130623000",
						"countyName": "涞水县",
						"id": 130623,
						"parentId": 1306
					},
					{
						"countyCode": "130624000",
						"countyName": "阜平县",
						"id": 130624,
						"parentId": 1306
					},
					{
						"countyCode": "130626000",
						"countyName": "定兴县",
						"id": 130626,
						"parentId": 1306
					},
					{
						"countyCode": "130627000",
						"countyName": "唐县",
						"id": 130627,
						"parentId": 1306
					},
					{
						"countyCode": "130628000",
						"countyName": "高阳县",
						"id": 130628,
						"parentId": 1306
					},
					{
						"countyCode": "130629000",
						"countyName": "容城县",
						"id": 130629,
						"parentId": 1306
					},
					{
						"countyCode": "130630000",
						"countyName": "涞源县",
						"id": 130630,
						"parentId": 1306
					},
					{
						"countyCode": "130631000",
						"countyName": "望都县",
						"id": 130631,
						"parentId": 1306
					},
					{
						"countyCode": "130632000",
						"countyName": "安新县",
						"id": 130632,
						"parentId": 1306
					},
					{
						"countyCode": "130633000",
						"countyName": "易县",
						"id": 130633,
						"parentId": 1306
					},
					{
						"countyCode": "130634000",
						"countyName": "曲阳县",
						"id": 130634,
						"parentId": 1306
					},
					{
						"countyCode": "130635000",
						"countyName": "蠡县",
						"id": 130635,
						"parentId": 1306
					},
					{
						"countyCode": "130636000",
						"countyName": "顺平县",
						"id": 130636,
						"parentId": 1306
					},
					{
						"countyCode": "130637000",
						"countyName": "博野县",
						"id": 130637,
						"parentId": 1306
					},
					{
						"countyCode": "130638000",
						"countyName": "雄县",
						"id": 130638,
						"parentId": 1306
					},
					{
						"countyCode": "130681000",
						"countyName": "涿州市",
						"id": 130681,
						"parentId": 1306
					},
					{
						"countyCode": "130682000",
						"countyName": "定州市",
						"id": 130682,
						"parentId": 1306
					},
					{
						"countyCode": "130683000",
						"countyName": "安国市",
						"id": 130683,
						"parentId": 1306
					},
					{
						"countyCode": "130684000",
						"countyName": "高碑店市",
						"id": 130684,
						"parentId": 1306
					}
				]
			},
			{
				"cityCode": "130700000",
				"cityName": "张家口市",
				"id": 1307,
				"parentId": 13,
				"children": [{
						"countyCode": "130702000",
						"countyName": "桥东区",
						"id": 130702,
						"parentId": 1307
					},
					{
						"countyCode": "130703000",
						"countyName": "桥西区",
						"id": 130703,
						"parentId": 1307
					},
					{
						"countyCode": "130705000",
						"countyName": "宣化区",
						"id": 130705,
						"parentId": 1307
					},
					{
						"countyCode": "130706000",
						"countyName": "下花园区",
						"id": 130706,
						"parentId": 1307
					},
					{
						"countyCode": "130708000",
						"countyName": "万全区",
						"id": 130708,
						"parentId": 1307
					},
					{
						"countyCode": "130709000",
						"countyName": "崇礼区",
						"id": 130709,
						"parentId": 1307
					},
					{
						"countyCode": "130722000",
						"countyName": "张北县",
						"id": 130722,
						"parentId": 1307
					},
					{
						"countyCode": "130723000",
						"countyName": "康保县",
						"id": 130723,
						"parentId": 1307
					},
					{
						"countyCode": "130724000",
						"countyName": "沽源县",
						"id": 130724,
						"parentId": 1307
					},
					{
						"countyCode": "130725000",
						"countyName": "尚义县",
						"id": 130725,
						"parentId": 1307
					},
					{
						"countyCode": "130726000",
						"countyName": "蔚县",
						"id": 130726,
						"parentId": 1307
					},
					{
						"countyCode": "130727000",
						"countyName": "阳原县",
						"id": 130727,
						"parentId": 1307
					},
					{
						"countyCode": "130728000",
						"countyName": "怀安县",
						"id": 130728,
						"parentId": 1307
					},
					{
						"countyCode": "130730000",
						"countyName": "怀来县",
						"id": 130730,
						"parentId": 1307
					},
					{
						"countyCode": "130731000",
						"countyName": "涿鹿县",
						"id": 130731,
						"parentId": 1307
					},
					{
						"countyCode": "130732000",
						"countyName": "赤城县",
						"id": 130732,
						"parentId": 1307
					}
				]
			},
			{
				"cityCode": "130800000",
				"cityName": "承德市",
				"id": 1308,
				"parentId": 13,
				"children": [{
						"countyCode": "130802000",
						"countyName": "双桥区",
						"id": 130802,
						"parentId": 1308
					},
					{
						"countyCode": "130803000",
						"countyName": "双滦区",
						"id": 130803,
						"parentId": 1308
					},
					{
						"countyCode": "130804000",
						"countyName": "鹰手营子矿区",
						"id": 130804,
						"parentId": 1308
					},
					{
						"countyCode": "130821000",
						"countyName": "承德县",
						"id": 130821,
						"parentId": 1308
					},
					{
						"countyCode": "130822000",
						"countyName": "兴隆县",
						"id": 130822,
						"parentId": 1308
					},
					{
						"countyCode": "130824000",
						"countyName": "滦平县",
						"id": 130824,
						"parentId": 1308
					},
					{
						"countyCode": "130825000",
						"countyName": "隆化县",
						"id": 130825,
						"parentId": 1308
					},
					{
						"countyCode": "130826000",
						"countyName": "丰宁满族自治县",
						"id": 130826,
						"parentId": 1308
					},
					{
						"countyCode": "130827000",
						"countyName": "宽城满族自治县",
						"id": 130827,
						"parentId": 1308
					},
					{
						"countyCode": "130828000",
						"countyName": "围场满族蒙古族自治县",
						"id": 130828,
						"parentId": 1308
					},
					{
						"countyCode": "130881000",
						"countyName": "平泉市",
						"id": 130881,
						"parentId": 1308
					}
				]
			},
			{
				"cityCode": "130900000",
				"cityName": "沧州市",
				"id": 1309,
				"parentId": 13,
				"children": [{
						"countyCode": "130902000",
						"countyName": "新华区",
						"id": 130902,
						"parentId": 1309
					},
					{
						"countyCode": "130903000",
						"countyName": "运河区",
						"id": 130903,
						"parentId": 1309
					},
					{
						"countyCode": "130921000",
						"countyName": "沧县",
						"id": 130921,
						"parentId": 1309
					},
					{
						"countyCode": "130922000",
						"countyName": "青县",
						"id": 130922,
						"parentId": 1309
					},
					{
						"countyCode": "130923000",
						"countyName": "东光县",
						"id": 130923,
						"parentId": 1309
					},
					{
						"countyCode": "130924000",
						"countyName": "海兴县",
						"id": 130924,
						"parentId": 1309
					},
					{
						"countyCode": "130925000",
						"countyName": "盐山县",
						"id": 130925,
						"parentId": 1309
					},
					{
						"countyCode": "130926000",
						"countyName": "肃宁县",
						"id": 130926,
						"parentId": 1309
					},
					{
						"countyCode": "130927000",
						"countyName": "南皮县",
						"id": 130927,
						"parentId": 1309
					},
					{
						"countyCode": "130928000",
						"countyName": "吴桥县",
						"id": 130928,
						"parentId": 1309
					},
					{
						"countyCode": "130929000",
						"countyName": "献县",
						"id": 130929,
						"parentId": 1309
					},
					{
						"countyCode": "130930000",
						"countyName": "孟村回族自治县",
						"id": 130930,
						"parentId": 1309
					},
					{
						"countyCode": "130981000",
						"countyName": "泊头市",
						"id": 130981,
						"parentId": 1309
					},
					{
						"countyCode": "130982000",
						"countyName": "任丘市",
						"id": 130982,
						"parentId": 1309
					},
					{
						"countyCode": "130983000",
						"countyName": "黄骅市",
						"id": 130983,
						"parentId": 1309
					},
					{
						"countyCode": "130984000",
						"countyName": "河间市",
						"id": 130984,
						"parentId": 1309
					}
				]
			},
			{
				"cityCode": "131000000",
				"cityName": "廊坊市",
				"id": 1310,
				"parentId": 13,
				"children": [{
						"countyCode": "131002000",
						"countyName": "安次区",
						"id": 131002,
						"parentId": 1310
					},
					{
						"countyCode": "131003000",
						"countyName": "广阳区",
						"id": 131003,
						"parentId": 1310
					},
					{
						"countyCode": "131022000",
						"countyName": "固安县",
						"id": 131022,
						"parentId": 1310
					},
					{
						"countyCode": "131023000",
						"countyName": "永清县",
						"id": 131023,
						"parentId": 1310
					},
					{
						"countyCode": "131024000",
						"countyName": "香河县",
						"id": 131024,
						"parentId": 1310
					},
					{
						"countyCode": "131025000",
						"countyName": "大城县",
						"id": 131025,
						"parentId": 1310
					},
					{
						"countyCode": "131026000",
						"countyName": "文安县",
						"id": 131026,
						"parentId": 1310
					},
					{
						"countyCode": "131028000",
						"countyName": "大厂回族自治县",
						"id": 131028,
						"parentId": 1310
					},
					{
						"countyCode": "131081000",
						"countyName": "霸州市",
						"id": 131081,
						"parentId": 1310
					},
					{
						"countyCode": "131082000",
						"countyName": "三河市",
						"id": 131082,
						"parentId": 1310
					}
				]
			},
			{
				"cityCode": "131100000",
				"cityName": "衡水市",
				"id": 1311,
				"parentId": 13,
				"children": [{
						"countyCode": "131102000",
						"countyName": "桃城区",
						"id": 131102,
						"parentId": 1311
					},
					{
						"countyCode": "131103000",
						"countyName": "冀州区",
						"id": 131103,
						"parentId": 1311
					},
					{
						"countyCode": "131121000",
						"countyName": "枣强县",
						"id": 131121,
						"parentId": 1311
					},
					{
						"countyCode": "131122000",
						"countyName": "武邑县",
						"id": 131122,
						"parentId": 1311
					},
					{
						"countyCode": "131123000",
						"countyName": "武强县",
						"id": 131123,
						"parentId": 1311
					},
					{
						"countyCode": "131124000",
						"countyName": "饶阳县",
						"id": 131124,
						"parentId": 1311
					},
					{
						"countyCode": "131125000",
						"countyName": "安平县",
						"id": 131125,
						"parentId": 1311
					},
					{
						"countyCode": "131126000",
						"countyName": "故城县",
						"id": 131126,
						"parentId": 1311
					},
					{
						"countyCode": "131127000",
						"countyName": "景县",
						"id": 131127,
						"parentId": 1311
					},
					{
						"countyCode": "131128000",
						"countyName": "阜城县",
						"id": 131128,
						"parentId": 1311
					},
					{
						"countyCode": "131182000",
						"countyName": "深州市",
						"id": 131182,
						"parentId": 1311
					}
				]
			}
		]
	},
	{
		"provinceCode": "140000000",
		"provinceName": "山西省",
		"id": 14,
		"parentId": 0,
		"children": [{
				"cityCode": "140100000",
				"cityName": "太原市",
				"id": 1401,
				"parentId": 14,
				"children": [{
						"countyCode": "140105000",
						"countyName": "小店区",
						"id": 140105,
						"parentId": 1401
					},
					{
						"countyCode": "140106000",
						"countyName": "迎泽区",
						"id": 140106,
						"parentId": 1401
					},
					{
						"countyCode": "140107000",
						"countyName": "杏花岭区",
						"id": 140107,
						"parentId": 1401
					},
					{
						"countyCode": "140108000",
						"countyName": "尖草坪区",
						"id": 140108,
						"parentId": 1401
					},
					{
						"countyCode": "140109000",
						"countyName": "万柏林区",
						"id": 140109,
						"parentId": 1401
					},
					{
						"countyCode": "140110000",
						"countyName": "晋源区",
						"id": 140110,
						"parentId": 1401
					},
					{
						"countyCode": "140121000",
						"countyName": "清徐县",
						"id": 140121,
						"parentId": 1401
					},
					{
						"countyCode": "140122000",
						"countyName": "阳曲县",
						"id": 140122,
						"parentId": 1401
					},
					{
						"countyCode": "140123000",
						"countyName": "娄烦县",
						"id": 140123,
						"parentId": 1401
					},
					{
						"countyCode": "140181000",
						"countyName": "古交市",
						"id": 140181,
						"parentId": 1401
					}
				]
			},
			{
				"cityCode": "140200000",
				"cityName": "大同市",
				"id": 1402,
				"parentId": 14,
				"children": [{
						"countyCode": "140212000",
						"countyName": "新荣区",
						"id": 140212,
						"parentId": 1402
					},
					{
						"countyCode": "140213000",
						"countyName": "平城区",
						"id": 140213,
						"parentId": 1402
					},
					{
						"countyCode": "140214000",
						"countyName": "云冈区",
						"id": 140214,
						"parentId": 1402
					},
					{
						"countyCode": "140215000",
						"countyName": "云州区",
						"id": 140215,
						"parentId": 1402
					},
					{
						"countyCode": "140221000",
						"countyName": "阳高县",
						"id": 140221,
						"parentId": 1402
					},
					{
						"countyCode": "140222000",
						"countyName": "天镇县",
						"id": 140222,
						"parentId": 1402
					},
					{
						"countyCode": "140223000",
						"countyName": "广灵县",
						"id": 140223,
						"parentId": 1402
					},
					{
						"countyCode": "140224000",
						"countyName": "灵丘县",
						"id": 140224,
						"parentId": 1402
					},
					{
						"countyCode": "140225000",
						"countyName": "浑源县",
						"id": 140225,
						"parentId": 1402
					},
					{
						"countyCode": "140226000",
						"countyName": "左云县",
						"id": 140226,
						"parentId": 1402
					}
				]
			},
			{
				"cityCode": "140300000",
				"cityName": "阳泉市",
				"id": 1403,
				"parentId": 14,
				"children": [{
						"countyCode": "140302000",
						"countyName": "城区",
						"id": 140302,
						"parentId": 1403
					},
					{
						"countyCode": "140303000",
						"countyName": "矿区",
						"id": 140303,
						"parentId": 1403
					},
					{
						"countyCode": "140311000",
						"countyName": "郊区",
						"id": 140311,
						"parentId": 1403
					},
					{
						"countyCode": "140321000",
						"countyName": "平定县",
						"id": 140321,
						"parentId": 1403
					},
					{
						"countyCode": "140322000",
						"countyName": "盂县",
						"id": 140322,
						"parentId": 1403
					}
				]
			},
			{
				"cityCode": "140400000",
				"cityName": "长治市",
				"id": 1404,
				"parentId": 14,
				"children": [{
						"countyCode": "140403000",
						"countyName": "潞州区",
						"id": 140403,
						"parentId": 1404
					},
					{
						"countyCode": "140404000",
						"countyName": "上党区",
						"id": 140404,
						"parentId": 1404
					},
					{
						"countyCode": "140405000",
						"countyName": "屯留区",
						"id": 140405,
						"parentId": 1404
					},
					{
						"countyCode": "140406000",
						"countyName": "潞城区",
						"id": 140406,
						"parentId": 1404
					},
					{
						"countyCode": "140423000",
						"countyName": "襄垣县",
						"id": 140423,
						"parentId": 1404
					},
					{
						"countyCode": "140425000",
						"countyName": "平顺县",
						"id": 140425,
						"parentId": 1404
					},
					{
						"countyCode": "140426000",
						"countyName": "黎城县",
						"id": 140426,
						"parentId": 1404
					},
					{
						"countyCode": "140427000",
						"countyName": "壶关县",
						"id": 140427,
						"parentId": 1404
					},
					{
						"countyCode": "140428000",
						"countyName": "长子县",
						"id": 140428,
						"parentId": 1404
					},
					{
						"countyCode": "140429000",
						"countyName": "武乡县",
						"id": 140429,
						"parentId": 1404
					},
					{
						"countyCode": "140430000",
						"countyName": "沁县",
						"id": 140430,
						"parentId": 1404
					},
					{
						"countyCode": "140431000",
						"countyName": "沁源县",
						"id": 140431,
						"parentId": 1404
					}
				]
			},
			{
				"cityCode": "140500000",
				"cityName": "晋城市",
				"id": 1405,
				"parentId": 14,
				"children": [{
						"countyCode": "140502000",
						"countyName": "城区",
						"id": 140502,
						"parentId": 1405
					},
					{
						"countyCode": "140521000",
						"countyName": "沁水县",
						"id": 140521,
						"parentId": 1405
					},
					{
						"countyCode": "140522000",
						"countyName": "阳城县",
						"id": 140522,
						"parentId": 1405
					},
					{
						"countyCode": "140524000",
						"countyName": "陵川县",
						"id": 140524,
						"parentId": 1405
					},
					{
						"countyCode": "140525000",
						"countyName": "泽州县",
						"id": 140525,
						"parentId": 1405
					},
					{
						"countyCode": "140581000",
						"countyName": "高平市",
						"id": 140581,
						"parentId": 1405
					}
				]
			},
			{
				"cityCode": "140600000",
				"cityName": "朔州市",
				"id": 1406,
				"parentId": 14,
				"children": [{
						"countyCode": "140602000",
						"countyName": "朔城区",
						"id": 140602,
						"parentId": 1406
					},
					{
						"countyCode": "140603000",
						"countyName": "平鲁区",
						"id": 140603,
						"parentId": 1406
					},
					{
						"countyCode": "140621000",
						"countyName": "山阴县",
						"id": 140621,
						"parentId": 1406
					},
					{
						"countyCode": "140622000",
						"countyName": "应县",
						"id": 140622,
						"parentId": 1406
					},
					{
						"countyCode": "140623000",
						"countyName": "右玉县",
						"id": 140623,
						"parentId": 1406
					},
					{
						"countyCode": "140681000",
						"countyName": "怀仁市",
						"id": 140681,
						"parentId": 1406
					}
				]
			},
			{
				"cityCode": "140700000",
				"cityName": "晋中市",
				"id": 1407,
				"parentId": 14,
				"children": [{
						"countyCode": "140702000",
						"countyName": "榆次区",
						"id": 140702,
						"parentId": 1407
					},
					{
						"countyCode": "140721000",
						"countyName": "榆社县",
						"id": 140721,
						"parentId": 1407
					},
					{
						"countyCode": "140722000",
						"countyName": "左权县",
						"id": 140722,
						"parentId": 1407
					},
					{
						"countyCode": "140723000",
						"countyName": "和顺县",
						"id": 140723,
						"parentId": 1407
					},
					{
						"countyCode": "140724000",
						"countyName": "昔阳县",
						"id": 140724,
						"parentId": 1407
					},
					{
						"countyCode": "140725000",
						"countyName": "寿阳县",
						"id": 140725,
						"parentId": 1407
					},
					{
						"countyCode": "140726000",
						"countyName": "太谷县",
						"id": 140726,
						"parentId": 1407
					},
					{
						"countyCode": "140727000",
						"countyName": "祁县",
						"id": 140727,
						"parentId": 1407
					},
					{
						"countyCode": "140728000",
						"countyName": "平遥县",
						"id": 140728,
						"parentId": 1407
					},
					{
						"countyCode": "140729000",
						"countyName": "灵石县",
						"id": 140729,
						"parentId": 1407
					},
					{
						"countyCode": "140781000",
						"countyName": "介休市",
						"id": 140781,
						"parentId": 1407
					}
				]
			},
			{
				"cityCode": "140800000",
				"cityName": "运城市",
				"id": 1408,
				"parentId": 14,
				"children": [{
						"countyCode": "140802000",
						"countyName": "盐湖区",
						"id": 140802,
						"parentId": 1408
					},
					{
						"countyCode": "140821000",
						"countyName": "临猗县",
						"id": 140821,
						"parentId": 1408
					},
					{
						"countyCode": "140822000",
						"countyName": "万荣县",
						"id": 140822,
						"parentId": 1408
					},
					{
						"countyCode": "140823000",
						"countyName": "闻喜县",
						"id": 140823,
						"parentId": 1408
					},
					{
						"countyCode": "140824000",
						"countyName": "稷山县",
						"id": 140824,
						"parentId": 1408
					},
					{
						"countyCode": "140825000",
						"countyName": "新绛县",
						"id": 140825,
						"parentId": 1408
					},
					{
						"countyCode": "140826000",
						"countyName": "绛县",
						"id": 140826,
						"parentId": 1408
					},
					{
						"countyCode": "140827000",
						"countyName": "垣曲县",
						"id": 140827,
						"parentId": 1408
					},
					{
						"countyCode": "140828000",
						"countyName": "夏县",
						"id": 140828,
						"parentId": 1408
					},
					{
						"countyCode": "140829000",
						"countyName": "平陆县",
						"id": 140829,
						"parentId": 1408
					},
					{
						"countyCode": "140830000",
						"countyName": "芮城县",
						"id": 140830,
						"parentId": 1408
					},
					{
						"countyCode": "140881000",
						"countyName": "永济市",
						"id": 140881,
						"parentId": 1408
					},
					{
						"countyCode": "140882000",
						"countyName": "河津市",
						"id": 140882,
						"parentId": 1408
					}
				]
			},
			{
				"cityCode": "140900000",
				"cityName": "忻州市",
				"id": 1409,
				"parentId": 14,
				"children": [{
						"countyCode": "140902000",
						"countyName": "忻府区",
						"id": 140902,
						"parentId": 1409
					},
					{
						"countyCode": "140921000",
						"countyName": "定襄县",
						"id": 140921,
						"parentId": 1409
					},
					{
						"countyCode": "140922000",
						"countyName": "五台县",
						"id": 140922,
						"parentId": 1409
					},
					{
						"countyCode": "140923000",
						"countyName": "代县",
						"id": 140923,
						"parentId": 1409
					},
					{
						"countyCode": "140924000",
						"countyName": "繁峙县",
						"id": 140924,
						"parentId": 1409
					},
					{
						"countyCode": "140925000",
						"countyName": "宁武县",
						"id": 140925,
						"parentId": 1409
					},
					{
						"countyCode": "140926000",
						"countyName": "静乐县",
						"id": 140926,
						"parentId": 1409
					},
					{
						"countyCode": "140927000",
						"countyName": "神池县",
						"id": 140927,
						"parentId": 1409
					},
					{
						"countyCode": "140928000",
						"countyName": "五寨县",
						"id": 140928,
						"parentId": 1409
					},
					{
						"countyCode": "140929000",
						"countyName": "岢岚县",
						"id": 140929,
						"parentId": 1409
					},
					{
						"countyCode": "140930000",
						"countyName": "河曲县",
						"id": 140930,
						"parentId": 1409
					},
					{
						"countyCode": "140931000",
						"countyName": "保德县",
						"id": 140931,
						"parentId": 1409
					},
					{
						"countyCode": "140932000",
						"countyName": "偏关县",
						"id": 140932,
						"parentId": 1409
					},
					{
						"countyCode": "140981000",
						"countyName": "原平市",
						"id": 140981,
						"parentId": 1409
					}
				]
			},
			{
				"cityCode": "141000000",
				"cityName": "临汾市",
				"id": 1410,
				"parentId": 14,
				"children": [{
						"countyCode": "141002000",
						"countyName": "尧都区",
						"id": 141002,
						"parentId": 1410
					},
					{
						"countyCode": "141021000",
						"countyName": "曲沃县",
						"id": 141021,
						"parentId": 1410
					},
					{
						"countyCode": "141022000",
						"countyName": "翼城县",
						"id": 141022,
						"parentId": 1410
					},
					{
						"countyCode": "141023000",
						"countyName": "襄汾县",
						"id": 141023,
						"parentId": 1410
					},
					{
						"countyCode": "141024000",
						"countyName": "洪洞县",
						"id": 141024,
						"parentId": 1410
					},
					{
						"countyCode": "141025000",
						"countyName": "古县",
						"id": 141025,
						"parentId": 1410
					},
					{
						"countyCode": "141026000",
						"countyName": "安泽县",
						"id": 141026,
						"parentId": 1410
					},
					{
						"countyCode": "141027000",
						"countyName": "浮山县",
						"id": 141027,
						"parentId": 1410
					},
					{
						"countyCode": "141028000",
						"countyName": "吉县",
						"id": 141028,
						"parentId": 1410
					},
					{
						"countyCode": "141029000",
						"countyName": "乡宁县",
						"id": 141029,
						"parentId": 1410
					},
					{
						"countyCode": "141030000",
						"countyName": "大宁县",
						"id": 141030,
						"parentId": 1410
					},
					{
						"countyCode": "141031000",
						"countyName": "隰县",
						"id": 141031,
						"parentId": 1410
					},
					{
						"countyCode": "141032000",
						"countyName": "永和县",
						"id": 141032,
						"parentId": 1410
					},
					{
						"countyCode": "141033000",
						"countyName": "蒲县",
						"id": 141033,
						"parentId": 1410
					},
					{
						"countyCode": "141034000",
						"countyName": "汾西县",
						"id": 141034,
						"parentId": 1410
					},
					{
						"countyCode": "141081000",
						"countyName": "侯马市",
						"id": 141081,
						"parentId": 1410
					},
					{
						"countyCode": "141082000",
						"countyName": "霍州市",
						"id": 141082,
						"parentId": 1410
					}
				]
			},
			{
				"cityCode": "141100000",
				"cityName": "吕梁市",
				"id": 1411,
				"parentId": 14,
				"children": [{
						"countyCode": "141102000",
						"countyName": "离石区",
						"id": 141102,
						"parentId": 1411
					},
					{
						"countyCode": "141121000",
						"countyName": "文水县",
						"id": 141121,
						"parentId": 1411
					},
					{
						"countyCode": "141122000",
						"countyName": "交城县",
						"id": 141122,
						"parentId": 1411
					},
					{
						"countyCode": "141123000",
						"countyName": "兴县",
						"id": 141123,
						"parentId": 1411
					},
					{
						"countyCode": "141124000",
						"countyName": "临县",
						"id": 141124,
						"parentId": 1411
					},
					{
						"countyCode": "141125000",
						"countyName": "柳林县",
						"id": 141125,
						"parentId": 1411
					},
					{
						"countyCode": "141126000",
						"countyName": "石楼县",
						"id": 141126,
						"parentId": 1411
					},
					{
						"countyCode": "141127000",
						"countyName": "岚县",
						"id": 141127,
						"parentId": 1411
					},
					{
						"countyCode": "141128000",
						"countyName": "方山县",
						"id": 141128,
						"parentId": 1411
					},
					{
						"countyCode": "141129000",
						"countyName": "中阳县",
						"id": 141129,
						"parentId": 1411
					},
					{
						"countyCode": "141130000",
						"countyName": "交口县",
						"id": 141130,
						"parentId": 1411
					},
					{
						"countyCode": "141181000",
						"countyName": "孝义市",
						"id": 141181,
						"parentId": 1411
					},
					{
						"countyCode": "141182000",
						"countyName": "汾阳市",
						"id": 141182,
						"parentId": 1411
					}
				]
			}
		]
	},
	{
		"provinceCode": "150000000",
		"provinceName": "内蒙古自治区",
		"id": 15,
		"parentId": 0,
		"children": [{
				"cityCode": "150100000",
				"cityName": "呼和浩特市",
				"id": 1501,
				"parentId": 15,
				"children": [{
						"countyCode": "150102000",
						"countyName": "新城区",
						"id": 150102,
						"parentId": 1501
					},
					{
						"countyCode": "150103000",
						"countyName": "回民区",
						"id": 150103,
						"parentId": 1501
					},
					{
						"countyCode": "150104000",
						"countyName": "玉泉区",
						"id": 150104,
						"parentId": 1501
					},
					{
						"countyCode": "150105000",
						"countyName": "赛罕区",
						"id": 150105,
						"parentId": 1501
					},
					{
						"countyCode": "150121000",
						"countyName": "土默特左旗",
						"id": 150121,
						"parentId": 1501
					},
					{
						"countyCode": "150122000",
						"countyName": "托克托县",
						"id": 150122,
						"parentId": 1501
					},
					{
						"countyCode": "150123000",
						"countyName": "和林格尔县",
						"id": 150123,
						"parentId": 1501
					},
					{
						"countyCode": "150124000",
						"countyName": "清水河县",
						"id": 150124,
						"parentId": 1501
					},
					{
						"countyCode": "150125000",
						"countyName": "武川县",
						"id": 150125,
						"parentId": 1501
					}
				]
			},
			{
				"cityCode": "150200000",
				"cityName": "包头市",
				"id": 1502,
				"parentId": 15,
				"children": [{
						"countyCode": "150202000",
						"countyName": "东河区",
						"id": 150202,
						"parentId": 1502
					},
					{
						"countyCode": "150203000",
						"countyName": "昆都仑区",
						"id": 150203,
						"parentId": 1502
					},
					{
						"countyCode": "150204000",
						"countyName": "青山区",
						"id": 150204,
						"parentId": 1502
					},
					{
						"countyCode": "150205000",
						"countyName": "石拐区",
						"id": 150205,
						"parentId": 1502
					},
					{
						"countyCode": "150206000",
						"countyName": "白云鄂博矿区",
						"id": 150206,
						"parentId": 1502
					},
					{
						"countyCode": "150207000",
						"countyName": "九原区",
						"id": 150207,
						"parentId": 1502
					},
					{
						"countyCode": "150221000",
						"countyName": "土默特右旗",
						"id": 150221,
						"parentId": 1502
					},
					{
						"countyCode": "150222000",
						"countyName": "固阳县",
						"id": 150222,
						"parentId": 1502
					},
					{
						"countyCode": "150223000",
						"countyName": "达尔罕茂明安联合旗",
						"id": 150223,
						"parentId": 1502
					}
				]
			},
			{
				"cityCode": "150300000",
				"cityName": "乌海市",
				"id": 1503,
				"parentId": 15,
				"children": [{
						"countyCode": "150302000",
						"countyName": "海勃湾区",
						"id": 150302,
						"parentId": 1503
					},
					{
						"countyCode": "150303000",
						"countyName": "海南区",
						"id": 150303,
						"parentId": 1503
					},
					{
						"countyCode": "150304000",
						"countyName": "乌达区",
						"id": 150304,
						"parentId": 1503
					}
				]
			},
			{
				"cityCode": "150400000",
				"cityName": "赤峰市",
				"id": 1504,
				"parentId": 15,
				"children": [{
						"countyCode": "150402000",
						"countyName": "红山区",
						"id": 150402,
						"parentId": 1504
					},
					{
						"countyCode": "150403000",
						"countyName": "元宝山区",
						"id": 150403,
						"parentId": 1504
					},
					{
						"countyCode": "150404000",
						"countyName": "松山区",
						"id": 150404,
						"parentId": 1504
					},
					{
						"countyCode": "150421000",
						"countyName": "阿鲁科尔沁旗",
						"id": 150421,
						"parentId": 1504
					},
					{
						"countyCode": "150422000",
						"countyName": "巴林左旗",
						"id": 150422,
						"parentId": 1504
					},
					{
						"countyCode": "150423000",
						"countyName": "巴林右旗",
						"id": 150423,
						"parentId": 1504
					},
					{
						"countyCode": "150424000",
						"countyName": "林西县",
						"id": 150424,
						"parentId": 1504
					},
					{
						"countyCode": "150425000",
						"countyName": "克什克腾旗",
						"id": 150425,
						"parentId": 1504
					},
					{
						"countyCode": "150426000",
						"countyName": "翁牛特旗",
						"id": 150426,
						"parentId": 1504
					},
					{
						"countyCode": "150428000",
						"countyName": "喀喇沁旗",
						"id": 150428,
						"parentId": 1504
					},
					{
						"countyCode": "150429000",
						"countyName": "宁城县",
						"id": 150429,
						"parentId": 1504
					},
					{
						"countyCode": "150430000",
						"countyName": "敖汉旗",
						"id": 150430,
						"parentId": 1504
					}
				]
			},
			{
				"cityCode": "150500000",
				"cityName": "通辽市",
				"id": 1505,
				"parentId": 15,
				"children": [{
						"countyCode": "150502000",
						"countyName": "科尔沁区",
						"id": 150502,
						"parentId": 1505
					},
					{
						"countyCode": "150521000",
						"countyName": "科尔沁左翼中旗",
						"id": 150521,
						"parentId": 1505
					},
					{
						"countyCode": "150522000",
						"countyName": "科尔沁左翼后旗",
						"id": 150522,
						"parentId": 1505
					},
					{
						"countyCode": "150523000",
						"countyName": "开鲁县",
						"id": 150523,
						"parentId": 1505
					},
					{
						"countyCode": "150524000",
						"countyName": "库伦旗",
						"id": 150524,
						"parentId": 1505
					},
					{
						"countyCode": "150525000",
						"countyName": "奈曼旗",
						"id": 150525,
						"parentId": 1505
					},
					{
						"countyCode": "150526000",
						"countyName": "扎鲁特旗",
						"id": 150526,
						"parentId": 1505
					},
					{
						"countyCode": "150581000",
						"countyName": "霍林郭勒市",
						"id": 150581,
						"parentId": 1505
					}
				]
			},
			{
				"cityCode": "150600000",
				"cityName": "鄂尔多斯市",
				"id": 1506,
				"parentId": 15,
				"children": [{
						"countyCode": "150602000",
						"countyName": "东胜区",
						"id": 150602,
						"parentId": 1506
					},
					{
						"countyCode": "150603000",
						"countyName": "康巴什区",
						"id": 150603,
						"parentId": 1506
					},
					{
						"countyCode": "150621000",
						"countyName": "达拉特旗",
						"id": 150621,
						"parentId": 1506
					},
					{
						"countyCode": "150622000",
						"countyName": "准格尔旗",
						"id": 150622,
						"parentId": 1506
					},
					{
						"countyCode": "150623000",
						"countyName": "鄂托克前旗",
						"id": 150623,
						"parentId": 1506
					},
					{
						"countyCode": "150624000",
						"countyName": "鄂托克旗",
						"id": 150624,
						"parentId": 1506
					},
					{
						"countyCode": "150625000",
						"countyName": "杭锦旗",
						"id": 150625,
						"parentId": 1506
					},
					{
						"countyCode": "150626000",
						"countyName": "乌审旗",
						"id": 150626,
						"parentId": 1506
					},
					{
						"countyCode": "150627000",
						"countyName": "伊金霍洛旗",
						"id": 150627,
						"parentId": 1506
					}
				]
			},
			{
				"cityCode": "150700000",
				"cityName": "呼伦贝尔市",
				"id": 1507,
				"parentId": 15,
				"children": [{
						"countyCode": "150702000",
						"countyName": "海拉尔区",
						"id": 150702,
						"parentId": 1507
					},
					{
						"countyCode": "150703000",
						"countyName": "扎赉诺尔区",
						"id": 150703,
						"parentId": 1507
					},
					{
						"countyCode": "150721000",
						"countyName": "阿荣旗",
						"id": 150721,
						"parentId": 1507
					},
					{
						"countyCode": "150722000",
						"countyName": "莫力达瓦达斡尔族自治旗",
						"id": 150722,
						"parentId": 1507
					},
					{
						"countyCode": "150723000",
						"countyName": "鄂伦春自治旗",
						"id": 150723,
						"parentId": 1507
					},
					{
						"countyCode": "150724000",
						"countyName": "鄂温克族自治旗",
						"id": 150724,
						"parentId": 1507
					},
					{
						"countyCode": "150725000",
						"countyName": "陈巴尔虎旗",
						"id": 150725,
						"parentId": 1507
					},
					{
						"countyCode": "150726000",
						"countyName": "新巴尔虎左旗",
						"id": 150726,
						"parentId": 1507
					},
					{
						"countyCode": "150727000",
						"countyName": "新巴尔虎右旗",
						"id": 150727,
						"parentId": 1507
					},
					{
						"countyCode": "150781000",
						"countyName": "满洲里市",
						"id": 150781,
						"parentId": 1507
					},
					{
						"countyCode": "150782000",
						"countyName": "牙克石市",
						"id": 150782,
						"parentId": 1507
					},
					{
						"countyCode": "150783000",
						"countyName": "扎兰屯市",
						"id": 150783,
						"parentId": 1507
					},
					{
						"countyCode": "150784000",
						"countyName": "额尔古纳市",
						"id": 150784,
						"parentId": 1507
					},
					{
						"countyCode": "150785000",
						"countyName": "根河市",
						"id": 150785,
						"parentId": 1507
					}
				]
			},
			{
				"cityCode": "150800000",
				"cityName": "巴彦淖尔市",
				"id": 1508,
				"parentId": 15,
				"children": [{
						"countyCode": "150802000",
						"countyName": "临河区",
						"id": 150802,
						"parentId": 1508
					},
					{
						"countyCode": "150821000",
						"countyName": "五原县",
						"id": 150821,
						"parentId": 1508
					},
					{
						"countyCode": "150822000",
						"countyName": "磴口县",
						"id": 150822,
						"parentId": 1508
					},
					{
						"countyCode": "150823000",
						"countyName": "乌拉特前旗",
						"id": 150823,
						"parentId": 1508
					},
					{
						"countyCode": "150824000",
						"countyName": "乌拉特中旗",
						"id": 150824,
						"parentId": 1508
					},
					{
						"countyCode": "150825000",
						"countyName": "乌拉特后旗",
						"id": 150825,
						"parentId": 1508
					},
					{
						"countyCode": "150826000",
						"countyName": "杭锦后旗",
						"id": 150826,
						"parentId": 1508
					}
				]
			},
			{
				"cityCode": "150900000",
				"cityName": "乌兰察布市",
				"id": 1509,
				"parentId": 15,
				"children": [{
						"countyCode": "150902000",
						"countyName": "集宁区",
						"id": 150902,
						"parentId": 1509
					},
					{
						"countyCode": "150921000",
						"countyName": "卓资县",
						"id": 150921,
						"parentId": 1509
					},
					{
						"countyCode": "150922000",
						"countyName": "化德县",
						"id": 150922,
						"parentId": 1509
					},
					{
						"countyCode": "150923000",
						"countyName": "商都县",
						"id": 150923,
						"parentId": 1509
					},
					{
						"countyCode": "150924000",
						"countyName": "兴和县",
						"id": 150924,
						"parentId": 1509
					},
					{
						"countyCode": "150925000",
						"countyName": "凉城县",
						"id": 150925,
						"parentId": 1509
					},
					{
						"countyCode": "150926000",
						"countyName": "察哈尔右翼前旗",
						"id": 150926,
						"parentId": 1509
					},
					{
						"countyCode": "150927000",
						"countyName": "察哈尔右翼中旗",
						"id": 150927,
						"parentId": 1509
					},
					{
						"countyCode": "150928000",
						"countyName": "察哈尔右翼后旗",
						"id": 150928,
						"parentId": 1509
					},
					{
						"countyCode": "150929000",
						"countyName": "四子王旗",
						"id": 150929,
						"parentId": 1509
					},
					{
						"countyCode": "150981000",
						"countyName": "丰镇市",
						"id": 150981,
						"parentId": 1509
					}
				]
			},
			{
				"cityCode": "152200000",
				"cityName": "兴安盟",
				"id": 1522,
				"parentId": 15,
				"children": [{
						"countyCode": "152201000",
						"countyName": "乌兰浩特市",
						"id": 152201,
						"parentId": 1522
					},
					{
						"countyCode": "152202000",
						"countyName": "阿尔山市",
						"id": 152202,
						"parentId": 1522
					},
					{
						"countyCode": "152221000",
						"countyName": "科尔沁右翼前旗",
						"id": 152221,
						"parentId": 1522
					},
					{
						"countyCode": "152222000",
						"countyName": "科尔沁右翼中旗",
						"id": 152222,
						"parentId": 1522
					},
					{
						"countyCode": "152223000",
						"countyName": "扎赉特旗",
						"id": 152223,
						"parentId": 1522
					},
					{
						"countyCode": "152224000",
						"countyName": "突泉县",
						"id": 152224,
						"parentId": 1522
					}
				]
			},
			{
				"cityCode": "152500000",
				"cityName": "锡林郭勒盟",
				"id": 1525,
				"parentId": 15,
				"children": [{
						"countyCode": "152501000",
						"countyName": "二连浩特市",
						"id": 152501,
						"parentId": 1525
					},
					{
						"countyCode": "152502000",
						"countyName": "锡林浩特市",
						"id": 152502,
						"parentId": 1525
					},
					{
						"countyCode": "152522000",
						"countyName": "阿巴嘎旗",
						"id": 152522,
						"parentId": 1525
					},
					{
						"countyCode": "152523000",
						"countyName": "苏尼特左旗",
						"id": 152523,
						"parentId": 1525
					},
					{
						"countyCode": "152524000",
						"countyName": "苏尼特右旗",
						"id": 152524,
						"parentId": 1525
					},
					{
						"countyCode": "152525000",
						"countyName": "东乌珠穆沁旗",
						"id": 152525,
						"parentId": 1525
					},
					{
						"countyCode": "152526000",
						"countyName": "西乌珠穆沁旗",
						"id": 152526,
						"parentId": 1525
					},
					{
						"countyCode": "152527000",
						"countyName": "太仆寺旗",
						"id": 152527,
						"parentId": 1525
					},
					{
						"countyCode": "152528000",
						"countyName": "镶黄旗",
						"id": 152528,
						"parentId": 1525
					},
					{
						"countyCode": "152529000",
						"countyName": "正镶白旗",
						"id": 152529,
						"parentId": 1525
					},
					{
						"countyCode": "152530000",
						"countyName": "正蓝旗",
						"id": 152530,
						"parentId": 1525
					},
					{
						"countyCode": "152531000",
						"countyName": "多伦县",
						"id": 152531,
						"parentId": 1525
					}
				]
			},
			{
				"cityCode": "152900000",
				"cityName": "阿拉善盟",
				"id": 1529,
				"parentId": 15,
				"children": [{
						"countyCode": "152921000",
						"countyName": "阿拉善左旗",
						"id": 152921,
						"parentId": 1529
					},
					{
						"countyCode": "152922000",
						"countyName": "阿拉善右旗",
						"id": 152922,
						"parentId": 1529
					},
					{
						"countyCode": "152923000",
						"countyName": "额济纳旗",
						"id": 152923,
						"parentId": 1529
					}
				]
			}
		]
	},
	{
		"provinceCode": "210000000",
		"provinceName": "辽宁省",
		"id": 21,
		"parentId": 0,
		"children": [{
				"cityCode": "210100000",
				"cityName": "沈阳市",
				"id": 2101,
				"parentId": 21,
				"children": [{
						"countyCode": "210102000",
						"countyName": "和平区",
						"id": 210102,
						"parentId": 2101
					},
					{
						"countyCode": "210103000",
						"countyName": "沈河区",
						"id": 210103,
						"parentId": 2101
					},
					{
						"countyCode": "210104000",
						"countyName": "大东区",
						"id": 210104,
						"parentId": 2101
					},
					{
						"countyCode": "210105000",
						"countyName": "皇姑区",
						"id": 210105,
						"parentId": 2101
					},
					{
						"countyCode": "210106000",
						"countyName": "铁西区",
						"id": 210106,
						"parentId": 2101
					},
					{
						"countyCode": "210111000",
						"countyName": "苏家屯区",
						"id": 210111,
						"parentId": 2101
					},
					{
						"countyCode": "210112000",
						"countyName": "浑南区",
						"id": 210112,
						"parentId": 2101
					},
					{
						"countyCode": "210113000",
						"countyName": "沈北新区",
						"id": 210113,
						"parentId": 2101
					},
					{
						"countyCode": "210114000",
						"countyName": "于洪区",
						"id": 210114,
						"parentId": 2101
					},
					{
						"countyCode": "210115000",
						"countyName": "辽中区",
						"id": 210115,
						"parentId": 2101
					},
					{
						"countyCode": "210123000",
						"countyName": "康平县",
						"id": 210123,
						"parentId": 2101
					},
					{
						"countyCode": "210124000",
						"countyName": "法库县",
						"id": 210124,
						"parentId": 2101
					},
					{
						"countyCode": "210181000",
						"countyName": "新民市",
						"id": 210181,
						"parentId": 2101
					}
				]
			},
			{
				"cityCode": "210200000",
				"cityName": "大连市",
				"id": 2102,
				"parentId": 21,
				"children": [{
						"countyCode": "210202000",
						"countyName": "中山区",
						"id": 210202,
						"parentId": 2102
					},
					{
						"countyCode": "210203000",
						"countyName": "西岗区",
						"id": 210203,
						"parentId": 2102
					},
					{
						"countyCode": "210204000",
						"countyName": "沙河口区",
						"id": 210204,
						"parentId": 2102
					},
					{
						"countyCode": "210211000",
						"countyName": "甘井子区",
						"id": 210211,
						"parentId": 2102
					},
					{
						"countyCode": "210212000",
						"countyName": "旅顺口区",
						"id": 210212,
						"parentId": 2102
					},
					{
						"countyCode": "210213000",
						"countyName": "金州区",
						"id": 210213,
						"parentId": 2102
					},
					{
						"countyCode": "210214000",
						"countyName": "普兰店区",
						"id": 210214,
						"parentId": 2102
					},
					{
						"countyCode": "210224000",
						"countyName": "长海县",
						"id": 210224,
						"parentId": 2102
					},
					{
						"countyCode": "210281000",
						"countyName": "瓦房店市",
						"id": 210281,
						"parentId": 2102
					},
					{
						"countyCode": "210283000",
						"countyName": "庄河市",
						"id": 210283,
						"parentId": 2102
					}
				]
			},
			{
				"cityCode": "210300000",
				"cityName": "鞍山市",
				"id": 2103,
				"parentId": 21,
				"children": [{
						"countyCode": "210302000",
						"countyName": "铁东区",
						"id": 210302,
						"parentId": 2103
					},
					{
						"countyCode": "210303000",
						"countyName": "铁西区",
						"id": 210303,
						"parentId": 2103
					},
					{
						"countyCode": "210304000",
						"countyName": "立山区",
						"id": 210304,
						"parentId": 2103
					},
					{
						"countyCode": "210311000",
						"countyName": "千山区",
						"id": 210311,
						"parentId": 2103
					},
					{
						"countyCode": "210321000",
						"countyName": "台安县",
						"id": 210321,
						"parentId": 2103
					},
					{
						"countyCode": "210323000",
						"countyName": "岫岩满族自治县",
						"id": 210323,
						"parentId": 2103
					},
					{
						"countyCode": "210381000",
						"countyName": "海城市",
						"id": 210381,
						"parentId": 2103
					}
				]
			},
			{
				"cityCode": "210400000",
				"cityName": "抚顺市",
				"id": 2104,
				"parentId": 21,
				"children": [{
						"countyCode": "210402000",
						"countyName": "新抚区",
						"id": 210402,
						"parentId": 2104
					},
					{
						"countyCode": "210403000",
						"countyName": "东洲区",
						"id": 210403,
						"parentId": 2104
					},
					{
						"countyCode": "210404000",
						"countyName": "望花区",
						"id": 210404,
						"parentId": 2104
					},
					{
						"countyCode": "210411000",
						"countyName": "顺城区",
						"id": 210411,
						"parentId": 2104
					},
					{
						"countyCode": "210421000",
						"countyName": "抚顺县",
						"id": 210421,
						"parentId": 2104
					},
					{
						"countyCode": "210422000",
						"countyName": "新宾满族自治县",
						"id": 210422,
						"parentId": 2104
					},
					{
						"countyCode": "210423000",
						"countyName": "清原满族自治县",
						"id": 210423,
						"parentId": 2104
					}
				]
			},
			{
				"cityCode": "210500000",
				"cityName": "本溪市",
				"id": 2105,
				"parentId": 21,
				"children": [{
						"countyCode": "210502000",
						"countyName": "平山区",
						"id": 210502,
						"parentId": 2105
					},
					{
						"countyCode": "210503000",
						"countyName": "溪湖区",
						"id": 210503,
						"parentId": 2105
					},
					{
						"countyCode": "210504000",
						"countyName": "明山区",
						"id": 210504,
						"parentId": 2105
					},
					{
						"countyCode": "210505000",
						"countyName": "南芬区",
						"id": 210505,
						"parentId": 2105
					},
					{
						"countyCode": "210521000",
						"countyName": "本溪满族自治县",
						"id": 210521,
						"parentId": 2105
					},
					{
						"countyCode": "210522000",
						"countyName": "桓仁满族自治县",
						"id": 210522,
						"parentId": 2105
					}
				]
			},
			{
				"cityCode": "210600000",
				"cityName": "丹东市",
				"id": 2106,
				"parentId": 21,
				"children": [{
						"countyCode": "210602000",
						"countyName": "元宝区",
						"id": 210602,
						"parentId": 2106
					},
					{
						"countyCode": "210603000",
						"countyName": "振兴区",
						"id": 210603,
						"parentId": 2106
					},
					{
						"countyCode": "210604000",
						"countyName": "振安区",
						"id": 210604,
						"parentId": 2106
					},
					{
						"countyCode": "210624000",
						"countyName": "宽甸满族自治县",
						"id": 210624,
						"parentId": 2106
					},
					{
						"countyCode": "210681000",
						"countyName": "东港市",
						"id": 210681,
						"parentId": 2106
					},
					{
						"countyCode": "210682000",
						"countyName": "凤城市",
						"id": 210682,
						"parentId": 2106
					}
				]
			},
			{
				"cityCode": "210700000",
				"cityName": "锦州市",
				"id": 2107,
				"parentId": 21,
				"children": [{
						"countyCode": "210702000",
						"countyName": "古塔区",
						"id": 210702,
						"parentId": 2107
					},
					{
						"countyCode": "210703000",
						"countyName": "凌河区",
						"id": 210703,
						"parentId": 2107
					},
					{
						"countyCode": "210711000",
						"countyName": "太和区",
						"id": 210711,
						"parentId": 2107
					},
					{
						"countyCode": "210726000",
						"countyName": "黑山县",
						"id": 210726,
						"parentId": 2107
					},
					{
						"countyCode": "210727000",
						"countyName": "义县",
						"id": 210727,
						"parentId": 2107
					},
					{
						"countyCode": "210781000",
						"countyName": "凌海市",
						"id": 210781,
						"parentId": 2107
					},
					{
						"countyCode": "210782000",
						"countyName": "北镇市",
						"id": 210782,
						"parentId": 2107
					}
				]
			},
			{
				"cityCode": "210800000",
				"cityName": "营口市",
				"id": 2108,
				"parentId": 21,
				"children": [{
						"countyCode": "210802000",
						"countyName": "站前区",
						"id": 210802,
						"parentId": 2108
					},
					{
						"countyCode": "210803000",
						"countyName": "西市区",
						"id": 210803,
						"parentId": 2108
					},
					{
						"countyCode": "210804000",
						"countyName": "鲅鱼圈区",
						"id": 210804,
						"parentId": 2108
					},
					{
						"countyCode": "210811000",
						"countyName": "老边区",
						"id": 210811,
						"parentId": 2108
					},
					{
						"countyCode": "210881000",
						"countyName": "盖州市",
						"id": 210881,
						"parentId": 2108
					},
					{
						"countyCode": "210882000",
						"countyName": "大石桥市",
						"id": 210882,
						"parentId": 2108
					}
				]
			},
			{
				"cityCode": "210900000",
				"cityName": "阜新市",
				"id": 2109,
				"parentId": 21,
				"children": [{
						"countyCode": "210902000",
						"countyName": "海州区",
						"id": 210902,
						"parentId": 2109
					},
					{
						"countyCode": "210903000",
						"countyName": "新邱区",
						"id": 210903,
						"parentId": 2109
					},
					{
						"countyCode": "210904000",
						"countyName": "太平区",
						"id": 210904,
						"parentId": 2109
					},
					{
						"countyCode": "210905000",
						"countyName": "清河门区",
						"id": 210905,
						"parentId": 2109
					},
					{
						"countyCode": "210911000",
						"countyName": "细河区",
						"id": 210911,
						"parentId": 2109
					},
					{
						"countyCode": "210921000",
						"countyName": "阜新蒙古族自治县",
						"id": 210921,
						"parentId": 2109
					},
					{
						"countyCode": "210922000",
						"countyName": "彰武县",
						"id": 210922,
						"parentId": 2109
					}
				]
			},
			{
				"cityCode": "211000000",
				"cityName": "辽阳市",
				"id": 2110,
				"parentId": 21,
				"children": [{
						"countyCode": "211002000",
						"countyName": "白塔区",
						"id": 211002,
						"parentId": 2110
					},
					{
						"countyCode": "211003000",
						"countyName": "文圣区",
						"id": 211003,
						"parentId": 2110
					},
					{
						"countyCode": "211004000",
						"countyName": "宏伟区",
						"id": 211004,
						"parentId": 2110
					},
					{
						"countyCode": "211005000",
						"countyName": "弓长岭区",
						"id": 211005,
						"parentId": 2110
					},
					{
						"countyCode": "211011000",
						"countyName": "太子河区",
						"id": 211011,
						"parentId": 2110
					},
					{
						"countyCode": "211021000",
						"countyName": "辽阳县",
						"id": 211021,
						"parentId": 2110
					},
					{
						"countyCode": "211081000",
						"countyName": "灯塔市",
						"id": 211081,
						"parentId": 2110
					}
				]
			},
			{
				"cityCode": "211100000",
				"cityName": "盘锦市",
				"id": 2111,
				"parentId": 21,
				"children": [{
						"countyCode": "211102000",
						"countyName": "双台子区",
						"id": 211102,
						"parentId": 2111
					},
					{
						"countyCode": "211103000",
						"countyName": "兴隆台区",
						"id": 211103,
						"parentId": 2111
					},
					{
						"countyCode": "211104000",
						"countyName": "大洼区",
						"id": 211104,
						"parentId": 2111
					},
					{
						"countyCode": "211122000",
						"countyName": "盘山县",
						"id": 211122,
						"parentId": 2111
					}
				]
			},
			{
				"cityCode": "211200000",
				"cityName": "铁岭市",
				"id": 2112,
				"parentId": 21,
				"children": [{
						"countyCode": "211202000",
						"countyName": "银州区",
						"id": 211202,
						"parentId": 2112
					},
					{
						"countyCode": "211204000",
						"countyName": "清河区",
						"id": 211204,
						"parentId": 2112
					},
					{
						"countyCode": "211221000",
						"countyName": "铁岭县",
						"id": 211221,
						"parentId": 2112
					},
					{
						"countyCode": "211223000",
						"countyName": "西丰县",
						"id": 211223,
						"parentId": 2112
					},
					{
						"countyCode": "211224000",
						"countyName": "昌图县",
						"id": 211224,
						"parentId": 2112
					},
					{
						"countyCode": "211281000",
						"countyName": "调兵山市",
						"id": 211281,
						"parentId": 2112
					},
					{
						"countyCode": "211282000",
						"countyName": "开原市",
						"id": 211282,
						"parentId": 2112
					}
				]
			},
			{
				"cityCode": "211300000",
				"cityName": "朝阳市",
				"id": 2113,
				"parentId": 21,
				"children": [{
						"countyCode": "211302000",
						"countyName": "双塔区",
						"id": 211302,
						"parentId": 2113
					},
					{
						"countyCode": "211303000",
						"countyName": "龙城区",
						"id": 211303,
						"parentId": 2113
					},
					{
						"countyCode": "211321000",
						"countyName": "朝阳县",
						"id": 211321,
						"parentId": 2113
					},
					{
						"countyCode": "211322000",
						"countyName": "建平县",
						"id": 211322,
						"parentId": 2113
					},
					{
						"countyCode": "211324000",
						"countyName": "喀喇沁左翼蒙古族自治县",
						"id": 211324,
						"parentId": 2113
					},
					{
						"countyCode": "211381000",
						"countyName": "北票市",
						"id": 211381,
						"parentId": 2113
					},
					{
						"countyCode": "211382000",
						"countyName": "凌源市",
						"id": 211382,
						"parentId": 2113
					}
				]
			},
			{
				"cityCode": "211400000",
				"cityName": "葫芦岛市",
				"id": 2114,
				"parentId": 21,
				"children": [{
						"countyCode": "211402000",
						"countyName": "连山区",
						"id": 211402,
						"parentId": 2114
					},
					{
						"countyCode": "211403000",
						"countyName": "龙港区",
						"id": 211403,
						"parentId": 2114
					},
					{
						"countyCode": "211404000",
						"countyName": "南票区",
						"id": 211404,
						"parentId": 2114
					},
					{
						"countyCode": "211421000",
						"countyName": "绥中县",
						"id": 211421,
						"parentId": 2114
					},
					{
						"countyCode": "211422000",
						"countyName": "建昌县",
						"id": 211422,
						"parentId": 2114
					},
					{
						"countyCode": "211481000",
						"countyName": "兴城市",
						"id": 211481,
						"parentId": 2114
					}
				]
			}
		]
	},
	{
		"provinceCode": "220000000",
		"provinceName": "吉林省",
		"id": 22,
		"parentId": 0,
		"children": [{
				"cityCode": "220100000",
				"cityName": "长春市",
				"id": 2201,
				"parentId": 22,
				"children": [{
						"countyCode": "220102000",
						"countyName": "南关区",
						"id": 220102,
						"parentId": 2201
					},
					{
						"countyCode": "220103000",
						"countyName": "宽城区",
						"id": 220103,
						"parentId": 2201
					},
					{
						"countyCode": "220104000",
						"countyName": "朝阳区",
						"id": 220104,
						"parentId": 2201
					},
					{
						"countyCode": "220105000",
						"countyName": "二道区",
						"id": 220105,
						"parentId": 2201
					},
					{
						"countyCode": "220106000",
						"countyName": "绿园区",
						"id": 220106,
						"parentId": 2201
					},
					{
						"countyCode": "220112000",
						"countyName": "双阳区",
						"id": 220112,
						"parentId": 2201
					},
					{
						"countyCode": "220113000",
						"countyName": "九台区",
						"id": 220113,
						"parentId": 2201
					},
					{
						"countyCode": "220122000",
						"countyName": "农安县",
						"id": 220122,
						"parentId": 2201
					},
					{
						"countyCode": "220182000",
						"countyName": "榆树市",
						"id": 220182,
						"parentId": 2201
					},
					{
						"countyCode": "220183000",
						"countyName": "德惠市",
						"id": 220183,
						"parentId": 2201
					}
				]
			},
			{
				"cityCode": "220200000",
				"cityName": "吉林市",
				"id": 2202,
				"parentId": 22,
				"children": [{
						"countyCode": "220202000",
						"countyName": "昌邑区",
						"id": 220202,
						"parentId": 2202
					},
					{
						"countyCode": "220203000",
						"countyName": "龙潭区",
						"id": 220203,
						"parentId": 2202
					},
					{
						"countyCode": "220204000",
						"countyName": "船营区",
						"id": 220204,
						"parentId": 2202
					},
					{
						"countyCode": "220211000",
						"countyName": "丰满区",
						"id": 220211,
						"parentId": 2202
					},
					{
						"countyCode": "220221000",
						"countyName": "永吉县",
						"id": 220221,
						"parentId": 2202
					},
					{
						"countyCode": "220281000",
						"countyName": "蛟河市",
						"id": 220281,
						"parentId": 2202
					},
					{
						"countyCode": "220282000",
						"countyName": "桦甸市",
						"id": 220282,
						"parentId": 2202
					},
					{
						"countyCode": "220283000",
						"countyName": "舒兰市",
						"id": 220283,
						"parentId": 2202
					},
					{
						"countyCode": "220284000",
						"countyName": "磐石市",
						"id": 220284,
						"parentId": 2202
					}
				]
			},
			{
				"cityCode": "220300000",
				"cityName": "四平市",
				"id": 2203,
				"parentId": 22,
				"children": [{
						"countyCode": "220302000",
						"countyName": "铁西区",
						"id": 220302,
						"parentId": 2203
					},
					{
						"countyCode": "220303000",
						"countyName": "铁东区",
						"id": 220303,
						"parentId": 2203
					},
					{
						"countyCode": "220322000",
						"countyName": "梨树县",
						"id": 220322,
						"parentId": 2203
					},
					{
						"countyCode": "220323000",
						"countyName": "伊通满族自治县",
						"id": 220323,
						"parentId": 2203
					},
					{
						"countyCode": "220381000",
						"countyName": "公主岭市",
						"id": 220381,
						"parentId": 2203
					},
					{
						"countyCode": "220382000",
						"countyName": "双辽市",
						"id": 220382,
						"parentId": 2203
					}
				]
			},
			{
				"cityCode": "220400000",
				"cityName": "辽源市",
				"id": 2204,
				"parentId": 22,
				"children": [{
						"countyCode": "220402000",
						"countyName": "龙山区",
						"id": 220402,
						"parentId": 2204
					},
					{
						"countyCode": "220403000",
						"countyName": "西安区",
						"id": 220403,
						"parentId": 2204
					},
					{
						"countyCode": "220421000",
						"countyName": "东丰县",
						"id": 220421,
						"parentId": 2204
					},
					{
						"countyCode": "220422000",
						"countyName": "东辽县",
						"id": 220422,
						"parentId": 2204
					}
				]
			},
			{
				"cityCode": "220500000",
				"cityName": "通化市",
				"id": 2205,
				"parentId": 22,
				"children": [{
						"countyCode": "220502000",
						"countyName": "东昌区",
						"id": 220502,
						"parentId": 2205
					},
					{
						"countyCode": "220503000",
						"countyName": "二道江区",
						"id": 220503,
						"parentId": 2205
					},
					{
						"countyCode": "220521000",
						"countyName": "通化县",
						"id": 220521,
						"parentId": 2205
					},
					{
						"countyCode": "220523000",
						"countyName": "辉南县",
						"id": 220523,
						"parentId": 2205
					},
					{
						"countyCode": "220524000",
						"countyName": "柳河县",
						"id": 220524,
						"parentId": 2205
					},
					{
						"countyCode": "220581000",
						"countyName": "梅河口市",
						"id": 220581,
						"parentId": 2205
					},
					{
						"countyCode": "220582000",
						"countyName": "集安市",
						"id": 220582,
						"parentId": 2205
					}
				]
			},
			{
				"cityCode": "220600000",
				"cityName": "白山市",
				"id": 2206,
				"parentId": 22,
				"children": [{
						"countyCode": "220602000",
						"countyName": "浑江区",
						"id": 220602,
						"parentId": 2206
					},
					{
						"countyCode": "220605000",
						"countyName": "江源区",
						"id": 220605,
						"parentId": 2206
					},
					{
						"countyCode": "220621000",
						"countyName": "抚松县",
						"id": 220621,
						"parentId": 2206
					},
					{
						"countyCode": "220622000",
						"countyName": "靖宇县",
						"id": 220622,
						"parentId": 2206
					},
					{
						"countyCode": "220623000",
						"countyName": "长白朝鲜族自治县",
						"id": 220623,
						"parentId": 2206
					},
					{
						"countyCode": "220681000",
						"countyName": "临江市",
						"id": 220681,
						"parentId": 2206
					}
				]
			},
			{
				"cityCode": "220700000",
				"cityName": "松原市",
				"id": 2207,
				"parentId": 22,
				"children": [{
						"countyCode": "220702000",
						"countyName": "宁江区",
						"id": 220702,
						"parentId": 2207
					},
					{
						"countyCode": "220721000",
						"countyName": "前郭尔罗斯蒙古族自治县",
						"id": 220721,
						"parentId": 2207
					},
					{
						"countyCode": "220722000",
						"countyName": "长岭县",
						"id": 220722,
						"parentId": 2207
					},
					{
						"countyCode": "220723000",
						"countyName": "乾安县",
						"id": 220723,
						"parentId": 2207
					},
					{
						"countyCode": "220781000",
						"countyName": "扶余市",
						"id": 220781,
						"parentId": 2207
					}
				]
			},
			{
				"cityCode": "220800000",
				"cityName": "白城市",
				"id": 2208,
				"parentId": 22,
				"children": [{
						"countyCode": "220802000",
						"countyName": "洮北区",
						"id": 220802,
						"parentId": 2208
					},
					{
						"countyCode": "220821000",
						"countyName": "镇赉县",
						"id": 220821,
						"parentId": 2208
					},
					{
						"countyCode": "220822000",
						"countyName": "通榆县",
						"id": 220822,
						"parentId": 2208
					},
					{
						"countyCode": "220881000",
						"countyName": "洮南市",
						"id": 220881,
						"parentId": 2208
					},
					{
						"countyCode": "220882000",
						"countyName": "大安市",
						"id": 220882,
						"parentId": 2208
					}
				]
			},
			{
				"cityCode": "222400000",
				"cityName": "延边朝鲜族自治州",
				"id": 2224,
				"parentId": 22,
				"children": [{
						"countyCode": "222401000",
						"countyName": "延吉市",
						"id": 222401,
						"parentId": 2224
					},
					{
						"countyCode": "222402000",
						"countyName": "图们市",
						"id": 222402,
						"parentId": 2224
					},
					{
						"countyCode": "222403000",
						"countyName": "敦化市",
						"id": 222403,
						"parentId": 2224
					},
					{
						"countyCode": "222404000",
						"countyName": "珲春市",
						"id": 222404,
						"parentId": 2224
					},
					{
						"countyCode": "222405000",
						"countyName": "龙井市",
						"id": 222405,
						"parentId": 2224
					},
					{
						"countyCode": "222406000",
						"countyName": "和龙市",
						"id": 222406,
						"parentId": 2224
					},
					{
						"countyCode": "222424000",
						"countyName": "汪清县",
						"id": 222424,
						"parentId": 2224
					},
					{
						"countyCode": "222426000",
						"countyName": "安图县",
						"id": 222426,
						"parentId": 2224
					}
				]
			}
		]
	},
	{
		"provinceCode": "230000000",
		"provinceName": "黑龙江省",
		"id": 23,
		"parentId": 0,
		"children": [{
				"cityCode": "230100000",
				"cityName": "哈尔滨市",
				"id": 2301,
				"parentId": 23,
				"children": [{
						"countyCode": "230102000",
						"countyName": "道里区",
						"id": 230102,
						"parentId": 2301
					},
					{
						"countyCode": "230103000",
						"countyName": "南岗区",
						"id": 230103,
						"parentId": 2301
					},
					{
						"countyCode": "230104000",
						"countyName": "道外区",
						"id": 230104,
						"parentId": 2301
					},
					{
						"countyCode": "230108000",
						"countyName": "平房区",
						"id": 230108,
						"parentId": 2301
					},
					{
						"countyCode": "230109000",
						"countyName": "松北区",
						"id": 230109,
						"parentId": 2301
					},
					{
						"countyCode": "230110000",
						"countyName": "香坊区",
						"id": 230110,
						"parentId": 2301
					},
					{
						"countyCode": "230111000",
						"countyName": "呼兰区",
						"id": 230111,
						"parentId": 2301
					},
					{
						"countyCode": "230112000",
						"countyName": "阿城区",
						"id": 230112,
						"parentId": 2301
					},
					{
						"countyCode": "230113000",
						"countyName": "双城区",
						"id": 230113,
						"parentId": 2301
					},
					{
						"countyCode": "230123000",
						"countyName": "依兰县",
						"id": 230123,
						"parentId": 2301
					},
					{
						"countyCode": "230124000",
						"countyName": "方正县",
						"id": 230124,
						"parentId": 2301
					},
					{
						"countyCode": "230125000",
						"countyName": "宾县",
						"id": 230125,
						"parentId": 2301
					},
					{
						"countyCode": "230126000",
						"countyName": "巴彦县",
						"id": 230126,
						"parentId": 2301
					},
					{
						"countyCode": "230127000",
						"countyName": "木兰县",
						"id": 230127,
						"parentId": 2301
					},
					{
						"countyCode": "230128000",
						"countyName": "通河县",
						"id": 230128,
						"parentId": 2301
					},
					{
						"countyCode": "230129000",
						"countyName": "延寿县",
						"id": 230129,
						"parentId": 2301
					},
					{
						"countyCode": "230183000",
						"countyName": "尚志市",
						"id": 230183,
						"parentId": 2301
					},
					{
						"countyCode": "230184000",
						"countyName": "五常市",
						"id": 230184,
						"parentId": 2301
					}
				]
			},
			{
				"cityCode": "230200000",
				"cityName": "齐齐哈尔市",
				"id": 2302,
				"parentId": 23,
				"children": [{
						"countyCode": "230202000",
						"countyName": "龙沙区",
						"id": 230202,
						"parentId": 2302
					},
					{
						"countyCode": "230203000",
						"countyName": "建华区",
						"id": 230203,
						"parentId": 2302
					},
					{
						"countyCode": "230204000",
						"countyName": "铁锋区",
						"id": 230204,
						"parentId": 2302
					},
					{
						"countyCode": "230205000",
						"countyName": "昂昂溪区",
						"id": 230205,
						"parentId": 2302
					},
					{
						"countyCode": "230206000",
						"countyName": "富拉尔基区",
						"id": 230206,
						"parentId": 2302
					},
					{
						"countyCode": "230207000",
						"countyName": "碾子山区",
						"id": 230207,
						"parentId": 2302
					},
					{
						"countyCode": "230208000",
						"countyName": "梅里斯达斡尔族区",
						"id": 230208,
						"parentId": 2302
					},
					{
						"countyCode": "230221000",
						"countyName": "龙江县",
						"id": 230221,
						"parentId": 2302
					},
					{
						"countyCode": "230223000",
						"countyName": "依安县",
						"id": 230223,
						"parentId": 2302
					},
					{
						"countyCode": "230224000",
						"countyName": "泰来县",
						"id": 230224,
						"parentId": 2302
					},
					{
						"countyCode": "230225000",
						"countyName": "甘南县",
						"id": 230225,
						"parentId": 2302
					},
					{
						"countyCode": "230227000",
						"countyName": "富裕县",
						"id": 230227,
						"parentId": 2302
					},
					{
						"countyCode": "230229000",
						"countyName": "克山县",
						"id": 230229,
						"parentId": 2302
					},
					{
						"countyCode": "230230000",
						"countyName": "克东县",
						"id": 230230,
						"parentId": 2302
					},
					{
						"countyCode": "230231000",
						"countyName": "拜泉县",
						"id": 230231,
						"parentId": 2302
					},
					{
						"countyCode": "230281000",
						"countyName": "讷河市",
						"id": 230281,
						"parentId": 2302
					}
				]
			},
			{
				"cityCode": "230300000",
				"cityName": "鸡西市",
				"id": 2303,
				"parentId": 23,
				"children": [{
						"countyCode": "230302000",
						"countyName": "鸡冠区",
						"id": 230302,
						"parentId": 2303
					},
					{
						"countyCode": "230303000",
						"countyName": "恒山区",
						"id": 230303,
						"parentId": 2303
					},
					{
						"countyCode": "230304000",
						"countyName": "滴道区",
						"id": 230304,
						"parentId": 2303
					},
					{
						"countyCode": "230305000",
						"countyName": "梨树区",
						"id": 230305,
						"parentId": 2303
					},
					{
						"countyCode": "230306000",
						"countyName": "城子河区",
						"id": 230306,
						"parentId": 2303
					},
					{
						"countyCode": "230307000",
						"countyName": "麻山区",
						"id": 230307,
						"parentId": 2303
					},
					{
						"countyCode": "230321000",
						"countyName": "鸡东县",
						"id": 230321,
						"parentId": 2303
					},
					{
						"countyCode": "230381000",
						"countyName": "虎林市",
						"id": 230381,
						"parentId": 2303
					},
					{
						"countyCode": "230382000",
						"countyName": "密山市",
						"id": 230382,
						"parentId": 2303
					}
				]
			},
			{
				"cityCode": "230400000",
				"cityName": "鹤岗市",
				"id": 2304,
				"parentId": 23,
				"children": [{
						"countyCode": "230402000",
						"countyName": "向阳区",
						"id": 230402,
						"parentId": 2304
					},
					{
						"countyCode": "230403000",
						"countyName": "工农区",
						"id": 230403,
						"parentId": 2304
					},
					{
						"countyCode": "230404000",
						"countyName": "南山区",
						"id": 230404,
						"parentId": 2304
					},
					{
						"countyCode": "230405000",
						"countyName": "兴安区",
						"id": 230405,
						"parentId": 2304
					},
					{
						"countyCode": "230406000",
						"countyName": "东山区",
						"id": 230406,
						"parentId": 2304
					},
					{
						"countyCode": "230407000",
						"countyName": "兴山区",
						"id": 230407,
						"parentId": 2304
					},
					{
						"countyCode": "230421000",
						"countyName": "萝北县",
						"id": 230421,
						"parentId": 2304
					},
					{
						"countyCode": "230422000",
						"countyName": "绥滨县",
						"id": 230422,
						"parentId": 2304
					}
				]
			},
			{
				"cityCode": "230500000",
				"cityName": "双鸭山市",
				"id": 2305,
				"parentId": 23,
				"children": [{
						"countyCode": "230502000",
						"countyName": "尖山区",
						"id": 230502,
						"parentId": 2305
					},
					{
						"countyCode": "230503000",
						"countyName": "岭东区",
						"id": 230503,
						"parentId": 2305
					},
					{
						"countyCode": "230505000",
						"countyName": "四方台区",
						"id": 230505,
						"parentId": 2305
					},
					{
						"countyCode": "230506000",
						"countyName": "宝山区",
						"id": 230506,
						"parentId": 2305
					},
					{
						"countyCode": "230521000",
						"countyName": "集贤县",
						"id": 230521,
						"parentId": 2305
					},
					{
						"countyCode": "230522000",
						"countyName": "友谊县",
						"id": 230522,
						"parentId": 2305
					},
					{
						"countyCode": "230523000",
						"countyName": "宝清县",
						"id": 230523,
						"parentId": 2305
					},
					{
						"countyCode": "230524000",
						"countyName": "饶河县",
						"id": 230524,
						"parentId": 2305
					}
				]
			},
			{
				"cityCode": "230600000",
				"cityName": "大庆市",
				"id": 2306,
				"parentId": 23,
				"children": [{
						"countyCode": "230602000",
						"countyName": "萨尔图区",
						"id": 230602,
						"parentId": 2306
					},
					{
						"countyCode": "230603000",
						"countyName": "龙凤区",
						"id": 230603,
						"parentId": 2306
					},
					{
						"countyCode": "230604000",
						"countyName": "让胡路区",
						"id": 230604,
						"parentId": 2306
					},
					{
						"countyCode": "230605000",
						"countyName": "红岗区",
						"id": 230605,
						"parentId": 2306
					},
					{
						"countyCode": "230606000",
						"countyName": "大同区",
						"id": 230606,
						"parentId": 2306
					},
					{
						"countyCode": "230621000",
						"countyName": "肇州县",
						"id": 230621,
						"parentId": 2306
					},
					{
						"countyCode": "230622000",
						"countyName": "肇源县",
						"id": 230622,
						"parentId": 2306
					},
					{
						"countyCode": "230623000",
						"countyName": "林甸县",
						"id": 230623,
						"parentId": 2306
					},
					{
						"countyCode": "230624000",
						"countyName": "杜尔伯特蒙古族自治县",
						"id": 230624,
						"parentId": 2306
					}
				]
			},
			{
				"cityCode": "230700000",
				"cityName": "伊春市",
				"id": 2307,
				"parentId": 23,
				"children": [{
						"countyCode": "230717000",
						"countyName": "伊美区",
						"id": 230717,
						"parentId": 2307
					},
					{
						"countyCode": "230718000",
						"countyName": "乌翠区",
						"id": 230718,
						"parentId": 2307
					},
					{
						"countyCode": "230719000",
						"countyName": "友好区",
						"id": 230719,
						"parentId": 2307
					},
					{
						"countyCode": "230722000",
						"countyName": "嘉荫县",
						"id": 230722,
						"parentId": 2307
					},
					{
						"countyCode": "230723000",
						"countyName": "汤旺县",
						"id": 230723,
						"parentId": 2307
					},
					{
						"countyCode": "230724000",
						"countyName": "丰林县",
						"id": 230724,
						"parentId": 2307
					},
					{
						"countyCode": "230725000",
						"countyName": "大箐山县",
						"id": 230725,
						"parentId": 2307
					},
					{
						"countyCode": "230726000",
						"countyName": "南岔县",
						"id": 230726,
						"parentId": 2307
					},
					{
						"countyCode": "230751000",
						"countyName": "金林区",
						"id": 230751,
						"parentId": 2307
					},
					{
						"countyCode": "230781000",
						"countyName": "铁力市",
						"id": 230781,
						"parentId": 2307
					}
				]
			},
			{
				"cityCode": "230800000",
				"cityName": "佳木斯市",
				"id": 2308,
				"parentId": 23,
				"children": [{
						"countyCode": "230803000",
						"countyName": "向阳区",
						"id": 230803,
						"parentId": 2308
					},
					{
						"countyCode": "230804000",
						"countyName": "前进区",
						"id": 230804,
						"parentId": 2308
					},
					{
						"countyCode": "230805000",
						"countyName": "东风区",
						"id": 230805,
						"parentId": 2308
					},
					{
						"countyCode": "230811000",
						"countyName": "郊区",
						"id": 230811,
						"parentId": 2308
					},
					{
						"countyCode": "230822000",
						"countyName": "桦南县",
						"id": 230822,
						"parentId": 2308
					},
					{
						"countyCode": "230826000",
						"countyName": "桦川县",
						"id": 230826,
						"parentId": 2308
					},
					{
						"countyCode": "230828000",
						"countyName": "汤原县",
						"id": 230828,
						"parentId": 2308
					},
					{
						"countyCode": "230881000",
						"countyName": "同江市",
						"id": 230881,
						"parentId": 2308
					},
					{
						"countyCode": "230882000",
						"countyName": "富锦市",
						"id": 230882,
						"parentId": 2308
					},
					{
						"countyCode": "230883000",
						"countyName": "抚远市",
						"id": 230883,
						"parentId": 2308
					}
				]
			},
			{
				"cityCode": "230900000",
				"cityName": "七台河市",
				"id": 2309,
				"parentId": 23,
				"children": [{
						"countyCode": "230902000",
						"countyName": "新兴区",
						"id": 230902,
						"parentId": 2309
					},
					{
						"countyCode": "230903000",
						"countyName": "桃山区",
						"id": 230903,
						"parentId": 2309
					},
					{
						"countyCode": "230904000",
						"countyName": "茄子河区",
						"id": 230904,
						"parentId": 2309
					},
					{
						"countyCode": "230921000",
						"countyName": "勃利县",
						"id": 230921,
						"parentId": 2309
					}
				]
			},
			{
				"cityCode": "231000000",
				"cityName": "牡丹江市",
				"id": 2310,
				"parentId": 23,
				"children": [{
						"countyCode": "231002000",
						"countyName": "东安区",
						"id": 231002,
						"parentId": 2310
					},
					{
						"countyCode": "231003000",
						"countyName": "阳明区",
						"id": 231003,
						"parentId": 2310
					},
					{
						"countyCode": "231004000",
						"countyName": "爱民区",
						"id": 231004,
						"parentId": 2310
					},
					{
						"countyCode": "231005000",
						"countyName": "西安区",
						"id": 231005,
						"parentId": 2310
					},
					{
						"countyCode": "231025000",
						"countyName": "林口县",
						"id": 231025,
						"parentId": 2310
					},
					{
						"countyCode": "231081000",
						"countyName": "绥芬河市",
						"id": 231081,
						"parentId": 2310
					},
					{
						"countyCode": "231083000",
						"countyName": "海林市",
						"id": 231083,
						"parentId": 2310
					},
					{
						"countyCode": "231084000",
						"countyName": "宁安市",
						"id": 231084,
						"parentId": 2310
					},
					{
						"countyCode": "231085000",
						"countyName": "穆棱市",
						"id": 231085,
						"parentId": 2310
					},
					{
						"countyCode": "231086000",
						"countyName": "东宁市",
						"id": 231086,
						"parentId": 2310
					}
				]
			},
			{
				"cityCode": "231100000",
				"cityName": "黑河市",
				"id": 2311,
				"parentId": 23,
				"children": [{
						"countyCode": "231102000",
						"countyName": "爱辉区",
						"id": 231102,
						"parentId": 2311
					},
					{
						"countyCode": "231121000",
						"countyName": "嫩江市",
						"id": 231121,
						"parentId": 2311
					},
					{
						"countyCode": "231123000",
						"countyName": "逊克县",
						"id": 231123,
						"parentId": 2311
					},
					{
						"countyCode": "231124000",
						"countyName": "孙吴县",
						"id": 231124,
						"parentId": 2311
					},
					{
						"countyCode": "231181000",
						"countyName": "北安市",
						"id": 231181,
						"parentId": 2311
					},
					{
						"countyCode": "231182000",
						"countyName": "五大连池市",
						"id": 231182,
						"parentId": 2311
					}
				]
			},
			{
				"cityCode": "231200000",
				"cityName": "绥化市",
				"id": 2312,
				"parentId": 23,
				"children": [{
						"countyCode": "231202000",
						"countyName": "北林区",
						"id": 231202,
						"parentId": 2312
					},
					{
						"countyCode": "231221000",
						"countyName": "望奎县",
						"id": 231221,
						"parentId": 2312
					},
					{
						"countyCode": "231222000",
						"countyName": "兰西县",
						"id": 231222,
						"parentId": 2312
					},
					{
						"countyCode": "231223000",
						"countyName": "青冈县",
						"id": 231223,
						"parentId": 2312
					},
					{
						"countyCode": "231224000",
						"countyName": "庆安县",
						"id": 231224,
						"parentId": 2312
					},
					{
						"countyCode": "231225000",
						"countyName": "明水县",
						"id": 231225,
						"parentId": 2312
					},
					{
						"countyCode": "231226000",
						"countyName": "绥棱县",
						"id": 231226,
						"parentId": 2312
					},
					{
						"countyCode": "231281000",
						"countyName": "安达市",
						"id": 231281,
						"parentId": 2312
					},
					{
						"countyCode": "231282000",
						"countyName": "肇东市",
						"id": 231282,
						"parentId": 2312
					},
					{
						"countyCode": "231283000",
						"countyName": "海伦市",
						"id": 231283,
						"parentId": 2312
					}
				]
			},
			{
				"cityCode": "232700000",
				"cityName": "大兴安岭地区",
				"id": 2327,
				"parentId": 23,
				"children": [{
						"countyCode": "232701000",
						"countyName": "漠河市",
						"id": 232701,
						"parentId": 2327
					},
					{
						"countyCode": "232721000",
						"countyName": "呼玛县",
						"id": 232721,
						"parentId": 2327
					},
					{
						"countyCode": "232722000",
						"countyName": "塔河县",
						"id": 232722,
						"parentId": 2327
					},
					{
						"countyCode": "232761000",
						"countyName": "加格达奇区",
						"id": 232761,
						"parentId": 2327
					}
				]
			}
		]
	},
	{
		"provinceCode": "310000000",
		"provinceName": "上海市",
		"id": 31,
		"parentId": 0,
		"children": [{
			"cityCode": "310100000",
			"cityName": "市辖区",
			"id": 3101,
			"parentId": 31,
			"children": [{
					"countyCode": "310101000",
					"countyName": "黄浦区",
					"id": 310101,
					"parentId": 3101
				},
				{
					"countyCode": "310104000",
					"countyName": "徐汇区",
					"id": 310104,
					"parentId": 3101
				},
				{
					"countyCode": "310105000",
					"countyName": "长宁区",
					"id": 310105,
					"parentId": 3101
				},
				{
					"countyCode": "310106000",
					"countyName": "静安区",
					"id": 310106,
					"parentId": 3101
				},
				{
					"countyCode": "310107000",
					"countyName": "普陀区",
					"id": 310107,
					"parentId": 3101
				},
				{
					"countyCode": "310109000",
					"countyName": "虹口区",
					"id": 310109,
					"parentId": 3101
				},
				{
					"countyCode": "310110000",
					"countyName": "杨浦区",
					"id": 310110,
					"parentId": 3101
				},
				{
					"countyCode": "310112000",
					"countyName": "闵行区",
					"id": 310112,
					"parentId": 3101
				},
				{
					"countyCode": "310113000",
					"countyName": "宝山区",
					"id": 310113,
					"parentId": 3101
				},
				{
					"countyCode": "310114000",
					"countyName": "嘉定区",
					"id": 310114,
					"parentId": 3101
				},
				{
					"countyCode": "310115000",
					"countyName": "浦东新区",
					"id": 310115,
					"parentId": 3101
				},
				{
					"countyCode": "310116000",
					"countyName": "金山区",
					"id": 310116,
					"parentId": 3101
				},
				{
					"countyCode": "310117000",
					"countyName": "松江区",
					"id": 310117,
					"parentId": 3101
				},
				{
					"countyCode": "310118000",
					"countyName": "青浦区",
					"id": 310118,
					"parentId": 3101
				},
				{
					"countyCode": "310120000",
					"countyName": "奉贤区",
					"id": 310120,
					"parentId": 3101
				},
				{
					"countyCode": "310151000",
					"countyName": "崇明区",
					"id": 310151,
					"parentId": 3101
				}
			]
		}]
	},
	{
		"provinceCode": "320000000",
		"provinceName": "江苏省",
		"id": 32,
		"parentId": 0,
		"children": [{
				"cityCode": "320100000",
				"cityName": "南京市",
				"id": 3201,
				"parentId": 32,
				"children": [{
						"countyCode": "320102000",
						"countyName": "玄武区",
						"id": 320102,
						"parentId": 3201
					},
					{
						"countyCode": "320104000",
						"countyName": "秦淮区",
						"id": 320104,
						"parentId": 3201
					},
					{
						"countyCode": "320105000",
						"countyName": "建邺区",
						"id": 320105,
						"parentId": 3201
					},
					{
						"countyCode": "320106000",
						"countyName": "鼓楼区",
						"id": 320106,
						"parentId": 3201
					},
					{
						"countyCode": "320111000",
						"countyName": "浦口区",
						"id": 320111,
						"parentId": 3201
					},
					{
						"countyCode": "320113000",
						"countyName": "栖霞区",
						"id": 320113,
						"parentId": 3201
					},
					{
						"countyCode": "320114000",
						"countyName": "雨花台区",
						"id": 320114,
						"parentId": 3201
					},
					{
						"countyCode": "320115000",
						"countyName": "江宁区",
						"id": 320115,
						"parentId": 3201
					},
					{
						"countyCode": "320116000",
						"countyName": "六合区",
						"id": 320116,
						"parentId": 3201
					},
					{
						"countyCode": "320117000",
						"countyName": "溧水区",
						"id": 320117,
						"parentId": 3201
					},
					{
						"countyCode": "320118000",
						"countyName": "高淳区",
						"id": 320118,
						"parentId": 3201
					}
				]
			},
			{
				"cityCode": "320200000",
				"cityName": "无锡市",
				"id": 3202,
				"parentId": 32,
				"children": [{
						"countyCode": "320205000",
						"countyName": "锡山区",
						"id": 320205,
						"parentId": 3202
					},
					{
						"countyCode": "320206000",
						"countyName": "惠山区",
						"id": 320206,
						"parentId": 3202
					},
					{
						"countyCode": "320211000",
						"countyName": "滨湖区",
						"id": 320211,
						"parentId": 3202
					},
					{
						"countyCode": "320213000",
						"countyName": "梁溪区",
						"id": 320213,
						"parentId": 3202
					},
					{
						"countyCode": "320214000",
						"countyName": "新吴区",
						"id": 320214,
						"parentId": 3202
					},
					{
						"countyCode": "320281000",
						"countyName": "江阴市",
						"id": 320281,
						"parentId": 3202
					},
					{
						"countyCode": "320282000",
						"countyName": "宜兴市",
						"id": 320282,
						"parentId": 3202
					}
				]
			},
			{
				"cityCode": "320300000",
				"cityName": "徐州市",
				"id": 3203,
				"parentId": 32,
				"children": [{
						"countyCode": "320302000",
						"countyName": "鼓楼区",
						"id": 320302,
						"parentId": 3203
					},
					{
						"countyCode": "320303000",
						"countyName": "云龙区",
						"id": 320303,
						"parentId": 3203
					},
					{
						"countyCode": "320305000",
						"countyName": "贾汪区",
						"id": 320305,
						"parentId": 3203
					},
					{
						"countyCode": "320311000",
						"countyName": "泉山区",
						"id": 320311,
						"parentId": 3203
					},
					{
						"countyCode": "320312000",
						"countyName": "铜山区",
						"id": 320312,
						"parentId": 3203
					},
					{
						"countyCode": "320321000",
						"countyName": "丰县",
						"id": 320321,
						"parentId": 3203
					},
					{
						"countyCode": "320322000",
						"countyName": "沛县",
						"id": 320322,
						"parentId": 3203
					},
					{
						"countyCode": "320324000",
						"countyName": "睢宁县",
						"id": 320324,
						"parentId": 3203
					},
					{
						"countyCode": "320381000",
						"countyName": "新沂市",
						"id": 320381,
						"parentId": 3203
					},
					{
						"countyCode": "320382000",
						"countyName": "邳州市",
						"id": 320382,
						"parentId": 3203
					}
				]
			},
			{
				"cityCode": "320400000",
				"cityName": "常州市",
				"id": 3204,
				"parentId": 32,
				"children": [{
						"countyCode": "320402000",
						"countyName": "天宁区",
						"id": 320402,
						"parentId": 3204
					},
					{
						"countyCode": "320404000",
						"countyName": "钟楼区",
						"id": 320404,
						"parentId": 3204
					},
					{
						"countyCode": "320411000",
						"countyName": "新北区",
						"id": 320411,
						"parentId": 3204
					},
					{
						"countyCode": "320412000",
						"countyName": "武进区",
						"id": 320412,
						"parentId": 3204
					},
					{
						"countyCode": "320413000",
						"countyName": "金坛区",
						"id": 320413,
						"parentId": 3204
					},
					{
						"countyCode": "320481000",
						"countyName": "溧阳市",
						"id": 320481,
						"parentId": 3204
					}
				]
			},
			{
				"cityCode": "320500000",
				"cityName": "苏州市",
				"id": 3205,
				"parentId": 32,
				"children": [{
						"countyCode": "320505000",
						"countyName": "虎丘区",
						"id": 320505,
						"parentId": 3205
					},
					{
						"countyCode": "320506000",
						"countyName": "吴中区",
						"id": 320506,
						"parentId": 3205
					},
					{
						"countyCode": "320507000",
						"countyName": "相城区",
						"id": 320507,
						"parentId": 3205
					},
					{
						"countyCode": "320508000",
						"countyName": "姑苏区",
						"id": 320508,
						"parentId": 3205
					},
					{
						"countyCode": "320509000",
						"countyName": "吴江区",
						"id": 320509,
						"parentId": 3205
					},
					{
						"countyCode": "320571000",
						"countyName": "苏州工业园区",
						"id": 320571,
						"parentId": 3205
					},
					{
						"countyCode": "320581000",
						"countyName": "常熟市",
						"id": 320581,
						"parentId": 3205
					},
					{
						"countyCode": "320582000",
						"countyName": "张家港市",
						"id": 320582,
						"parentId": 3205
					},
					{
						"countyCode": "320583000",
						"countyName": "昆山市",
						"id": 320583,
						"parentId": 3205
					},
					{
						"countyCode": "320585000",
						"countyName": "太仓市",
						"id": 320585,
						"parentId": 3205
					}
				]
			},
			{
				"cityCode": "320600000",
				"cityName": "南通市",
				"id": 3206,
				"parentId": 32,
				"children": [{
						"countyCode": "320602000",
						"countyName": "崇川区",
						"id": 320602,
						"parentId": 3206
					},
					{
						"countyCode": "320611000",
						"countyName": "港闸区",
						"id": 320611,
						"parentId": 3206
					},
					{
						"countyCode": "320612000",
						"countyName": "通州区",
						"id": 320612,
						"parentId": 3206
					},
					{
						"countyCode": "320623000",
						"countyName": "如东县",
						"id": 320623,
						"parentId": 3206
					},
					{
						"countyCode": "320681000",
						"countyName": "启东市",
						"id": 320681,
						"parentId": 3206
					},
					{
						"countyCode": "320682000",
						"countyName": "如皋市",
						"id": 320682,
						"parentId": 3206
					},
					{
						"countyCode": "320684000",
						"countyName": "海门市",
						"id": 320684,
						"parentId": 3206
					},
					{
						"countyCode": "320685000",
						"countyName": "海安市",
						"id": 320685,
						"parentId": 3206
					}
				]
			},
			{
				"cityCode": "320700000",
				"cityName": "连云港市",
				"id": 3207,
				"parentId": 32,
				"children": [{
						"countyCode": "320703000",
						"countyName": "连云区",
						"id": 320703,
						"parentId": 3207
					},
					{
						"countyCode": "320706000",
						"countyName": "海州区",
						"id": 320706,
						"parentId": 3207
					},
					{
						"countyCode": "320707000",
						"countyName": "赣榆区",
						"id": 320707,
						"parentId": 3207
					},
					{
						"countyCode": "320722000",
						"countyName": "东海县",
						"id": 320722,
						"parentId": 3207
					},
					{
						"countyCode": "320723000",
						"countyName": "灌云县",
						"id": 320723,
						"parentId": 3207
					},
					{
						"countyCode": "320724000",
						"countyName": "灌南县",
						"id": 320724,
						"parentId": 3207
					}
				]
			},
			{
				"cityCode": "320800000",
				"cityName": "淮安市",
				"id": 3208,
				"parentId": 32,
				"children": [{
						"countyCode": "320803000",
						"countyName": "淮安区",
						"id": 320803,
						"parentId": 3208
					},
					{
						"countyCode": "320804000",
						"countyName": "淮阴区",
						"id": 320804,
						"parentId": 3208
					},
					{
						"countyCode": "320812000",
						"countyName": "清江浦区",
						"id": 320812,
						"parentId": 3208
					},
					{
						"countyCode": "320813000",
						"countyName": "洪泽区",
						"id": 320813,
						"parentId": 3208
					},
					{
						"countyCode": "320826000",
						"countyName": "涟水县",
						"id": 320826,
						"parentId": 3208
					},
					{
						"countyCode": "320830000",
						"countyName": "盱眙县",
						"id": 320830,
						"parentId": 3208
					},
					{
						"countyCode": "320831000",
						"countyName": "金湖县",
						"id": 320831,
						"parentId": 3208
					}
				]
			},
			{
				"cityCode": "320900000",
				"cityName": "盐城市",
				"id": 3209,
				"parentId": 32,
				"children": [{
						"countyCode": "320902000",
						"countyName": "亭湖区",
						"id": 320902,
						"parentId": 3209
					},
					{
						"countyCode": "320903000",
						"countyName": "盐都区",
						"id": 320903,
						"parentId": 3209
					},
					{
						"countyCode": "320904000",
						"countyName": "大丰区",
						"id": 320904,
						"parentId": 3209
					},
					{
						"countyCode": "320921000",
						"countyName": "响水县",
						"id": 320921,
						"parentId": 3209
					},
					{
						"countyCode": "320922000",
						"countyName": "滨海县",
						"id": 320922,
						"parentId": 3209
					},
					{
						"countyCode": "320923000",
						"countyName": "阜宁县",
						"id": 320923,
						"parentId": 3209
					},
					{
						"countyCode": "320924000",
						"countyName": "射阳县",
						"id": 320924,
						"parentId": 3209
					},
					{
						"countyCode": "320925000",
						"countyName": "建湖县",
						"id": 320925,
						"parentId": 3209
					},
					{
						"countyCode": "320981000",
						"countyName": "东台市",
						"id": 320981,
						"parentId": 3209
					}
				]
			},
			{
				"cityCode": "321000000",
				"cityName": "扬州市",
				"id": 3210,
				"parentId": 32,
				"children": [{
						"countyCode": "321002000",
						"countyName": "广陵区",
						"id": 321002,
						"parentId": 3210
					},
					{
						"countyCode": "321003000",
						"countyName": "邗江区",
						"id": 321003,
						"parentId": 3210
					},
					{
						"countyCode": "321012000",
						"countyName": "江都区",
						"id": 321012,
						"parentId": 3210
					},
					{
						"countyCode": "321023000",
						"countyName": "宝应县",
						"id": 321023,
						"parentId": 3210
					},
					{
						"countyCode": "321081000",
						"countyName": "仪征市",
						"id": 321081,
						"parentId": 3210
					},
					{
						"countyCode": "321084000",
						"countyName": "高邮市",
						"id": 321084,
						"parentId": 3210
					}
				]
			},
			{
				"cityCode": "321100000",
				"cityName": "镇江市",
				"id": 3211,
				"parentId": 32,
				"children": [{
						"countyCode": "321102000",
						"countyName": "京口区",
						"id": 321102,
						"parentId": 3211
					},
					{
						"countyCode": "321111000",
						"countyName": "润州区",
						"id": 321111,
						"parentId": 3211
					},
					{
						"countyCode": "321112000",
						"countyName": "丹徒区",
						"id": 321112,
						"parentId": 3211
					},
					{
						"countyCode": "321181000",
						"countyName": "丹阳市",
						"id": 321181,
						"parentId": 3211
					},
					{
						"countyCode": "321182000",
						"countyName": "扬中市",
						"id": 321182,
						"parentId": 3211
					},
					{
						"countyCode": "321183000",
						"countyName": "句容市",
						"id": 321183,
						"parentId": 3211
					}
				]
			},
			{
				"cityCode": "321200000",
				"cityName": "泰州市",
				"id": 3212,
				"parentId": 32,
				"children": [{
						"countyCode": "321202000",
						"countyName": "海陵区",
						"id": 321202,
						"parentId": 3212
					},
					{
						"countyCode": "321203000",
						"countyName": "高港区",
						"id": 321203,
						"parentId": 3212
					},
					{
						"countyCode": "321204000",
						"countyName": "姜堰区",
						"id": 321204,
						"parentId": 3212
					},
					{
						"countyCode": "321281000",
						"countyName": "兴化市",
						"id": 321281,
						"parentId": 3212
					},
					{
						"countyCode": "321282000",
						"countyName": "靖江市",
						"id": 321282,
						"parentId": 3212
					},
					{
						"countyCode": "321283000",
						"countyName": "泰兴市",
						"id": 321283,
						"parentId": 3212
					}
				]
			},
			{
				"cityCode": "321300000",
				"cityName": "宿迁市",
				"id": 3213,
				"parentId": 32,
				"children": [{
						"countyCode": "321302000",
						"countyName": "宿城区",
						"id": 321302,
						"parentId": 3213
					},
					{
						"countyCode": "321311000",
						"countyName": "宿豫区",
						"id": 321311,
						"parentId": 3213
					},
					{
						"countyCode": "321322000",
						"countyName": "沭阳县",
						"id": 321322,
						"parentId": 3213
					},
					{
						"countyCode": "321323000",
						"countyName": "泗阳县",
						"id": 321323,
						"parentId": 3213
					},
					{
						"countyCode": "321324000",
						"countyName": "泗洪县",
						"id": 321324,
						"parentId": 3213
					}
				]
			}
		]
	},
	{
		"provinceCode": "330000000",
		"provinceName": "浙江省",
		"id": 33,
		"parentId": 0,
		"children": [{
				"cityCode": "330100000",
				"cityName": "杭州市",
				"id": 3301,
				"parentId": 33,
				"children": [{
						"countyCode": "330102000",
						"countyName": "上城区",
						"id": 330102,
						"parentId": 3301
					},
					{
						"countyCode": "330103000",
						"countyName": "下城区",
						"id": 330103,
						"parentId": 3301
					},
					{
						"countyCode": "330104000",
						"countyName": "江干区",
						"id": 330104,
						"parentId": 3301
					},
					{
						"countyCode": "330105000",
						"countyName": "拱墅区",
						"id": 330105,
						"parentId": 3301
					},
					{
						"countyCode": "330106000",
						"countyName": "西湖区",
						"id": 330106,
						"parentId": 3301
					},
					{
						"countyCode": "330108000",
						"countyName": "滨江区",
						"id": 330108,
						"parentId": 3301
					},
					{
						"countyCode": "330109000",
						"countyName": "萧山区",
						"id": 330109,
						"parentId": 3301
					},
					{
						"countyCode": "330110000",
						"countyName": "余杭区",
						"id": 330110,
						"parentId": 3301
					},
					{
						"countyCode": "330111000",
						"countyName": "富阳区",
						"id": 330111,
						"parentId": 3301
					},
					{
						"countyCode": "330112000",
						"countyName": "临安区",
						"id": 330112,
						"parentId": 3301
					},
					{
						"countyCode": "330122000",
						"countyName": "桐庐县",
						"id": 330122,
						"parentId": 3301
					},
					{
						"countyCode": "330127000",
						"countyName": "淳安县",
						"id": 330127,
						"parentId": 3301
					},
					{
						"countyCode": "330182000",
						"countyName": "建德市",
						"id": 330182,
						"parentId": 3301
					}
				]
			},
			{
				"cityCode": "330200000",
				"cityName": "宁波市",
				"id": 3302,
				"parentId": 33,
				"children": [{
						"countyCode": "330203000",
						"countyName": "海曙区",
						"id": 330203,
						"parentId": 3302
					},
					{
						"countyCode": "330205000",
						"countyName": "江北区",
						"id": 330205,
						"parentId": 3302
					},
					{
						"countyCode": "330206000",
						"countyName": "北仑区",
						"id": 330206,
						"parentId": 3302
					},
					{
						"countyCode": "330211000",
						"countyName": "镇海区",
						"id": 330211,
						"parentId": 3302
					},
					{
						"countyCode": "330212000",
						"countyName": "鄞州区",
						"id": 330212,
						"parentId": 3302
					},
					{
						"countyCode": "330213000",
						"countyName": "奉化区",
						"id": 330213,
						"parentId": 3302
					},
					{
						"countyCode": "330225000",
						"countyName": "象山县",
						"id": 330225,
						"parentId": 3302
					},
					{
						"countyCode": "330226000",
						"countyName": "宁海县",
						"id": 330226,
						"parentId": 3302
					},
					{
						"countyCode": "330281000",
						"countyName": "余姚市",
						"id": 330281,
						"parentId": 3302
					},
					{
						"countyCode": "330282000",
						"countyName": "慈溪市",
						"id": 330282,
						"parentId": 3302
					}
				]
			},
			{
				"cityCode": "330300000",
				"cityName": "温州市",
				"id": 3303,
				"parentId": 33,
				"children": [{
						"countyCode": "330302000",
						"countyName": "鹿城区",
						"id": 330302,
						"parentId": 3303
					},
					{
						"countyCode": "330303000",
						"countyName": "龙湾区",
						"id": 330303,
						"parentId": 3303
					},
					{
						"countyCode": "330304000",
						"countyName": "瓯海区",
						"id": 330304,
						"parentId": 3303
					},
					{
						"countyCode": "330305000",
						"countyName": "洞头区",
						"id": 330305,
						"parentId": 3303
					},
					{
						"countyCode": "330324000",
						"countyName": "永嘉县",
						"id": 330324,
						"parentId": 3303
					},
					{
						"countyCode": "330326000",
						"countyName": "平阳县",
						"id": 330326,
						"parentId": 3303
					},
					{
						"countyCode": "330327000",
						"countyName": "苍南县",
						"id": 330327,
						"parentId": 3303
					},
					{
						"countyCode": "330328000",
						"countyName": "文成县",
						"id": 330328,
						"parentId": 3303
					},
					{
						"countyCode": "330329000",
						"countyName": "泰顺县",
						"id": 330329,
						"parentId": 3303
					},
					{
						"countyCode": "330381000",
						"countyName": "瑞安市",
						"id": 330381,
						"parentId": 3303
					},
					{
						"countyCode": "330382000",
						"countyName": "乐清市",
						"id": 330382,
						"parentId": 3303
					},
					{
						"countyCode": "330383000",
						"countyName": "龙港市",
						"id": 330383,
						"parentId": 3303
					}
				]
			},
			{
				"cityCode": "330400000",
				"cityName": "嘉兴市",
				"id": 3304,
				"parentId": 33,
				"children": [{
						"countyCode": "330402000",
						"countyName": "南湖区",
						"id": 330402,
						"parentId": 3304
					},
					{
						"countyCode": "330411000",
						"countyName": "秀洲区",
						"id": 330411,
						"parentId": 3304
					},
					{
						"countyCode": "330421000",
						"countyName": "嘉善县",
						"id": 330421,
						"parentId": 3304
					},
					{
						"countyCode": "330424000",
						"countyName": "海盐县",
						"id": 330424,
						"parentId": 3304
					},
					{
						"countyCode": "330481000",
						"countyName": "海宁市",
						"id": 330481,
						"parentId": 3304
					},
					{
						"countyCode": "330482000",
						"countyName": "平湖市",
						"id": 330482,
						"parentId": 3304
					},
					{
						"countyCode": "330483000",
						"countyName": "桐乡市",
						"id": 330483,
						"parentId": 3304
					}
				]
			},
			{
				"cityCode": "330500000",
				"cityName": "湖州市",
				"id": 3305,
				"parentId": 33,
				"children": [{
						"countyCode": "330502000",
						"countyName": "吴兴区",
						"id": 330502,
						"parentId": 3305
					},
					{
						"countyCode": "330503000",
						"countyName": "南浔区",
						"id": 330503,
						"parentId": 3305
					},
					{
						"countyCode": "330521000",
						"countyName": "德清县",
						"id": 330521,
						"parentId": 3305
					},
					{
						"countyCode": "330522000",
						"countyName": "长兴县",
						"id": 330522,
						"parentId": 3305
					},
					{
						"countyCode": "330523000",
						"countyName": "安吉县",
						"id": 330523,
						"parentId": 3305
					}
				]
			},
			{
				"cityCode": "330600000",
				"cityName": "绍兴市",
				"id": 3306,
				"parentId": 33,
				"children": [{
						"countyCode": "330602000",
						"countyName": "越城区",
						"id": 330602,
						"parentId": 3306
					},
					{
						"countyCode": "330603000",
						"countyName": "柯桥区",
						"id": 330603,
						"parentId": 3306
					},
					{
						"countyCode": "330604000",
						"countyName": "上虞区",
						"id": 330604,
						"parentId": 3306
					},
					{
						"countyCode": "330624000",
						"countyName": "新昌县",
						"id": 330624,
						"parentId": 3306
					},
					{
						"countyCode": "330681000",
						"countyName": "诸暨市",
						"id": 330681,
						"parentId": 3306
					},
					{
						"countyCode": "330683000",
						"countyName": "嵊州市",
						"id": 330683,
						"parentId": 3306
					}
				]
			},
			{
				"cityCode": "330700000",
				"cityName": "金华市",
				"id": 3307,
				"parentId": 33,
				"children": [{
						"countyCode": "330702000",
						"countyName": "婺城区",
						"id": 330702,
						"parentId": 3307
					},
					{
						"countyCode": "330703000",
						"countyName": "金东区",
						"id": 330703,
						"parentId": 3307
					},
					{
						"countyCode": "330723000",
						"countyName": "武义县",
						"id": 330723,
						"parentId": 3307
					},
					{
						"countyCode": "330726000",
						"countyName": "浦江县",
						"id": 330726,
						"parentId": 3307
					},
					{
						"countyCode": "330727000",
						"countyName": "磐安县",
						"id": 330727,
						"parentId": 3307
					},
					{
						"countyCode": "330781000",
						"countyName": "兰溪市",
						"id": 330781,
						"parentId": 3307
					},
					{
						"countyCode": "330782000",
						"countyName": "义乌市",
						"id": 330782,
						"parentId": 3307
					},
					{
						"countyCode": "330783000",
						"countyName": "东阳市",
						"id": 330783,
						"parentId": 3307
					},
					{
						"countyCode": "330784000",
						"countyName": "永康市",
						"id": 330784,
						"parentId": 3307
					}
				]
			},
			{
				"cityCode": "330800000",
				"cityName": "衢州市",
				"id": 3308,
				"parentId": 33,
				"children": [{
						"countyCode": "330802000",
						"countyName": "柯城区",
						"id": 330802,
						"parentId": 3308
					},
					{
						"countyCode": "330803000",
						"countyName": "衢江区",
						"id": 330803,
						"parentId": 3308
					},
					{
						"countyCode": "330822000",
						"countyName": "常山县",
						"id": 330822,
						"parentId": 3308
					},
					{
						"countyCode": "330824000",
						"countyName": "开化县",
						"id": 330824,
						"parentId": 3308
					},
					{
						"countyCode": "330825000",
						"countyName": "龙游县",
						"id": 330825,
						"parentId": 3308
					},
					{
						"countyCode": "330881000",
						"countyName": "江山市",
						"id": 330881,
						"parentId": 3308
					}
				]
			},
			{
				"cityCode": "330900000",
				"cityName": "舟山市",
				"id": 3309,
				"parentId": 33,
				"children": [{
						"countyCode": "330902000",
						"countyName": "定海区",
						"id": 330902,
						"parentId": 3309
					},
					{
						"countyCode": "330903000",
						"countyName": "普陀区",
						"id": 330903,
						"parentId": 3309
					},
					{
						"countyCode": "330921000",
						"countyName": "岱山县",
						"id": 330921,
						"parentId": 3309
					},
					{
						"countyCode": "330922000",
						"countyName": "嵊泗县",
						"id": 330922,
						"parentId": 3309
					}
				]
			},
			{
				"cityCode": "331000000",
				"cityName": "台州市",
				"id": 3310,
				"parentId": 33,
				"children": [{
						"countyCode": "331002000",
						"countyName": "椒江区",
						"id": 331002,
						"parentId": 3310
					},
					{
						"countyCode": "331003000",
						"countyName": "黄岩区",
						"id": 331003,
						"parentId": 3310
					},
					{
						"countyCode": "331004000",
						"countyName": "路桥区",
						"id": 331004,
						"parentId": 3310
					},
					{
						"countyCode": "331022000",
						"countyName": "三门县",
						"id": 331022,
						"parentId": 3310
					},
					{
						"countyCode": "331023000",
						"countyName": "天台县",
						"id": 331023,
						"parentId": 3310
					},
					{
						"countyCode": "331024000",
						"countyName": "仙居县",
						"id": 331024,
						"parentId": 3310
					},
					{
						"countyCode": "331081000",
						"countyName": "温岭市",
						"id": 331081,
						"parentId": 3310
					},
					{
						"countyCode": "331082000",
						"countyName": "临海市",
						"id": 331082,
						"parentId": 3310
					},
					{
						"countyCode": "331083000",
						"countyName": "玉环市",
						"id": 331083,
						"parentId": 3310
					}
				]
			},
			{
				"cityCode": "331100000",
				"cityName": "丽水市",
				"id": 3311,
				"parentId": 33,
				"children": [{
						"countyCode": "331102000",
						"countyName": "莲都区",
						"id": 331102,
						"parentId": 3311
					},
					{
						"countyCode": "331121000",
						"countyName": "青田县",
						"id": 331121,
						"parentId": 3311
					},
					{
						"countyCode": "331122000",
						"countyName": "缙云县",
						"id": 331122,
						"parentId": 3311
					},
					{
						"countyCode": "331123000",
						"countyName": "遂昌县",
						"id": 331123,
						"parentId": 3311
					},
					{
						"countyCode": "331124000",
						"countyName": "松阳县",
						"id": 331124,
						"parentId": 3311
					},
					{
						"countyCode": "331125000",
						"countyName": "云和县",
						"id": 331125,
						"parentId": 3311
					},
					{
						"countyCode": "331126000",
						"countyName": "庆元县",
						"id": 331126,
						"parentId": 3311
					},
					{
						"countyCode": "331127000",
						"countyName": "景宁畲族自治县",
						"id": 331127,
						"parentId": 3311
					},
					{
						"countyCode": "331181000",
						"countyName": "龙泉市",
						"id": 331181,
						"parentId": 3311
					}
				]
			}
		]
	},
	{
		"provinceCode": "340000000",
		"provinceName": "安徽省",
		"id": 34,
		"parentId": 0,
		"children": [{
				"cityCode": "340100000",
				"cityName": "合肥市",
				"id": 3401,
				"parentId": 34,
				"children": [{
						"countyCode": "340102000",
						"countyName": "瑶海区",
						"id": 340102,
						"parentId": 3401
					},
					{
						"countyCode": "340103000",
						"countyName": "庐阳区",
						"id": 340103,
						"parentId": 3401
					},
					{
						"countyCode": "340104000",
						"countyName": "蜀山区",
						"id": 340104,
						"parentId": 3401
					},
					{
						"countyCode": "340111000",
						"countyName": "包河区",
						"id": 340111,
						"parentId": 3401
					},
					{
						"countyCode": "340121000",
						"countyName": "长丰县",
						"id": 340121,
						"parentId": 3401
					},
					{
						"countyCode": "340122000",
						"countyName": "肥东县",
						"id": 340122,
						"parentId": 3401
					},
					{
						"countyCode": "340123000",
						"countyName": "肥西县",
						"id": 340123,
						"parentId": 3401
					},
					{
						"countyCode": "340124000",
						"countyName": "庐江县",
						"id": 340124,
						"parentId": 3401
					},
					{
						"countyCode": "340181000",
						"countyName": "巢湖市",
						"id": 340181,
						"parentId": 3401
					}
				]
			},
			{
				"cityCode": "340200000",
				"cityName": "芜湖市",
				"id": 3402,
				"parentId": 34,
				"children": [{
						"countyCode": "340202000",
						"countyName": "镜湖区",
						"id": 340202,
						"parentId": 3402
					},
					{
						"countyCode": "340203000",
						"countyName": "弋江区",
						"id": 340203,
						"parentId": 3402
					},
					{
						"countyCode": "340207000",
						"countyName": "鸠江区",
						"id": 340207,
						"parentId": 3402
					},
					{
						"countyCode": "340208000",
						"countyName": "三山区",
						"id": 340208,
						"parentId": 3402
					},
					{
						"countyCode": "340221000",
						"countyName": "芜湖县",
						"id": 340221,
						"parentId": 3402
					},
					{
						"countyCode": "340222000",
						"countyName": "繁昌县",
						"id": 340222,
						"parentId": 3402
					},
					{
						"countyCode": "340223000",
						"countyName": "南陵县",
						"id": 340223,
						"parentId": 3402
					},
					{
						"countyCode": "340225000",
						"countyName": "无为县",
						"id": 340225,
						"parentId": 3402
					}
				]
			},
			{
				"cityCode": "340300000",
				"cityName": "蚌埠市",
				"id": 3403,
				"parentId": 34,
				"children": [{
						"countyCode": "340302000",
						"countyName": "龙子湖区",
						"id": 340302,
						"parentId": 3403
					},
					{
						"countyCode": "340303000",
						"countyName": "蚌山区",
						"id": 340303,
						"parentId": 3403
					},
					{
						"countyCode": "340304000",
						"countyName": "禹会区",
						"id": 340304,
						"parentId": 3403
					},
					{
						"countyCode": "340311000",
						"countyName": "淮上区",
						"id": 340311,
						"parentId": 3403
					},
					{
						"countyCode": "340321000",
						"countyName": "怀远县",
						"id": 340321,
						"parentId": 3403
					},
					{
						"countyCode": "340322000",
						"countyName": "五河县",
						"id": 340322,
						"parentId": 3403
					},
					{
						"countyCode": "340323000",
						"countyName": "固镇县",
						"id": 340323,
						"parentId": 3403
					}
				]
			},
			{
				"cityCode": "340400000",
				"cityName": "淮南市",
				"id": 3404,
				"parentId": 34,
				"children": [{
						"countyCode": "340402000",
						"countyName": "大通区",
						"id": 340402,
						"parentId": 3404
					},
					{
						"countyCode": "340403000",
						"countyName": "田家庵区",
						"id": 340403,
						"parentId": 3404
					},
					{
						"countyCode": "340404000",
						"countyName": "谢家集区",
						"id": 340404,
						"parentId": 3404
					},
					{
						"countyCode": "340405000",
						"countyName": "八公山区",
						"id": 340405,
						"parentId": 3404
					},
					{
						"countyCode": "340406000",
						"countyName": "潘集区",
						"id": 340406,
						"parentId": 3404
					},
					{
						"countyCode": "340421000",
						"countyName": "凤台县",
						"id": 340421,
						"parentId": 3404
					},
					{
						"countyCode": "340422000",
						"countyName": "寿县",
						"id": 340422,
						"parentId": 3404
					}
				]
			},
			{
				"cityCode": "340500000",
				"cityName": "马鞍山市",
				"id": 3405,
				"parentId": 34,
				"children": [{
						"countyCode": "340503000",
						"countyName": "花山区",
						"id": 340503,
						"parentId": 3405
					},
					{
						"countyCode": "340504000",
						"countyName": "雨山区",
						"id": 340504,
						"parentId": 3405
					},
					{
						"countyCode": "340506000",
						"countyName": "博望区",
						"id": 340506,
						"parentId": 3405
					},
					{
						"countyCode": "340521000",
						"countyName": "当涂县",
						"id": 340521,
						"parentId": 3405
					},
					{
						"countyCode": "340522000",
						"countyName": "含山县",
						"id": 340522,
						"parentId": 3405
					},
					{
						"countyCode": "340523000",
						"countyName": "和县",
						"id": 340523,
						"parentId": 3405
					}
				]
			},
			{
				"cityCode": "340600000",
				"cityName": "淮北市",
				"id": 3406,
				"parentId": 34,
				"children": [{
						"countyCode": "340602000",
						"countyName": "杜集区",
						"id": 340602,
						"parentId": 3406
					},
					{
						"countyCode": "340603000",
						"countyName": "相山区",
						"id": 340603,
						"parentId": 3406
					},
					{
						"countyCode": "340604000",
						"countyName": "烈山区",
						"id": 340604,
						"parentId": 3406
					},
					{
						"countyCode": "340621000",
						"countyName": "濉溪县",
						"id": 340621,
						"parentId": 3406
					}
				]
			},
			{
				"cityCode": "340700000",
				"cityName": "铜陵市",
				"id": 3407,
				"parentId": 34,
				"children": [{
						"countyCode": "340705000",
						"countyName": "铜官区",
						"id": 340705,
						"parentId": 3407
					},
					{
						"countyCode": "340706000",
						"countyName": "义安区",
						"id": 340706,
						"parentId": 3407
					},
					{
						"countyCode": "340711000",
						"countyName": "郊区",
						"id": 340711,
						"parentId": 3407
					},
					{
						"countyCode": "340722000",
						"countyName": "枞阳县",
						"id": 340722,
						"parentId": 3407
					}
				]
			},
			{
				"cityCode": "340800000",
				"cityName": "安庆市",
				"id": 3408,
				"parentId": 34,
				"children": [{
						"countyCode": "340802000",
						"countyName": "迎江区",
						"id": 340802,
						"parentId": 3408
					},
					{
						"countyCode": "340803000",
						"countyName": "大观区",
						"id": 340803,
						"parentId": 3408
					},
					{
						"countyCode": "340811000",
						"countyName": "宜秀区",
						"id": 340811,
						"parentId": 3408
					},
					{
						"countyCode": "340822000",
						"countyName": "怀宁县",
						"id": 340822,
						"parentId": 3408
					},
					{
						"countyCode": "340825000",
						"countyName": "太湖县",
						"id": 340825,
						"parentId": 3408
					},
					{
						"countyCode": "340826000",
						"countyName": "宿松县",
						"id": 340826,
						"parentId": 3408
					},
					{
						"countyCode": "340827000",
						"countyName": "望江县",
						"id": 340827,
						"parentId": 3408
					},
					{
						"countyCode": "340828000",
						"countyName": "岳西县",
						"id": 340828,
						"parentId": 3408
					},
					{
						"countyCode": "340881000",
						"countyName": "桐城市",
						"id": 340881,
						"parentId": 3408
					},
					{
						"countyCode": "340882000",
						"countyName": "潜山市",
						"id": 340882,
						"parentId": 3408
					}
				]
			},
			{
				"cityCode": "341000000",
				"cityName": "黄山市",
				"id": 3410,
				"parentId": 34,
				"children": [{
						"countyCode": "341002000",
						"countyName": "屯溪区",
						"id": 341002,
						"parentId": 3410
					},
					{
						"countyCode": "341003000",
						"countyName": "黄山区",
						"id": 341003,
						"parentId": 3410
					},
					{
						"countyCode": "341004000",
						"countyName": "徽州区",
						"id": 341004,
						"parentId": 3410
					},
					{
						"countyCode": "341021000",
						"countyName": "歙县",
						"id": 341021,
						"parentId": 3410
					},
					{
						"countyCode": "341022000",
						"countyName": "休宁县",
						"id": 341022,
						"parentId": 3410
					},
					{
						"countyCode": "341023000",
						"countyName": "黟县",
						"id": 341023,
						"parentId": 3410
					},
					{
						"countyCode": "341024000",
						"countyName": "祁门县",
						"id": 341024,
						"parentId": 3410
					}
				]
			},
			{
				"cityCode": "341100000",
				"cityName": "滁州市",
				"id": 3411,
				"parentId": 34,
				"children": [{
						"countyCode": "341102000",
						"countyName": "琅琊区",
						"id": 341102,
						"parentId": 3411
					},
					{
						"countyCode": "341103000",
						"countyName": "南谯区",
						"id": 341103,
						"parentId": 3411
					},
					{
						"countyCode": "341122000",
						"countyName": "来安县",
						"id": 341122,
						"parentId": 3411
					},
					{
						"countyCode": "341124000",
						"countyName": "全椒县",
						"id": 341124,
						"parentId": 3411
					},
					{
						"countyCode": "341125000",
						"countyName": "定远县",
						"id": 341125,
						"parentId": 3411
					},
					{
						"countyCode": "341126000",
						"countyName": "凤阳县",
						"id": 341126,
						"parentId": 3411
					},
					{
						"countyCode": "341181000",
						"countyName": "天长市",
						"id": 341181,
						"parentId": 3411
					},
					{
						"countyCode": "341182000",
						"countyName": "明光市",
						"id": 341182,
						"parentId": 3411
					}
				]
			},
			{
				"cityCode": "341200000",
				"cityName": "阜阳市",
				"id": 3412,
				"parentId": 34,
				"children": [{
						"countyCode": "341202000",
						"countyName": "颍州区",
						"id": 341202,
						"parentId": 3412
					},
					{
						"countyCode": "341203000",
						"countyName": "颍东区",
						"id": 341203,
						"parentId": 3412
					},
					{
						"countyCode": "341204000",
						"countyName": "颍泉区",
						"id": 341204,
						"parentId": 3412
					},
					{
						"countyCode": "341221000",
						"countyName": "临泉县",
						"id": 341221,
						"parentId": 3412
					},
					{
						"countyCode": "341222000",
						"countyName": "太和县",
						"id": 341222,
						"parentId": 3412
					},
					{
						"countyCode": "341225000",
						"countyName": "阜南县",
						"id": 341225,
						"parentId": 3412
					},
					{
						"countyCode": "341226000",
						"countyName": "颍上县",
						"id": 341226,
						"parentId": 3412
					},
					{
						"countyCode": "341282000",
						"countyName": "界首市",
						"id": 341282,
						"parentId": 3412
					}
				]
			},
			{
				"cityCode": "341300000",
				"cityName": "宿州市",
				"id": 3413,
				"parentId": 34,
				"children": [{
						"countyCode": "341302000",
						"countyName": "埇桥区",
						"id": 341302,
						"parentId": 3413
					},
					{
						"countyCode": "341321000",
						"countyName": "砀山县",
						"id": 341321,
						"parentId": 3413
					},
					{
						"countyCode": "341322000",
						"countyName": "萧县",
						"id": 341322,
						"parentId": 3413
					},
					{
						"countyCode": "341323000",
						"countyName": "灵璧县",
						"id": 341323,
						"parentId": 3413
					},
					{
						"countyCode": "341324000",
						"countyName": "泗县",
						"id": 341324,
						"parentId": 3413
					}
				]
			},
			{
				"cityCode": "341500000",
				"cityName": "六安市",
				"id": 3415,
				"parentId": 34,
				"children": [{
						"countyCode": "341502000",
						"countyName": "金安区",
						"id": 341502,
						"parentId": 3415
					},
					{
						"countyCode": "341503000",
						"countyName": "裕安区",
						"id": 341503,
						"parentId": 3415
					},
					{
						"countyCode": "341504000",
						"countyName": "叶集区",
						"id": 341504,
						"parentId": 3415
					},
					{
						"countyCode": "341522000",
						"countyName": "霍邱县",
						"id": 341522,
						"parentId": 3415
					},
					{
						"countyCode": "341523000",
						"countyName": "舒城县",
						"id": 341523,
						"parentId": 3415
					},
					{
						"countyCode": "341524000",
						"countyName": "金寨县",
						"id": 341524,
						"parentId": 3415
					},
					{
						"countyCode": "341525000",
						"countyName": "霍山县",
						"id": 341525,
						"parentId": 3415
					}
				]
			},
			{
				"cityCode": "341600000",
				"cityName": "亳州市",
				"id": 3416,
				"parentId": 34,
				"children": [{
						"countyCode": "341602000",
						"countyName": "谯城区",
						"id": 341602,
						"parentId": 3416
					},
					{
						"countyCode": "341621000",
						"countyName": "涡阳县",
						"id": 341621,
						"parentId": 3416
					},
					{
						"countyCode": "341622000",
						"countyName": "蒙城县",
						"id": 341622,
						"parentId": 3416
					},
					{
						"countyCode": "341623000",
						"countyName": "利辛县",
						"id": 341623,
						"parentId": 3416
					}
				]
			},
			{
				"cityCode": "341700000",
				"cityName": "池州市",
				"id": 3417,
				"parentId": 34,
				"children": [{
						"countyCode": "341702000",
						"countyName": "贵池区",
						"id": 341702,
						"parentId": 3417
					},
					{
						"countyCode": "341721000",
						"countyName": "东至县",
						"id": 341721,
						"parentId": 3417
					},
					{
						"countyCode": "341722000",
						"countyName": "石台县",
						"id": 341722,
						"parentId": 3417
					},
					{
						"countyCode": "341723000",
						"countyName": "青阳县",
						"id": 341723,
						"parentId": 3417
					}
				]
			},
			{
				"cityCode": "341800000",
				"cityName": "宣城市",
				"id": 3418,
				"parentId": 34,
				"children": [{
						"countyCode": "341802000",
						"countyName": "宣州区",
						"id": 341802,
						"parentId": 3418
					},
					{
						"countyCode": "341821000",
						"countyName": "郎溪县",
						"id": 341821,
						"parentId": 3418
					},
					{
						"countyCode": "341822000",
						"countyName": "广德市",
						"id": 341822,
						"parentId": 3418
					},
					{
						"countyCode": "341823000",
						"countyName": "泾县",
						"id": 341823,
						"parentId": 3418
					},
					{
						"countyCode": "341824000",
						"countyName": "绩溪县",
						"id": 341824,
						"parentId": 3418
					},
					{
						"countyCode": "341825000",
						"countyName": "旌德县",
						"id": 341825,
						"parentId": 3418
					},
					{
						"countyCode": "341881000",
						"countyName": "宁国市",
						"id": 341881,
						"parentId": 3418
					}
				]
			}
		]
	},
	{
		"provinceCode": "350000000",
		"provinceName": "福建省",
		"id": 35,
		"parentId": 0,
		"children": [{
				"cityCode": "350100000",
				"cityName": "福州市",
				"id": 3501,
				"parentId": 35,
				"children": [{
						"countyCode": "350102000",
						"countyName": "鼓楼区",
						"id": 350102,
						"parentId": 3501
					},
					{
						"countyCode": "350103000",
						"countyName": "台江区",
						"id": 350103,
						"parentId": 3501
					},
					{
						"countyCode": "350104000",
						"countyName": "仓山区",
						"id": 350104,
						"parentId": 3501
					},
					{
						"countyCode": "350105000",
						"countyName": "马尾区",
						"id": 350105,
						"parentId": 3501
					},
					{
						"countyCode": "350111000",
						"countyName": "晋安区",
						"id": 350111,
						"parentId": 3501
					},
					{
						"countyCode": "350112000",
						"countyName": "长乐区",
						"id": 350112,
						"parentId": 3501
					},
					{
						"countyCode": "350121000",
						"countyName": "闽侯县",
						"id": 350121,
						"parentId": 3501
					},
					{
						"countyCode": "350122000",
						"countyName": "连江县",
						"id": 350122,
						"parentId": 3501
					},
					{
						"countyCode": "350123000",
						"countyName": "罗源县",
						"id": 350123,
						"parentId": 3501
					},
					{
						"countyCode": "350124000",
						"countyName": "闽清县",
						"id": 350124,
						"parentId": 3501
					},
					{
						"countyCode": "350125000",
						"countyName": "永泰县",
						"id": 350125,
						"parentId": 3501
					},
					{
						"countyCode": "350128000",
						"countyName": "平潭县",
						"id": 350128,
						"parentId": 3501
					},
					{
						"countyCode": "350181000",
						"countyName": "福清市",
						"id": 350181,
						"parentId": 3501
					}
				]
			},
			{
				"cityCode": "350200000",
				"cityName": "厦门市",
				"id": 3502,
				"parentId": 35,
				"children": [{
						"countyCode": "350203000",
						"countyName": "思明区",
						"id": 350203,
						"parentId": 3502
					},
					{
						"countyCode": "350205000",
						"countyName": "海沧区",
						"id": 350205,
						"parentId": 3502
					},
					{
						"countyCode": "350206000",
						"countyName": "湖里区",
						"id": 350206,
						"parentId": 3502
					},
					{
						"countyCode": "350211000",
						"countyName": "集美区",
						"id": 350211,
						"parentId": 3502
					},
					{
						"countyCode": "350212000",
						"countyName": "同安区",
						"id": 350212,
						"parentId": 3502
					},
					{
						"countyCode": "350213000",
						"countyName": "翔安区",
						"id": 350213,
						"parentId": 3502
					}
				]
			},
			{
				"cityCode": "350300000",
				"cityName": "莆田市",
				"id": 3503,
				"parentId": 35,
				"children": [{
						"countyCode": "350302000",
						"countyName": "城厢区",
						"id": 350302,
						"parentId": 3503
					},
					{
						"countyCode": "350303000",
						"countyName": "涵江区",
						"id": 350303,
						"parentId": 3503
					},
					{
						"countyCode": "350304000",
						"countyName": "荔城区",
						"id": 350304,
						"parentId": 3503
					},
					{
						"countyCode": "350305000",
						"countyName": "秀屿区",
						"id": 350305,
						"parentId": 3503
					},
					{
						"countyCode": "350322000",
						"countyName": "仙游县",
						"id": 350322,
						"parentId": 3503
					}
				]
			},
			{
				"cityCode": "350400000",
				"cityName": "三明市",
				"id": 3504,
				"parentId": 35,
				"children": [{
						"countyCode": "350402000",
						"countyName": "梅列区",
						"id": 350402,
						"parentId": 3504
					},
					{
						"countyCode": "350403000",
						"countyName": "三元区",
						"id": 350403,
						"parentId": 3504
					},
					{
						"countyCode": "350421000",
						"countyName": "明溪县",
						"id": 350421,
						"parentId": 3504
					},
					{
						"countyCode": "350423000",
						"countyName": "清流县",
						"id": 350423,
						"parentId": 3504
					},
					{
						"countyCode": "350424000",
						"countyName": "宁化县",
						"id": 350424,
						"parentId": 3504
					},
					{
						"countyCode": "350425000",
						"countyName": "大田县",
						"id": 350425,
						"parentId": 3504
					},
					{
						"countyCode": "350426000",
						"countyName": "尤溪县",
						"id": 350426,
						"parentId": 3504
					},
					{
						"countyCode": "350427000",
						"countyName": "沙县",
						"id": 350427,
						"parentId": 3504
					},
					{
						"countyCode": "350428000",
						"countyName": "将乐县",
						"id": 350428,
						"parentId": 3504
					},
					{
						"countyCode": "350429000",
						"countyName": "泰宁县",
						"id": 350429,
						"parentId": 3504
					},
					{
						"countyCode": "350430000",
						"countyName": "建宁县",
						"id": 350430,
						"parentId": 3504
					},
					{
						"countyCode": "350481000",
						"countyName": "永安市",
						"id": 350481,
						"parentId": 3504
					}
				]
			},
			{
				"cityCode": "350500000",
				"cityName": "泉州市",
				"id": 3505,
				"parentId": 35,
				"children": [{
						"countyCode": "350502000",
						"countyName": "鲤城区",
						"id": 350502,
						"parentId": 3505
					},
					{
						"countyCode": "350503000",
						"countyName": "丰泽区",
						"id": 350503,
						"parentId": 3505
					},
					{
						"countyCode": "350504000",
						"countyName": "洛江区",
						"id": 350504,
						"parentId": 3505
					},
					{
						"countyCode": "350505000",
						"countyName": "泉港区",
						"id": 350505,
						"parentId": 3505
					},
					{
						"countyCode": "350521000",
						"countyName": "惠安县",
						"id": 350521,
						"parentId": 3505
					},
					{
						"countyCode": "350524000",
						"countyName": "安溪县",
						"id": 350524,
						"parentId": 3505
					},
					{
						"countyCode": "350525000",
						"countyName": "永春县",
						"id": 350525,
						"parentId": 3505
					},
					{
						"countyCode": "350526000",
						"countyName": "德化县",
						"id": 350526,
						"parentId": 3505
					},
					{
						"countyCode": "350527000",
						"countyName": "金门县",
						"id": 350527,
						"parentId": 3505
					},
					{
						"countyCode": "350581000",
						"countyName": "石狮市",
						"id": 350581,
						"parentId": 3505
					},
					{
						"countyCode": "350582000",
						"countyName": "晋江市",
						"id": 350582,
						"parentId": 3505
					},
					{
						"countyCode": "350583000",
						"countyName": "南安市",
						"id": 350583,
						"parentId": 3505
					}
				]
			},
			{
				"cityCode": "350600000",
				"cityName": "漳州市",
				"id": 3506,
				"parentId": 35,
				"children": [{
						"countyCode": "350602000",
						"countyName": "芗城区",
						"id": 350602,
						"parentId": 3506
					},
					{
						"countyCode": "350603000",
						"countyName": "龙文区",
						"id": 350603,
						"parentId": 3506
					},
					{
						"countyCode": "350622000",
						"countyName": "云霄县",
						"id": 350622,
						"parentId": 3506
					},
					{
						"countyCode": "350623000",
						"countyName": "漳浦县",
						"id": 350623,
						"parentId": 3506
					},
					{
						"countyCode": "350624000",
						"countyName": "诏安县",
						"id": 350624,
						"parentId": 3506
					},
					{
						"countyCode": "350625000",
						"countyName": "长泰县",
						"id": 350625,
						"parentId": 3506
					},
					{
						"countyCode": "350626000",
						"countyName": "东山县",
						"id": 350626,
						"parentId": 3506
					},
					{
						"countyCode": "350627000",
						"countyName": "南靖县",
						"id": 350627,
						"parentId": 3506
					},
					{
						"countyCode": "350628000",
						"countyName": "平和县",
						"id": 350628,
						"parentId": 3506
					},
					{
						"countyCode": "350629000",
						"countyName": "华安县",
						"id": 350629,
						"parentId": 3506
					},
					{
						"countyCode": "350681000",
						"countyName": "龙海市",
						"id": 350681,
						"parentId": 3506
					}
				]
			},
			{
				"cityCode": "350700000",
				"cityName": "南平市",
				"id": 3507,
				"parentId": 35,
				"children": [{
						"countyCode": "350702000",
						"countyName": "延平区",
						"id": 350702,
						"parentId": 3507
					},
					{
						"countyCode": "350703000",
						"countyName": "建阳区",
						"id": 350703,
						"parentId": 3507
					},
					{
						"countyCode": "350721000",
						"countyName": "顺昌县",
						"id": 350721,
						"parentId": 3507
					},
					{
						"countyCode": "350722000",
						"countyName": "浦城县",
						"id": 350722,
						"parentId": 3507
					},
					{
						"countyCode": "350723000",
						"countyName": "光泽县",
						"id": 350723,
						"parentId": 3507
					},
					{
						"countyCode": "350724000",
						"countyName": "松溪县",
						"id": 350724,
						"parentId": 3507
					},
					{
						"countyCode": "350725000",
						"countyName": "政和县",
						"id": 350725,
						"parentId": 3507
					},
					{
						"countyCode": "350781000",
						"countyName": "邵武市",
						"id": 350781,
						"parentId": 3507
					},
					{
						"countyCode": "350782000",
						"countyName": "武夷山市",
						"id": 350782,
						"parentId": 3507
					},
					{
						"countyCode": "350783000",
						"countyName": "建瓯市",
						"id": 350783,
						"parentId": 3507
					}
				]
			},
			{
				"cityCode": "350800000",
				"cityName": "龙岩市",
				"id": 3508,
				"parentId": 35,
				"children": [{
						"countyCode": "350802000",
						"countyName": "新罗区",
						"id": 350802,
						"parentId": 3508
					},
					{
						"countyCode": "350803000",
						"countyName": "永定区",
						"id": 350803,
						"parentId": 3508
					},
					{
						"countyCode": "350821000",
						"countyName": "长汀县",
						"id": 350821,
						"parentId": 3508
					},
					{
						"countyCode": "350823000",
						"countyName": "上杭县",
						"id": 350823,
						"parentId": 3508
					},
					{
						"countyCode": "350824000",
						"countyName": "武平县",
						"id": 350824,
						"parentId": 3508
					},
					{
						"countyCode": "350825000",
						"countyName": "连城县",
						"id": 350825,
						"parentId": 3508
					},
					{
						"countyCode": "350881000",
						"countyName": "漳平市",
						"id": 350881,
						"parentId": 3508
					}
				]
			},
			{
				"cityCode": "350900000",
				"cityName": "宁德市",
				"id": 3509,
				"parentId": 35,
				"children": [{
						"countyCode": "350902000",
						"countyName": "蕉城区",
						"id": 350902,
						"parentId": 3509
					},
					{
						"countyCode": "350921000",
						"countyName": "霞浦县",
						"id": 350921,
						"parentId": 3509
					},
					{
						"countyCode": "350922000",
						"countyName": "古田县",
						"id": 350922,
						"parentId": 3509
					},
					{
						"countyCode": "350923000",
						"countyName": "屏南县",
						"id": 350923,
						"parentId": 3509
					},
					{
						"countyCode": "350924000",
						"countyName": "寿宁县",
						"id": 350924,
						"parentId": 3509
					},
					{
						"countyCode": "350925000",
						"countyName": "周宁县",
						"id": 350925,
						"parentId": 3509
					},
					{
						"countyCode": "350926000",
						"countyName": "柘荣县",
						"id": 350926,
						"parentId": 3509
					},
					{
						"countyCode": "350981000",
						"countyName": "福安市",
						"id": 350981,
						"parentId": 3509
					},
					{
						"countyCode": "350982000",
						"countyName": "福鼎市",
						"id": 350982,
						"parentId": 3509
					}
				]
			}
		]
	},
	{
		"provinceCode": "360000000",
		"provinceName": "江西省",
		"id": 36,
		"parentId": 0,
		"children": [{
				"cityCode": "360100000",
				"cityName": "南昌市",
				"id": 3601,
				"parentId": 36,
				"children": [{
						"countyCode": "360102000",
						"countyName": "东湖区",
						"id": 360102,
						"parentId": 3601
					},
					{
						"countyCode": "360103000",
						"countyName": "西湖区",
						"id": 360103,
						"parentId": 3601
					},
					{
						"countyCode": "360104000",
						"countyName": "青云谱区",
						"id": 360104,
						"parentId": 3601
					},
					{
						"countyCode": "360105000",
						"countyName": "湾里区",
						"id": 360105,
						"parentId": 3601
					},
					{
						"countyCode": "360111000",
						"countyName": "青山湖区",
						"id": 360111,
						"parentId": 3601
					},
					{
						"countyCode": "360112000",
						"countyName": "新建区",
						"id": 360112,
						"parentId": 3601
					},
					{
						"countyCode": "360121000",
						"countyName": "南昌县",
						"id": 360121,
						"parentId": 3601
					},
					{
						"countyCode": "360123000",
						"countyName": "安义县",
						"id": 360123,
						"parentId": 3601
					},
					{
						"countyCode": "360124000",
						"countyName": "进贤县",
						"id": 360124,
						"parentId": 3601
					}
				]
			},
			{
				"cityCode": "360200000",
				"cityName": "景德镇市",
				"id": 3602,
				"parentId": 36,
				"children": [{
						"countyCode": "360202000",
						"countyName": "昌江区",
						"id": 360202,
						"parentId": 3602
					},
					{
						"countyCode": "360203000",
						"countyName": "珠山区",
						"id": 360203,
						"parentId": 3602
					},
					{
						"countyCode": "360222000",
						"countyName": "浮梁县",
						"id": 360222,
						"parentId": 3602
					},
					{
						"countyCode": "360281000",
						"countyName": "乐平市",
						"id": 360281,
						"parentId": 3602
					}
				]
			},
			{
				"cityCode": "360300000",
				"cityName": "萍乡市",
				"id": 3603,
				"parentId": 36,
				"children": [{
						"countyCode": "360302000",
						"countyName": "安源区",
						"id": 360302,
						"parentId": 3603
					},
					{
						"countyCode": "360313000",
						"countyName": "湘东区",
						"id": 360313,
						"parentId": 3603
					},
					{
						"countyCode": "360321000",
						"countyName": "莲花县",
						"id": 360321,
						"parentId": 3603
					},
					{
						"countyCode": "360322000",
						"countyName": "上栗县",
						"id": 360322,
						"parentId": 3603
					},
					{
						"countyCode": "360323000",
						"countyName": "芦溪县",
						"id": 360323,
						"parentId": 3603
					}
				]
			},
			{
				"cityCode": "360400000",
				"cityName": "九江市",
				"id": 3604,
				"parentId": 36,
				"children": [{
						"countyCode": "360402000",
						"countyName": "濂溪区",
						"id": 360402,
						"parentId": 3604
					},
					{
						"countyCode": "360403000",
						"countyName": "浔阳区",
						"id": 360403,
						"parentId": 3604
					},
					{
						"countyCode": "360404000",
						"countyName": "柴桑区",
						"id": 360404,
						"parentId": 3604
					},
					{
						"countyCode": "360423000",
						"countyName": "武宁县",
						"id": 360423,
						"parentId": 3604
					},
					{
						"countyCode": "360424000",
						"countyName": "修水县",
						"id": 360424,
						"parentId": 3604
					},
					{
						"countyCode": "360425000",
						"countyName": "永修县",
						"id": 360425,
						"parentId": 3604
					},
					{
						"countyCode": "360426000",
						"countyName": "德安县",
						"id": 360426,
						"parentId": 3604
					},
					{
						"countyCode": "360428000",
						"countyName": "都昌县",
						"id": 360428,
						"parentId": 3604
					},
					{
						"countyCode": "360429000",
						"countyName": "湖口县",
						"id": 360429,
						"parentId": 3604
					},
					{
						"countyCode": "360430000",
						"countyName": "彭泽县",
						"id": 360430,
						"parentId": 3604
					},
					{
						"countyCode": "360481000",
						"countyName": "瑞昌市",
						"id": 360481,
						"parentId": 3604
					},
					{
						"countyCode": "360482000",
						"countyName": "共青城市",
						"id": 360482,
						"parentId": 3604
					},
					{
						"countyCode": "360483000",
						"countyName": "庐山市",
						"id": 360483,
						"parentId": 3604
					}
				]
			},
			{
				"cityCode": "360500000",
				"cityName": "新余市",
				"id": 3605,
				"parentId": 36,
				"children": [{
						"countyCode": "360502000",
						"countyName": "渝水区",
						"id": 360502,
						"parentId": 3605
					},
					{
						"countyCode": "360521000",
						"countyName": "分宜县",
						"id": 360521,
						"parentId": 3605
					}
				]
			},
			{
				"cityCode": "360600000",
				"cityName": "鹰潭市",
				"id": 3606,
				"parentId": 36,
				"children": [{
						"countyCode": "360602000",
						"countyName": "月湖区",
						"id": 360602,
						"parentId": 3606
					},
					{
						"countyCode": "360603000",
						"countyName": "余江区",
						"id": 360603,
						"parentId": 3606
					},
					{
						"countyCode": "360681000",
						"countyName": "贵溪市",
						"id": 360681,
						"parentId": 3606
					}
				]
			},
			{
				"cityCode": "360700000",
				"cityName": "赣州市",
				"id": 3607,
				"parentId": 36,
				"children": [{
						"countyCode": "360702000",
						"countyName": "章贡区",
						"id": 360702,
						"parentId": 3607
					},
					{
						"countyCode": "360703000",
						"countyName": "南康区",
						"id": 360703,
						"parentId": 3607
					},
					{
						"countyCode": "360704000",
						"countyName": "赣县区",
						"id": 360704,
						"parentId": 3607
					},
					{
						"countyCode": "360722000",
						"countyName": "信丰县",
						"id": 360722,
						"parentId": 3607
					},
					{
						"countyCode": "360723000",
						"countyName": "大余县",
						"id": 360723,
						"parentId": 3607
					},
					{
						"countyCode": "360724000",
						"countyName": "上犹县",
						"id": 360724,
						"parentId": 3607
					},
					{
						"countyCode": "360725000",
						"countyName": "崇义县",
						"id": 360725,
						"parentId": 3607
					},
					{
						"countyCode": "360726000",
						"countyName": "安远县",
						"id": 360726,
						"parentId": 3607
					},
					{
						"countyCode": "360727000",
						"countyName": "龙南县",
						"id": 360727,
						"parentId": 3607
					},
					{
						"countyCode": "360728000",
						"countyName": "定南县",
						"id": 360728,
						"parentId": 3607
					},
					{
						"countyCode": "360729000",
						"countyName": "全南县",
						"id": 360729,
						"parentId": 3607
					},
					{
						"countyCode": "360730000",
						"countyName": "宁都县",
						"id": 360730,
						"parentId": 3607
					},
					{
						"countyCode": "360731000",
						"countyName": "于都县",
						"id": 360731,
						"parentId": 3607
					},
					{
						"countyCode": "360732000",
						"countyName": "兴国县",
						"id": 360732,
						"parentId": 3607
					},
					{
						"countyCode": "360733000",
						"countyName": "会昌县",
						"id": 360733,
						"parentId": 3607
					},
					{
						"countyCode": "360734000",
						"countyName": "寻乌县",
						"id": 360734,
						"parentId": 3607
					},
					{
						"countyCode": "360735000",
						"countyName": "石城县",
						"id": 360735,
						"parentId": 3607
					},
					{
						"countyCode": "360781000",
						"countyName": "瑞金市",
						"id": 360781,
						"parentId": 3607
					}
				]
			},
			{
				"cityCode": "360800000",
				"cityName": "吉安市",
				"id": 3608,
				"parentId": 36,
				"children": [{
						"countyCode": "360802000",
						"countyName": "吉州区",
						"id": 360802,
						"parentId": 3608
					},
					{
						"countyCode": "360803000",
						"countyName": "青原区",
						"id": 360803,
						"parentId": 3608
					},
					{
						"countyCode": "360821000",
						"countyName": "吉安县",
						"id": 360821,
						"parentId": 3608
					},
					{
						"countyCode": "360822000",
						"countyName": "吉水县",
						"id": 360822,
						"parentId": 3608
					},
					{
						"countyCode": "360823000",
						"countyName": "峡江县",
						"id": 360823,
						"parentId": 3608
					},
					{
						"countyCode": "360824000",
						"countyName": "新干县",
						"id": 360824,
						"parentId": 3608
					},
					{
						"countyCode": "360825000",
						"countyName": "永丰县",
						"id": 360825,
						"parentId": 3608
					},
					{
						"countyCode": "360826000",
						"countyName": "泰和县",
						"id": 360826,
						"parentId": 3608
					},
					{
						"countyCode": "360827000",
						"countyName": "遂川县",
						"id": 360827,
						"parentId": 3608
					},
					{
						"countyCode": "360828000",
						"countyName": "万安县",
						"id": 360828,
						"parentId": 3608
					},
					{
						"countyCode": "360829000",
						"countyName": "安福县",
						"id": 360829,
						"parentId": 3608
					},
					{
						"countyCode": "360830000",
						"countyName": "永新县",
						"id": 360830,
						"parentId": 3608
					},
					{
						"countyCode": "360881000",
						"countyName": "井冈山市",
						"id": 360881,
						"parentId": 3608
					}
				]
			},
			{
				"cityCode": "360900000",
				"cityName": "宜春市",
				"id": 3609,
				"parentId": 36,
				"children": [{
						"countyCode": "360902000",
						"countyName": "袁州区",
						"id": 360902,
						"parentId": 3609
					},
					{
						"countyCode": "360921000",
						"countyName": "奉新县",
						"id": 360921,
						"parentId": 3609
					},
					{
						"countyCode": "360922000",
						"countyName": "万载县",
						"id": 360922,
						"parentId": 3609
					},
					{
						"countyCode": "360923000",
						"countyName": "上高县",
						"id": 360923,
						"parentId": 3609
					},
					{
						"countyCode": "360924000",
						"countyName": "宜丰县",
						"id": 360924,
						"parentId": 3609
					},
					{
						"countyCode": "360925000",
						"countyName": "靖安县",
						"id": 360925,
						"parentId": 3609
					},
					{
						"countyCode": "360926000",
						"countyName": "铜鼓县",
						"id": 360926,
						"parentId": 3609
					},
					{
						"countyCode": "360981000",
						"countyName": "丰城市",
						"id": 360981,
						"parentId": 3609
					},
					{
						"countyCode": "360982000",
						"countyName": "樟树市",
						"id": 360982,
						"parentId": 3609
					},
					{
						"countyCode": "360983000",
						"countyName": "高安市",
						"id": 360983,
						"parentId": 3609
					}
				]
			},
			{
				"cityCode": "361000000",
				"cityName": "抚州市",
				"id": 3610,
				"parentId": 36,
				"children": [{
						"countyCode": "361002000",
						"countyName": "临川区",
						"id": 361002,
						"parentId": 3610
					},
					{
						"countyCode": "361003000",
						"countyName": "东乡区",
						"id": 361003,
						"parentId": 3610
					},
					{
						"countyCode": "361021000",
						"countyName": "南城县",
						"id": 361021,
						"parentId": 3610
					},
					{
						"countyCode": "361022000",
						"countyName": "黎川县",
						"id": 361022,
						"parentId": 3610
					},
					{
						"countyCode": "361023000",
						"countyName": "南丰县",
						"id": 361023,
						"parentId": 3610
					},
					{
						"countyCode": "361024000",
						"countyName": "崇仁县",
						"id": 361024,
						"parentId": 3610
					},
					{
						"countyCode": "361025000",
						"countyName": "乐安县",
						"id": 361025,
						"parentId": 3610
					},
					{
						"countyCode": "361026000",
						"countyName": "宜黄县",
						"id": 361026,
						"parentId": 3610
					},
					{
						"countyCode": "361027000",
						"countyName": "金溪县",
						"id": 361027,
						"parentId": 3610
					},
					{
						"countyCode": "361028000",
						"countyName": "资溪县",
						"id": 361028,
						"parentId": 3610
					},
					{
						"countyCode": "361030000",
						"countyName": "广昌县",
						"id": 361030,
						"parentId": 3610
					}
				]
			},
			{
				"cityCode": "361100000",
				"cityName": "上饶市",
				"id": 3611,
				"parentId": 36,
				"children": [{
						"countyCode": "361102000",
						"countyName": "信州区",
						"id": 361102,
						"parentId": 3611
					},
					{
						"countyCode": "361103000",
						"countyName": "广丰区",
						"id": 361103,
						"parentId": 3611
					},
					{
						"countyCode": "361121000",
						"countyName": "广信区",
						"id": 361121,
						"parentId": 3611
					},
					{
						"countyCode": "361123000",
						"countyName": "玉山县",
						"id": 361123,
						"parentId": 3611
					},
					{
						"countyCode": "361124000",
						"countyName": "铅山县",
						"id": 361124,
						"parentId": 3611
					},
					{
						"countyCode": "361125000",
						"countyName": "横峰县",
						"id": 361125,
						"parentId": 3611
					},
					{
						"countyCode": "361126000",
						"countyName": "弋阳县",
						"id": 361126,
						"parentId": 3611
					},
					{
						"countyCode": "361127000",
						"countyName": "余干县",
						"id": 361127,
						"parentId": 3611
					},
					{
						"countyCode": "361128000",
						"countyName": "鄱阳县",
						"id": 361128,
						"parentId": 3611
					},
					{
						"countyCode": "361129000",
						"countyName": "万年县",
						"id": 361129,
						"parentId": 3611
					},
					{
						"countyCode": "361130000",
						"countyName": "婺源县",
						"id": 361130,
						"parentId": 3611
					},
					{
						"countyCode": "361181000",
						"countyName": "德兴市",
						"id": 361181,
						"parentId": 3611
					}
				]
			}
		]
	},
	{
		"provinceCode": "370000000",
		"provinceName": "山东省",
		"id": 37,
		"parentId": 0,
		"children": [{
				"cityCode": "370100000",
				"cityName": "济南市",
				"id": 3701,
				"parentId": 37,
				"children": [{
						"countyCode": "370102000",
						"countyName": "历下区",
						"id": 370102,
						"parentId": 3701
					},
					{
						"countyCode": "370103000",
						"countyName": "市中区",
						"id": 370103,
						"parentId": 3701
					},
					{
						"countyCode": "370104000",
						"countyName": "槐荫区",
						"id": 370104,
						"parentId": 3701
					},
					{
						"countyCode": "370105000",
						"countyName": "天桥区",
						"id": 370105,
						"parentId": 3701
					},
					{
						"countyCode": "370112000",
						"countyName": "历城区",
						"id": 370112,
						"parentId": 3701
					},
					{
						"countyCode": "370113000",
						"countyName": "长清区",
						"id": 370113,
						"parentId": 3701
					},
					{
						"countyCode": "370114000",
						"countyName": "章丘区",
						"id": 370114,
						"parentId": 3701
					},
					{
						"countyCode": "370115000",
						"countyName": "济阳区",
						"id": 370115,
						"parentId": 3701
					},
					{
						"countyCode": "370116000",
						"countyName": "莱芜区",
						"id": 370116,
						"parentId": 3701
					},
					{
						"countyCode": "370117000",
						"countyName": "钢城区",
						"id": 370117,
						"parentId": 3701
					},
					{
						"countyCode": "370124000",
						"countyName": "平阴县",
						"id": 370124,
						"parentId": 3701
					},
					{
						"countyCode": "370126000",
						"countyName": "商河县",
						"id": 370126,
						"parentId": 3701
					}
				]
			},
			{
				"cityCode": "370200000",
				"cityName": "青岛市",
				"id": 3702,
				"parentId": 37,
				"children": [{
						"countyCode": "370202000",
						"countyName": "市南区",
						"id": 370202,
						"parentId": 3702
					},
					{
						"countyCode": "370203000",
						"countyName": "市北区",
						"id": 370203,
						"parentId": 3702
					},
					{
						"countyCode": "370211000",
						"countyName": "黄岛区",
						"id": 370211,
						"parentId": 3702
					},
					{
						"countyCode": "370212000",
						"countyName": "崂山区",
						"id": 370212,
						"parentId": 3702
					},
					{
						"countyCode": "370213000",
						"countyName": "李沧区",
						"id": 370213,
						"parentId": 3702
					},
					{
						"countyCode": "370214000",
						"countyName": "城阳区",
						"id": 370214,
						"parentId": 3702
					},
					{
						"countyCode": "370215000",
						"countyName": "即墨区",
						"id": 370215,
						"parentId": 3702
					},
					{
						"countyCode": "370281000",
						"countyName": "胶州市",
						"id": 370281,
						"parentId": 3702
					},
					{
						"countyCode": "370283000",
						"countyName": "平度市",
						"id": 370283,
						"parentId": 3702
					},
					{
						"countyCode": "370285000",
						"countyName": "莱西市",
						"id": 370285,
						"parentId": 3702
					}
				]
			},
			{
				"cityCode": "370300000",
				"cityName": "淄博市",
				"id": 3703,
				"parentId": 37,
				"children": [{
						"countyCode": "370302000",
						"countyName": "淄川区",
						"id": 370302,
						"parentId": 3703
					},
					{
						"countyCode": "370303000",
						"countyName": "张店区",
						"id": 370303,
						"parentId": 3703
					},
					{
						"countyCode": "370304000",
						"countyName": "博山区",
						"id": 370304,
						"parentId": 3703
					},
					{
						"countyCode": "370305000",
						"countyName": "临淄区",
						"id": 370305,
						"parentId": 3703
					},
					{
						"countyCode": "370306000",
						"countyName": "周村区",
						"id": 370306,
						"parentId": 3703
					},
					{
						"countyCode": "370321000",
						"countyName": "桓台县",
						"id": 370321,
						"parentId": 3703
					},
					{
						"countyCode": "370322000",
						"countyName": "高青县",
						"id": 370322,
						"parentId": 3703
					},
					{
						"countyCode": "370323000",
						"countyName": "沂源县",
						"id": 370323,
						"parentId": 3703
					}
				]
			},
			{
				"cityCode": "370400000",
				"cityName": "枣庄市",
				"id": 3704,
				"parentId": 37,
				"children": [{
						"countyCode": "370402000",
						"countyName": "市中区",
						"id": 370402,
						"parentId": 3704
					},
					{
						"countyCode": "370403000",
						"countyName": "薛城区",
						"id": 370403,
						"parentId": 3704
					},
					{
						"countyCode": "370404000",
						"countyName": "峄城区",
						"id": 370404,
						"parentId": 3704
					},
					{
						"countyCode": "370405000",
						"countyName": "台儿庄区",
						"id": 370405,
						"parentId": 3704
					},
					{
						"countyCode": "370406000",
						"countyName": "山亭区",
						"id": 370406,
						"parentId": 3704
					},
					{
						"countyCode": "370481000",
						"countyName": "滕州市",
						"id": 370481,
						"parentId": 3704
					}
				]
			},
			{
				"cityCode": "370500000",
				"cityName": "东营市",
				"id": 3705,
				"parentId": 37,
				"children": [{
						"countyCode": "370502000",
						"countyName": "东营区",
						"id": 370502,
						"parentId": 3705
					},
					{
						"countyCode": "370503000",
						"countyName": "河口区",
						"id": 370503,
						"parentId": 3705
					},
					{
						"countyCode": "370505000",
						"countyName": "垦利区",
						"id": 370505,
						"parentId": 3705
					},
					{
						"countyCode": "370522000",
						"countyName": "利津县",
						"id": 370522,
						"parentId": 3705
					},
					{
						"countyCode": "370523000",
						"countyName": "广饶县",
						"id": 370523,
						"parentId": 3705
					}
				]
			},
			{
				"cityCode": "370600000",
				"cityName": "烟台市",
				"id": 3706,
				"parentId": 37,
				"children": [{
						"countyCode": "370602000",
						"countyName": "芝罘区",
						"id": 370602,
						"parentId": 3706
					},
					{
						"countyCode": "370611000",
						"countyName": "福山区",
						"id": 370611,
						"parentId": 3706
					},
					{
						"countyCode": "370612000",
						"countyName": "牟平区",
						"id": 370612,
						"parentId": 3706
					},
					{
						"countyCode": "370613000",
						"countyName": "莱山区",
						"id": 370613,
						"parentId": 3706
					},
					{
						"countyCode": "370634000",
						"countyName": "长岛县",
						"id": 370634,
						"parentId": 3706
					},
					{
						"countyCode": "370681000",
						"countyName": "龙口市",
						"id": 370681,
						"parentId": 3706
					},
					{
						"countyCode": "370682000",
						"countyName": "莱阳市",
						"id": 370682,
						"parentId": 3706
					},
					{
						"countyCode": "370683000",
						"countyName": "莱州市",
						"id": 370683,
						"parentId": 3706
					},
					{
						"countyCode": "370684000",
						"countyName": "蓬莱市",
						"id": 370684,
						"parentId": 3706
					},
					{
						"countyCode": "370685000",
						"countyName": "招远市",
						"id": 370685,
						"parentId": 3706
					},
					{
						"countyCode": "370686000",
						"countyName": "栖霞市",
						"id": 370686,
						"parentId": 3706
					},
					{
						"countyCode": "370687000",
						"countyName": "海阳市",
						"id": 370687,
						"parentId": 3706
					}
				]
			},
			{
				"cityCode": "370700000",
				"cityName": "潍坊市",
				"id": 3707,
				"parentId": 37,
				"children": [{
						"countyCode": "370702000",
						"countyName": "潍城区",
						"id": 370702,
						"parentId": 3707
					},
					{
						"countyCode": "370703000",
						"countyName": "寒亭区",
						"id": 370703,
						"parentId": 3707
					},
					{
						"countyCode": "370704000",
						"countyName": "坊子区",
						"id": 370704,
						"parentId": 3707
					},
					{
						"countyCode": "370705000",
						"countyName": "奎文区",
						"id": 370705,
						"parentId": 3707
					},
					{
						"countyCode": "370724000",
						"countyName": "临朐县",
						"id": 370724,
						"parentId": 3707
					},
					{
						"countyCode": "370725000",
						"countyName": "昌乐县",
						"id": 370725,
						"parentId": 3707
					},
					{
						"countyCode": "370781000",
						"countyName": "青州市",
						"id": 370781,
						"parentId": 3707
					},
					{
						"countyCode": "370782000",
						"countyName": "诸城市",
						"id": 370782,
						"parentId": 3707
					},
					{
						"countyCode": "370783000",
						"countyName": "寿光市",
						"id": 370783,
						"parentId": 3707
					},
					{
						"countyCode": "370784000",
						"countyName": "安丘市",
						"id": 370784,
						"parentId": 3707
					},
					{
						"countyCode": "370785000",
						"countyName": "高密市",
						"id": 370785,
						"parentId": 3707
					},
					{
						"countyCode": "370786000",
						"countyName": "昌邑市",
						"id": 370786,
						"parentId": 3707
					}
				]
			},
			{
				"cityCode": "370800000",
				"cityName": "济宁市",
				"id": 3708,
				"parentId": 37,
				"children": [{
						"countyCode": "370811000",
						"countyName": "任城区",
						"id": 370811,
						"parentId": 3708
					},
					{
						"countyCode": "370812000",
						"countyName": "兖州区",
						"id": 370812,
						"parentId": 3708
					},
					{
						"countyCode": "370826000",
						"countyName": "微山县",
						"id": 370826,
						"parentId": 3708
					},
					{
						"countyCode": "370827000",
						"countyName": "鱼台县",
						"id": 370827,
						"parentId": 3708
					},
					{
						"countyCode": "370828000",
						"countyName": "金乡县",
						"id": 370828,
						"parentId": 3708
					},
					{
						"countyCode": "370829000",
						"countyName": "嘉祥县",
						"id": 370829,
						"parentId": 3708
					},
					{
						"countyCode": "370830000",
						"countyName": "汶上县",
						"id": 370830,
						"parentId": 3708
					},
					{
						"countyCode": "370831000",
						"countyName": "泗水县",
						"id": 370831,
						"parentId": 3708
					},
					{
						"countyCode": "370832000",
						"countyName": "梁山县",
						"id": 370832,
						"parentId": 3708
					},
					{
						"countyCode": "370881000",
						"countyName": "曲阜市",
						"id": 370881,
						"parentId": 3708
					},
					{
						"countyCode": "370883000",
						"countyName": "邹城市",
						"id": 370883,
						"parentId": 3708
					}
				]
			},
			{
				"cityCode": "370900000",
				"cityName": "泰安市",
				"id": 3709,
				"parentId": 37,
				"children": [{
						"countyCode": "370902000",
						"countyName": "泰山区",
						"id": 370902,
						"parentId": 3709
					},
					{
						"countyCode": "370911000",
						"countyName": "岱岳区",
						"id": 370911,
						"parentId": 3709
					},
					{
						"countyCode": "370921000",
						"countyName": "宁阳县",
						"id": 370921,
						"parentId": 3709
					},
					{
						"countyCode": "370923000",
						"countyName": "东平县",
						"id": 370923,
						"parentId": 3709
					},
					{
						"countyCode": "370982000",
						"countyName": "新泰市",
						"id": 370982,
						"parentId": 3709
					},
					{
						"countyCode": "370983000",
						"countyName": "肥城市",
						"id": 370983,
						"parentId": 3709
					}
				]
			},
			{
				"cityCode": "371000000",
				"cityName": "威海市",
				"id": 3710,
				"parentId": 37,
				"children": [{
						"countyCode": "371002000",
						"countyName": "环翠区",
						"id": 371002,
						"parentId": 3710
					},
					{
						"countyCode": "371003000",
						"countyName": "文登区",
						"id": 371003,
						"parentId": 3710
					},
					{
						"countyCode": "371082000",
						"countyName": "荣成市",
						"id": 371082,
						"parentId": 3710
					},
					{
						"countyCode": "371083000",
						"countyName": "乳山市",
						"id": 371083,
						"parentId": 3710
					}
				]
			},
			{
				"cityCode": "371100000",
				"cityName": "日照市",
				"id": 3711,
				"parentId": 37,
				"children": [{
						"countyCode": "371102000",
						"countyName": "东港区",
						"id": 371102,
						"parentId": 3711
					},
					{
						"countyCode": "371103000",
						"countyName": "岚山区",
						"id": 371103,
						"parentId": 3711
					},
					{
						"countyCode": "371121000",
						"countyName": "五莲县",
						"id": 371121,
						"parentId": 3711
					},
					{
						"countyCode": "371122000",
						"countyName": "莒县",
						"id": 371122,
						"parentId": 3711
					}
				]
			},
			{
				"cityCode": "371300000",
				"cityName": "临沂市",
				"id": 3713,
				"parentId": 37,
				"children": [{
						"countyCode": "371302000",
						"countyName": "兰山区",
						"id": 371302,
						"parentId": 3713
					},
					{
						"countyCode": "371311000",
						"countyName": "罗庄区",
						"id": 371311,
						"parentId": 3713
					},
					{
						"countyCode": "371312000",
						"countyName": "河东区",
						"id": 371312,
						"parentId": 3713
					},
					{
						"countyCode": "371321000",
						"countyName": "沂南县",
						"id": 371321,
						"parentId": 3713
					},
					{
						"countyCode": "371322000",
						"countyName": "郯城县",
						"id": 371322,
						"parentId": 3713
					},
					{
						"countyCode": "371323000",
						"countyName": "沂水县",
						"id": 371323,
						"parentId": 3713
					},
					{
						"countyCode": "371324000",
						"countyName": "兰陵县",
						"id": 371324,
						"parentId": 3713
					},
					{
						"countyCode": "371325000",
						"countyName": "费县",
						"id": 371325,
						"parentId": 3713
					},
					{
						"countyCode": "371326000",
						"countyName": "平邑县",
						"id": 371326,
						"parentId": 3713
					},
					{
						"countyCode": "371327000",
						"countyName": "莒南县",
						"id": 371327,
						"parentId": 3713
					},
					{
						"countyCode": "371328000",
						"countyName": "蒙阴县",
						"id": 371328,
						"parentId": 3713
					},
					{
						"countyCode": "371329000",
						"countyName": "临沭县",
						"id": 371329,
						"parentId": 3713
					}
				]
			},
			{
				"cityCode": "371400000",
				"cityName": "德州市",
				"id": 3714,
				"parentId": 37,
				"children": [{
						"countyCode": "371402000",
						"countyName": "德城区",
						"id": 371402,
						"parentId": 3714
					},
					{
						"countyCode": "371403000",
						"countyName": "陵城区",
						"id": 371403,
						"parentId": 3714
					},
					{
						"countyCode": "371422000",
						"countyName": "宁津县",
						"id": 371422,
						"parentId": 3714
					},
					{
						"countyCode": "371423000",
						"countyName": "庆云县",
						"id": 371423,
						"parentId": 3714
					},
					{
						"countyCode": "371424000",
						"countyName": "临邑县",
						"id": 371424,
						"parentId": 3714
					},
					{
						"countyCode": "371425000",
						"countyName": "齐河县",
						"id": 371425,
						"parentId": 3714
					},
					{
						"countyCode": "371426000",
						"countyName": "平原县",
						"id": 371426,
						"parentId": 3714
					},
					{
						"countyCode": "371427000",
						"countyName": "夏津县",
						"id": 371427,
						"parentId": 3714
					},
					{
						"countyCode": "371428000",
						"countyName": "武城县",
						"id": 371428,
						"parentId": 3714
					},
					{
						"countyCode": "371481000",
						"countyName": "乐陵市",
						"id": 371481,
						"parentId": 3714
					},
					{
						"countyCode": "371482000",
						"countyName": "禹城市",
						"id": 371482,
						"parentId": 3714
					}
				]
			},
			{
				"cityCode": "371500000",
				"cityName": "聊城市",
				"id": 3715,
				"parentId": 37,
				"children": [{
						"countyCode": "371502000",
						"countyName": "东昌府区",
						"id": 371502,
						"parentId": 3715
					},
					{
						"countyCode": "371521000",
						"countyName": "阳谷县",
						"id": 371521,
						"parentId": 3715
					},
					{
						"countyCode": "371522000",
						"countyName": "莘县",
						"id": 371522,
						"parentId": 3715
					},
					{
						"countyCode": "371523000",
						"countyName": "茌平区",
						"id": 371523,
						"parentId": 3715
					},
					{
						"countyCode": "371524000",
						"countyName": "东阿县",
						"id": 371524,
						"parentId": 3715
					},
					{
						"countyCode": "371525000",
						"countyName": "冠县",
						"id": 371525,
						"parentId": 3715
					},
					{
						"countyCode": "371526000",
						"countyName": "高唐县",
						"id": 371526,
						"parentId": 3715
					},
					{
						"countyCode": "371581000",
						"countyName": "临清市",
						"id": 371581,
						"parentId": 3715
					}
				]
			},
			{
				"cityCode": "371600000",
				"cityName": "滨州市",
				"id": 3716,
				"parentId": 37,
				"children": [{
						"countyCode": "371602000",
						"countyName": "滨城区",
						"id": 371602,
						"parentId": 3716
					},
					{
						"countyCode": "371603000",
						"countyName": "沾化区",
						"id": 371603,
						"parentId": 3716
					},
					{
						"countyCode": "371621000",
						"countyName": "惠民县",
						"id": 371621,
						"parentId": 3716
					},
					{
						"countyCode": "371622000",
						"countyName": "阳信县",
						"id": 371622,
						"parentId": 3716
					},
					{
						"countyCode": "371623000",
						"countyName": "无棣县",
						"id": 371623,
						"parentId": 3716
					},
					{
						"countyCode": "371625000",
						"countyName": "博兴县",
						"id": 371625,
						"parentId": 3716
					},
					{
						"countyCode": "371681000",
						"countyName": "邹平市",
						"id": 371681,
						"parentId": 3716
					}
				]
			},
			{
				"cityCode": "371700000",
				"cityName": "菏泽市",
				"id": 3717,
				"parentId": 37,
				"children": [{
						"countyCode": "371702000",
						"countyName": "牡丹区",
						"id": 371702,
						"parentId": 3717
					},
					{
						"countyCode": "371703000",
						"countyName": "定陶区",
						"id": 371703,
						"parentId": 3717
					},
					{
						"countyCode": "371721000",
						"countyName": "曹县",
						"id": 371721,
						"parentId": 3717
					},
					{
						"countyCode": "371722000",
						"countyName": "单县",
						"id": 371722,
						"parentId": 3717
					},
					{
						"countyCode": "371723000",
						"countyName": "成武县",
						"id": 371723,
						"parentId": 3717
					},
					{
						"countyCode": "371724000",
						"countyName": "巨野县",
						"id": 371724,
						"parentId": 3717
					},
					{
						"countyCode": "371725000",
						"countyName": "郓城县",
						"id": 371725,
						"parentId": 3717
					},
					{
						"countyCode": "371726000",
						"countyName": "鄄城县",
						"id": 371726,
						"parentId": 3717
					},
					{
						"countyCode": "371728000",
						"countyName": "东明县",
						"id": 371728,
						"parentId": 3717
					}
				]
			}
		]
	},
	{
		"provinceCode": "410000000",
		"provinceName": "河南省",
		"id": 41,
		"parentId": 0,
		"children": [{
				"cityCode": "410100000",
				"cityName": "郑州市",
				"id": 4101,
				"parentId": 41,
				"children": [{
						"countyCode": "410102000",
						"countyName": "中原区",
						"id": 410102,
						"parentId": 4101
					},
					{
						"countyCode": "410103000",
						"countyName": "二七区",
						"id": 410103,
						"parentId": 4101
					},
					{
						"countyCode": "410104000",
						"countyName": "管城回族区",
						"id": 410104,
						"parentId": 4101
					},
					{
						"countyCode": "410105000",
						"countyName": "金水区",
						"id": 410105,
						"parentId": 4101
					},
					{
						"countyCode": "410106000",
						"countyName": "上街区",
						"id": 410106,
						"parentId": 4101
					},
					{
						"countyCode": "410108000",
						"countyName": "惠济区",
						"id": 410108,
						"parentId": 4101
					},
					{
						"countyCode": "410122000",
						"countyName": "中牟县",
						"id": 410122,
						"parentId": 4101
					},
					{
						"countyCode": "410181000",
						"countyName": "巩义市",
						"id": 410181,
						"parentId": 4101
					},
					{
						"countyCode": "410182000",
						"countyName": "荥阳市",
						"id": 410182,
						"parentId": 4101
					},
					{
						"countyCode": "410183000",
						"countyName": "新密市",
						"id": 410183,
						"parentId": 4101
					},
					{
						"countyCode": "410184000",
						"countyName": "新郑市",
						"id": 410184,
						"parentId": 4101
					},
					{
						"countyCode": "410185000",
						"countyName": "登封市",
						"id": 410185,
						"parentId": 4101
					}
				]
			},
			{
				"cityCode": "410200000",
				"cityName": "开封市",
				"id": 4102,
				"parentId": 41,
				"children": [{
						"countyCode": "410202000",
						"countyName": "龙亭区",
						"id": 410202,
						"parentId": 4102
					},
					{
						"countyCode": "410203000",
						"countyName": "顺河回族区",
						"id": 410203,
						"parentId": 4102
					},
					{
						"countyCode": "410204000",
						"countyName": "鼓楼区",
						"id": 410204,
						"parentId": 4102
					},
					{
						"countyCode": "410205000",
						"countyName": "禹王台区",
						"id": 410205,
						"parentId": 4102
					},
					{
						"countyCode": "410212000",
						"countyName": "祥符区",
						"id": 410212,
						"parentId": 4102
					},
					{
						"countyCode": "410221000",
						"countyName": "杞县",
						"id": 410221,
						"parentId": 4102
					},
					{
						"countyCode": "410222000",
						"countyName": "通许县",
						"id": 410222,
						"parentId": 4102
					},
					{
						"countyCode": "410223000",
						"countyName": "尉氏县",
						"id": 410223,
						"parentId": 4102
					},
					{
						"countyCode": "410225000",
						"countyName": "兰考县",
						"id": 410225,
						"parentId": 4102
					}
				]
			},
			{
				"cityCode": "410300000",
				"cityName": "洛阳市",
				"id": 4103,
				"parentId": 41,
				"children": [{
						"countyCode": "410302000",
						"countyName": "老城区",
						"id": 410302,
						"parentId": 4103
					},
					{
						"countyCode": "410303000",
						"countyName": "西工区",
						"id": 410303,
						"parentId": 4103
					},
					{
						"countyCode": "410304000",
						"countyName": "瀍河回族区",
						"id": 410304,
						"parentId": 4103
					},
					{
						"countyCode": "410305000",
						"countyName": "涧西区",
						"id": 410305,
						"parentId": 4103
					},
					{
						"countyCode": "410306000",
						"countyName": "吉利区",
						"id": 410306,
						"parentId": 4103
					},
					{
						"countyCode": "410311000",
						"countyName": "洛龙区",
						"id": 410311,
						"parentId": 4103
					},
					{
						"countyCode": "410322000",
						"countyName": "孟津县",
						"id": 410322,
						"parentId": 4103
					},
					{
						"countyCode": "410323000",
						"countyName": "新安县",
						"id": 410323,
						"parentId": 4103
					},
					{
						"countyCode": "410324000",
						"countyName": "栾川县",
						"id": 410324,
						"parentId": 4103
					},
					{
						"countyCode": "410325000",
						"countyName": "嵩县",
						"id": 410325,
						"parentId": 4103
					},
					{
						"countyCode": "410326000",
						"countyName": "汝阳县",
						"id": 410326,
						"parentId": 4103
					},
					{
						"countyCode": "410327000",
						"countyName": "宜阳县",
						"id": 410327,
						"parentId": 4103
					},
					{
						"countyCode": "410328000",
						"countyName": "洛宁县",
						"id": 410328,
						"parentId": 4103
					},
					{
						"countyCode": "410329000",
						"countyName": "伊川县",
						"id": 410329,
						"parentId": 4103
					},
					{
						"countyCode": "410381000",
						"countyName": "偃师市",
						"id": 410381,
						"parentId": 4103
					}
				]
			},
			{
				"cityCode": "410400000",
				"cityName": "平顶山市",
				"id": 4104,
				"parentId": 41,
				"children": [{
						"countyCode": "410402000",
						"countyName": "新华区",
						"id": 410402,
						"parentId": 4104
					},
					{
						"countyCode": "410403000",
						"countyName": "卫东区",
						"id": 410403,
						"parentId": 4104
					},
					{
						"countyCode": "410404000",
						"countyName": "石龙区",
						"id": 410404,
						"parentId": 4104
					},
					{
						"countyCode": "410411000",
						"countyName": "湛河区",
						"id": 410411,
						"parentId": 4104
					},
					{
						"countyCode": "410421000",
						"countyName": "宝丰县",
						"id": 410421,
						"parentId": 4104
					},
					{
						"countyCode": "410422000",
						"countyName": "叶县",
						"id": 410422,
						"parentId": 4104
					},
					{
						"countyCode": "410423000",
						"countyName": "鲁山县",
						"id": 410423,
						"parentId": 4104
					},
					{
						"countyCode": "410425000",
						"countyName": "郏县",
						"id": 410425,
						"parentId": 4104
					},
					{
						"countyCode": "410481000",
						"countyName": "舞钢市",
						"id": 410481,
						"parentId": 4104
					},
					{
						"countyCode": "410482000",
						"countyName": "汝州市",
						"id": 410482,
						"parentId": 4104
					}
				]
			},
			{
				"cityCode": "410500000",
				"cityName": "安阳市",
				"id": 4105,
				"parentId": 41,
				"children": [{
						"countyCode": "410502000",
						"countyName": "文峰区",
						"id": 410502,
						"parentId": 4105
					},
					{
						"countyCode": "410503000",
						"countyName": "北关区",
						"id": 410503,
						"parentId": 4105
					},
					{
						"countyCode": "410505000",
						"countyName": "殷都区",
						"id": 410505,
						"parentId": 4105
					},
					{
						"countyCode": "410506000",
						"countyName": "龙安区",
						"id": 410506,
						"parentId": 4105
					},
					{
						"countyCode": "410522000",
						"countyName": "安阳县",
						"id": 410522,
						"parentId": 4105
					},
					{
						"countyCode": "410523000",
						"countyName": "汤阴县",
						"id": 410523,
						"parentId": 4105
					},
					{
						"countyCode": "410526000",
						"countyName": "滑县",
						"id": 410526,
						"parentId": 4105
					},
					{
						"countyCode": "410527000",
						"countyName": "内黄县",
						"id": 410527,
						"parentId": 4105
					},
					{
						"countyCode": "410581000",
						"countyName": "林州市",
						"id": 410581,
						"parentId": 4105
					}
				]
			},
			{
				"cityCode": "410600000",
				"cityName": "鹤壁市",
				"id": 4106,
				"parentId": 41,
				"children": [{
						"countyCode": "410602000",
						"countyName": "鹤山区",
						"id": 410602,
						"parentId": 4106
					},
					{
						"countyCode": "410603000",
						"countyName": "山城区",
						"id": 410603,
						"parentId": 4106
					},
					{
						"countyCode": "410611000",
						"countyName": "淇滨区",
						"id": 410611,
						"parentId": 4106
					},
					{
						"countyCode": "410621000",
						"countyName": "浚县",
						"id": 410621,
						"parentId": 4106
					},
					{
						"countyCode": "410622000",
						"countyName": "淇县",
						"id": 410622,
						"parentId": 4106
					}
				]
			},
			{
				"cityCode": "410700000",
				"cityName": "新乡市",
				"id": 4107,
				"parentId": 41,
				"children": [{
						"countyCode": "410702000",
						"countyName": "红旗区",
						"id": 410702,
						"parentId": 4107
					},
					{
						"countyCode": "410703000",
						"countyName": "卫滨区",
						"id": 410703,
						"parentId": 4107
					},
					{
						"countyCode": "410704000",
						"countyName": "凤泉区",
						"id": 410704,
						"parentId": 4107
					},
					{
						"countyCode": "410711000",
						"countyName": "牧野区",
						"id": 410711,
						"parentId": 4107
					},
					{
						"countyCode": "410721000",
						"countyName": "新乡县",
						"id": 410721,
						"parentId": 4107
					},
					{
						"countyCode": "410724000",
						"countyName": "获嘉县",
						"id": 410724,
						"parentId": 4107
					},
					{
						"countyCode": "410725000",
						"countyName": "原阳县",
						"id": 410725,
						"parentId": 4107
					},
					{
						"countyCode": "410726000",
						"countyName": "延津县",
						"id": 410726,
						"parentId": 4107
					},
					{
						"countyCode": "410727000",
						"countyName": "封丘县",
						"id": 410727,
						"parentId": 4107
					},
					{
						"countyCode": "410728000",
						"countyName": "长垣市",
						"id": 410728,
						"parentId": 4107
					},
					{
						"countyCode": "410781000",
						"countyName": "卫辉市",
						"id": 410781,
						"parentId": 4107
					},
					{
						"countyCode": "410782000",
						"countyName": "辉县市",
						"id": 410782,
						"parentId": 4107
					}
				]
			},
			{
				"cityCode": "410800000",
				"cityName": "焦作市",
				"id": 4108,
				"parentId": 41,
				"children": [{
						"countyCode": "410802000",
						"countyName": "解放区",
						"id": 410802,
						"parentId": 4108
					},
					{
						"countyCode": "410803000",
						"countyName": "中站区",
						"id": 410803,
						"parentId": 4108
					},
					{
						"countyCode": "410804000",
						"countyName": "马村区",
						"id": 410804,
						"parentId": 4108
					},
					{
						"countyCode": "410811000",
						"countyName": "山阳区",
						"id": 410811,
						"parentId": 4108
					},
					{
						"countyCode": "410821000",
						"countyName": "修武县",
						"id": 410821,
						"parentId": 4108
					},
					{
						"countyCode": "410822000",
						"countyName": "博爱县",
						"id": 410822,
						"parentId": 4108
					},
					{
						"countyCode": "410823000",
						"countyName": "武陟县",
						"id": 410823,
						"parentId": 4108
					},
					{
						"countyCode": "410825000",
						"countyName": "温县",
						"id": 410825,
						"parentId": 4108
					},
					{
						"countyCode": "410882000",
						"countyName": "沁阳市",
						"id": 410882,
						"parentId": 4108
					},
					{
						"countyCode": "410883000",
						"countyName": "孟州市",
						"id": 410883,
						"parentId": 4108
					}
				]
			},
			{
				"cityCode": "410900000",
				"cityName": "濮阳市",
				"id": 4109,
				"parentId": 41,
				"children": [{
						"countyCode": "410902000",
						"countyName": "华龙区",
						"id": 410902,
						"parentId": 4109
					},
					{
						"countyCode": "410922000",
						"countyName": "清丰县",
						"id": 410922,
						"parentId": 4109
					},
					{
						"countyCode": "410923000",
						"countyName": "南乐县",
						"id": 410923,
						"parentId": 4109
					},
					{
						"countyCode": "410926000",
						"countyName": "范县",
						"id": 410926,
						"parentId": 4109
					},
					{
						"countyCode": "410927000",
						"countyName": "台前县",
						"id": 410927,
						"parentId": 4109
					},
					{
						"countyCode": "410928000",
						"countyName": "濮阳县",
						"id": 410928,
						"parentId": 4109
					}
				]
			},
			{
				"cityCode": "411000000",
				"cityName": "许昌市",
				"id": 4110,
				"parentId": 41,
				"children": [{
						"countyCode": "411002000",
						"countyName": "魏都区",
						"id": 411002,
						"parentId": 4110
					},
					{
						"countyCode": "411003000",
						"countyName": "建安区",
						"id": 411003,
						"parentId": 4110
					},
					{
						"countyCode": "411024000",
						"countyName": "鄢陵县",
						"id": 411024,
						"parentId": 4110
					},
					{
						"countyCode": "411025000",
						"countyName": "襄城县",
						"id": 411025,
						"parentId": 4110
					},
					{
						"countyCode": "411081000",
						"countyName": "禹州市",
						"id": 411081,
						"parentId": 4110
					},
					{
						"countyCode": "411082000",
						"countyName": "长葛市",
						"id": 411082,
						"parentId": 4110
					}
				]
			},
			{
				"cityCode": "411100000",
				"cityName": "漯河市",
				"id": 4111,
				"parentId": 41,
				"children": [{
						"countyCode": "411102000",
						"countyName": "源汇区",
						"id": 411102,
						"parentId": 4111
					},
					{
						"countyCode": "411103000",
						"countyName": "郾城区",
						"id": 411103,
						"parentId": 4111
					},
					{
						"countyCode": "411104000",
						"countyName": "召陵区",
						"id": 411104,
						"parentId": 4111
					},
					{
						"countyCode": "411121000",
						"countyName": "舞阳县",
						"id": 411121,
						"parentId": 4111
					},
					{
						"countyCode": "411122000",
						"countyName": "临颍县",
						"id": 411122,
						"parentId": 4111
					}
				]
			},
			{
				"cityCode": "411200000",
				"cityName": "三门峡市",
				"id": 4112,
				"parentId": 41,
				"children": [{
						"countyCode": "411202000",
						"countyName": "湖滨区",
						"id": 411202,
						"parentId": 4112
					},
					{
						"countyCode": "411203000",
						"countyName": "陕州区",
						"id": 411203,
						"parentId": 4112
					},
					{
						"countyCode": "411221000",
						"countyName": "渑池县",
						"id": 411221,
						"parentId": 4112
					},
					{
						"countyCode": "411224000",
						"countyName": "卢氏县",
						"id": 411224,
						"parentId": 4112
					},
					{
						"countyCode": "411281000",
						"countyName": "义马市",
						"id": 411281,
						"parentId": 4112
					},
					{
						"countyCode": "411282000",
						"countyName": "灵宝市",
						"id": 411282,
						"parentId": 4112
					}
				]
			},
			{
				"cityCode": "411300000",
				"cityName": "南阳市",
				"id": 4113,
				"parentId": 41,
				"children": [{
						"countyCode": "411302000",
						"countyName": "宛城区",
						"id": 411302,
						"parentId": 4113
					},
					{
						"countyCode": "411303000",
						"countyName": "卧龙区",
						"id": 411303,
						"parentId": 4113
					},
					{
						"countyCode": "411321000",
						"countyName": "南召县",
						"id": 411321,
						"parentId": 4113
					},
					{
						"countyCode": "411322000",
						"countyName": "方城县",
						"id": 411322,
						"parentId": 4113
					},
					{
						"countyCode": "411323000",
						"countyName": "西峡县",
						"id": 411323,
						"parentId": 4113
					},
					{
						"countyCode": "411324000",
						"countyName": "镇平县",
						"id": 411324,
						"parentId": 4113
					},
					{
						"countyCode": "411325000",
						"countyName": "内乡县",
						"id": 411325,
						"parentId": 4113
					},
					{
						"countyCode": "411326000",
						"countyName": "淅川县",
						"id": 411326,
						"parentId": 4113
					},
					{
						"countyCode": "411327000",
						"countyName": "社旗县",
						"id": 411327,
						"parentId": 4113
					},
					{
						"countyCode": "411328000",
						"countyName": "唐河县",
						"id": 411328,
						"parentId": 4113
					},
					{
						"countyCode": "411329000",
						"countyName": "新野县",
						"id": 411329,
						"parentId": 4113
					},
					{
						"countyCode": "411330000",
						"countyName": "桐柏县",
						"id": 411330,
						"parentId": 4113
					},
					{
						"countyCode": "411381000",
						"countyName": "邓州市",
						"id": 411381,
						"parentId": 4113
					}
				]
			},
			{
				"cityCode": "411400000",
				"cityName": "商丘市",
				"id": 4114,
				"parentId": 41,
				"children": [{
						"countyCode": "411402000",
						"countyName": "梁园区",
						"id": 411402,
						"parentId": 4114
					},
					{
						"countyCode": "411403000",
						"countyName": "睢阳区",
						"id": 411403,
						"parentId": 4114
					},
					{
						"countyCode": "411421000",
						"countyName": "民权县",
						"id": 411421,
						"parentId": 4114
					},
					{
						"countyCode": "411422000",
						"countyName": "睢县",
						"id": 411422,
						"parentId": 4114
					},
					{
						"countyCode": "411423000",
						"countyName": "宁陵县",
						"id": 411423,
						"parentId": 4114
					},
					{
						"countyCode": "411424000",
						"countyName": "柘城县",
						"id": 411424,
						"parentId": 4114
					},
					{
						"countyCode": "411425000",
						"countyName": "虞城县",
						"id": 411425,
						"parentId": 4114
					},
					{
						"countyCode": "411426000",
						"countyName": "夏邑县",
						"id": 411426,
						"parentId": 4114
					},
					{
						"countyCode": "411481000",
						"countyName": "永城市",
						"id": 411481,
						"parentId": 4114
					}
				]
			},
			{
				"cityCode": "411500000",
				"cityName": "信阳市",
				"id": 4115,
				"parentId": 41,
				"children": [{
						"countyCode": "411502000",
						"countyName": "浉河区",
						"id": 411502,
						"parentId": 4115
					},
					{
						"countyCode": "411503000",
						"countyName": "平桥区",
						"id": 411503,
						"parentId": 4115
					},
					{
						"countyCode": "411521000",
						"countyName": "罗山县",
						"id": 411521,
						"parentId": 4115
					},
					{
						"countyCode": "411522000",
						"countyName": "光山县",
						"id": 411522,
						"parentId": 4115
					},
					{
						"countyCode": "411523000",
						"countyName": "新县",
						"id": 411523,
						"parentId": 4115
					},
					{
						"countyCode": "411524000",
						"countyName": "商城县",
						"id": 411524,
						"parentId": 4115
					},
					{
						"countyCode": "411525000",
						"countyName": "固始县",
						"id": 411525,
						"parentId": 4115
					},
					{
						"countyCode": "411526000",
						"countyName": "潢川县",
						"id": 411526,
						"parentId": 4115
					},
					{
						"countyCode": "411527000",
						"countyName": "淮滨县",
						"id": 411527,
						"parentId": 4115
					},
					{
						"countyCode": "411528000",
						"countyName": "息县",
						"id": 411528,
						"parentId": 4115
					}
				]
			},
			{
				"cityCode": "411600000",
				"cityName": "周口市",
				"id": 4116,
				"parentId": 41,
				"children": [{
						"countyCode": "411602000",
						"countyName": "川汇区",
						"id": 411602,
						"parentId": 4116
					},
					{
						"countyCode": "411621000",
						"countyName": "扶沟县",
						"id": 411621,
						"parentId": 4116
					},
					{
						"countyCode": "411622000",
						"countyName": "西华县",
						"id": 411622,
						"parentId": 4116
					},
					{
						"countyCode": "411623000",
						"countyName": "商水县",
						"id": 411623,
						"parentId": 4116
					},
					{
						"countyCode": "411624000",
						"countyName": "沈丘县",
						"id": 411624,
						"parentId": 4116
					},
					{
						"countyCode": "411625000",
						"countyName": "郸城县",
						"id": 411625,
						"parentId": 4116
					},
					{
						"countyCode": "411626000",
						"countyName": "淮阳区",
						"id": 411626,
						"parentId": 4116
					},
					{
						"countyCode": "411627000",
						"countyName": "太康县",
						"id": 411627,
						"parentId": 4116
					},
					{
						"countyCode": "411628000",
						"countyName": "鹿邑县",
						"id": 411628,
						"parentId": 4116
					},
					{
						"countyCode": "411681000",
						"countyName": "项城市",
						"id": 411681,
						"parentId": 4116
					}
				]
			},
			{
				"cityCode": "411700000",
				"cityName": "驻马店市",
				"id": 4117,
				"parentId": 41,
				"children": [{
						"countyCode": "411702000",
						"countyName": "驿城区",
						"id": 411702,
						"parentId": 4117
					},
					{
						"countyCode": "411721000",
						"countyName": "西平县",
						"id": 411721,
						"parentId": 4117
					},
					{
						"countyCode": "411722000",
						"countyName": "上蔡县",
						"id": 411722,
						"parentId": 4117
					},
					{
						"countyCode": "411723000",
						"countyName": "平舆县",
						"id": 411723,
						"parentId": 4117
					},
					{
						"countyCode": "411724000",
						"countyName": "正阳县",
						"id": 411724,
						"parentId": 4117
					},
					{
						"countyCode": "411725000",
						"countyName": "确山县",
						"id": 411725,
						"parentId": 4117
					},
					{
						"countyCode": "411726000",
						"countyName": "泌阳县",
						"id": 411726,
						"parentId": 4117
					},
					{
						"countyCode": "411727000",
						"countyName": "汝南县",
						"id": 411727,
						"parentId": 4117
					},
					{
						"countyCode": "411728000",
						"countyName": "遂平县",
						"id": 411728,
						"parentId": 4117
					},
					{
						"countyCode": "411729000",
						"countyName": "新蔡县",
						"id": 411729,
						"parentId": 4117
					}
				]
			},
			{
				"cityCode": "419001000",
				"cityName": "济源市",
				"id": 419001,
				"parentId": 41,
				"children": [{
					"countyCode": "419001000",
					"countyName": "济源市",
					"id": 419001000,
					"parentId": 419001
				}]
			}
		]
	},
	{
		"provinceCode": "420000000",
		"provinceName": "湖北省",
		"id": 42,
		"parentId": 0,
		"children": [{
				"cityCode": "420100000",
				"cityName": "武汉市",
				"id": 4201,
				"parentId": 42,
				"children": [{
						"countyCode": "420102000",
						"countyName": "江岸区",
						"id": 420102,
						"parentId": 4201
					},
					{
						"countyCode": "420103000",
						"countyName": "江汉区",
						"id": 420103,
						"parentId": 4201
					},
					{
						"countyCode": "420104000",
						"countyName": "硚口区",
						"id": 420104,
						"parentId": 4201
					},
					{
						"countyCode": "420105000",
						"countyName": "汉阳区",
						"id": 420105,
						"parentId": 4201
					},
					{
						"countyCode": "420106000",
						"countyName": "武昌区",
						"id": 420106,
						"parentId": 4201
					},
					{
						"countyCode": "420107000",
						"countyName": "青山区",
						"id": 420107,
						"parentId": 4201
					},
					{
						"countyCode": "420111000",
						"countyName": "洪山区",
						"id": 420111,
						"parentId": 4201
					},
					{
						"countyCode": "420112000",
						"countyName": "东西湖区",
						"id": 420112,
						"parentId": 4201
					},
					{
						"countyCode": "420113000",
						"countyName": "汉南区",
						"id": 420113,
						"parentId": 4201
					},
					{
						"countyCode": "420114000",
						"countyName": "蔡甸区",
						"id": 420114,
						"parentId": 4201
					},
					{
						"countyCode": "420115000",
						"countyName": "江夏区",
						"id": 420115,
						"parentId": 4201
					},
					{
						"countyCode": "420116000",
						"countyName": "黄陂区",
						"id": 420116,
						"parentId": 4201
					},
					{
						"countyCode": "420117000",
						"countyName": "新洲区",
						"id": 420117,
						"parentId": 4201
					}
				]
			},
			{
				"cityCode": "420200000",
				"cityName": "黄石市",
				"id": 4202,
				"parentId": 42,
				"children": [{
						"countyCode": "420202000",
						"countyName": "黄石港区",
						"id": 420202,
						"parentId": 4202
					},
					{
						"countyCode": "420203000",
						"countyName": "西塞山区",
						"id": 420203,
						"parentId": 4202
					},
					{
						"countyCode": "420204000",
						"countyName": "下陆区",
						"id": 420204,
						"parentId": 4202
					},
					{
						"countyCode": "420205000",
						"countyName": "铁山区",
						"id": 420205,
						"parentId": 4202
					},
					{
						"countyCode": "420222000",
						"countyName": "阳新县",
						"id": 420222,
						"parentId": 4202
					},
					{
						"countyCode": "420281000",
						"countyName": "大冶市",
						"id": 420281,
						"parentId": 4202
					}
				]
			},
			{
				"cityCode": "420300000",
				"cityName": "十堰市",
				"id": 4203,
				"parentId": 42,
				"children": [{
						"countyCode": "420302000",
						"countyName": "茅箭区",
						"id": 420302,
						"parentId": 4203
					},
					{
						"countyCode": "420303000",
						"countyName": "张湾区",
						"id": 420303,
						"parentId": 4203
					},
					{
						"countyCode": "420304000",
						"countyName": "郧阳区",
						"id": 420304,
						"parentId": 4203
					},
					{
						"countyCode": "420322000",
						"countyName": "郧西县",
						"id": 420322,
						"parentId": 4203
					},
					{
						"countyCode": "420323000",
						"countyName": "竹山县",
						"id": 420323,
						"parentId": 4203
					},
					{
						"countyCode": "420324000",
						"countyName": "竹溪县",
						"id": 420324,
						"parentId": 4203
					},
					{
						"countyCode": "420325000",
						"countyName": "房县",
						"id": 420325,
						"parentId": 4203
					},
					{
						"countyCode": "420381000",
						"countyName": "丹江口市",
						"id": 420381,
						"parentId": 4203
					}
				]
			},
			{
				"cityCode": "420500000",
				"cityName": "宜昌市",
				"id": 4205,
				"parentId": 42,
				"children": [{
						"countyCode": "420502000",
						"countyName": "西陵区",
						"id": 420502,
						"parentId": 4205
					},
					{
						"countyCode": "420503000",
						"countyName": "伍家岗区",
						"id": 420503,
						"parentId": 4205
					},
					{
						"countyCode": "420504000",
						"countyName": "点军区",
						"id": 420504,
						"parentId": 4205
					},
					{
						"countyCode": "420505000",
						"countyName": "猇亭区",
						"id": 420505,
						"parentId": 4205
					},
					{
						"countyCode": "420506000",
						"countyName": "夷陵区",
						"id": 420506,
						"parentId": 4205
					},
					{
						"countyCode": "420525000",
						"countyName": "远安县",
						"id": 420525,
						"parentId": 4205
					},
					{
						"countyCode": "420526000",
						"countyName": "兴山县",
						"id": 420526,
						"parentId": 4205
					},
					{
						"countyCode": "420527000",
						"countyName": "秭归县",
						"id": 420527,
						"parentId": 4205
					},
					{
						"countyCode": "420528000",
						"countyName": "长阳土家族自治县",
						"id": 420528,
						"parentId": 4205
					},
					{
						"countyCode": "420529000",
						"countyName": "五峰土家族自治县",
						"id": 420529,
						"parentId": 4205
					},
					{
						"countyCode": "420581000",
						"countyName": "宜都市",
						"id": 420581,
						"parentId": 4205
					},
					{
						"countyCode": "420582000",
						"countyName": "当阳市",
						"id": 420582,
						"parentId": 4205
					},
					{
						"countyCode": "420583000",
						"countyName": "枝江市",
						"id": 420583,
						"parentId": 4205
					}
				]
			},
			{
				"cityCode": "420600000",
				"cityName": "襄阳市",
				"id": 4206,
				"parentId": 42,
				"children": [{
						"countyCode": "420602000",
						"countyName": "襄城区",
						"id": 420602,
						"parentId": 4206
					},
					{
						"countyCode": "420606000",
						"countyName": "樊城区",
						"id": 420606,
						"parentId": 4206
					},
					{
						"countyCode": "420607000",
						"countyName": "襄州区",
						"id": 420607,
						"parentId": 4206
					},
					{
						"countyCode": "420624000",
						"countyName": "南漳县",
						"id": 420624,
						"parentId": 4206
					},
					{
						"countyCode": "420625000",
						"countyName": "谷城县",
						"id": 420625,
						"parentId": 4206
					},
					{
						"countyCode": "420626000",
						"countyName": "保康县",
						"id": 420626,
						"parentId": 4206
					},
					{
						"countyCode": "420682000",
						"countyName": "老河口市",
						"id": 420682,
						"parentId": 4206
					},
					{
						"countyCode": "420683000",
						"countyName": "枣阳市",
						"id": 420683,
						"parentId": 4206
					},
					{
						"countyCode": "420684000",
						"countyName": "宜城市",
						"id": 420684,
						"parentId": 4206
					}
				]
			},
			{
				"cityCode": "420700000",
				"cityName": "鄂州市",
				"id": 4207,
				"parentId": 42,
				"children": [{
						"countyCode": "420702000",
						"countyName": "梁子湖区",
						"id": 420702,
						"parentId": 4207
					},
					{
						"countyCode": "420703000",
						"countyName": "华容区",
						"id": 420703,
						"parentId": 4207
					},
					{
						"countyCode": "420704000",
						"countyName": "鄂城区",
						"id": 420704,
						"parentId": 4207
					}
				]
			},
			{
				"cityCode": "420800000",
				"cityName": "荆门市",
				"id": 4208,
				"parentId": 42,
				"children": [{
						"countyCode": "420802000",
						"countyName": "东宝区",
						"id": 420802,
						"parentId": 4208
					},
					{
						"countyCode": "420804000",
						"countyName": "掇刀区",
						"id": 420804,
						"parentId": 4208
					},
					{
						"countyCode": "420822000",
						"countyName": "沙洋县",
						"id": 420822,
						"parentId": 4208
					},
					{
						"countyCode": "420881000",
						"countyName": "钟祥市",
						"id": 420881,
						"parentId": 4208
					},
					{
						"countyCode": "420882000",
						"countyName": "京山市",
						"id": 420882,
						"parentId": 4208
					}
				]
			},
			{
				"cityCode": "420900000",
				"cityName": "孝感市",
				"id": 4209,
				"parentId": 42,
				"children": [{
						"countyCode": "420902000",
						"countyName": "孝南区",
						"id": 420902,
						"parentId": 4209
					},
					{
						"countyCode": "420921000",
						"countyName": "孝昌县",
						"id": 420921,
						"parentId": 4209
					},
					{
						"countyCode": "420922000",
						"countyName": "大悟县",
						"id": 420922,
						"parentId": 4209
					},
					{
						"countyCode": "420923000",
						"countyName": "云梦县",
						"id": 420923,
						"parentId": 4209
					},
					{
						"countyCode": "420981000",
						"countyName": "应城市",
						"id": 420981,
						"parentId": 4209
					},
					{
						"countyCode": "420982000",
						"countyName": "安陆市",
						"id": 420982,
						"parentId": 4209
					},
					{
						"countyCode": "420984000",
						"countyName": "汉川市",
						"id": 420984,
						"parentId": 4209
					}
				]
			},
			{
				"cityCode": "421000000",
				"cityName": "荆州市",
				"id": 4210,
				"parentId": 42,
				"children": [{
						"countyCode": "421002000",
						"countyName": "沙市区",
						"id": 421002,
						"parentId": 4210
					},
					{
						"countyCode": "421003000",
						"countyName": "荆州区",
						"id": 421003,
						"parentId": 4210
					},
					{
						"countyCode": "421022000",
						"countyName": "公安县",
						"id": 421022,
						"parentId": 4210
					},
					{
						"countyCode": "421023000",
						"countyName": "监利县",
						"id": 421023,
						"parentId": 4210
					},
					{
						"countyCode": "421024000",
						"countyName": "江陵县",
						"id": 421024,
						"parentId": 4210
					},
					{
						"countyCode": "421081000",
						"countyName": "石首市",
						"id": 421081,
						"parentId": 4210
					},
					{
						"countyCode": "421083000",
						"countyName": "洪湖市",
						"id": 421083,
						"parentId": 4210
					},
					{
						"countyCode": "421087000",
						"countyName": "松滋市",
						"id": 421087,
						"parentId": 4210
					}
				]
			},
			{
				"cityCode": "421100000",
				"cityName": "黄冈市",
				"id": 4211,
				"parentId": 42,
				"children": [{
						"countyCode": "421102000",
						"countyName": "黄州区",
						"id": 421102,
						"parentId": 4211
					},
					{
						"countyCode": "421121000",
						"countyName": "团风县",
						"id": 421121,
						"parentId": 4211
					},
					{
						"countyCode": "421122000",
						"countyName": "红安县",
						"id": 421122,
						"parentId": 4211
					},
					{
						"countyCode": "421123000",
						"countyName": "罗田县",
						"id": 421123,
						"parentId": 4211
					},
					{
						"countyCode": "421124000",
						"countyName": "英山县",
						"id": 421124,
						"parentId": 4211
					},
					{
						"countyCode": "421125000",
						"countyName": "浠水县",
						"id": 421125,
						"parentId": 4211
					},
					{
						"countyCode": "421126000",
						"countyName": "蕲春县",
						"id": 421126,
						"parentId": 4211
					},
					{
						"countyCode": "421127000",
						"countyName": "黄梅县",
						"id": 421127,
						"parentId": 4211
					},
					{
						"countyCode": "421181000",
						"countyName": "麻城市",
						"id": 421181,
						"parentId": 4211
					},
					{
						"countyCode": "421182000",
						"countyName": "武穴市",
						"id": 421182,
						"parentId": 4211
					}
				]
			},
			{
				"cityCode": "421200000",
				"cityName": "咸宁市",
				"id": 4212,
				"parentId": 42,
				"children": [{
						"countyCode": "421202000",
						"countyName": "咸安区",
						"id": 421202,
						"parentId": 4212
					},
					{
						"countyCode": "421221000",
						"countyName": "嘉鱼县",
						"id": 421221,
						"parentId": 4212
					},
					{
						"countyCode": "421222000",
						"countyName": "通城县",
						"id": 421222,
						"parentId": 4212
					},
					{
						"countyCode": "421223000",
						"countyName": "崇阳县",
						"id": 421223,
						"parentId": 4212
					},
					{
						"countyCode": "421224000",
						"countyName": "通山县",
						"id": 421224,
						"parentId": 4212
					},
					{
						"countyCode": "421281000",
						"countyName": "赤壁市",
						"id": 421281,
						"parentId": 4212
					}
				]
			},
			{
				"cityCode": "421300000",
				"cityName": "随州市",
				"id": 4213,
				"parentId": 42,
				"children": [{
						"countyCode": "421303000",
						"countyName": "曾都区",
						"id": 421303,
						"parentId": 4213
					},
					{
						"countyCode": "421321000",
						"countyName": "随县",
						"id": 421321,
						"parentId": 4213
					},
					{
						"countyCode": "421381000",
						"countyName": "广水市",
						"id": 421381,
						"parentId": 4213
					}
				]
			},
			{
				"cityCode": "422800000",
				"cityName": "恩施土家族苗族自治州",
				"id": 4228,
				"parentId": 42,
				"children": [{
						"countyCode": "422801000",
						"countyName": "恩施市",
						"id": 422801,
						"parentId": 4228
					},
					{
						"countyCode": "422802000",
						"countyName": "利川市",
						"id": 422802,
						"parentId": 4228
					},
					{
						"countyCode": "422822000",
						"countyName": "建始县",
						"id": 422822,
						"parentId": 4228
					},
					{
						"countyCode": "422823000",
						"countyName": "巴东县",
						"id": 422823,
						"parentId": 4228
					},
					{
						"countyCode": "422825000",
						"countyName": "宣恩县",
						"id": 422825,
						"parentId": 4228
					},
					{
						"countyCode": "422826000",
						"countyName": "咸丰县",
						"id": 422826,
						"parentId": 4228
					},
					{
						"countyCode": "422827000",
						"countyName": "来凤县",
						"id": 422827,
						"parentId": 4228
					},
					{
						"countyCode": "422828000",
						"countyName": "鹤峰县",
						"id": 422828,
						"parentId": 4228
					}
				]
			},
			{
				"cityCode": "429004000",
				"cityName": "仙桃市",
				"id": 429004,
				"parentId": 42,
				"children": [{
					"countyCode": "429004000",
					"countyName": "仙桃市",
					"id": 429004000,
					"parentId": 429004
				}]
			},
			{
				"cityCode": "429005000",
				"cityName": "潜江市",
				"id": 429005,
				"parentId": 42,
				"children": [{
					"countyCode": "429005000",
					"countyName": "潜江市",
					"id": 429005000,
					"parentId": 429005
				}]
			},
			{
				"cityCode": "429006000",
				"cityName": "天门市",
				"id": 429006,
				"parentId": 42,
				"children": [{
					"countyCode": "429006000",
					"countyName": "天门市",
					"id": 429006000,
					"parentId": 429006
				}]
			},
			{
				"cityCode": "429021000",
				"cityName": "神农架林区",
				"id": 429021,
				"parentId": 42,
				"children": [{
					"countyCode": "429021000",
					"countyName": "神农架林区",
					"id": 429021000,
					"parentId": 429021
				}]
			}
		]
	},
	{
		"provinceCode": "430000000",
		"provinceName": "湖南省",
		"id": 43,
		"parentId": 0,
		"children": [{
				"cityCode": "430100000",
				"cityName": "长沙市",
				"id": 4301,
				"parentId": 43,
				"children": [{
						"countyCode": "430102000",
						"countyName": "芙蓉区",
						"id": 430102,
						"parentId": 4301
					},
					{
						"countyCode": "430103000",
						"countyName": "天心区",
						"id": 430103,
						"parentId": 4301
					},
					{
						"countyCode": "430104000",
						"countyName": "岳麓区",
						"id": 430104,
						"parentId": 4301
					},
					{
						"countyCode": "430105000",
						"countyName": "开福区",
						"id": 430105,
						"parentId": 4301
					},
					{
						"countyCode": "430111000",
						"countyName": "雨花区",
						"id": 430111,
						"parentId": 4301
					},
					{
						"countyCode": "430112000",
						"countyName": "望城区",
						"id": 430112,
						"parentId": 4301
					},
					{
						"countyCode": "430121000",
						"countyName": "长沙县",
						"id": 430121,
						"parentId": 4301
					},
					{
						"countyCode": "430181000",
						"countyName": "浏阳市",
						"id": 430181,
						"parentId": 4301
					},
					{
						"countyCode": "430182000",
						"countyName": "宁乡市",
						"id": 430182,
						"parentId": 4301
					}
				]
			},
			{
				"cityCode": "430200000",
				"cityName": "株洲市",
				"id": 4302,
				"parentId": 43,
				"children": [{
						"countyCode": "430202000",
						"countyName": "荷塘区",
						"id": 430202,
						"parentId": 4302
					},
					{
						"countyCode": "430203000",
						"countyName": "芦淞区",
						"id": 430203,
						"parentId": 4302
					},
					{
						"countyCode": "430204000",
						"countyName": "石峰区",
						"id": 430204,
						"parentId": 4302
					},
					{
						"countyCode": "430211000",
						"countyName": "天元区",
						"id": 430211,
						"parentId": 4302
					},
					{
						"countyCode": "430212000",
						"countyName": "渌口区",
						"id": 430212,
						"parentId": 4302
					},
					{
						"countyCode": "430223000",
						"countyName": "攸县",
						"id": 430223,
						"parentId": 4302
					},
					{
						"countyCode": "430224000",
						"countyName": "茶陵县",
						"id": 430224,
						"parentId": 4302
					},
					{
						"countyCode": "430225000",
						"countyName": "炎陵县",
						"id": 430225,
						"parentId": 4302
					},
					{
						"countyCode": "430281000",
						"countyName": "醴陵市",
						"id": 430281,
						"parentId": 4302
					}
				]
			},
			{
				"cityCode": "430300000",
				"cityName": "湘潭市",
				"id": 4303,
				"parentId": 43,
				"children": [{
						"countyCode": "430302000",
						"countyName": "雨湖区",
						"id": 430302,
						"parentId": 4303
					},
					{
						"countyCode": "430304000",
						"countyName": "岳塘区",
						"id": 430304,
						"parentId": 4303
					},
					{
						"countyCode": "430321000",
						"countyName": "湘潭县",
						"id": 430321,
						"parentId": 4303
					},
					{
						"countyCode": "430381000",
						"countyName": "湘乡市",
						"id": 430381,
						"parentId": 4303
					},
					{
						"countyCode": "430382000",
						"countyName": "韶山市",
						"id": 430382,
						"parentId": 4303
					}
				]
			},
			{
				"cityCode": "430400000",
				"cityName": "衡阳市",
				"id": 4304,
				"parentId": 43,
				"children": [{
						"countyCode": "430405000",
						"countyName": "珠晖区",
						"id": 430405,
						"parentId": 4304
					},
					{
						"countyCode": "430406000",
						"countyName": "雁峰区",
						"id": 430406,
						"parentId": 4304
					},
					{
						"countyCode": "430407000",
						"countyName": "石鼓区",
						"id": 430407,
						"parentId": 4304
					},
					{
						"countyCode": "430408000",
						"countyName": "蒸湘区",
						"id": 430408,
						"parentId": 4304
					},
					{
						"countyCode": "430412000",
						"countyName": "南岳区",
						"id": 430412,
						"parentId": 4304
					},
					{
						"countyCode": "430421000",
						"countyName": "衡阳县",
						"id": 430421,
						"parentId": 4304
					},
					{
						"countyCode": "430422000",
						"countyName": "衡南县",
						"id": 430422,
						"parentId": 4304
					},
					{
						"countyCode": "430423000",
						"countyName": "衡山县",
						"id": 430423,
						"parentId": 4304
					},
					{
						"countyCode": "430424000",
						"countyName": "衡东县",
						"id": 430424,
						"parentId": 4304
					},
					{
						"countyCode": "430426000",
						"countyName": "祁东县",
						"id": 430426,
						"parentId": 4304
					},
					{
						"countyCode": "430481000",
						"countyName": "耒阳市",
						"id": 430481,
						"parentId": 4304
					},
					{
						"countyCode": "430482000",
						"countyName": "常宁市",
						"id": 430482,
						"parentId": 4304
					}
				]
			},
			{
				"cityCode": "430500000",
				"cityName": "邵阳市",
				"id": 4305,
				"parentId": 43,
				"children": [{
						"countyCode": "430502000",
						"countyName": "双清区",
						"id": 430502,
						"parentId": 4305
					},
					{
						"countyCode": "430503000",
						"countyName": "大祥区",
						"id": 430503,
						"parentId": 4305
					},
					{
						"countyCode": "430511000",
						"countyName": "北塔区",
						"id": 430511,
						"parentId": 4305
					},
					{
						"countyCode": "430521000",
						"countyName": "邵东市",
						"id": 430521,
						"parentId": 4305
					},
					{
						"countyCode": "430522000",
						"countyName": "新邵县",
						"id": 430522,
						"parentId": 4305
					},
					{
						"countyCode": "430523000",
						"countyName": "邵阳县",
						"id": 430523,
						"parentId": 4305
					},
					{
						"countyCode": "430524000",
						"countyName": "隆回县",
						"id": 430524,
						"parentId": 4305
					},
					{
						"countyCode": "430525000",
						"countyName": "洞口县",
						"id": 430525,
						"parentId": 4305
					},
					{
						"countyCode": "430527000",
						"countyName": "绥宁县",
						"id": 430527,
						"parentId": 4305
					},
					{
						"countyCode": "430528000",
						"countyName": "新宁县",
						"id": 430528,
						"parentId": 4305
					},
					{
						"countyCode": "430529000",
						"countyName": "城步苗族自治县",
						"id": 430529,
						"parentId": 4305
					},
					{
						"countyCode": "430581000",
						"countyName": "武冈市",
						"id": 430581,
						"parentId": 4305
					}
				]
			},
			{
				"cityCode": "430600000",
				"cityName": "岳阳市",
				"id": 4306,
				"parentId": 43,
				"children": [{
						"countyCode": "430602000",
						"countyName": "岳阳楼区",
						"id": 430602,
						"parentId": 4306
					},
					{
						"countyCode": "430603000",
						"countyName": "云溪区",
						"id": 430603,
						"parentId": 4306
					},
					{
						"countyCode": "430611000",
						"countyName": "君山区",
						"id": 430611,
						"parentId": 4306
					},
					{
						"countyCode": "430621000",
						"countyName": "岳阳县",
						"id": 430621,
						"parentId": 4306
					},
					{
						"countyCode": "430623000",
						"countyName": "华容县",
						"id": 430623,
						"parentId": 4306
					},
					{
						"countyCode": "430624000",
						"countyName": "湘阴县",
						"id": 430624,
						"parentId": 4306
					},
					{
						"countyCode": "430626000",
						"countyName": "平江县",
						"id": 430626,
						"parentId": 4306
					},
					{
						"countyCode": "430681000",
						"countyName": "汨罗市",
						"id": 430681,
						"parentId": 4306
					},
					{
						"countyCode": "430682000",
						"countyName": "临湘市",
						"id": 430682,
						"parentId": 4306
					}
				]
			},
			{
				"cityCode": "430700000",
				"cityName": "常德市",
				"id": 4307,
				"parentId": 43,
				"children": [{
						"countyCode": "430702000",
						"countyName": "武陵区",
						"id": 430702,
						"parentId": 4307
					},
					{
						"countyCode": "430703000",
						"countyName": "鼎城区",
						"id": 430703,
						"parentId": 4307
					},
					{
						"countyCode": "430721000",
						"countyName": "安乡县",
						"id": 430721,
						"parentId": 4307
					},
					{
						"countyCode": "430722000",
						"countyName": "汉寿县",
						"id": 430722,
						"parentId": 4307
					},
					{
						"countyCode": "430723000",
						"countyName": "澧县",
						"id": 430723,
						"parentId": 4307
					},
					{
						"countyCode": "430724000",
						"countyName": "临澧县",
						"id": 430724,
						"parentId": 4307
					},
					{
						"countyCode": "430725000",
						"countyName": "桃源县",
						"id": 430725,
						"parentId": 4307
					},
					{
						"countyCode": "430726000",
						"countyName": "石门县",
						"id": 430726,
						"parentId": 4307
					},
					{
						"countyCode": "430781000",
						"countyName": "津市市",
						"id": 430781,
						"parentId": 4307
					}
				]
			},
			{
				"cityCode": "430800000",
				"cityName": "张家界市",
				"id": 4308,
				"parentId": 43,
				"children": [{
						"countyCode": "430802000",
						"countyName": "永定区",
						"id": 430802,
						"parentId": 4308
					},
					{
						"countyCode": "430811000",
						"countyName": "武陵源区",
						"id": 430811,
						"parentId": 4308
					},
					{
						"countyCode": "430821000",
						"countyName": "慈利县",
						"id": 430821,
						"parentId": 4308
					},
					{
						"countyCode": "430822000",
						"countyName": "桑植县",
						"id": 430822,
						"parentId": 4308
					}
				]
			},
			{
				"cityCode": "430900000",
				"cityName": "益阳市",
				"id": 4309,
				"parentId": 43,
				"children": [{
						"countyCode": "430902000",
						"countyName": "资阳区",
						"id": 430902,
						"parentId": 4309
					},
					{
						"countyCode": "430903000",
						"countyName": "赫山区",
						"id": 430903,
						"parentId": 4309
					},
					{
						"countyCode": "430921000",
						"countyName": "南县",
						"id": 430921,
						"parentId": 4309
					},
					{
						"countyCode": "430922000",
						"countyName": "桃江县",
						"id": 430922,
						"parentId": 4309
					},
					{
						"countyCode": "430923000",
						"countyName": "安化县",
						"id": 430923,
						"parentId": 4309
					},
					{
						"countyCode": "430981000",
						"countyName": "沅江市",
						"id": 430981,
						"parentId": 4309
					}
				]
			},
			{
				"cityCode": "431000000",
				"cityName": "郴州市",
				"id": 4310,
				"parentId": 43,
				"children": [{
						"countyCode": "431002000",
						"countyName": "北湖区",
						"id": 431002,
						"parentId": 4310
					},
					{
						"countyCode": "431003000",
						"countyName": "苏仙区",
						"id": 431003,
						"parentId": 4310
					},
					{
						"countyCode": "431021000",
						"countyName": "桂阳县",
						"id": 431021,
						"parentId": 4310
					},
					{
						"countyCode": "431022000",
						"countyName": "宜章县",
						"id": 431022,
						"parentId": 4310
					},
					{
						"countyCode": "431023000",
						"countyName": "永兴县",
						"id": 431023,
						"parentId": 4310
					},
					{
						"countyCode": "431024000",
						"countyName": "嘉禾县",
						"id": 431024,
						"parentId": 4310
					},
					{
						"countyCode": "431025000",
						"countyName": "临武县",
						"id": 431025,
						"parentId": 4310
					},
					{
						"countyCode": "431026000",
						"countyName": "汝城县",
						"id": 431026,
						"parentId": 4310
					},
					{
						"countyCode": "431027000",
						"countyName": "桂东县",
						"id": 431027,
						"parentId": 4310
					},
					{
						"countyCode": "431028000",
						"countyName": "安仁县",
						"id": 431028,
						"parentId": 4310
					},
					{
						"countyCode": "431081000",
						"countyName": "资兴市",
						"id": 431081,
						"parentId": 4310
					}
				]
			},
			{
				"cityCode": "431100000",
				"cityName": "永州市",
				"id": 4311,
				"parentId": 43,
				"children": [{
						"countyCode": "431102000",
						"countyName": "零陵区",
						"id": 431102,
						"parentId": 4311
					},
					{
						"countyCode": "431103000",
						"countyName": "冷水滩区",
						"id": 431103,
						"parentId": 4311
					},
					{
						"countyCode": "431121000",
						"countyName": "祁阳县",
						"id": 431121,
						"parentId": 4311
					},
					{
						"countyCode": "431122000",
						"countyName": "东安县",
						"id": 431122,
						"parentId": 4311
					},
					{
						"countyCode": "431123000",
						"countyName": "双牌县",
						"id": 431123,
						"parentId": 4311
					},
					{
						"countyCode": "431124000",
						"countyName": "道县",
						"id": 431124,
						"parentId": 4311
					},
					{
						"countyCode": "431125000",
						"countyName": "江永县",
						"id": 431125,
						"parentId": 4311
					},
					{
						"countyCode": "431126000",
						"countyName": "宁远县",
						"id": 431126,
						"parentId": 4311
					},
					{
						"countyCode": "431127000",
						"countyName": "蓝山县",
						"id": 431127,
						"parentId": 4311
					},
					{
						"countyCode": "431128000",
						"countyName": "新田县",
						"id": 431128,
						"parentId": 4311
					},
					{
						"countyCode": "431129000",
						"countyName": "江华瑶族自治县",
						"id": 431129,
						"parentId": 4311
					}
				]
			},
			{
				"cityCode": "431200000",
				"cityName": "怀化市",
				"id": 4312,
				"parentId": 43,
				"children": [{
						"countyCode": "431202000",
						"countyName": "鹤城区",
						"id": 431202,
						"parentId": 4312
					},
					{
						"countyCode": "431221000",
						"countyName": "中方县",
						"id": 431221,
						"parentId": 4312
					},
					{
						"countyCode": "431222000",
						"countyName": "沅陵县",
						"id": 431222,
						"parentId": 4312
					},
					{
						"countyCode": "431223000",
						"countyName": "辰溪县",
						"id": 431223,
						"parentId": 4312
					},
					{
						"countyCode": "431224000",
						"countyName": "溆浦县",
						"id": 431224,
						"parentId": 4312
					},
					{
						"countyCode": "431225000",
						"countyName": "会同县",
						"id": 431225,
						"parentId": 4312
					},
					{
						"countyCode": "431226000",
						"countyName": "麻阳苗族自治县",
						"id": 431226,
						"parentId": 4312
					},
					{
						"countyCode": "431227000",
						"countyName": "新晃侗族自治县",
						"id": 431227,
						"parentId": 4312
					},
					{
						"countyCode": "431228000",
						"countyName": "芷江侗族自治县",
						"id": 431228,
						"parentId": 4312
					},
					{
						"countyCode": "431229000",
						"countyName": "靖州苗族侗族自治县",
						"id": 431229,
						"parentId": 4312
					},
					{
						"countyCode": "431230000",
						"countyName": "通道侗族自治县",
						"id": 431230,
						"parentId": 4312
					},
					{
						"countyCode": "431281000",
						"countyName": "洪江市",
						"id": 431281,
						"parentId": 4312
					}
				]
			},
			{
				"cityCode": "431300000",
				"cityName": "娄底市",
				"id": 4313,
				"parentId": 43,
				"children": [{
						"countyCode": "431302000",
						"countyName": "娄星区",
						"id": 431302,
						"parentId": 4313
					},
					{
						"countyCode": "431321000",
						"countyName": "双峰县",
						"id": 431321,
						"parentId": 4313
					},
					{
						"countyCode": "431322000",
						"countyName": "新化县",
						"id": 431322,
						"parentId": 4313
					},
					{
						"countyCode": "431381000",
						"countyName": "冷水江市",
						"id": 431381,
						"parentId": 4313
					},
					{
						"countyCode": "431382000",
						"countyName": "涟源市",
						"id": 431382,
						"parentId": 4313
					}
				]
			},
			{
				"cityCode": "433100000",
				"cityName": "湘西土家族苗族自治州",
				"id": 4331,
				"parentId": 43,
				"children": [{
						"countyCode": "433101000",
						"countyName": "吉首市",
						"id": 433101,
						"parentId": 4331
					},
					{
						"countyCode": "433122000",
						"countyName": "泸溪县",
						"id": 433122,
						"parentId": 4331
					},
					{
						"countyCode": "433123000",
						"countyName": "凤凰县",
						"id": 433123,
						"parentId": 4331
					},
					{
						"countyCode": "433124000",
						"countyName": "花垣县",
						"id": 433124,
						"parentId": 4331
					},
					{
						"countyCode": "433125000",
						"countyName": "保靖县",
						"id": 433125,
						"parentId": 4331
					},
					{
						"countyCode": "433126000",
						"countyName": "古丈县",
						"id": 433126,
						"parentId": 4331
					},
					{
						"countyCode": "433127000",
						"countyName": "永顺县",
						"id": 433127,
						"parentId": 4331
					},
					{
						"countyCode": "433130000",
						"countyName": "龙山县",
						"id": 433130,
						"parentId": 4331
					}
				]
			}
		]
	},
	{
		"provinceCode": "440000000",
		"provinceName": "广东省",
		"id": 44,
		"parentId": 0,
		"children": [{
				"cityCode": "440100000",
				"cityName": "广州市",
				"id": 4401,
				"parentId": 44,
				"children": [{
						"countyCode": "440103000",
						"countyName": "荔湾区",
						"id": 440103,
						"parentId": 4401
					},
					{
						"countyCode": "440104000",
						"countyName": "越秀区",
						"id": 440104,
						"parentId": 4401
					},
					{
						"countyCode": "440105000",
						"countyName": "海珠区",
						"id": 440105,
						"parentId": 4401
					},
					{
						"countyCode": "440106000",
						"countyName": "天河区",
						"id": 440106,
						"parentId": 4401
					},
					{
						"countyCode": "440111000",
						"countyName": "白云区",
						"id": 440111,
						"parentId": 4401
					},
					{
						"countyCode": "440112000",
						"countyName": "黄埔区",
						"id": 440112,
						"parentId": 4401
					},
					{
						"countyCode": "440113000",
						"countyName": "番禺区",
						"id": 440113,
						"parentId": 4401
					},
					{
						"countyCode": "440114000",
						"countyName": "花都区",
						"id": 440114,
						"parentId": 4401
					},
					{
						"countyCode": "440115000",
						"countyName": "南沙区",
						"id": 440115,
						"parentId": 4401
					},
					{
						"countyCode": "440117000",
						"countyName": "从化区",
						"id": 440117,
						"parentId": 4401
					},
					{
						"countyCode": "440118000",
						"countyName": "增城区",
						"id": 440118,
						"parentId": 4401
					}
				]
			},
			{
				"cityCode": "440200000",
				"cityName": "韶关市",
				"id": 4402,
				"parentId": 44,
				"children": [{
						"countyCode": "440203000",
						"countyName": "武江区",
						"id": 440203,
						"parentId": 4402
					},
					{
						"countyCode": "440204000",
						"countyName": "浈江区",
						"id": 440204,
						"parentId": 4402
					},
					{
						"countyCode": "440205000",
						"countyName": "曲江区",
						"id": 440205,
						"parentId": 4402
					},
					{
						"countyCode": "440222000",
						"countyName": "始兴县",
						"id": 440222,
						"parentId": 4402
					},
					{
						"countyCode": "440224000",
						"countyName": "仁化县",
						"id": 440224,
						"parentId": 4402
					},
					{
						"countyCode": "440229000",
						"countyName": "翁源县",
						"id": 440229,
						"parentId": 4402
					},
					{
						"countyCode": "440232000",
						"countyName": "乳源瑶族自治县",
						"id": 440232,
						"parentId": 4402
					},
					{
						"countyCode": "440233000",
						"countyName": "新丰县",
						"id": 440233,
						"parentId": 4402
					},
					{
						"countyCode": "440281000",
						"countyName": "乐昌市",
						"id": 440281,
						"parentId": 4402
					},
					{
						"countyCode": "440282000",
						"countyName": "南雄市",
						"id": 440282,
						"parentId": 4402
					}
				]
			},
			{
				"cityCode": "440300000",
				"cityName": "深圳市",
				"id": 4403,
				"parentId": 44,
				"children": [{
						"countyCode": "440303000",
						"countyName": "罗湖区",
						"id": 440303,
						"parentId": 4403
					},
					{
						"countyCode": "440304000",
						"countyName": "福田区",
						"id": 440304,
						"parentId": 4403
					},
					{
						"countyCode": "440305000",
						"countyName": "南山区",
						"id": 440305,
						"parentId": 4403
					},
					{
						"countyCode": "440306000",
						"countyName": "宝安区",
						"id": 440306,
						"parentId": 4403
					},
					{
						"countyCode": "440307000",
						"countyName": "龙岗区",
						"id": 440307,
						"parentId": 4403
					},
					{
						"countyCode": "440308000",
						"countyName": "盐田区",
						"id": 440308,
						"parentId": 4403
					},
					{
						"countyCode": "440309000",
						"countyName": "龙华区",
						"id": 440309,
						"parentId": 4403
					},
					{
						"countyCode": "440310000",
						"countyName": "坪山区",
						"id": 440310,
						"parentId": 4403
					},
					{
						"countyCode": "440311000",
						"countyName": "光明区",
						"id": 440311,
						"parentId": 4403
					}
				]
			},
			{
				"cityCode": "440400000",
				"cityName": "珠海市",
				"id": 4404,
				"parentId": 44,
				"children": [{
						"countyCode": "440402000",
						"countyName": "香洲区",
						"id": 440402,
						"parentId": 4404
					},
					{
						"countyCode": "440403000",
						"countyName": "斗门区",
						"id": 440403,
						"parentId": 4404
					},
					{
						"countyCode": "440404000",
						"countyName": "金湾区",
						"id": 440404,
						"parentId": 4404
					}
				]
			},
			{
				"cityCode": "440500000",
				"cityName": "汕头市",
				"id": 4405,
				"parentId": 44,
				"children": [{
						"countyCode": "440507000",
						"countyName": "龙湖区",
						"id": 440507,
						"parentId": 4405
					},
					{
						"countyCode": "440511000",
						"countyName": "金平区",
						"id": 440511,
						"parentId": 4405
					},
					{
						"countyCode": "440512000",
						"countyName": "濠江区",
						"id": 440512,
						"parentId": 4405
					},
					{
						"countyCode": "440513000",
						"countyName": "潮阳区",
						"id": 440513,
						"parentId": 4405
					},
					{
						"countyCode": "440514000",
						"countyName": "潮南区",
						"id": 440514,
						"parentId": 4405
					},
					{
						"countyCode": "440515000",
						"countyName": "澄海区",
						"id": 440515,
						"parentId": 4405
					},
					{
						"countyCode": "440523000",
						"countyName": "南澳县",
						"id": 440523,
						"parentId": 4405
					}
				]
			},
			{
				"cityCode": "440600000",
				"cityName": "佛山市",
				"id": 4406,
				"parentId": 44,
				"children": [{
						"countyCode": "440604000",
						"countyName": "禅城区",
						"id": 440604,
						"parentId": 4406
					},
					{
						"countyCode": "440605000",
						"countyName": "南海区",
						"id": 440605,
						"parentId": 4406
					},
					{
						"countyCode": "440606000",
						"countyName": "顺德区",
						"id": 440606,
						"parentId": 4406
					},
					{
						"countyCode": "440607000",
						"countyName": "三水区",
						"id": 440607,
						"parentId": 4406
					},
					{
						"countyCode": "440608000",
						"countyName": "高明区",
						"id": 440608,
						"parentId": 4406
					}
				]
			},
			{
				"cityCode": "440700000",
				"cityName": "江门市",
				"id": 4407,
				"parentId": 44,
				"children": [{
						"countyCode": "440703000",
						"countyName": "蓬江区",
						"id": 440703,
						"parentId": 4407
					},
					{
						"countyCode": "440704000",
						"countyName": "江海区",
						"id": 440704,
						"parentId": 4407
					},
					{
						"countyCode": "440705000",
						"countyName": "新会区",
						"id": 440705,
						"parentId": 4407
					},
					{
						"countyCode": "440781000",
						"countyName": "台山市",
						"id": 440781,
						"parentId": 4407
					},
					{
						"countyCode": "440783000",
						"countyName": "开平市",
						"id": 440783,
						"parentId": 4407
					},
					{
						"countyCode": "440784000",
						"countyName": "鹤山市",
						"id": 440784,
						"parentId": 4407
					},
					{
						"countyCode": "440785000",
						"countyName": "恩平市",
						"id": 440785,
						"parentId": 4407
					}
				]
			},
			{
				"cityCode": "440800000",
				"cityName": "湛江市",
				"id": 4408,
				"parentId": 44,
				"children": [{
						"countyCode": "440802000",
						"countyName": "赤坎区",
						"id": 440802,
						"parentId": 4408
					},
					{
						"countyCode": "440803000",
						"countyName": "霞山区",
						"id": 440803,
						"parentId": 4408
					},
					{
						"countyCode": "440804000",
						"countyName": "坡头区",
						"id": 440804,
						"parentId": 4408
					},
					{
						"countyCode": "440811000",
						"countyName": "麻章区",
						"id": 440811,
						"parentId": 4408
					},
					{
						"countyCode": "440823000",
						"countyName": "遂溪县",
						"id": 440823,
						"parentId": 4408
					},
					{
						"countyCode": "440825000",
						"countyName": "徐闻县",
						"id": 440825,
						"parentId": 4408
					},
					{
						"countyCode": "440881000",
						"countyName": "廉江市",
						"id": 440881,
						"parentId": 4408
					},
					{
						"countyCode": "440882000",
						"countyName": "雷州市",
						"id": 440882,
						"parentId": 4408
					},
					{
						"countyCode": "440883000",
						"countyName": "吴川市",
						"id": 440883,
						"parentId": 4408
					}
				]
			},
			{
				"cityCode": "440900000",
				"cityName": "茂名市",
				"id": 4409,
				"parentId": 44,
				"children": [{
						"countyCode": "440902000",
						"countyName": "茂南区",
						"id": 440902,
						"parentId": 4409
					},
					{
						"countyCode": "440904000",
						"countyName": "电白区",
						"id": 440904,
						"parentId": 4409
					},
					{
						"countyCode": "440981000",
						"countyName": "高州市",
						"id": 440981,
						"parentId": 4409
					},
					{
						"countyCode": "440982000",
						"countyName": "化州市",
						"id": 440982,
						"parentId": 4409
					},
					{
						"countyCode": "440983000",
						"countyName": "信宜市",
						"id": 440983,
						"parentId": 4409
					}
				]
			},
			{
				"cityCode": "441200000",
				"cityName": "肇庆市",
				"id": 4412,
				"parentId": 44,
				"children": [{
						"countyCode": "441202000",
						"countyName": "端州区",
						"id": 441202,
						"parentId": 4412
					},
					{
						"countyCode": "441203000",
						"countyName": "鼎湖区",
						"id": 441203,
						"parentId": 4412
					},
					{
						"countyCode": "441204000",
						"countyName": "高要区",
						"id": 441204,
						"parentId": 4412
					},
					{
						"countyCode": "441223000",
						"countyName": "广宁县",
						"id": 441223,
						"parentId": 4412
					},
					{
						"countyCode": "441224000",
						"countyName": "怀集县",
						"id": 441224,
						"parentId": 4412
					},
					{
						"countyCode": "441225000",
						"countyName": "封开县",
						"id": 441225,
						"parentId": 4412
					},
					{
						"countyCode": "441226000",
						"countyName": "德庆县",
						"id": 441226,
						"parentId": 4412
					},
					{
						"countyCode": "441284000",
						"countyName": "四会市",
						"id": 441284,
						"parentId": 4412
					}
				]
			},
			{
				"cityCode": "441300000",
				"cityName": "惠州市",
				"id": 4413,
				"parentId": 44,
				"children": [{
						"countyCode": "441302000",
						"countyName": "惠城区",
						"id": 441302,
						"parentId": 4413
					},
					{
						"countyCode": "441303000",
						"countyName": "惠阳区",
						"id": 441303,
						"parentId": 4413
					},
					{
						"countyCode": "441322000",
						"countyName": "博罗县",
						"id": 441322,
						"parentId": 4413
					},
					{
						"countyCode": "441323000",
						"countyName": "惠东县",
						"id": 441323,
						"parentId": 4413
					},
					{
						"countyCode": "441324000",
						"countyName": "龙门县",
						"id": 441324,
						"parentId": 4413
					}
				]
			},
			{
				"cityCode": "441400000",
				"cityName": "梅州市",
				"id": 4414,
				"parentId": 44,
				"children": [{
						"countyCode": "441402000",
						"countyName": "梅江区",
						"id": 441402,
						"parentId": 4414
					},
					{
						"countyCode": "441403000",
						"countyName": "梅县区",
						"id": 441403,
						"parentId": 4414
					},
					{
						"countyCode": "441422000",
						"countyName": "大埔县",
						"id": 441422,
						"parentId": 4414
					},
					{
						"countyCode": "441423000",
						"countyName": "丰顺县",
						"id": 441423,
						"parentId": 4414
					},
					{
						"countyCode": "441424000",
						"countyName": "五华县",
						"id": 441424,
						"parentId": 4414
					},
					{
						"countyCode": "441426000",
						"countyName": "平远县",
						"id": 441426,
						"parentId": 4414
					},
					{
						"countyCode": "441427000",
						"countyName": "蕉岭县",
						"id": 441427,
						"parentId": 4414
					},
					{
						"countyCode": "441481000",
						"countyName": "兴宁市",
						"id": 441481,
						"parentId": 4414
					}
				]
			},
			{
				"cityCode": "441500000",
				"cityName": "汕尾市",
				"id": 4415,
				"parentId": 44,
				"children": [{
						"countyCode": "441502000",
						"countyName": "城区",
						"id": 441502,
						"parentId": 4415
					},
					{
						"countyCode": "441521000",
						"countyName": "海丰县",
						"id": 441521,
						"parentId": 4415
					},
					{
						"countyCode": "441523000",
						"countyName": "陆河县",
						"id": 441523,
						"parentId": 4415
					},
					{
						"countyCode": "441581000",
						"countyName": "陆丰市",
						"id": 441581,
						"parentId": 4415
					}
				]
			},
			{
				"cityCode": "441600000",
				"cityName": "河源市",
				"id": 4416,
				"parentId": 44,
				"children": [{
						"countyCode": "441602000",
						"countyName": "源城区",
						"id": 441602,
						"parentId": 4416
					},
					{
						"countyCode": "441621000",
						"countyName": "紫金县",
						"id": 441621,
						"parentId": 4416
					},
					{
						"countyCode": "441622000",
						"countyName": "龙川县",
						"id": 441622,
						"parentId": 4416
					},
					{
						"countyCode": "441623000",
						"countyName": "连平县",
						"id": 441623,
						"parentId": 4416
					},
					{
						"countyCode": "441624000",
						"countyName": "和平县",
						"id": 441624,
						"parentId": 4416
					},
					{
						"countyCode": "441625000",
						"countyName": "东源县",
						"id": 441625,
						"parentId": 4416
					}
				]
			},
			{
				"cityCode": "441700000",
				"cityName": "阳江市",
				"id": 4417,
				"parentId": 44,
				"children": [{
						"countyCode": "441702000",
						"countyName": "江城区",
						"id": 441702,
						"parentId": 4417
					},
					{
						"countyCode": "441704000",
						"countyName": "阳东区",
						"id": 441704,
						"parentId": 4417
					},
					{
						"countyCode": "441721000",
						"countyName": "阳西县",
						"id": 441721,
						"parentId": 4417
					},
					{
						"countyCode": "441781000",
						"countyName": "阳春市",
						"id": 441781,
						"parentId": 4417
					}
				]
			},
			{
				"cityCode": "441800000",
				"cityName": "清远市",
				"id": 4418,
				"parentId": 44,
				"children": [{
						"countyCode": "441802000",
						"countyName": "清城区",
						"id": 441802,
						"parentId": 4418
					},
					{
						"countyCode": "441803000",
						"countyName": "清新区",
						"id": 441803,
						"parentId": 4418
					},
					{
						"countyCode": "441821000",
						"countyName": "佛冈县",
						"id": 441821,
						"parentId": 4418
					},
					{
						"countyCode": "441823000",
						"countyName": "阳山县",
						"id": 441823,
						"parentId": 4418
					},
					{
						"countyCode": "441825000",
						"countyName": "连山壮族瑶族自治县",
						"id": 441825,
						"parentId": 4418
					},
					{
						"countyCode": "441826000",
						"countyName": "连南瑶族自治县",
						"id": 441826,
						"parentId": 4418
					},
					{
						"countyCode": "441881000",
						"countyName": "英德市",
						"id": 441881,
						"parentId": 4418
					},
					{
						"countyCode": "441882000",
						"countyName": "连州市",
						"id": 441882,
						"parentId": 4418
					}
				]
			},
			{
				"cityCode": "441900000",
				"cityName": "东莞市",
				"id": 4419,
				"parentId": 44,
				"children": [{
					"countyCode": "441900000",
					"countyName": "东莞市",
					"id": 441900,
					"parentId": 4419
				}]
			},
			{
				"cityCode": "442000000",
				"cityName": "中山市",
				"id": 4420,
				"parentId": 44,
				"children": [{
					"countyCode": "442000000",
					"countyName": "中山市",
					"id": 442000,
					"parentId": 4420
				}]
			},
			{
				"cityCode": "445100000",
				"cityName": "潮州市",
				"id": 4451,
				"parentId": 44,
				"children": [{
						"countyCode": "445102000",
						"countyName": "湘桥区",
						"id": 445102,
						"parentId": 4451
					},
					{
						"countyCode": "445103000",
						"countyName": "潮安区",
						"id": 445103,
						"parentId": 4451
					},
					{
						"countyCode": "445122000",
						"countyName": "饶平县",
						"id": 445122,
						"parentId": 4451
					}
				]
			},
			{
				"cityCode": "445200000",
				"cityName": "揭阳市",
				"id": 4452,
				"parentId": 44,
				"children": [{
						"countyCode": "445202000",
						"countyName": "榕城区",
						"id": 445202,
						"parentId": 4452
					},
					{
						"countyCode": "445203000",
						"countyName": "揭东区",
						"id": 445203,
						"parentId": 4452
					},
					{
						"countyCode": "445222000",
						"countyName": "揭西县",
						"id": 445222,
						"parentId": 4452
					},
					{
						"countyCode": "445224000",
						"countyName": "惠来县",
						"id": 445224,
						"parentId": 4452
					},
					{
						"countyCode": "445281000",
						"countyName": "普宁市",
						"id": 445281,
						"parentId": 4452
					}
				]
			},
			{
				"cityCode": "445300000",
				"cityName": "云浮市",
				"id": 4453,
				"parentId": 44,
				"children": [{
						"countyCode": "445302000",
						"countyName": "云城区",
						"id": 445302,
						"parentId": 4453
					},
					{
						"countyCode": "445303000",
						"countyName": "云安区",
						"id": 445303,
						"parentId": 4453
					},
					{
						"countyCode": "445321000",
						"countyName": "新兴县",
						"id": 445321,
						"parentId": 4453
					},
					{
						"countyCode": "445322000",
						"countyName": "郁南县",
						"id": 445322,
						"parentId": 4453
					},
					{
						"countyCode": "445381000",
						"countyName": "罗定市",
						"id": 445381,
						"parentId": 4453
					}
				]
			}
		]
	},
	{
		"provinceCode": "450000000",
		"provinceName": "广西壮族自治区",
		"id": 45,
		"parentId": 0,
		"children": [{
				"cityCode": "450100000",
				"cityName": "南宁市",
				"id": 4501,
				"parentId": 45,
				"children": [{
						"countyCode": "450102000",
						"countyName": "兴宁区",
						"id": 450102,
						"parentId": 4501
					},
					{
						"countyCode": "450103000",
						"countyName": "青秀区",
						"id": 450103,
						"parentId": 4501
					},
					{
						"countyCode": "450105000",
						"countyName": "江南区",
						"id": 450105,
						"parentId": 4501
					},
					{
						"countyCode": "450107000",
						"countyName": "西乡塘区",
						"id": 450107,
						"parentId": 4501
					},
					{
						"countyCode": "450108000",
						"countyName": "良庆区",
						"id": 450108,
						"parentId": 4501
					},
					{
						"countyCode": "450109000",
						"countyName": "邕宁区",
						"id": 450109,
						"parentId": 4501
					},
					{
						"countyCode": "450110000",
						"countyName": "武鸣区",
						"id": 450110,
						"parentId": 4501
					},
					{
						"countyCode": "450123000",
						"countyName": "隆安县",
						"id": 450123,
						"parentId": 4501
					},
					{
						"countyCode": "450124000",
						"countyName": "马山县",
						"id": 450124,
						"parentId": 4501
					},
					{
						"countyCode": "450125000",
						"countyName": "上林县",
						"id": 450125,
						"parentId": 4501
					},
					{
						"countyCode": "450126000",
						"countyName": "宾阳县",
						"id": 450126,
						"parentId": 4501
					},
					{
						"countyCode": "450127000",
						"countyName": "横县",
						"id": 450127,
						"parentId": 4501
					}
				]
			},
			{
				"cityCode": "450200000",
				"cityName": "柳州市",
				"id": 4502,
				"parentId": 45,
				"children": [{
						"countyCode": "450202000",
						"countyName": "城中区",
						"id": 450202,
						"parentId": 4502
					},
					{
						"countyCode": "450203000",
						"countyName": "鱼峰区",
						"id": 450203,
						"parentId": 4502
					},
					{
						"countyCode": "450204000",
						"countyName": "柳南区",
						"id": 450204,
						"parentId": 4502
					},
					{
						"countyCode": "450205000",
						"countyName": "柳北区",
						"id": 450205,
						"parentId": 4502
					},
					{
						"countyCode": "450206000",
						"countyName": "柳江区",
						"id": 450206,
						"parentId": 4502
					},
					{
						"countyCode": "450222000",
						"countyName": "柳城县",
						"id": 450222,
						"parentId": 4502
					},
					{
						"countyCode": "450223000",
						"countyName": "鹿寨县",
						"id": 450223,
						"parentId": 4502
					},
					{
						"countyCode": "450224000",
						"countyName": "融安县",
						"id": 450224,
						"parentId": 4502
					},
					{
						"countyCode": "450225000",
						"countyName": "融水苗族自治县",
						"id": 450225,
						"parentId": 4502
					},
					{
						"countyCode": "450226000",
						"countyName": "三江侗族自治县",
						"id": 450226,
						"parentId": 4502
					}
				]
			},
			{
				"cityCode": "450300000",
				"cityName": "桂林市",
				"id": 4503,
				"parentId": 45,
				"children": [{
						"countyCode": "450302000",
						"countyName": "秀峰区",
						"id": 450302,
						"parentId": 4503
					},
					{
						"countyCode": "450303000",
						"countyName": "叠彩区",
						"id": 450303,
						"parentId": 4503
					},
					{
						"countyCode": "450304000",
						"countyName": "象山区",
						"id": 450304,
						"parentId": 4503
					},
					{
						"countyCode": "450305000",
						"countyName": "七星区",
						"id": 450305,
						"parentId": 4503
					},
					{
						"countyCode": "450311000",
						"countyName": "雁山区",
						"id": 450311,
						"parentId": 4503
					},
					{
						"countyCode": "450312000",
						"countyName": "临桂区",
						"id": 450312,
						"parentId": 4503
					},
					{
						"countyCode": "450321000",
						"countyName": "阳朔县",
						"id": 450321,
						"parentId": 4503
					},
					{
						"countyCode": "450323000",
						"countyName": "灵川县",
						"id": 450323,
						"parentId": 4503
					},
					{
						"countyCode": "450324000",
						"countyName": "全州县",
						"id": 450324,
						"parentId": 4503
					},
					{
						"countyCode": "450325000",
						"countyName": "兴安县",
						"id": 450325,
						"parentId": 4503
					},
					{
						"countyCode": "450326000",
						"countyName": "永福县",
						"id": 450326,
						"parentId": 4503
					},
					{
						"countyCode": "450327000",
						"countyName": "灌阳县",
						"id": 450327,
						"parentId": 4503
					},
					{
						"countyCode": "450328000",
						"countyName": "龙胜各族自治县",
						"id": 450328,
						"parentId": 4503
					},
					{
						"countyCode": "450329000",
						"countyName": "资源县",
						"id": 450329,
						"parentId": 4503
					},
					{
						"countyCode": "450330000",
						"countyName": "平乐县",
						"id": 450330,
						"parentId": 4503
					},
					{
						"countyCode": "450332000",
						"countyName": "恭城瑶族自治县",
						"id": 450332,
						"parentId": 4503
					},
					{
						"countyCode": "450381000",
						"countyName": "荔浦市",
						"id": 450381,
						"parentId": 4503
					}
				]
			},
			{
				"cityCode": "450400000",
				"cityName": "梧州市",
				"id": 4504,
				"parentId": 45,
				"children": [{
						"countyCode": "450403000",
						"countyName": "万秀区",
						"id": 450403,
						"parentId": 4504
					},
					{
						"countyCode": "450405000",
						"countyName": "长洲区",
						"id": 450405,
						"parentId": 4504
					},
					{
						"countyCode": "450406000",
						"countyName": "龙圩区",
						"id": 450406,
						"parentId": 4504
					},
					{
						"countyCode": "450421000",
						"countyName": "苍梧县",
						"id": 450421,
						"parentId": 4504
					},
					{
						"countyCode": "450422000",
						"countyName": "藤县",
						"id": 450422,
						"parentId": 4504
					},
					{
						"countyCode": "450423000",
						"countyName": "蒙山县",
						"id": 450423,
						"parentId": 4504
					},
					{
						"countyCode": "450481000",
						"countyName": "岑溪市",
						"id": 450481,
						"parentId": 4504
					}
				]
			},
			{
				"cityCode": "450500000",
				"cityName": "北海市",
				"id": 4505,
				"parentId": 45,
				"children": [{
						"countyCode": "450502000",
						"countyName": "海城区",
						"id": 450502,
						"parentId": 4505
					},
					{
						"countyCode": "450503000",
						"countyName": "银海区",
						"id": 450503,
						"parentId": 4505
					},
					{
						"countyCode": "450512000",
						"countyName": "铁山港区",
						"id": 450512,
						"parentId": 4505
					},
					{
						"countyCode": "450521000",
						"countyName": "合浦县",
						"id": 450521,
						"parentId": 4505
					}
				]
			},
			{
				"cityCode": "450600000",
				"cityName": "防城港市",
				"id": 4506,
				"parentId": 45,
				"children": [{
						"countyCode": "450602000",
						"countyName": "港口区",
						"id": 450602,
						"parentId": 4506
					},
					{
						"countyCode": "450603000",
						"countyName": "防城区",
						"id": 450603,
						"parentId": 4506
					},
					{
						"countyCode": "450621000",
						"countyName": "上思县",
						"id": 450621,
						"parentId": 4506
					},
					{
						"countyCode": "450681000",
						"countyName": "东兴市",
						"id": 450681,
						"parentId": 4506
					}
				]
			},
			{
				"cityCode": "450700000",
				"cityName": "钦州市",
				"id": 4507,
				"parentId": 45,
				"children": [{
						"countyCode": "450702000",
						"countyName": "钦南区",
						"id": 450702,
						"parentId": 4507
					},
					{
						"countyCode": "450703000",
						"countyName": "钦北区",
						"id": 450703,
						"parentId": 4507
					},
					{
						"countyCode": "450721000",
						"countyName": "灵山县",
						"id": 450721,
						"parentId": 4507
					},
					{
						"countyCode": "450722000",
						"countyName": "浦北县",
						"id": 450722,
						"parentId": 4507
					}
				]
			},
			{
				"cityCode": "450800000",
				"cityName": "贵港市",
				"id": 4508,
				"parentId": 45,
				"children": [{
						"countyCode": "450802000",
						"countyName": "港北区",
						"id": 450802,
						"parentId": 4508
					},
					{
						"countyCode": "450803000",
						"countyName": "港南区",
						"id": 450803,
						"parentId": 4508
					},
					{
						"countyCode": "450804000",
						"countyName": "覃塘区",
						"id": 450804,
						"parentId": 4508
					},
					{
						"countyCode": "450821000",
						"countyName": "平南县",
						"id": 450821,
						"parentId": 4508
					},
					{
						"countyCode": "450881000",
						"countyName": "桂平市",
						"id": 450881,
						"parentId": 4508
					}
				]
			},
			{
				"cityCode": "450900000",
				"cityName": "玉林市",
				"id": 4509,
				"parentId": 45,
				"children": [{
						"countyCode": "450902000",
						"countyName": "玉州区",
						"id": 450902,
						"parentId": 4509
					},
					{
						"countyCode": "450903000",
						"countyName": "福绵区",
						"id": 450903,
						"parentId": 4509
					},
					{
						"countyCode": "450921000",
						"countyName": "容县",
						"id": 450921,
						"parentId": 4509
					},
					{
						"countyCode": "450922000",
						"countyName": "陆川县",
						"id": 450922,
						"parentId": 4509
					},
					{
						"countyCode": "450923000",
						"countyName": "博白县",
						"id": 450923,
						"parentId": 4509
					},
					{
						"countyCode": "450924000",
						"countyName": "兴业县",
						"id": 450924,
						"parentId": 4509
					},
					{
						"countyCode": "450981000",
						"countyName": "北流市",
						"id": 450981,
						"parentId": 4509
					}
				]
			},
			{
				"cityCode": "451000000",
				"cityName": "百色市",
				"id": 4510,
				"parentId": 45,
				"children": [{
						"countyCode": "451002000",
						"countyName": "右江区",
						"id": 451002,
						"parentId": 4510
					},
					{
						"countyCode": "451021000",
						"countyName": "田阳区",
						"id": 451021,
						"parentId": 4510
					},
					{
						"countyCode": "451022000",
						"countyName": "田东县",
						"id": 451022,
						"parentId": 4510
					},
					{
						"countyCode": "451023000",
						"countyName": "平果县",
						"id": 451023,
						"parentId": 4510
					},
					{
						"countyCode": "451024000",
						"countyName": "德保县",
						"id": 451024,
						"parentId": 4510
					},
					{
						"countyCode": "451026000",
						"countyName": "那坡县",
						"id": 451026,
						"parentId": 4510
					},
					{
						"countyCode": "451027000",
						"countyName": "凌云县",
						"id": 451027,
						"parentId": 4510
					},
					{
						"countyCode": "451028000",
						"countyName": "乐业县",
						"id": 451028,
						"parentId": 4510
					},
					{
						"countyCode": "451029000",
						"countyName": "田林县",
						"id": 451029,
						"parentId": 4510
					},
					{
						"countyCode": "451030000",
						"countyName": "西林县",
						"id": 451030,
						"parentId": 4510
					},
					{
						"countyCode": "451031000",
						"countyName": "隆林各族自治县",
						"id": 451031,
						"parentId": 4510
					},
					{
						"countyCode": "451081000",
						"countyName": "靖西市",
						"id": 451081,
						"parentId": 4510
					}
				]
			},
			{
				"cityCode": "451100000",
				"cityName": "贺州市",
				"id": 4511,
				"parentId": 45,
				"children": [{
						"countyCode": "451102000",
						"countyName": "八步区",
						"id": 451102,
						"parentId": 4511
					},
					{
						"countyCode": "451103000",
						"countyName": "平桂区",
						"id": 451103,
						"parentId": 4511
					},
					{
						"countyCode": "451121000",
						"countyName": "昭平县",
						"id": 451121,
						"parentId": 4511
					},
					{
						"countyCode": "451122000",
						"countyName": "钟山县",
						"id": 451122,
						"parentId": 4511
					},
					{
						"countyCode": "451123000",
						"countyName": "富川瑶族自治县",
						"id": 451123,
						"parentId": 4511
					}
				]
			},
			{
				"cityCode": "451200000",
				"cityName": "河池市",
				"id": 4512,
				"parentId": 45,
				"children": [{
						"countyCode": "451202000",
						"countyName": "金城江区",
						"id": 451202,
						"parentId": 4512
					},
					{
						"countyCode": "451203000",
						"countyName": "宜州区",
						"id": 451203,
						"parentId": 4512
					},
					{
						"countyCode": "451221000",
						"countyName": "南丹县",
						"id": 451221,
						"parentId": 4512
					},
					{
						"countyCode": "451222000",
						"countyName": "天峨县",
						"id": 451222,
						"parentId": 4512
					},
					{
						"countyCode": "451223000",
						"countyName": "凤山县",
						"id": 451223,
						"parentId": 4512
					},
					{
						"countyCode": "451224000",
						"countyName": "东兰县",
						"id": 451224,
						"parentId": 4512
					},
					{
						"countyCode": "451225000",
						"countyName": "罗城仫佬族自治县",
						"id": 451225,
						"parentId": 4512
					},
					{
						"countyCode": "451226000",
						"countyName": "环江毛南族自治县",
						"id": 451226,
						"parentId": 4512
					},
					{
						"countyCode": "451227000",
						"countyName": "巴马瑶族自治县",
						"id": 451227,
						"parentId": 4512
					},
					{
						"countyCode": "451228000",
						"countyName": "都安瑶族自治县",
						"id": 451228,
						"parentId": 4512
					},
					{
						"countyCode": "451229000",
						"countyName": "大化瑶族自治县",
						"id": 451229,
						"parentId": 4512
					}
				]
			},
			{
				"cityCode": "451300000",
				"cityName": "来宾市",
				"id": 4513,
				"parentId": 45,
				"children": [{
						"countyCode": "451302000",
						"countyName": "兴宾区",
						"id": 451302,
						"parentId": 4513
					},
					{
						"countyCode": "451321000",
						"countyName": "忻城县",
						"id": 451321,
						"parentId": 4513
					},
					{
						"countyCode": "451322000",
						"countyName": "象州县",
						"id": 451322,
						"parentId": 4513
					},
					{
						"countyCode": "451323000",
						"countyName": "武宣县",
						"id": 451323,
						"parentId": 4513
					},
					{
						"countyCode": "451324000",
						"countyName": "金秀瑶族自治县",
						"id": 451324,
						"parentId": 4513
					},
					{
						"countyCode": "451381000",
						"countyName": "合山市",
						"id": 451381,
						"parentId": 4513
					}
				]
			},
			{
				"cityCode": "451400000",
				"cityName": "崇左市",
				"id": 4514,
				"parentId": 45,
				"children": [{
						"countyCode": "451402000",
						"countyName": "江州区",
						"id": 451402,
						"parentId": 4514
					},
					{
						"countyCode": "451421000",
						"countyName": "扶绥县",
						"id": 451421,
						"parentId": 4514
					},
					{
						"countyCode": "451422000",
						"countyName": "宁明县",
						"id": 451422,
						"parentId": 4514
					},
					{
						"countyCode": "451423000",
						"countyName": "龙州县",
						"id": 451423,
						"parentId": 4514
					},
					{
						"countyCode": "451424000",
						"countyName": "大新县",
						"id": 451424,
						"parentId": 4514
					},
					{
						"countyCode": "451425000",
						"countyName": "天等县",
						"id": 451425,
						"parentId": 4514
					},
					{
						"countyCode": "451481000",
						"countyName": "凭祥市",
						"id": 451481,
						"parentId": 4514
					}
				]
			}
		]
	},
	{
		"provinceCode": "460000000",
		"provinceName": "海南省",
		"id": 46,
		"parentId": 0,
		"children": [{
				"cityCode": "460100000",
				"cityName": "海口市",
				"id": 4601,
				"parentId": 46,
				"children": [{
						"countyCode": "460105000",
						"countyName": "秀英区",
						"id": 460105,
						"parentId": 4601
					},
					{
						"countyCode": "460106000",
						"countyName": "龙华区",
						"id": 460106,
						"parentId": 4601
					},
					{
						"countyCode": "460107000",
						"countyName": "琼山区",
						"id": 460107,
						"parentId": 4601
					},
					{
						"countyCode": "460108000",
						"countyName": "美兰区",
						"id": 460108,
						"parentId": 4601
					}
				]
			},
			{
				"cityCode": "460200000",
				"cityName": "三亚市",
				"id": 4602,
				"parentId": 46,
				"children": [{
						"countyCode": "460202000",
						"countyName": "海棠区",
						"id": 460202,
						"parentId": 4602
					},
					{
						"countyCode": "460203000",
						"countyName": "吉阳区",
						"id": 460203,
						"parentId": 4602
					},
					{
						"countyCode": "460204000",
						"countyName": "天涯区",
						"id": 460204,
						"parentId": 4602
					},
					{
						"countyCode": "460205000",
						"countyName": "崖州区",
						"id": 460205,
						"parentId": 4602
					}
				]
			},
			{
				"cityCode": "460300000",
				"cityName": "三沙市",
				"id": 4603,
				"parentId": 46,
				"children": [{
						"countyCode": "460321000",
						"countyName": "西沙群岛",
						"id": 460321,
						"parentId": 4603
					},
					{
						"countyCode": "460322000",
						"countyName": "南沙群岛",
						"id": 460322,
						"parentId": 4603
					},
					{
						"countyCode": "460323000",
						"countyName": "中沙群岛的岛礁及其海域",
						"id": 460323,
						"parentId": 4603
					}
				]
			},
			{
				"cityCode": "460400000",
				"cityName": "儋州市",
				"id": 4604,
				"parentId": 46,
				"children": [{
					"countyCode": "460400000",
					"countyName": "儋州市",
					"id": 460400,
					"parentId": 4604
				}]
			},
			{
				"cityCode": "469001000",
				"cityName": "五指山市",
				"id": 469001,
				"parentId": 46,
				"children": [{
					"countyCode": "469001000",
					"countyName": "五指山市",
					"id": 469001000,
					"parentId": 469001
				}]
			},
			{
				"cityCode": "469002000",
				"cityName": "琼海市",
				"id": 469002,
				"parentId": 46,
				"children": [{
					"countyCode": "469002000",
					"countyName": "琼海市",
					"id": 469002000,
					"parentId": 469002
				}]
			},
			{
				"cityCode": "469005000",
				"cityName": "文昌市",
				"id": 469005,
				"parentId": 46,
				"children": [{
					"countyCode": "469005000",
					"countyName": "文昌市",
					"id": 469005000,
					"parentId": 469005
				}]
			},
			{
				"cityCode": "469006000",
				"cityName": "万宁市",
				"id": 469006,
				"parentId": 46,
				"children": [{
					"countyCode": "469006000",
					"countyName": "万宁市",
					"id": 469006000,
					"parentId": 469006
				}]
			},
			{
				"cityCode": "469007000",
				"cityName": "东方市",
				"id": 469007,
				"parentId": 46,
				"children": [{
					"countyCode": "469007000",
					"countyName": "东方市",
					"id": 469007000,
					"parentId": 469007
				}]
			},
			{
				"cityCode": "469021000",
				"cityName": "定安县",
				"id": 469021,
				"parentId": 46,
				"children": [{
					"countyCode": "469021000",
					"countyName": "定安县",
					"id": 469021000,
					"parentId": 469021
				}]
			},
			{
				"cityCode": "469022000",
				"cityName": "屯昌县",
				"id": 469022,
				"parentId": 46,
				"children": [{
					"countyCode": "469022000",
					"countyName": "屯昌县",
					"id": 469022000,
					"parentId": 469022
				}]
			},
			{
				"cityCode": "469023000",
				"cityName": "澄迈县",
				"id": 469023,
				"parentId": 46,
				"children": [{
					"countyCode": "469023000",
					"countyName": "澄迈县",
					"id": 469023000,
					"parentId": 469023
				}]
			},
			{
				"cityCode": "469024000",
				"cityName": "临高县",
				"id": 469024,
				"parentId": 46,
				"children": [{
					"countyCode": "469024000",
					"countyName": "临高县",
					"id": 469024000,
					"parentId": 469024
				}]
			},
			{
				"cityCode": "469025000",
				"cityName": "白沙黎族自治县",
				"id": 469025,
				"parentId": 46,
				"children": [{
					"countyCode": "469025000",
					"countyName": "白沙黎族自治县",
					"id": 469025000,
					"parentId": 469025
				}]
			},
			{
				"cityCode": "469026000",
				"cityName": "昌江黎族自治县",
				"id": 469026,
				"parentId": 46,
				"children": [{
					"countyCode": "469026000",
					"countyName": "昌江黎族自治县",
					"id": 469026000,
					"parentId": 469026
				}]
			},
			{
				"cityCode": "469027000",
				"cityName": "乐东黎族自治县",
				"id": 469027,
				"parentId": 46,
				"children": [{
					"countyCode": "469027000",
					"countyName": "乐东黎族自治县",
					"id": 469027000,
					"parentId": 469027
				}]
			},
			{
				"cityCode": "469028000",
				"cityName": "陵水黎族自治县",
				"id": 469028,
				"parentId": 46,
				"children": [{
					"countyCode": "469028000",
					"countyName": "陵水黎族自治县",
					"id": 469028000,
					"parentId": 469028
				}]
			},
			{
				"cityCode": "469029000",
				"cityName": "保亭黎族苗族自治县",
				"id": 469029,
				"parentId": 46,
				"children": [{
					"countyCode": "469029000",
					"countyName": "保亭黎族苗族自治县",
					"id": 469029000,
					"parentId": 469029
				}]
			},
			{
				"cityCode": "469030000",
				"cityName": "琼中黎族苗族自治县",
				"id": 469030,
				"parentId": 46,
				"children": [{
					"countyCode": "469030000",
					"countyName": "琼中黎族苗族自治县",
					"id": 469030000,
					"parentId": 469030
				}]
			}
		]
	},
	{
		"provinceCode": "500000000",
		"provinceName": "重庆市",
		"id": 50,
		"parentId": 0,
		"children": [{
				"cityCode": "500100000",
				"cityName": "重庆城区",
				"id": 5001,
				"parentId": 50,
				"children": [{
						"countyCode": "500101000",
						"countyName": "万州区",
						"id": 500101,
						"parentId": 5001
					},
					{
						"countyCode": "500102000",
						"countyName": "涪陵区",
						"id": 500102,
						"parentId": 5001
					},
					{
						"countyCode": "500103000",
						"countyName": "渝中区",
						"id": 500103,
						"parentId": 5001
					},
					{
						"countyCode": "500104000",
						"countyName": "大渡口区",
						"id": 500104,
						"parentId": 5001
					},
					{
						"countyCode": "500105000",
						"countyName": "江北区",
						"id": 500105,
						"parentId": 5001
					},
					{
						"countyCode": "500106000",
						"countyName": "沙坪坝区",
						"id": 500106,
						"parentId": 5001
					},
					{
						"countyCode": "500107000",
						"countyName": "九龙坡区",
						"id": 500107,
						"parentId": 5001
					},
					{
						"countyCode": "500108000",
						"countyName": "南岸区",
						"id": 500108,
						"parentId": 5001
					},
					{
						"countyCode": "500109000",
						"countyName": "北碚区",
						"id": 500109,
						"parentId": 5001
					},
					{
						"countyCode": "500110000",
						"countyName": "綦江区",
						"id": 500110,
						"parentId": 5001
					},
					{
						"countyCode": "500111000",
						"countyName": "大足区",
						"id": 500111,
						"parentId": 5001
					},
					{
						"countyCode": "500112000",
						"countyName": "渝北区",
						"id": 500112,
						"parentId": 5001
					},
					{
						"countyCode": "500113000",
						"countyName": "巴南区",
						"id": 500113,
						"parentId": 5001
					},
					{
						"countyCode": "500114000",
						"countyName": "黔江区",
						"id": 500114,
						"parentId": 5001
					},
					{
						"countyCode": "500115000",
						"countyName": "长寿区",
						"id": 500115,
						"parentId": 5001
					},
					{
						"countyCode": "500116000",
						"countyName": "江津区",
						"id": 500116,
						"parentId": 5001
					},
					{
						"countyCode": "500117000",
						"countyName": "合川区",
						"id": 500117,
						"parentId": 5001
					},
					{
						"countyCode": "500118000",
						"countyName": "永川区",
						"id": 500118,
						"parentId": 5001
					},
					{
						"countyCode": "500119000",
						"countyName": "南川区",
						"id": 500119,
						"parentId": 5001
					},
					{
						"countyCode": "500120000",
						"countyName": "璧山区",
						"id": 500120,
						"parentId": 5001
					},
					{
						"countyCode": "500151000",
						"countyName": "铜梁区",
						"id": 500151,
						"parentId": 5001
					},
					{
						"countyCode": "500152000",
						"countyName": "潼南区",
						"id": 500152,
						"parentId": 5001
					},
					{
						"countyCode": "500153000",
						"countyName": "荣昌区",
						"id": 500153,
						"parentId": 5001
					},
					{
						"countyCode": "500154000",
						"countyName": "开州区",
						"id": 500154,
						"parentId": 5001
					},
					{
						"countyCode": "500155000",
						"countyName": "梁平区",
						"id": 500155,
						"parentId": 5001
					},
					{
						"countyCode": "500156000",
						"countyName": "武隆区",
						"id": 500156,
						"parentId": 5001
					}
				]
			},
			{
				"cityCode": "500200000",
				"cityName": "重庆郊县",
				"id": 5002,
				"parentId": 50,
				"children": [{
						"countyCode": "500229000",
						"countyName": "城口县",
						"id": 500229,
						"parentId": 5002
					},
					{
						"countyCode": "500230000",
						"countyName": "丰都县",
						"id": 500230,
						"parentId": 5002
					},
					{
						"countyCode": "500231000",
						"countyName": "垫江县",
						"id": 500231,
						"parentId": 5002
					},
					{
						"countyCode": "500233000",
						"countyName": "忠县",
						"id": 500233,
						"parentId": 5002
					},
					{
						"countyCode": "500235000",
						"countyName": "云阳县",
						"id": 500235,
						"parentId": 5002
					},
					{
						"countyCode": "500236000",
						"countyName": "奉节县",
						"id": 500236,
						"parentId": 5002
					},
					{
						"countyCode": "500237000",
						"countyName": "巫山县",
						"id": 500237,
						"parentId": 5002
					},
					{
						"countyCode": "500238000",
						"countyName": "巫溪县",
						"id": 500238,
						"parentId": 5002
					},
					{
						"countyCode": "500240000",
						"countyName": "石柱土家族自治县",
						"id": 500240,
						"parentId": 5002
					},
					{
						"countyCode": "500241000",
						"countyName": "秀山土家族苗族自治县",
						"id": 500241,
						"parentId": 5002
					},
					{
						"countyCode": "500242000",
						"countyName": "酉阳土家族苗族自治县",
						"id": 500242,
						"parentId": 5002
					},
					{
						"countyCode": "500243000",
						"countyName": "彭水苗族土家族自治县",
						"id": 500243,
						"parentId": 5002
					}
				]
			}
		]
	},
	{
		"provinceCode": "510000000",
		"provinceName": "四川省",
		"id": 51,
		"parentId": 0,
		"children": [{
				"cityCode": "510100000",
				"cityName": "成都市",
				"id": 5101,
				"parentId": 51,
				"children": [{
						"countyCode": "510104000",
						"countyName": "锦江区",
						"id": 510104,
						"parentId": 5101
					},
					{
						"countyCode": "510105000",
						"countyName": "青羊区",
						"id": 510105,
						"parentId": 5101
					},
					{
						"countyCode": "510106000",
						"countyName": "金牛区",
						"id": 510106,
						"parentId": 5101
					},
					{
						"countyCode": "510107000",
						"countyName": "武侯区",
						"id": 510107,
						"parentId": 5101
					},
					{
						"countyCode": "510108000",
						"countyName": "成华区",
						"id": 510108,
						"parentId": 5101
					},
					{
						"countyCode": "510112000",
						"countyName": "龙泉驿区",
						"id": 510112,
						"parentId": 5101
					},
					{
						"countyCode": "510113000",
						"countyName": "青白江区",
						"id": 510113,
						"parentId": 5101
					},
					{
						"countyCode": "510114000",
						"countyName": "新都区",
						"id": 510114,
						"parentId": 5101
					},
					{
						"countyCode": "510115000",
						"countyName": "温江区",
						"id": 510115,
						"parentId": 5101
					},
					{
						"countyCode": "510116000",
						"countyName": "双流区",
						"id": 510116,
						"parentId": 5101
					},
					{
						"countyCode": "510117000",
						"countyName": "郫都区",
						"id": 510117,
						"parentId": 5101
					},
					{
						"countyCode": "510121000",
						"countyName": "金堂县",
						"id": 510121,
						"parentId": 5101
					},
					{
						"countyCode": "510129000",
						"countyName": "大邑县",
						"id": 510129,
						"parentId": 5101
					},
					{
						"countyCode": "510131000",
						"countyName": "蒲江县",
						"id": 510131,
						"parentId": 5101
					},
					{
						"countyCode": "510132000",
						"countyName": "新津县",
						"id": 510132,
						"parentId": 5101
					},
					{
						"countyCode": "510181000",
						"countyName": "都江堰市",
						"id": 510181,
						"parentId": 5101
					},
					{
						"countyCode": "510182000",
						"countyName": "彭州市",
						"id": 510182,
						"parentId": 5101
					},
					{
						"countyCode": "510183000",
						"countyName": "邛崃市",
						"id": 510183,
						"parentId": 5101
					},
					{
						"countyCode": "510184000",
						"countyName": "崇州市",
						"id": 510184,
						"parentId": 5101
					},
					{
						"countyCode": "510185000",
						"countyName": "简阳市",
						"id": 510185,
						"parentId": 5101
					}
				]
			},
			{
				"cityCode": "510300000",
				"cityName": "自贡市",
				"id": 5103,
				"parentId": 51,
				"children": [{
						"countyCode": "510302000",
						"countyName": "自流井区",
						"id": 510302,
						"parentId": 5103
					},
					{
						"countyCode": "510303000",
						"countyName": "贡井区",
						"id": 510303,
						"parentId": 5103
					},
					{
						"countyCode": "510304000",
						"countyName": "大安区",
						"id": 510304,
						"parentId": 5103
					},
					{
						"countyCode": "510311000",
						"countyName": "沿滩区",
						"id": 510311,
						"parentId": 5103
					},
					{
						"countyCode": "510321000",
						"countyName": "荣县",
						"id": 510321,
						"parentId": 5103
					},
					{
						"countyCode": "510322000",
						"countyName": "富顺县",
						"id": 510322,
						"parentId": 5103
					}
				]
			},
			{
				"cityCode": "510400000",
				"cityName": "攀枝花市",
				"id": 5104,
				"parentId": 51,
				"children": [{
						"countyCode": "510402000",
						"countyName": "东区",
						"id": 510402,
						"parentId": 5104
					},
					{
						"countyCode": "510403000",
						"countyName": "西区",
						"id": 510403,
						"parentId": 5104
					},
					{
						"countyCode": "510411000",
						"countyName": "仁和区",
						"id": 510411,
						"parentId": 5104
					},
					{
						"countyCode": "510421000",
						"countyName": "米易县",
						"id": 510421,
						"parentId": 5104
					},
					{
						"countyCode": "510422000",
						"countyName": "盐边县",
						"id": 510422,
						"parentId": 5104
					}
				]
			},
			{
				"cityCode": "510500000",
				"cityName": "泸州市",
				"id": 5105,
				"parentId": 51,
				"children": [{
						"countyCode": "510502000",
						"countyName": "江阳区",
						"id": 510502,
						"parentId": 5105
					},
					{
						"countyCode": "510503000",
						"countyName": "纳溪区",
						"id": 510503,
						"parentId": 5105
					},
					{
						"countyCode": "510504000",
						"countyName": "龙马潭区",
						"id": 510504,
						"parentId": 5105
					},
					{
						"countyCode": "510521000",
						"countyName": "泸县",
						"id": 510521,
						"parentId": 5105
					},
					{
						"countyCode": "510522000",
						"countyName": "合江县",
						"id": 510522,
						"parentId": 5105
					},
					{
						"countyCode": "510524000",
						"countyName": "叙永县",
						"id": 510524,
						"parentId": 5105
					},
					{
						"countyCode": "510525000",
						"countyName": "古蔺县",
						"id": 510525,
						"parentId": 5105
					}
				]
			},
			{
				"cityCode": "510600000",
				"cityName": "德阳市",
				"id": 5106,
				"parentId": 51,
				"children": [{
						"countyCode": "510603000",
						"countyName": "旌阳区",
						"id": 510603,
						"parentId": 5106
					},
					{
						"countyCode": "510604000",
						"countyName": "罗江区",
						"id": 510604,
						"parentId": 5106
					},
					{
						"countyCode": "510623000",
						"countyName": "中江县",
						"id": 510623,
						"parentId": 5106
					},
					{
						"countyCode": "510681000",
						"countyName": "广汉市",
						"id": 510681,
						"parentId": 5106
					},
					{
						"countyCode": "510682000",
						"countyName": "什邡市",
						"id": 510682,
						"parentId": 5106
					},
					{
						"countyCode": "510683000",
						"countyName": "绵竹市",
						"id": 510683,
						"parentId": 5106
					}
				]
			},
			{
				"cityCode": "510700000",
				"cityName": "绵阳市",
				"id": 5107,
				"parentId": 51,
				"children": [{
						"countyCode": "510703000",
						"countyName": "涪城区",
						"id": 510703,
						"parentId": 5107
					},
					{
						"countyCode": "510704000",
						"countyName": "游仙区",
						"id": 510704,
						"parentId": 5107
					},
					{
						"countyCode": "510705000",
						"countyName": "安州区",
						"id": 510705,
						"parentId": 5107
					},
					{
						"countyCode": "510722000",
						"countyName": "三台县",
						"id": 510722,
						"parentId": 5107
					},
					{
						"countyCode": "510723000",
						"countyName": "盐亭县",
						"id": 510723,
						"parentId": 5107
					},
					{
						"countyCode": "510725000",
						"countyName": "梓潼县",
						"id": 510725,
						"parentId": 5107
					},
					{
						"countyCode": "510726000",
						"countyName": "北川羌族自治县",
						"id": 510726,
						"parentId": 5107
					},
					{
						"countyCode": "510727000",
						"countyName": "平武县",
						"id": 510727,
						"parentId": 5107
					},
					{
						"countyCode": "510781000",
						"countyName": "江油市",
						"id": 510781,
						"parentId": 5107
					}
				]
			},
			{
				"cityCode": "510800000",
				"cityName": "广元市",
				"id": 5108,
				"parentId": 51,
				"children": [{
						"countyCode": "510802000",
						"countyName": "利州区",
						"id": 510802,
						"parentId": 5108
					},
					{
						"countyCode": "510811000",
						"countyName": "昭化区",
						"id": 510811,
						"parentId": 5108
					},
					{
						"countyCode": "510812000",
						"countyName": "朝天区",
						"id": 510812,
						"parentId": 5108
					},
					{
						"countyCode": "510821000",
						"countyName": "旺苍县",
						"id": 510821,
						"parentId": 5108
					},
					{
						"countyCode": "510822000",
						"countyName": "青川县",
						"id": 510822,
						"parentId": 5108
					},
					{
						"countyCode": "510823000",
						"countyName": "剑阁县",
						"id": 510823,
						"parentId": 5108
					},
					{
						"countyCode": "510824000",
						"countyName": "苍溪县",
						"id": 510824,
						"parentId": 5108
					}
				]
			},
			{
				"cityCode": "510900000",
				"cityName": "遂宁市",
				"id": 5109,
				"parentId": 51,
				"children": [{
						"countyCode": "510903000",
						"countyName": "船山区",
						"id": 510903,
						"parentId": 5109
					},
					{
						"countyCode": "510904000",
						"countyName": "安居区",
						"id": 510904,
						"parentId": 5109
					},
					{
						"countyCode": "510921000",
						"countyName": "蓬溪县",
						"id": 510921,
						"parentId": 5109
					},
					{
						"countyCode": "510922000",
						"countyName": "射洪市",
						"id": 510922,
						"parentId": 5109
					},
					{
						"countyCode": "510923000",
						"countyName": "大英县",
						"id": 510923,
						"parentId": 5109
					}
				]
			},
			{
				"cityCode": "511000000",
				"cityName": "内江市",
				"id": 5110,
				"parentId": 51,
				"children": [{
						"countyCode": "511002000",
						"countyName": "市中区",
						"id": 511002,
						"parentId": 5110
					},
					{
						"countyCode": "511011000",
						"countyName": "东兴区",
						"id": 511011,
						"parentId": 5110
					},
					{
						"countyCode": "511024000",
						"countyName": "威远县",
						"id": 511024,
						"parentId": 5110
					},
					{
						"countyCode": "511025000",
						"countyName": "资中县",
						"id": 511025,
						"parentId": 5110
					},
					{
						"countyCode": "511083000",
						"countyName": "隆昌市",
						"id": 511083,
						"parentId": 5110
					}
				]
			},
			{
				"cityCode": "511100000",
				"cityName": "乐山市",
				"id": 5111,
				"parentId": 51,
				"children": [{
						"countyCode": "511102000",
						"countyName": "市中区",
						"id": 511102,
						"parentId": 5111
					},
					{
						"countyCode": "511111000",
						"countyName": "沙湾区",
						"id": 511111,
						"parentId": 5111
					},
					{
						"countyCode": "511112000",
						"countyName": "五通桥区",
						"id": 511112,
						"parentId": 5111
					},
					{
						"countyCode": "511113000",
						"countyName": "金口河区",
						"id": 511113,
						"parentId": 5111
					},
					{
						"countyCode": "511123000",
						"countyName": "犍为县",
						"id": 511123,
						"parentId": 5111
					},
					{
						"countyCode": "511124000",
						"countyName": "井研县",
						"id": 511124,
						"parentId": 5111
					},
					{
						"countyCode": "511126000",
						"countyName": "夹江县",
						"id": 511126,
						"parentId": 5111
					},
					{
						"countyCode": "511129000",
						"countyName": "沐川县",
						"id": 511129,
						"parentId": 5111
					},
					{
						"countyCode": "511132000",
						"countyName": "峨边彝族自治县",
						"id": 511132,
						"parentId": 5111
					},
					{
						"countyCode": "511133000",
						"countyName": "马边彝族自治县",
						"id": 511133,
						"parentId": 5111
					},
					{
						"countyCode": "511181000",
						"countyName": "峨眉山市",
						"id": 511181,
						"parentId": 5111
					}
				]
			},
			{
				"cityCode": "511300000",
				"cityName": "南充市",
				"id": 5113,
				"parentId": 51,
				"children": [{
						"countyCode": "511302000",
						"countyName": "顺庆区",
						"id": 511302,
						"parentId": 5113
					},
					{
						"countyCode": "511303000",
						"countyName": "高坪区",
						"id": 511303,
						"parentId": 5113
					},
					{
						"countyCode": "511304000",
						"countyName": "嘉陵区",
						"id": 511304,
						"parentId": 5113
					},
					{
						"countyCode": "511321000",
						"countyName": "南部县",
						"id": 511321,
						"parentId": 5113
					},
					{
						"countyCode": "511322000",
						"countyName": "营山县",
						"id": 511322,
						"parentId": 5113
					},
					{
						"countyCode": "511323000",
						"countyName": "蓬安县",
						"id": 511323,
						"parentId": 5113
					},
					{
						"countyCode": "511324000",
						"countyName": "仪陇县",
						"id": 511324,
						"parentId": 5113
					},
					{
						"countyCode": "511325000",
						"countyName": "西充县",
						"id": 511325,
						"parentId": 5113
					},
					{
						"countyCode": "511381000",
						"countyName": "阆中市",
						"id": 511381,
						"parentId": 5113
					}
				]
			},
			{
				"cityCode": "511400000",
				"cityName": "眉山市",
				"id": 5114,
				"parentId": 51,
				"children": [{
						"countyCode": "511402000",
						"countyName": "东坡区",
						"id": 511402,
						"parentId": 5114
					},
					{
						"countyCode": "511403000",
						"countyName": "彭山区",
						"id": 511403,
						"parentId": 5114
					},
					{
						"countyCode": "511421000",
						"countyName": "仁寿县",
						"id": 511421,
						"parentId": 5114
					},
					{
						"countyCode": "511423000",
						"countyName": "洪雅县",
						"id": 511423,
						"parentId": 5114
					},
					{
						"countyCode": "511424000",
						"countyName": "丹棱县",
						"id": 511424,
						"parentId": 5114
					},
					{
						"countyCode": "511425000",
						"countyName": "青神县",
						"id": 511425,
						"parentId": 5114
					}
				]
			},
			{
				"cityCode": "511500000",
				"cityName": "宜宾市",
				"id": 5115,
				"parentId": 51,
				"children": [{
						"countyCode": "511502000",
						"countyName": "翠屏区",
						"id": 511502,
						"parentId": 5115
					},
					{
						"countyCode": "511503000",
						"countyName": "南溪区",
						"id": 511503,
						"parentId": 5115
					},
					{
						"countyCode": "511504000",
						"countyName": "叙州区",
						"id": 511504,
						"parentId": 5115
					},
					{
						"countyCode": "511523000",
						"countyName": "江安县",
						"id": 511523,
						"parentId": 5115
					},
					{
						"countyCode": "511524000",
						"countyName": "长宁县",
						"id": 511524,
						"parentId": 5115
					},
					{
						"countyCode": "511525000",
						"countyName": "高县",
						"id": 511525,
						"parentId": 5115
					},
					{
						"countyCode": "511526000",
						"countyName": "珙县",
						"id": 511526,
						"parentId": 5115
					},
					{
						"countyCode": "511527000",
						"countyName": "筠连县",
						"id": 511527,
						"parentId": 5115
					},
					{
						"countyCode": "511528000",
						"countyName": "兴文县",
						"id": 511528,
						"parentId": 5115
					},
					{
						"countyCode": "511529000",
						"countyName": "屏山县",
						"id": 511529,
						"parentId": 5115
					}
				]
			},
			{
				"cityCode": "511600000",
				"cityName": "广安市",
				"id": 5116,
				"parentId": 51,
				"children": [{
						"countyCode": "511602000",
						"countyName": "广安区",
						"id": 511602,
						"parentId": 5116
					},
					{
						"countyCode": "511603000",
						"countyName": "前锋区",
						"id": 511603,
						"parentId": 5116
					},
					{
						"countyCode": "511621000",
						"countyName": "岳池县",
						"id": 511621,
						"parentId": 5116
					},
					{
						"countyCode": "511622000",
						"countyName": "武胜县",
						"id": 511622,
						"parentId": 5116
					},
					{
						"countyCode": "511623000",
						"countyName": "邻水县",
						"id": 511623,
						"parentId": 5116
					},
					{
						"countyCode": "511681000",
						"countyName": "华蓥市",
						"id": 511681,
						"parentId": 5116
					}
				]
			},
			{
				"cityCode": "511700000",
				"cityName": "达州市",
				"id": 5117,
				"parentId": 51,
				"children": [{
						"countyCode": "511702000",
						"countyName": "通川区",
						"id": 511702,
						"parentId": 5117
					},
					{
						"countyCode": "511703000",
						"countyName": "达川区",
						"id": 511703,
						"parentId": 5117
					},
					{
						"countyCode": "511722000",
						"countyName": "宣汉县",
						"id": 511722,
						"parentId": 5117
					},
					{
						"countyCode": "511723000",
						"countyName": "开江县",
						"id": 511723,
						"parentId": 5117
					},
					{
						"countyCode": "511724000",
						"countyName": "大竹县",
						"id": 511724,
						"parentId": 5117
					},
					{
						"countyCode": "511725000",
						"countyName": "渠县",
						"id": 511725,
						"parentId": 5117
					},
					{
						"countyCode": "511781000",
						"countyName": "万源市",
						"id": 511781,
						"parentId": 5117
					}
				]
			},
			{
				"cityCode": "511800000",
				"cityName": "雅安市",
				"id": 5118,
				"parentId": 51,
				"children": [{
						"countyCode": "511802000",
						"countyName": "雨城区",
						"id": 511802,
						"parentId": 5118
					},
					{
						"countyCode": "511803000",
						"countyName": "名山区",
						"id": 511803,
						"parentId": 5118
					},
					{
						"countyCode": "511822000",
						"countyName": "荥经县",
						"id": 511822,
						"parentId": 5118
					},
					{
						"countyCode": "511823000",
						"countyName": "汉源县",
						"id": 511823,
						"parentId": 5118
					},
					{
						"countyCode": "511824000",
						"countyName": "石棉县",
						"id": 511824,
						"parentId": 5118
					},
					{
						"countyCode": "511825000",
						"countyName": "天全县",
						"id": 511825,
						"parentId": 5118
					},
					{
						"countyCode": "511826000",
						"countyName": "芦山县",
						"id": 511826,
						"parentId": 5118
					},
					{
						"countyCode": "511827000",
						"countyName": "宝兴县",
						"id": 511827,
						"parentId": 5118
					}
				]
			},
			{
				"cityCode": "511900000",
				"cityName": "巴中市",
				"id": 5119,
				"parentId": 51,
				"children": [{
						"countyCode": "511902000",
						"countyName": "巴州区",
						"id": 511902,
						"parentId": 5119
					},
					{
						"countyCode": "511903000",
						"countyName": "恩阳区",
						"id": 511903,
						"parentId": 5119
					},
					{
						"countyCode": "511921000",
						"countyName": "通江县",
						"id": 511921,
						"parentId": 5119
					},
					{
						"countyCode": "511922000",
						"countyName": "南江县",
						"id": 511922,
						"parentId": 5119
					},
					{
						"countyCode": "511923000",
						"countyName": "平昌县",
						"id": 511923,
						"parentId": 5119
					}
				]
			},
			{
				"cityCode": "512000000",
				"cityName": "资阳市",
				"id": 5120,
				"parentId": 51,
				"children": [{
						"countyCode": "512002000",
						"countyName": "雁江区",
						"id": 512002,
						"parentId": 5120
					},
					{
						"countyCode": "512021000",
						"countyName": "安岳县",
						"id": 512021,
						"parentId": 5120
					},
					{
						"countyCode": "512022000",
						"countyName": "乐至县",
						"id": 512022,
						"parentId": 5120
					}
				]
			},
			{
				"cityCode": "513200000",
				"cityName": "阿坝藏族羌族自治州",
				"id": 5132,
				"parentId": 51,
				"children": [{
						"countyCode": "513201000",
						"countyName": "马尔康市",
						"id": 513201,
						"parentId": 5132
					},
					{
						"countyCode": "513221000",
						"countyName": "汶川县",
						"id": 513221,
						"parentId": 5132
					},
					{
						"countyCode": "513222000",
						"countyName": "理县",
						"id": 513222,
						"parentId": 5132
					},
					{
						"countyCode": "513223000",
						"countyName": "茂县",
						"id": 513223,
						"parentId": 5132
					},
					{
						"countyCode": "513224000",
						"countyName": "松潘县",
						"id": 513224,
						"parentId": 5132
					},
					{
						"countyCode": "513225000",
						"countyName": "九寨沟县",
						"id": 513225,
						"parentId": 5132
					},
					{
						"countyCode": "513226000",
						"countyName": "金川县",
						"id": 513226,
						"parentId": 5132
					},
					{
						"countyCode": "513227000",
						"countyName": "小金县",
						"id": 513227,
						"parentId": 5132
					},
					{
						"countyCode": "513228000",
						"countyName": "黑水县",
						"id": 513228,
						"parentId": 5132
					},
					{
						"countyCode": "513230000",
						"countyName": "壤塘县",
						"id": 513230,
						"parentId": 5132
					},
					{
						"countyCode": "513231000",
						"countyName": "阿坝县",
						"id": 513231,
						"parentId": 5132
					},
					{
						"countyCode": "513232000",
						"countyName": "若尔盖县",
						"id": 513232,
						"parentId": 5132
					},
					{
						"countyCode": "513233000",
						"countyName": "红原县",
						"id": 513233,
						"parentId": 5132
					}
				]
			},
			{
				"cityCode": "513300000",
				"cityName": "甘孜藏族自治州",
				"id": 5133,
				"parentId": 51,
				"children": [{
						"countyCode": "513301000",
						"countyName": "康定市",
						"id": 513301,
						"parentId": 5133
					},
					{
						"countyCode": "513322000",
						"countyName": "泸定县",
						"id": 513322,
						"parentId": 5133
					},
					{
						"countyCode": "513323000",
						"countyName": "丹巴县",
						"id": 513323,
						"parentId": 5133
					},
					{
						"countyCode": "513324000",
						"countyName": "九龙县",
						"id": 513324,
						"parentId": 5133
					},
					{
						"countyCode": "513325000",
						"countyName": "雅江县",
						"id": 513325,
						"parentId": 5133
					},
					{
						"countyCode": "513326000",
						"countyName": "道孚县",
						"id": 513326,
						"parentId": 5133
					},
					{
						"countyCode": "513327000",
						"countyName": "炉霍县",
						"id": 513327,
						"parentId": 5133
					},
					{
						"countyCode": "513328000",
						"countyName": "甘孜县",
						"id": 513328,
						"parentId": 5133
					},
					{
						"countyCode": "513329000",
						"countyName": "新龙县",
						"id": 513329,
						"parentId": 5133
					},
					{
						"countyCode": "513330000",
						"countyName": "德格县",
						"id": 513330,
						"parentId": 5133
					},
					{
						"countyCode": "513331000",
						"countyName": "白玉县",
						"id": 513331,
						"parentId": 5133
					},
					{
						"countyCode": "513332000",
						"countyName": "石渠县",
						"id": 513332,
						"parentId": 5133
					},
					{
						"countyCode": "513333000",
						"countyName": "色达县",
						"id": 513333,
						"parentId": 5133
					},
					{
						"countyCode": "513334000",
						"countyName": "理塘县",
						"id": 513334,
						"parentId": 5133
					},
					{
						"countyCode": "513335000",
						"countyName": "巴塘县",
						"id": 513335,
						"parentId": 5133
					},
					{
						"countyCode": "513336000",
						"countyName": "乡城县",
						"id": 513336,
						"parentId": 5133
					},
					{
						"countyCode": "513337000",
						"countyName": "稻城县",
						"id": 513337,
						"parentId": 5133
					},
					{
						"countyCode": "513338000",
						"countyName": "得荣县",
						"id": 513338,
						"parentId": 5133
					}
				]
			},
			{
				"cityCode": "513400000",
				"cityName": "凉山彝族自治州",
				"id": 5134,
				"parentId": 51,
				"children": [{
						"countyCode": "513401000",
						"countyName": "西昌市",
						"id": 513401,
						"parentId": 5134
					},
					{
						"countyCode": "513422000",
						"countyName": "木里藏族自治县",
						"id": 513422,
						"parentId": 5134
					},
					{
						"countyCode": "513423000",
						"countyName": "盐源县",
						"id": 513423,
						"parentId": 5134
					},
					{
						"countyCode": "513424000",
						"countyName": "德昌县",
						"id": 513424,
						"parentId": 5134
					},
					{
						"countyCode": "513425000",
						"countyName": "会理县",
						"id": 513425,
						"parentId": 5134
					},
					{
						"countyCode": "513426000",
						"countyName": "会东县",
						"id": 513426,
						"parentId": 5134
					},
					{
						"countyCode": "513427000",
						"countyName": "宁南县",
						"id": 513427,
						"parentId": 5134
					},
					{
						"countyCode": "513428000",
						"countyName": "普格县",
						"id": 513428,
						"parentId": 5134
					},
					{
						"countyCode": "513429000",
						"countyName": "布拖县",
						"id": 513429,
						"parentId": 5134
					},
					{
						"countyCode": "513430000",
						"countyName": "金阳县",
						"id": 513430,
						"parentId": 5134
					},
					{
						"countyCode": "513431000",
						"countyName": "昭觉县",
						"id": 513431,
						"parentId": 5134
					},
					{
						"countyCode": "513432000",
						"countyName": "喜德县",
						"id": 513432,
						"parentId": 5134
					},
					{
						"countyCode": "513433000",
						"countyName": "冕宁县",
						"id": 513433,
						"parentId": 5134
					},
					{
						"countyCode": "513434000",
						"countyName": "越西县",
						"id": 513434,
						"parentId": 5134
					},
					{
						"countyCode": "513435000",
						"countyName": "甘洛县",
						"id": 513435,
						"parentId": 5134
					},
					{
						"countyCode": "513436000",
						"countyName": "美姑县",
						"id": 513436,
						"parentId": 5134
					},
					{
						"countyCode": "513437000",
						"countyName": "雷波县",
						"id": 513437,
						"parentId": 5134
					}
				]
			}
		]
	},
	{
		"provinceCode": "520000000",
		"provinceName": "贵州省",
		"id": 52,
		"parentId": 0,
		"children": [{
				"cityCode": "520100000",
				"cityName": "贵阳市",
				"id": 5201,
				"parentId": 52,
				"children": [{
						"countyCode": "520102000",
						"countyName": "南明区",
						"id": 520102,
						"parentId": 5201
					},
					{
						"countyCode": "520103000",
						"countyName": "云岩区",
						"id": 520103,
						"parentId": 5201
					},
					{
						"countyCode": "520111000",
						"countyName": "花溪区",
						"id": 520111,
						"parentId": 5201
					},
					{
						"countyCode": "520112000",
						"countyName": "乌当区",
						"id": 520112,
						"parentId": 5201
					},
					{
						"countyCode": "520113000",
						"countyName": "白云区",
						"id": 520113,
						"parentId": 5201
					},
					{
						"countyCode": "520115000",
						"countyName": "观山湖区",
						"id": 520115,
						"parentId": 5201
					},
					{
						"countyCode": "520121000",
						"countyName": "开阳县",
						"id": 520121,
						"parentId": 5201
					},
					{
						"countyCode": "520122000",
						"countyName": "息烽县",
						"id": 520122,
						"parentId": 5201
					},
					{
						"countyCode": "520123000",
						"countyName": "修文县",
						"id": 520123,
						"parentId": 5201
					},
					{
						"countyCode": "520181000",
						"countyName": "清镇市",
						"id": 520181,
						"parentId": 5201
					}
				]
			},
			{
				"cityCode": "520200000",
				"cityName": "六盘水市",
				"id": 5202,
				"parentId": 52,
				"children": [{
						"countyCode": "520201000",
						"countyName": "钟山区",
						"id": 520201,
						"parentId": 5202
					},
					{
						"countyCode": "520203000",
						"countyName": "六枝特区",
						"id": 520203,
						"parentId": 5202
					},
					{
						"countyCode": "520221000",
						"countyName": "水城县",
						"id": 520221,
						"parentId": 5202
					},
					{
						"countyCode": "520281000",
						"countyName": "盘州市",
						"id": 520281,
						"parentId": 5202
					}
				]
			},
			{
				"cityCode": "520300000",
				"cityName": "遵义市",
				"id": 5203,
				"parentId": 52,
				"children": [{
						"countyCode": "520302000",
						"countyName": "红花岗区",
						"id": 520302,
						"parentId": 5203
					},
					{
						"countyCode": "520303000",
						"countyName": "汇川区",
						"id": 520303,
						"parentId": 5203
					},
					{
						"countyCode": "520304000",
						"countyName": "播州区",
						"id": 520304,
						"parentId": 5203
					},
					{
						"countyCode": "520322000",
						"countyName": "桐梓县",
						"id": 520322,
						"parentId": 5203
					},
					{
						"countyCode": "520323000",
						"countyName": "绥阳县",
						"id": 520323,
						"parentId": 5203
					},
					{
						"countyCode": "520324000",
						"countyName": "正安县",
						"id": 520324,
						"parentId": 5203
					},
					{
						"countyCode": "520325000",
						"countyName": "道真仡佬族苗族自治县",
						"id": 520325,
						"parentId": 5203
					},
					{
						"countyCode": "520326000",
						"countyName": "务川仡佬族苗族自治县",
						"id": 520326,
						"parentId": 5203
					},
					{
						"countyCode": "520327000",
						"countyName": "凤冈县",
						"id": 520327,
						"parentId": 5203
					},
					{
						"countyCode": "520328000",
						"countyName": "湄潭县",
						"id": 520328,
						"parentId": 5203
					},
					{
						"countyCode": "520329000",
						"countyName": "余庆县",
						"id": 520329,
						"parentId": 5203
					},
					{
						"countyCode": "520330000",
						"countyName": "习水县",
						"id": 520330,
						"parentId": 5203
					},
					{
						"countyCode": "520381000",
						"countyName": "赤水市",
						"id": 520381,
						"parentId": 5203
					},
					{
						"countyCode": "520382000",
						"countyName": "仁怀市",
						"id": 520382,
						"parentId": 5203
					}
				]
			},
			{
				"cityCode": "520400000",
				"cityName": "安顺市",
				"id": 5204,
				"parentId": 52,
				"children": [{
						"countyCode": "520402000",
						"countyName": "西秀区",
						"id": 520402,
						"parentId": 5204
					},
					{
						"countyCode": "520403000",
						"countyName": "平坝区",
						"id": 520403,
						"parentId": 5204
					},
					{
						"countyCode": "520422000",
						"countyName": "普定县",
						"id": 520422,
						"parentId": 5204
					},
					{
						"countyCode": "520423000",
						"countyName": "镇宁布依族苗族自治县",
						"id": 520423,
						"parentId": 5204
					},
					{
						"countyCode": "520424000",
						"countyName": "关岭布依族苗族自治县",
						"id": 520424,
						"parentId": 5204
					},
					{
						"countyCode": "520425000",
						"countyName": "紫云苗族布依族自治县",
						"id": 520425,
						"parentId": 5204
					}
				]
			},
			{
				"cityCode": "520500000",
				"cityName": "毕节市",
				"id": 5205,
				"parentId": 52,
				"children": [{
						"countyCode": "520502000",
						"countyName": "七星关区",
						"id": 520502,
						"parentId": 5205
					},
					{
						"countyCode": "520521000",
						"countyName": "大方县",
						"id": 520521,
						"parentId": 5205
					},
					{
						"countyCode": "520522000",
						"countyName": "黔西县",
						"id": 520522,
						"parentId": 5205
					},
					{
						"countyCode": "520523000",
						"countyName": "金沙县",
						"id": 520523,
						"parentId": 5205
					},
					{
						"countyCode": "520524000",
						"countyName": "织金县",
						"id": 520524,
						"parentId": 5205
					},
					{
						"countyCode": "520525000",
						"countyName": "纳雍县",
						"id": 520525,
						"parentId": 5205
					},
					{
						"countyCode": "520526000",
						"countyName": "威宁彝族回族苗族自治县",
						"id": 520526,
						"parentId": 5205
					},
					{
						"countyCode": "520527000",
						"countyName": "赫章县",
						"id": 520527,
						"parentId": 5205
					}
				]
			},
			{
				"cityCode": "520600000",
				"cityName": "铜仁市",
				"id": 5206,
				"parentId": 52,
				"children": [{
						"countyCode": "520602000",
						"countyName": "碧江区",
						"id": 520602,
						"parentId": 5206
					},
					{
						"countyCode": "520603000",
						"countyName": "万山区",
						"id": 520603,
						"parentId": 5206
					},
					{
						"countyCode": "520621000",
						"countyName": "江口县",
						"id": 520621,
						"parentId": 5206
					},
					{
						"countyCode": "520622000",
						"countyName": "玉屏侗族自治县",
						"id": 520622,
						"parentId": 5206
					},
					{
						"countyCode": "520623000",
						"countyName": "石阡县",
						"id": 520623,
						"parentId": 5206
					},
					{
						"countyCode": "520624000",
						"countyName": "思南县",
						"id": 520624,
						"parentId": 5206
					},
					{
						"countyCode": "520625000",
						"countyName": "印江土家族苗族自治县",
						"id": 520625,
						"parentId": 5206
					},
					{
						"countyCode": "520626000",
						"countyName": "德江县",
						"id": 520626,
						"parentId": 5206
					},
					{
						"countyCode": "520627000",
						"countyName": "沿河土家族自治县",
						"id": 520627,
						"parentId": 5206
					},
					{
						"countyCode": "520628000",
						"countyName": "松桃苗族自治县",
						"id": 520628,
						"parentId": 5206
					}
				]
			},
			{
				"cityCode": "522300000",
				"cityName": "黔西南布依族苗族自治州",
				"id": 5223,
				"parentId": 52,
				"children": [{
						"countyCode": "522301000",
						"countyName": "兴义市",
						"id": 522301,
						"parentId": 5223
					},
					{
						"countyCode": "522302000",
						"countyName": "兴仁市",
						"id": 522302,
						"parentId": 5223
					},
					{
						"countyCode": "522323000",
						"countyName": "普安县",
						"id": 522323,
						"parentId": 5223
					},
					{
						"countyCode": "522324000",
						"countyName": "晴隆县",
						"id": 522324,
						"parentId": 5223
					},
					{
						"countyCode": "522325000",
						"countyName": "贞丰县",
						"id": 522325,
						"parentId": 5223
					},
					{
						"countyCode": "522326000",
						"countyName": "望谟县",
						"id": 522326,
						"parentId": 5223
					},
					{
						"countyCode": "522327000",
						"countyName": "册亨县",
						"id": 522327,
						"parentId": 5223
					},
					{
						"countyCode": "522328000",
						"countyName": "安龙县",
						"id": 522328,
						"parentId": 5223
					}
				]
			},
			{
				"cityCode": "522600000",
				"cityName": "黔东南苗族侗族自治州",
				"id": 5226,
				"parentId": 52,
				"children": [{
						"countyCode": "522601000",
						"countyName": "凯里市",
						"id": 522601,
						"parentId": 5226
					},
					{
						"countyCode": "522622000",
						"countyName": "黄平县",
						"id": 522622,
						"parentId": 5226
					},
					{
						"countyCode": "522623000",
						"countyName": "施秉县",
						"id": 522623,
						"parentId": 5226
					},
					{
						"countyCode": "522624000",
						"countyName": "三穗县",
						"id": 522624,
						"parentId": 5226
					},
					{
						"countyCode": "522625000",
						"countyName": "镇远县",
						"id": 522625,
						"parentId": 5226
					},
					{
						"countyCode": "522626000",
						"countyName": "岑巩县",
						"id": 522626,
						"parentId": 5226
					},
					{
						"countyCode": "522627000",
						"countyName": "天柱县",
						"id": 522627,
						"parentId": 5226
					},
					{
						"countyCode": "522628000",
						"countyName": "锦屏县",
						"id": 522628,
						"parentId": 5226
					},
					{
						"countyCode": "522629000",
						"countyName": "剑河县",
						"id": 522629,
						"parentId": 5226
					},
					{
						"countyCode": "522630000",
						"countyName": "台江县",
						"id": 522630,
						"parentId": 5226
					},
					{
						"countyCode": "522631000",
						"countyName": "黎平县",
						"id": 522631,
						"parentId": 5226
					},
					{
						"countyCode": "522632000",
						"countyName": "榕江县",
						"id": 522632,
						"parentId": 5226
					},
					{
						"countyCode": "522633000",
						"countyName": "从江县",
						"id": 522633,
						"parentId": 5226
					},
					{
						"countyCode": "522634000",
						"countyName": "雷山县",
						"id": 522634,
						"parentId": 5226
					},
					{
						"countyCode": "522635000",
						"countyName": "麻江县",
						"id": 522635,
						"parentId": 5226
					},
					{
						"countyCode": "522636000",
						"countyName": "丹寨县",
						"id": 522636,
						"parentId": 5226
					}
				]
			},
			{
				"cityCode": "522700000",
				"cityName": "黔南布依族苗族自治州",
				"id": 5227,
				"parentId": 52,
				"children": [{
						"countyCode": "522701000",
						"countyName": "都匀市",
						"id": 522701,
						"parentId": 5227
					},
					{
						"countyCode": "522702000",
						"countyName": "福泉市",
						"id": 522702,
						"parentId": 5227
					},
					{
						"countyCode": "522722000",
						"countyName": "荔波县",
						"id": 522722,
						"parentId": 5227
					},
					{
						"countyCode": "522723000",
						"countyName": "贵定县",
						"id": 522723,
						"parentId": 5227
					},
					{
						"countyCode": "522725000",
						"countyName": "瓮安县",
						"id": 522725,
						"parentId": 5227
					},
					{
						"countyCode": "522726000",
						"countyName": "独山县",
						"id": 522726,
						"parentId": 5227
					},
					{
						"countyCode": "522727000",
						"countyName": "平塘县",
						"id": 522727,
						"parentId": 5227
					},
					{
						"countyCode": "522728000",
						"countyName": "罗甸县",
						"id": 522728,
						"parentId": 5227
					},
					{
						"countyCode": "522729000",
						"countyName": "长顺县",
						"id": 522729,
						"parentId": 5227
					},
					{
						"countyCode": "522730000",
						"countyName": "龙里县",
						"id": 522730,
						"parentId": 5227
					},
					{
						"countyCode": "522731000",
						"countyName": "惠水县",
						"id": 522731,
						"parentId": 5227
					},
					{
						"countyCode": "522732000",
						"countyName": "三都水族自治县",
						"id": 522732,
						"parentId": 5227
					}
				]
			}
		]
	},
	{
		"provinceCode": "530000000",
		"provinceName": "云南省",
		"id": 53,
		"parentId": 0,
		"children": [{
				"cityCode": "530100000",
				"cityName": "昆明市",
				"id": 5301,
				"parentId": 53,
				"children": [{
						"countyCode": "530102000",
						"countyName": "五华区",
						"id": 530102,
						"parentId": 5301
					},
					{
						"countyCode": "530103000",
						"countyName": "盘龙区",
						"id": 530103,
						"parentId": 5301
					},
					{
						"countyCode": "530111000",
						"countyName": "官渡区",
						"id": 530111,
						"parentId": 5301
					},
					{
						"countyCode": "530112000",
						"countyName": "西山区",
						"id": 530112,
						"parentId": 5301
					},
					{
						"countyCode": "530113000",
						"countyName": "东川区",
						"id": 530113,
						"parentId": 5301
					},
					{
						"countyCode": "530114000",
						"countyName": "呈贡区",
						"id": 530114,
						"parentId": 5301
					},
					{
						"countyCode": "530115000",
						"countyName": "晋宁区",
						"id": 530115,
						"parentId": 5301
					},
					{
						"countyCode": "530124000",
						"countyName": "富民县",
						"id": 530124,
						"parentId": 5301
					},
					{
						"countyCode": "530125000",
						"countyName": "宜良县",
						"id": 530125,
						"parentId": 5301
					},
					{
						"countyCode": "530126000",
						"countyName": "石林彝族自治县",
						"id": 530126,
						"parentId": 5301
					},
					{
						"countyCode": "530127000",
						"countyName": "嵩明县",
						"id": 530127,
						"parentId": 5301
					},
					{
						"countyCode": "530128000",
						"countyName": "禄劝彝族苗族自治县",
						"id": 530128,
						"parentId": 5301
					},
					{
						"countyCode": "530129000",
						"countyName": "寻甸回族彝族自治县",
						"id": 530129,
						"parentId": 5301
					},
					{
						"countyCode": "530181000",
						"countyName": "安宁市",
						"id": 530181,
						"parentId": 5301
					}
				]
			},
			{
				"cityCode": "530300000",
				"cityName": "曲靖市",
				"id": 5303,
				"parentId": 53,
				"children": [{
						"countyCode": "530302000",
						"countyName": "麒麟区",
						"id": 530302,
						"parentId": 5303
					},
					{
						"countyCode": "530303000",
						"countyName": "沾益区",
						"id": 530303,
						"parentId": 5303
					},
					{
						"countyCode": "530304000",
						"countyName": "马龙区",
						"id": 530304,
						"parentId": 5303
					},
					{
						"countyCode": "530322000",
						"countyName": "陆良县",
						"id": 530322,
						"parentId": 5303
					},
					{
						"countyCode": "530323000",
						"countyName": "师宗县",
						"id": 530323,
						"parentId": 5303
					},
					{
						"countyCode": "530324000",
						"countyName": "罗平县",
						"id": 530324,
						"parentId": 5303
					},
					{
						"countyCode": "530325000",
						"countyName": "富源县",
						"id": 530325,
						"parentId": 5303
					},
					{
						"countyCode": "530326000",
						"countyName": "会泽县",
						"id": 530326,
						"parentId": 5303
					},
					{
						"countyCode": "530381000",
						"countyName": "宣威市",
						"id": 530381,
						"parentId": 5303
					}
				]
			},
			{
				"cityCode": "530400000",
				"cityName": "玉溪市",
				"id": 5304,
				"parentId": 53,
				"children": [{
						"countyCode": "530402000",
						"countyName": "红塔区",
						"id": 530402,
						"parentId": 5304
					},
					{
						"countyCode": "530403000",
						"countyName": "江川区",
						"id": 530403,
						"parentId": 5304
					},
					{
						"countyCode": "530422000",
						"countyName": "澄江县",
						"id": 530422,
						"parentId": 5304
					},
					{
						"countyCode": "530423000",
						"countyName": "通海县",
						"id": 530423,
						"parentId": 5304
					},
					{
						"countyCode": "530424000",
						"countyName": "华宁县",
						"id": 530424,
						"parentId": 5304
					},
					{
						"countyCode": "530425000",
						"countyName": "易门县",
						"id": 530425,
						"parentId": 5304
					},
					{
						"countyCode": "530426000",
						"countyName": "峨山彝族自治县",
						"id": 530426,
						"parentId": 5304
					},
					{
						"countyCode": "530427000",
						"countyName": "新平彝族傣族自治县",
						"id": 530427,
						"parentId": 5304
					},
					{
						"countyCode": "530428000",
						"countyName": "元江哈尼族彝族傣族自治县",
						"id": 530428,
						"parentId": 5304
					}
				]
			},
			{
				"cityCode": "530500000",
				"cityName": "保山市",
				"id": 5305,
				"parentId": 53,
				"children": [{
						"countyCode": "530502000",
						"countyName": "隆阳区",
						"id": 530502,
						"parentId": 5305
					},
					{
						"countyCode": "530521000",
						"countyName": "施甸县",
						"id": 530521,
						"parentId": 5305
					},
					{
						"countyCode": "530523000",
						"countyName": "龙陵县",
						"id": 530523,
						"parentId": 5305
					},
					{
						"countyCode": "530524000",
						"countyName": "昌宁县",
						"id": 530524,
						"parentId": 5305
					},
					{
						"countyCode": "530581000",
						"countyName": "腾冲市",
						"id": 530581,
						"parentId": 5305
					}
				]
			},
			{
				"cityCode": "530600000",
				"cityName": "昭通市",
				"id": 5306,
				"parentId": 53,
				"children": [{
						"countyCode": "530602000",
						"countyName": "昭阳区",
						"id": 530602,
						"parentId": 5306
					},
					{
						"countyCode": "530621000",
						"countyName": "鲁甸县",
						"id": 530621,
						"parentId": 5306
					},
					{
						"countyCode": "530622000",
						"countyName": "巧家县",
						"id": 530622,
						"parentId": 5306
					},
					{
						"countyCode": "530623000",
						"countyName": "盐津县",
						"id": 530623,
						"parentId": 5306
					},
					{
						"countyCode": "530624000",
						"countyName": "大关县",
						"id": 530624,
						"parentId": 5306
					},
					{
						"countyCode": "530625000",
						"countyName": "永善县",
						"id": 530625,
						"parentId": 5306
					},
					{
						"countyCode": "530626000",
						"countyName": "绥江县",
						"id": 530626,
						"parentId": 5306
					},
					{
						"countyCode": "530627000",
						"countyName": "镇雄县",
						"id": 530627,
						"parentId": 5306
					},
					{
						"countyCode": "530628000",
						"countyName": "彝良县",
						"id": 530628,
						"parentId": 5306
					},
					{
						"countyCode": "530629000",
						"countyName": "威信县",
						"id": 530629,
						"parentId": 5306
					},
					{
						"countyCode": "530681000",
						"countyName": "水富市",
						"id": 530681,
						"parentId": 5306
					}
				]
			},
			{
				"cityCode": "530700000",
				"cityName": "丽江市",
				"id": 5307,
				"parentId": 53,
				"children": [{
						"countyCode": "530702000",
						"countyName": "古城区",
						"id": 530702,
						"parentId": 5307
					},
					{
						"countyCode": "530721000",
						"countyName": "玉龙纳西族自治县",
						"id": 530721,
						"parentId": 5307
					},
					{
						"countyCode": "530722000",
						"countyName": "永胜县",
						"id": 530722,
						"parentId": 5307
					},
					{
						"countyCode": "530723000",
						"countyName": "华坪县",
						"id": 530723,
						"parentId": 5307
					},
					{
						"countyCode": "530724000",
						"countyName": "宁蒗彝族自治县",
						"id": 530724,
						"parentId": 5307
					}
				]
			},
			{
				"cityCode": "530800000",
				"cityName": "普洱市",
				"id": 5308,
				"parentId": 53,
				"children": [{
						"countyCode": "530802000",
						"countyName": "思茅区",
						"id": 530802,
						"parentId": 5308
					},
					{
						"countyCode": "530821000",
						"countyName": "宁洱哈尼族彝族自治县",
						"id": 530821,
						"parentId": 5308
					},
					{
						"countyCode": "530822000",
						"countyName": "墨江哈尼族自治县",
						"id": 530822,
						"parentId": 5308
					},
					{
						"countyCode": "530823000",
						"countyName": "景东彝族自治县",
						"id": 530823,
						"parentId": 5308
					},
					{
						"countyCode": "530824000",
						"countyName": "景谷傣族彝族自治县",
						"id": 530824,
						"parentId": 5308
					},
					{
						"countyCode": "530825000",
						"countyName": "镇沅彝族哈尼族拉祜族自治县",
						"id": 530825,
						"parentId": 5308
					},
					{
						"countyCode": "530826000",
						"countyName": "江城哈尼族彝族自治县",
						"id": 530826,
						"parentId": 5308
					},
					{
						"countyCode": "530827000",
						"countyName": "孟连傣族拉祜族佤族自治县",
						"id": 530827,
						"parentId": 5308
					},
					{
						"countyCode": "530828000",
						"countyName": "澜沧拉祜族自治县",
						"id": 530828,
						"parentId": 5308
					},
					{
						"countyCode": "530829000",
						"countyName": "西盟佤族自治县",
						"id": 530829,
						"parentId": 5308
					}
				]
			},
			{
				"cityCode": "530900000",
				"cityName": "临沧市",
				"id": 5309,
				"parentId": 53,
				"children": [{
						"countyCode": "530902000",
						"countyName": "临翔区",
						"id": 530902,
						"parentId": 5309
					},
					{
						"countyCode": "530921000",
						"countyName": "凤庆县",
						"id": 530921,
						"parentId": 5309
					},
					{
						"countyCode": "530922000",
						"countyName": "云县",
						"id": 530922,
						"parentId": 5309
					},
					{
						"countyCode": "530923000",
						"countyName": "永德县",
						"id": 530923,
						"parentId": 5309
					},
					{
						"countyCode": "530924000",
						"countyName": "镇康县",
						"id": 530924,
						"parentId": 5309
					},
					{
						"countyCode": "530925000",
						"countyName": "双江拉祜族佤族布朗族傣族自治县",
						"id": 530925,
						"parentId": 5309
					},
					{
						"countyCode": "530926000",
						"countyName": "耿马傣族佤族自治县",
						"id": 530926,
						"parentId": 5309
					},
					{
						"countyCode": "530927000",
						"countyName": "沧源佤族自治县",
						"id": 530927,
						"parentId": 5309
					}
				]
			},
			{
				"cityCode": "532300000",
				"cityName": "楚雄彝族自治州",
				"id": 5323,
				"parentId": 53,
				"children": [{
						"countyCode": "532301000",
						"countyName": "楚雄市",
						"id": 532301,
						"parentId": 5323
					},
					{
						"countyCode": "532322000",
						"countyName": "双柏县",
						"id": 532322,
						"parentId": 5323
					},
					{
						"countyCode": "532323000",
						"countyName": "牟定县",
						"id": 532323,
						"parentId": 5323
					},
					{
						"countyCode": "532324000",
						"countyName": "南华县",
						"id": 532324,
						"parentId": 5323
					},
					{
						"countyCode": "532325000",
						"countyName": "姚安县",
						"id": 532325,
						"parentId": 5323
					},
					{
						"countyCode": "532326000",
						"countyName": "大姚县",
						"id": 532326,
						"parentId": 5323
					},
					{
						"countyCode": "532327000",
						"countyName": "永仁县",
						"id": 532327,
						"parentId": 5323
					},
					{
						"countyCode": "532328000",
						"countyName": "元谋县",
						"id": 532328,
						"parentId": 5323
					},
					{
						"countyCode": "532329000",
						"countyName": "武定县",
						"id": 532329,
						"parentId": 5323
					},
					{
						"countyCode": "532331000",
						"countyName": "禄丰县",
						"id": 532331,
						"parentId": 5323
					}
				]
			},
			{
				"cityCode": "532500000",
				"cityName": "红河哈尼族彝族自治州",
				"id": 5325,
				"parentId": 53,
				"children": [{
						"countyCode": "532501000",
						"countyName": "个旧市",
						"id": 532501,
						"parentId": 5325
					},
					{
						"countyCode": "532502000",
						"countyName": "开远市",
						"id": 532502,
						"parentId": 5325
					},
					{
						"countyCode": "532503000",
						"countyName": "蒙自市",
						"id": 532503,
						"parentId": 5325
					},
					{
						"countyCode": "532504000",
						"countyName": "弥勒市",
						"id": 532504,
						"parentId": 5325
					},
					{
						"countyCode": "532523000",
						"countyName": "屏边苗族自治县",
						"id": 532523,
						"parentId": 5325
					},
					{
						"countyCode": "532524000",
						"countyName": "建水县",
						"id": 532524,
						"parentId": 5325
					},
					{
						"countyCode": "532525000",
						"countyName": "石屏县",
						"id": 532525,
						"parentId": 5325
					},
					{
						"countyCode": "532527000",
						"countyName": "泸西县",
						"id": 532527,
						"parentId": 5325
					},
					{
						"countyCode": "532528000",
						"countyName": "元阳县",
						"id": 532528,
						"parentId": 5325
					},
					{
						"countyCode": "532529000",
						"countyName": "红河县",
						"id": 532529,
						"parentId": 5325
					},
					{
						"countyCode": "532530000",
						"countyName": "金平苗族瑶族傣族自治县",
						"id": 532530,
						"parentId": 5325
					},
					{
						"countyCode": "532531000",
						"countyName": "绿春县",
						"id": 532531,
						"parentId": 5325
					},
					{
						"countyCode": "532532000",
						"countyName": "河口瑶族自治县",
						"id": 532532,
						"parentId": 5325
					}
				]
			},
			{
				"cityCode": "532600000",
				"cityName": "文山壮族苗族自治州",
				"id": 5326,
				"parentId": 53,
				"children": [{
						"countyCode": "532601000",
						"countyName": "文山市",
						"id": 532601,
						"parentId": 5326
					},
					{
						"countyCode": "532622000",
						"countyName": "砚山县",
						"id": 532622,
						"parentId": 5326
					},
					{
						"countyCode": "532623000",
						"countyName": "西畴县",
						"id": 532623,
						"parentId": 5326
					},
					{
						"countyCode": "532624000",
						"countyName": "麻栗坡县",
						"id": 532624,
						"parentId": 5326
					},
					{
						"countyCode": "532625000",
						"countyName": "马关县",
						"id": 532625,
						"parentId": 5326
					},
					{
						"countyCode": "532626000",
						"countyName": "丘北县",
						"id": 532626,
						"parentId": 5326
					},
					{
						"countyCode": "532627000",
						"countyName": "广南县",
						"id": 532627,
						"parentId": 5326
					},
					{
						"countyCode": "532628000",
						"countyName": "富宁县",
						"id": 532628,
						"parentId": 5326
					}
				]
			},
			{
				"cityCode": "532800000",
				"cityName": "西双版纳傣族自治州",
				"id": 5328,
				"parentId": 53,
				"children": [{
						"countyCode": "532801000",
						"countyName": "景洪市",
						"id": 532801,
						"parentId": 5328
					},
					{
						"countyCode": "532822000",
						"countyName": "勐海县",
						"id": 532822,
						"parentId": 5328
					},
					{
						"countyCode": "532823000",
						"countyName": "勐腊县",
						"id": 532823,
						"parentId": 5328
					}
				]
			},
			{
				"cityCode": "532900000",
				"cityName": "大理白族自治州",
				"id": 5329,
				"parentId": 53,
				"children": [{
						"countyCode": "532901000",
						"countyName": "大理市",
						"id": 532901,
						"parentId": 5329
					},
					{
						"countyCode": "532922000",
						"countyName": "漾濞彝族自治县",
						"id": 532922,
						"parentId": 5329
					},
					{
						"countyCode": "532923000",
						"countyName": "祥云县",
						"id": 532923,
						"parentId": 5329
					},
					{
						"countyCode": "532924000",
						"countyName": "宾川县",
						"id": 532924,
						"parentId": 5329
					},
					{
						"countyCode": "532925000",
						"countyName": "弥渡县",
						"id": 532925,
						"parentId": 5329
					},
					{
						"countyCode": "532926000",
						"countyName": "南涧彝族自治县",
						"id": 532926,
						"parentId": 5329
					},
					{
						"countyCode": "532927000",
						"countyName": "巍山彝族回族自治县",
						"id": 532927,
						"parentId": 5329
					},
					{
						"countyCode": "532928000",
						"countyName": "永平县",
						"id": 532928,
						"parentId": 5329
					},
					{
						"countyCode": "532929000",
						"countyName": "云龙县",
						"id": 532929,
						"parentId": 5329
					},
					{
						"countyCode": "532930000",
						"countyName": "洱源县",
						"id": 532930,
						"parentId": 5329
					},
					{
						"countyCode": "532931000",
						"countyName": "剑川县",
						"id": 532931,
						"parentId": 5329
					},
					{
						"countyCode": "532932000",
						"countyName": "鹤庆县",
						"id": 532932,
						"parentId": 5329
					}
				]
			},
			{
				"cityCode": "533100000",
				"cityName": "德宏傣族景颇族自治州",
				"id": 5331,
				"parentId": 53,
				"children": [{
						"countyCode": "533102000",
						"countyName": "瑞丽市",
						"id": 533102,
						"parentId": 5331
					},
					{
						"countyCode": "533103000",
						"countyName": "芒市",
						"id": 533103,
						"parentId": 5331
					},
					{
						"countyCode": "533122000",
						"countyName": "梁河县",
						"id": 533122,
						"parentId": 5331
					},
					{
						"countyCode": "533123000",
						"countyName": "盈江县",
						"id": 533123,
						"parentId": 5331
					},
					{
						"countyCode": "533124000",
						"countyName": "陇川县",
						"id": 533124,
						"parentId": 5331
					}
				]
			},
			{
				"cityCode": "533300000",
				"cityName": "怒江傈僳族自治州",
				"id": 5333,
				"parentId": 53,
				"children": [{
						"countyCode": "533301000",
						"countyName": "泸水市",
						"id": 533301,
						"parentId": 5333
					},
					{
						"countyCode": "533323000",
						"countyName": "福贡县",
						"id": 533323,
						"parentId": 5333
					},
					{
						"countyCode": "533324000",
						"countyName": "贡山独龙族怒族自治县",
						"id": 533324,
						"parentId": 5333
					},
					{
						"countyCode": "533325000",
						"countyName": "兰坪白族普米族自治县",
						"id": 533325,
						"parentId": 5333
					}
				]
			},
			{
				"cityCode": "533400000",
				"cityName": "迪庆藏族自治州",
				"id": 5334,
				"parentId": 53,
				"children": [{
						"countyCode": "533401000",
						"countyName": "香格里拉市",
						"id": 533401,
						"parentId": 5334
					},
					{
						"countyCode": "533422000",
						"countyName": "德钦县",
						"id": 533422,
						"parentId": 5334
					},
					{
						"countyCode": "533423000",
						"countyName": "维西傈僳族自治县",
						"id": 533423,
						"parentId": 5334
					}
				]
			}
		]
	},
	{
		"provinceCode": "540000000",
		"provinceName": "西藏自治区",
		"id": 54,
		"parentId": 0,
		"children": [{
				"cityCode": "540100000",
				"cityName": "拉萨市",
				"id": 5401,
				"parentId": 54,
				"children": [{
						"countyCode": "540102000",
						"countyName": "城关区",
						"id": 540102,
						"parentId": 5401
					},
					{
						"countyCode": "540103000",
						"countyName": "堆龙德庆区",
						"id": 540103,
						"parentId": 5401
					},
					{
						"countyCode": "540104000",
						"countyName": "达孜区",
						"id": 540104,
						"parentId": 5401
					},
					{
						"countyCode": "540121000",
						"countyName": "林周县",
						"id": 540121,
						"parentId": 5401
					},
					{
						"countyCode": "540122000",
						"countyName": "当雄县",
						"id": 540122,
						"parentId": 5401
					},
					{
						"countyCode": "540123000",
						"countyName": "尼木县",
						"id": 540123,
						"parentId": 5401
					},
					{
						"countyCode": "540124000",
						"countyName": "曲水县",
						"id": 540124,
						"parentId": 5401
					},
					{
						"countyCode": "540127000",
						"countyName": "墨竹工卡县",
						"id": 540127,
						"parentId": 5401
					}
				]
			},
			{
				"cityCode": "540200000",
				"cityName": "日喀则市",
				"id": 5402,
				"parentId": 54,
				"children": [{
						"countyCode": "540202000",
						"countyName": "桑珠孜区",
						"id": 540202,
						"parentId": 5402
					},
					{
						"countyCode": "540221000",
						"countyName": "南木林县",
						"id": 540221,
						"parentId": 5402
					},
					{
						"countyCode": "540222000",
						"countyName": "江孜县",
						"id": 540222,
						"parentId": 5402
					},
					{
						"countyCode": "540223000",
						"countyName": "定日县",
						"id": 540223,
						"parentId": 5402
					},
					{
						"countyCode": "540224000",
						"countyName": "萨迦县",
						"id": 540224,
						"parentId": 5402
					},
					{
						"countyCode": "540225000",
						"countyName": "拉孜县",
						"id": 540225,
						"parentId": 5402
					},
					{
						"countyCode": "540226000",
						"countyName": "昂仁县",
						"id": 540226,
						"parentId": 5402
					},
					{
						"countyCode": "540227000",
						"countyName": "谢通门县",
						"id": 540227,
						"parentId": 5402
					},
					{
						"countyCode": "540228000",
						"countyName": "白朗县",
						"id": 540228,
						"parentId": 5402
					},
					{
						"countyCode": "540229000",
						"countyName": "仁布县",
						"id": 540229,
						"parentId": 5402
					},
					{
						"countyCode": "540230000",
						"countyName": "康马县",
						"id": 540230,
						"parentId": 5402
					},
					{
						"countyCode": "540231000",
						"countyName": "定结县",
						"id": 540231,
						"parentId": 5402
					},
					{
						"countyCode": "540232000",
						"countyName": "仲巴县",
						"id": 540232,
						"parentId": 5402
					},
					{
						"countyCode": "540233000",
						"countyName": "亚东县",
						"id": 540233,
						"parentId": 5402
					},
					{
						"countyCode": "540234000",
						"countyName": "吉隆县",
						"id": 540234,
						"parentId": 5402
					},
					{
						"countyCode": "540235000",
						"countyName": "聂拉木县",
						"id": 540235,
						"parentId": 5402
					},
					{
						"countyCode": "540236000",
						"countyName": "萨嘎县",
						"id": 540236,
						"parentId": 5402
					},
					{
						"countyCode": "540237000",
						"countyName": "岗巴县",
						"id": 540237,
						"parentId": 5402
					}
				]
			},
			{
				"cityCode": "540300000",
				"cityName": "昌都市",
				"id": 5403,
				"parentId": 54,
				"children": [{
						"countyCode": "540302000",
						"countyName": "卡若区",
						"id": 540302,
						"parentId": 5403
					},
					{
						"countyCode": "540321000",
						"countyName": "江达县",
						"id": 540321,
						"parentId": 5403
					},
					{
						"countyCode": "540322000",
						"countyName": "贡觉县",
						"id": 540322,
						"parentId": 5403
					},
					{
						"countyCode": "540323000",
						"countyName": "类乌齐县",
						"id": 540323,
						"parentId": 5403
					},
					{
						"countyCode": "540324000",
						"countyName": "丁青县",
						"id": 540324,
						"parentId": 5403
					},
					{
						"countyCode": "540325000",
						"countyName": "察雅县",
						"id": 540325,
						"parentId": 5403
					},
					{
						"countyCode": "540326000",
						"countyName": "八宿县",
						"id": 540326,
						"parentId": 5403
					},
					{
						"countyCode": "540327000",
						"countyName": "左贡县",
						"id": 540327,
						"parentId": 5403
					},
					{
						"countyCode": "540328000",
						"countyName": "芒康县",
						"id": 540328,
						"parentId": 5403
					},
					{
						"countyCode": "540329000",
						"countyName": "洛隆县",
						"id": 540329,
						"parentId": 5403
					},
					{
						"countyCode": "540330000",
						"countyName": "边坝县",
						"id": 540330,
						"parentId": 5403
					}
				]
			},
			{
				"cityCode": "540400000",
				"cityName": "林芝市",
				"id": 5404,
				"parentId": 54,
				"children": [{
						"countyCode": "540402000",
						"countyName": "巴宜区",
						"id": 540402,
						"parentId": 5404
					},
					{
						"countyCode": "540421000",
						"countyName": "工布江达县",
						"id": 540421,
						"parentId": 5404
					},
					{
						"countyCode": "540422000",
						"countyName": "米林县",
						"id": 540422,
						"parentId": 5404
					},
					{
						"countyCode": "540423000",
						"countyName": "墨脱县",
						"id": 540423,
						"parentId": 5404
					},
					{
						"countyCode": "540424000",
						"countyName": "波密县",
						"id": 540424,
						"parentId": 5404
					},
					{
						"countyCode": "540425000",
						"countyName": "察隅县",
						"id": 540425,
						"parentId": 5404
					},
					{
						"countyCode": "540426000",
						"countyName": "朗县",
						"id": 540426,
						"parentId": 5404
					}
				]
			},
			{
				"cityCode": "540500000",
				"cityName": "山南市",
				"id": 5405,
				"parentId": 54,
				"children": [{
						"countyCode": "540502000",
						"countyName": "乃东区",
						"id": 540502,
						"parentId": 5405
					},
					{
						"countyCode": "540521000",
						"countyName": "扎囊县",
						"id": 540521,
						"parentId": 5405
					},
					{
						"countyCode": "540522000",
						"countyName": "贡嘎县",
						"id": 540522,
						"parentId": 5405
					},
					{
						"countyCode": "540523000",
						"countyName": "桑日县",
						"id": 540523,
						"parentId": 5405
					},
					{
						"countyCode": "540524000",
						"countyName": "琼结县",
						"id": 540524,
						"parentId": 5405
					},
					{
						"countyCode": "540525000",
						"countyName": "曲松县",
						"id": 540525,
						"parentId": 5405
					},
					{
						"countyCode": "540526000",
						"countyName": "措美县",
						"id": 540526,
						"parentId": 5405
					},
					{
						"countyCode": "540527000",
						"countyName": "洛扎县",
						"id": 540527,
						"parentId": 5405
					},
					{
						"countyCode": "540528000",
						"countyName": "加查县",
						"id": 540528,
						"parentId": 5405
					},
					{
						"countyCode": "540529000",
						"countyName": "隆子县",
						"id": 540529,
						"parentId": 5405
					},
					{
						"countyCode": "540530000",
						"countyName": "错那县",
						"id": 540530,
						"parentId": 5405
					},
					{
						"countyCode": "540531000",
						"countyName": "浪卡子县",
						"id": 540531,
						"parentId": 5405
					}
				]
			},
			{
				"cityCode": "540600000",
				"cityName": "那曲市",
				"id": 5406,
				"parentId": 54,
				"children": [{
						"countyCode": "540602000",
						"countyName": "色尼区",
						"id": 540602,
						"parentId": 5406
					},
					{
						"countyCode": "540621000",
						"countyName": "嘉黎县",
						"id": 540621,
						"parentId": 5406
					},
					{
						"countyCode": "540622000",
						"countyName": "比如县",
						"id": 540622,
						"parentId": 5406
					},
					{
						"countyCode": "540623000",
						"countyName": "聂荣县",
						"id": 540623,
						"parentId": 5406
					},
					{
						"countyCode": "540624000",
						"countyName": "安多县",
						"id": 540624,
						"parentId": 5406
					},
					{
						"countyCode": "540625000",
						"countyName": "申扎县",
						"id": 540625,
						"parentId": 5406
					},
					{
						"countyCode": "540626000",
						"countyName": "索县",
						"id": 540626,
						"parentId": 5406
					},
					{
						"countyCode": "540627000",
						"countyName": "班戈县",
						"id": 540627,
						"parentId": 5406
					},
					{
						"countyCode": "540628000",
						"countyName": "巴青县",
						"id": 540628,
						"parentId": 5406
					},
					{
						"countyCode": "540629000",
						"countyName": "尼玛县",
						"id": 540629,
						"parentId": 5406
					},
					{
						"countyCode": "540630000",
						"countyName": "双湖县",
						"id": 540630,
						"parentId": 5406
					}
				]
			},
			{
				"cityCode": "542500000",
				"cityName": "阿里地区",
				"id": 5425,
				"parentId": 54,
				"children": [{
						"countyCode": "542521000",
						"countyName": "普兰县",
						"id": 542521,
						"parentId": 5425
					},
					{
						"countyCode": "542522000",
						"countyName": "札达县",
						"id": 542522,
						"parentId": 5425
					},
					{
						"countyCode": "542523000",
						"countyName": "噶尔县",
						"id": 542523,
						"parentId": 5425
					},
					{
						"countyCode": "542524000",
						"countyName": "日土县",
						"id": 542524,
						"parentId": 5425
					},
					{
						"countyCode": "542525000",
						"countyName": "革吉县",
						"id": 542525,
						"parentId": 5425
					},
					{
						"countyCode": "542526000",
						"countyName": "改则县",
						"id": 542526,
						"parentId": 5425
					},
					{
						"countyCode": "542527000",
						"countyName": "措勤县",
						"id": 542527,
						"parentId": 5425
					}
				]
			}
		]
	},
	{
		"provinceCode": "610000000",
		"provinceName": "陕西省",
		"id": 61,
		"parentId": 0,
		"children": [{
				"cityCode": "610100000",
				"cityName": "西安市",
				"id": 6101,
				"parentId": 61,
				"children": [{
						"countyCode": "610102000",
						"countyName": "新城区",
						"id": 610102,
						"parentId": 6101
					},
					{
						"countyCode": "610103000",
						"countyName": "碑林区",
						"id": 610103,
						"parentId": 6101
					},
					{
						"countyCode": "610104000",
						"countyName": "莲湖区",
						"id": 610104,
						"parentId": 6101
					},
					{
						"countyCode": "610111000",
						"countyName": "灞桥区",
						"id": 610111,
						"parentId": 6101
					},
					{
						"countyCode": "610112000",
						"countyName": "未央区",
						"id": 610112,
						"parentId": 6101
					},
					{
						"countyCode": "610113000",
						"countyName": "雁塔区",
						"id": 610113,
						"parentId": 6101
					},
					{
						"countyCode": "610114000",
						"countyName": "阎良区",
						"id": 610114,
						"parentId": 6101
					},
					{
						"countyCode": "610115000",
						"countyName": "临潼区",
						"id": 610115,
						"parentId": 6101
					},
					{
						"countyCode": "610116000",
						"countyName": "长安区",
						"id": 610116,
						"parentId": 6101
					},
					{
						"countyCode": "610117000",
						"countyName": "高陵区",
						"id": 610117,
						"parentId": 6101
					},
					{
						"countyCode": "610118000",
						"countyName": "鄠邑区",
						"id": 610118,
						"parentId": 6101
					},
					{
						"countyCode": "610122000",
						"countyName": "蓝田县",
						"id": 610122,
						"parentId": 6101
					},
					{
						"countyCode": "610124000",
						"countyName": "周至县",
						"id": 610124,
						"parentId": 6101
					}
				]
			},
			{
				"cityCode": "610200000",
				"cityName": "铜川市",
				"id": 6102,
				"parentId": 61,
				"children": [{
						"countyCode": "610202000",
						"countyName": "王益区",
						"id": 610202,
						"parentId": 6102
					},
					{
						"countyCode": "610203000",
						"countyName": "印台区",
						"id": 610203,
						"parentId": 6102
					},
					{
						"countyCode": "610204000",
						"countyName": "耀州区",
						"id": 610204,
						"parentId": 6102
					},
					{
						"countyCode": "610222000",
						"countyName": "宜君县",
						"id": 610222,
						"parentId": 6102
					}
				]
			},
			{
				"cityCode": "610300000",
				"cityName": "宝鸡市",
				"id": 6103,
				"parentId": 61,
				"children": [{
						"countyCode": "610302000",
						"countyName": "渭滨区",
						"id": 610302,
						"parentId": 6103
					},
					{
						"countyCode": "610303000",
						"countyName": "金台区",
						"id": 610303,
						"parentId": 6103
					},
					{
						"countyCode": "610304000",
						"countyName": "陈仓区",
						"id": 610304,
						"parentId": 6103
					},
					{
						"countyCode": "610322000",
						"countyName": "凤翔县",
						"id": 610322,
						"parentId": 6103
					},
					{
						"countyCode": "610323000",
						"countyName": "岐山县",
						"id": 610323,
						"parentId": 6103
					},
					{
						"countyCode": "610324000",
						"countyName": "扶风县",
						"id": 610324,
						"parentId": 6103
					},
					{
						"countyCode": "610326000",
						"countyName": "眉县",
						"id": 610326,
						"parentId": 6103
					},
					{
						"countyCode": "610327000",
						"countyName": "陇县",
						"id": 610327,
						"parentId": 6103
					},
					{
						"countyCode": "610328000",
						"countyName": "千阳县",
						"id": 610328,
						"parentId": 6103
					},
					{
						"countyCode": "610329000",
						"countyName": "麟游县",
						"id": 610329,
						"parentId": 6103
					},
					{
						"countyCode": "610330000",
						"countyName": "凤县",
						"id": 610330,
						"parentId": 6103
					},
					{
						"countyCode": "610331000",
						"countyName": "太白县",
						"id": 610331,
						"parentId": 6103
					}
				]
			},
			{
				"cityCode": "610400000",
				"cityName": "咸阳市",
				"id": 6104,
				"parentId": 61,
				"children": [{
						"countyCode": "610402000",
						"countyName": "秦都区",
						"id": 610402,
						"parentId": 6104
					},
					{
						"countyCode": "610403000",
						"countyName": "杨陵区",
						"id": 610403,
						"parentId": 6104
					},
					{
						"countyCode": "610404000",
						"countyName": "渭城区",
						"id": 610404,
						"parentId": 6104
					},
					{
						"countyCode": "610422000",
						"countyName": "三原县",
						"id": 610422,
						"parentId": 6104
					},
					{
						"countyCode": "610423000",
						"countyName": "泾阳县",
						"id": 610423,
						"parentId": 6104
					},
					{
						"countyCode": "610424000",
						"countyName": "乾县",
						"id": 610424,
						"parentId": 6104
					},
					{
						"countyCode": "610425000",
						"countyName": "礼泉县",
						"id": 610425,
						"parentId": 6104
					},
					{
						"countyCode": "610426000",
						"countyName": "永寿县",
						"id": 610426,
						"parentId": 6104
					},
					{
						"countyCode": "610428000",
						"countyName": "长武县",
						"id": 610428,
						"parentId": 6104
					},
					{
						"countyCode": "610429000",
						"countyName": "旬邑县",
						"id": 610429,
						"parentId": 6104
					},
					{
						"countyCode": "610430000",
						"countyName": "淳化县",
						"id": 610430,
						"parentId": 6104
					},
					{
						"countyCode": "610431000",
						"countyName": "武功县",
						"id": 610431,
						"parentId": 6104
					},
					{
						"countyCode": "610481000",
						"countyName": "兴平市",
						"id": 610481,
						"parentId": 6104
					},
					{
						"countyCode": "610482000",
						"countyName": "彬州市",
						"id": 610482,
						"parentId": 6104
					}
				]
			},
			{
				"cityCode": "610500000",
				"cityName": "渭南市",
				"id": 6105,
				"parentId": 61,
				"children": [{
						"countyCode": "610502000",
						"countyName": "临渭区",
						"id": 610502,
						"parentId": 6105
					},
					{
						"countyCode": "610503000",
						"countyName": "华州区",
						"id": 610503,
						"parentId": 6105
					},
					{
						"countyCode": "610522000",
						"countyName": "潼关县",
						"id": 610522,
						"parentId": 6105
					},
					{
						"countyCode": "610523000",
						"countyName": "大荔县",
						"id": 610523,
						"parentId": 6105
					},
					{
						"countyCode": "610524000",
						"countyName": "合阳县",
						"id": 610524,
						"parentId": 6105
					},
					{
						"countyCode": "610525000",
						"countyName": "澄城县",
						"id": 610525,
						"parentId": 6105
					},
					{
						"countyCode": "610526000",
						"countyName": "蒲城县",
						"id": 610526,
						"parentId": 6105
					},
					{
						"countyCode": "610527000",
						"countyName": "白水县",
						"id": 610527,
						"parentId": 6105
					},
					{
						"countyCode": "610528000",
						"countyName": "富平县",
						"id": 610528,
						"parentId": 6105
					},
					{
						"countyCode": "610581000",
						"countyName": "韩城市",
						"id": 610581,
						"parentId": 6105
					},
					{
						"countyCode": "610582000",
						"countyName": "华阴市",
						"id": 610582,
						"parentId": 6105
					}
				]
			},
			{
				"cityCode": "610600000",
				"cityName": "延安市",
				"id": 6106,
				"parentId": 61,
				"children": [{
						"countyCode": "610602000",
						"countyName": "宝塔区",
						"id": 610602,
						"parentId": 6106
					},
					{
						"countyCode": "610603000",
						"countyName": "安塞区",
						"id": 610603,
						"parentId": 6106
					},
					{
						"countyCode": "610621000",
						"countyName": "延长县",
						"id": 610621,
						"parentId": 6106
					},
					{
						"countyCode": "610622000",
						"countyName": "延川县",
						"id": 610622,
						"parentId": 6106
					},
					{
						"countyCode": "610623000",
						"countyName": "子长市",
						"id": 610623,
						"parentId": 6106
					},
					{
						"countyCode": "610625000",
						"countyName": "志丹县",
						"id": 610625,
						"parentId": 6106
					},
					{
						"countyCode": "610626000",
						"countyName": "吴起县",
						"id": 610626,
						"parentId": 6106
					},
					{
						"countyCode": "610627000",
						"countyName": "甘泉县",
						"id": 610627,
						"parentId": 6106
					},
					{
						"countyCode": "610628000",
						"countyName": "富县",
						"id": 610628,
						"parentId": 6106
					},
					{
						"countyCode": "610629000",
						"countyName": "洛川县",
						"id": 610629,
						"parentId": 6106
					},
					{
						"countyCode": "610630000",
						"countyName": "宜川县",
						"id": 610630,
						"parentId": 6106
					},
					{
						"countyCode": "610631000",
						"countyName": "黄龙县",
						"id": 610631,
						"parentId": 6106
					},
					{
						"countyCode": "610632000",
						"countyName": "黄陵县",
						"id": 610632,
						"parentId": 6106
					}
				]
			},
			{
				"cityCode": "610700000",
				"cityName": "汉中市",
				"id": 6107,
				"parentId": 61,
				"children": [{
						"countyCode": "610702000",
						"countyName": "汉台区",
						"id": 610702,
						"parentId": 6107
					},
					{
						"countyCode": "610703000",
						"countyName": "南郑区",
						"id": 610703,
						"parentId": 6107
					},
					{
						"countyCode": "610722000",
						"countyName": "城固县",
						"id": 610722,
						"parentId": 6107
					},
					{
						"countyCode": "610723000",
						"countyName": "洋县",
						"id": 610723,
						"parentId": 6107
					},
					{
						"countyCode": "610724000",
						"countyName": "西乡县",
						"id": 610724,
						"parentId": 6107
					},
					{
						"countyCode": "610725000",
						"countyName": "勉县",
						"id": 610725,
						"parentId": 6107
					},
					{
						"countyCode": "610726000",
						"countyName": "宁强县",
						"id": 610726,
						"parentId": 6107
					},
					{
						"countyCode": "610727000",
						"countyName": "略阳县",
						"id": 610727,
						"parentId": 6107
					},
					{
						"countyCode": "610728000",
						"countyName": "镇巴县",
						"id": 610728,
						"parentId": 6107
					},
					{
						"countyCode": "610729000",
						"countyName": "留坝县",
						"id": 610729,
						"parentId": 6107
					},
					{
						"countyCode": "610730000",
						"countyName": "佛坪县",
						"id": 610730,
						"parentId": 6107
					}
				]
			},
			{
				"cityCode": "610800000",
				"cityName": "榆林市",
				"id": 6108,
				"parentId": 61,
				"children": [{
						"countyCode": "610802000",
						"countyName": "榆阳区",
						"id": 610802,
						"parentId": 6108
					},
					{
						"countyCode": "610803000",
						"countyName": "横山区",
						"id": 610803,
						"parentId": 6108
					},
					{
						"countyCode": "610822000",
						"countyName": "府谷县",
						"id": 610822,
						"parentId": 6108
					},
					{
						"countyCode": "610824000",
						"countyName": "靖边县",
						"id": 610824,
						"parentId": 6108
					},
					{
						"countyCode": "610825000",
						"countyName": "定边县",
						"id": 610825,
						"parentId": 6108
					},
					{
						"countyCode": "610826000",
						"countyName": "绥德县",
						"id": 610826,
						"parentId": 6108
					},
					{
						"countyCode": "610827000",
						"countyName": "米脂县",
						"id": 610827,
						"parentId": 6108
					},
					{
						"countyCode": "610828000",
						"countyName": "佳县",
						"id": 610828,
						"parentId": 6108
					},
					{
						"countyCode": "610829000",
						"countyName": "吴堡县",
						"id": 610829,
						"parentId": 6108
					},
					{
						"countyCode": "610830000",
						"countyName": "清涧县",
						"id": 610830,
						"parentId": 6108
					},
					{
						"countyCode": "610831000",
						"countyName": "子洲县",
						"id": 610831,
						"parentId": 6108
					},
					{
						"countyCode": "610881000",
						"countyName": "神木市",
						"id": 610881,
						"parentId": 6108
					}
				]
			},
			{
				"cityCode": "610900000",
				"cityName": "安康市",
				"id": 6109,
				"parentId": 61,
				"children": [{
						"countyCode": "610902000",
						"countyName": "汉滨区",
						"id": 610902,
						"parentId": 6109
					},
					{
						"countyCode": "610921000",
						"countyName": "汉阴县",
						"id": 610921,
						"parentId": 6109
					},
					{
						"countyCode": "610922000",
						"countyName": "石泉县",
						"id": 610922,
						"parentId": 6109
					},
					{
						"countyCode": "610923000",
						"countyName": "宁陕县",
						"id": 610923,
						"parentId": 6109
					},
					{
						"countyCode": "610924000",
						"countyName": "紫阳县",
						"id": 610924,
						"parentId": 6109
					},
					{
						"countyCode": "610925000",
						"countyName": "岚皋县",
						"id": 610925,
						"parentId": 6109
					},
					{
						"countyCode": "610926000",
						"countyName": "平利县",
						"id": 610926,
						"parentId": 6109
					},
					{
						"countyCode": "610927000",
						"countyName": "镇坪县",
						"id": 610927,
						"parentId": 6109
					},
					{
						"countyCode": "610928000",
						"countyName": "旬阳县",
						"id": 610928,
						"parentId": 6109
					},
					{
						"countyCode": "610929000",
						"countyName": "白河县",
						"id": 610929,
						"parentId": 6109
					}
				]
			},
			{
				"cityCode": "611000000",
				"cityName": "商洛市",
				"id": 6110,
				"parentId": 61,
				"children": [{
						"countyCode": "611002000",
						"countyName": "商州区",
						"id": 611002,
						"parentId": 6110
					},
					{
						"countyCode": "611021000",
						"countyName": "洛南县",
						"id": 611021,
						"parentId": 6110
					},
					{
						"countyCode": "611022000",
						"countyName": "丹凤县",
						"id": 611022,
						"parentId": 6110
					},
					{
						"countyCode": "611023000",
						"countyName": "商南县",
						"id": 611023,
						"parentId": 6110
					},
					{
						"countyCode": "611024000",
						"countyName": "山阳县",
						"id": 611024,
						"parentId": 6110
					},
					{
						"countyCode": "611025000",
						"countyName": "镇安县",
						"id": 611025,
						"parentId": 6110
					},
					{
						"countyCode": "611026000",
						"countyName": "柞水县",
						"id": 611026,
						"parentId": 6110
					}
				]
			}
		]
	},
	{
		"provinceCode": "620000000",
		"provinceName": "甘肃省",
		"id": 62,
		"parentId": 0,
		"children": [{
				"cityCode": "620100000",
				"cityName": "兰州市",
				"id": 6201,
				"parentId": 62,
				"children": [{
						"countyCode": "620102000",
						"countyName": "城关区",
						"id": 620102,
						"parentId": 6201
					},
					{
						"countyCode": "620103000",
						"countyName": "七里河区",
						"id": 620103,
						"parentId": 6201
					},
					{
						"countyCode": "620104000",
						"countyName": "西固区",
						"id": 620104,
						"parentId": 6201
					},
					{
						"countyCode": "620105000",
						"countyName": "安宁区",
						"id": 620105,
						"parentId": 6201
					},
					{
						"countyCode": "620111000",
						"countyName": "红古区",
						"id": 620111,
						"parentId": 6201
					},
					{
						"countyCode": "620121000",
						"countyName": "永登县",
						"id": 620121,
						"parentId": 6201
					},
					{
						"countyCode": "620122000",
						"countyName": "皋兰县",
						"id": 620122,
						"parentId": 6201
					},
					{
						"countyCode": "620123000",
						"countyName": "榆中县",
						"id": 620123,
						"parentId": 6201
					}
				]
			},
			{
				"cityCode": "620200000",
				"cityName": "嘉峪关市",
				"id": 6202,
				"parentId": 62,
				"children": [{
					"countyCode": "620201000",
					"countyName": "市辖区",
					"id": 620201,
					"parentId": 6202
				}]
			},
			{
				"cityCode": "620300000",
				"cityName": "金昌市",
				"id": 6203,
				"parentId": 62,
				"children": [{
						"countyCode": "620302000",
						"countyName": "金川区",
						"id": 620302,
						"parentId": 6203
					},
					{
						"countyCode": "620321000",
						"countyName": "永昌县",
						"id": 620321,
						"parentId": 6203
					}
				]
			},
			{
				"cityCode": "620400000",
				"cityName": "白银市",
				"id": 6204,
				"parentId": 62,
				"children": [{
						"countyCode": "620402000",
						"countyName": "白银区",
						"id": 620402,
						"parentId": 6204
					},
					{
						"countyCode": "620403000",
						"countyName": "平川区",
						"id": 620403,
						"parentId": 6204
					},
					{
						"countyCode": "620421000",
						"countyName": "靖远县",
						"id": 620421,
						"parentId": 6204
					},
					{
						"countyCode": "620422000",
						"countyName": "会宁县",
						"id": 620422,
						"parentId": 6204
					},
					{
						"countyCode": "620423000",
						"countyName": "景泰县",
						"id": 620423,
						"parentId": 6204
					}
				]
			},
			{
				"cityCode": "620500000",
				"cityName": "天水市",
				"id": 6205,
				"parentId": 62,
				"children": [{
						"countyCode": "620502000",
						"countyName": "秦州区",
						"id": 620502,
						"parentId": 6205
					},
					{
						"countyCode": "620503000",
						"countyName": "麦积区",
						"id": 620503,
						"parentId": 6205
					},
					{
						"countyCode": "620521000",
						"countyName": "清水县",
						"id": 620521,
						"parentId": 6205
					},
					{
						"countyCode": "620522000",
						"countyName": "秦安县",
						"id": 620522,
						"parentId": 6205
					},
					{
						"countyCode": "620523000",
						"countyName": "甘谷县",
						"id": 620523,
						"parentId": 6205
					},
					{
						"countyCode": "620524000",
						"countyName": "武山县",
						"id": 620524,
						"parentId": 6205
					},
					{
						"countyCode": "620525000",
						"countyName": "张家川回族自治县",
						"id": 620525,
						"parentId": 6205
					}
				]
			},
			{
				"cityCode": "620600000",
				"cityName": "武威市",
				"id": 6206,
				"parentId": 62,
				"children": [{
						"countyCode": "620602000",
						"countyName": "凉州区",
						"id": 620602,
						"parentId": 6206
					},
					{
						"countyCode": "620621000",
						"countyName": "民勤县",
						"id": 620621,
						"parentId": 6206
					},
					{
						"countyCode": "620622000",
						"countyName": "古浪县",
						"id": 620622,
						"parentId": 6206
					},
					{
						"countyCode": "620623000",
						"countyName": "天祝藏族自治县",
						"id": 620623,
						"parentId": 6206
					}
				]
			},
			{
				"cityCode": "620700000",
				"cityName": "张掖市",
				"id": 6207,
				"parentId": 62,
				"children": [{
						"countyCode": "620702000",
						"countyName": "甘州区",
						"id": 620702,
						"parentId": 6207
					},
					{
						"countyCode": "620721000",
						"countyName": "肃南裕固族自治县",
						"id": 620721,
						"parentId": 6207
					},
					{
						"countyCode": "620722000",
						"countyName": "民乐县",
						"id": 620722,
						"parentId": 6207
					},
					{
						"countyCode": "620723000",
						"countyName": "临泽县",
						"id": 620723,
						"parentId": 6207
					},
					{
						"countyCode": "620724000",
						"countyName": "高台县",
						"id": 620724,
						"parentId": 6207
					},
					{
						"countyCode": "620725000",
						"countyName": "山丹县",
						"id": 620725,
						"parentId": 6207
					}
				]
			},
			{
				"cityCode": "620800000",
				"cityName": "平凉市",
				"id": 6208,
				"parentId": 62,
				"children": [{
						"countyCode": "620802000",
						"countyName": "崆峒区",
						"id": 620802,
						"parentId": 6208
					},
					{
						"countyCode": "620821000",
						"countyName": "泾川县",
						"id": 620821,
						"parentId": 6208
					},
					{
						"countyCode": "620822000",
						"countyName": "灵台县",
						"id": 620822,
						"parentId": 6208
					},
					{
						"countyCode": "620823000",
						"countyName": "崇信县",
						"id": 620823,
						"parentId": 6208
					},
					{
						"countyCode": "620825000",
						"countyName": "庄浪县",
						"id": 620825,
						"parentId": 6208
					},
					{
						"countyCode": "620826000",
						"countyName": "静宁县",
						"id": 620826,
						"parentId": 6208
					},
					{
						"countyCode": "620881000",
						"countyName": "华亭市",
						"id": 620881,
						"parentId": 6208
					}
				]
			},
			{
				"cityCode": "620900000",
				"cityName": "酒泉市",
				"id": 6209,
				"parentId": 62,
				"children": [{
						"countyCode": "620902000",
						"countyName": "肃州区",
						"id": 620902,
						"parentId": 6209
					},
					{
						"countyCode": "620921000",
						"countyName": "金塔县",
						"id": 620921,
						"parentId": 6209
					},
					{
						"countyCode": "620922000",
						"countyName": "瓜州县",
						"id": 620922,
						"parentId": 6209
					},
					{
						"countyCode": "620923000",
						"countyName": "肃北蒙古族自治县",
						"id": 620923,
						"parentId": 6209
					},
					{
						"countyCode": "620924000",
						"countyName": "阿克塞哈萨克族自治县",
						"id": 620924,
						"parentId": 6209
					},
					{
						"countyCode": "620981000",
						"countyName": "玉门市",
						"id": 620981,
						"parentId": 6209
					},
					{
						"countyCode": "620982000",
						"countyName": "敦煌市",
						"id": 620982,
						"parentId": 6209
					}
				]
			},
			{
				"cityCode": "621000000",
				"cityName": "庆阳市",
				"id": 6210,
				"parentId": 62,
				"children": [{
						"countyCode": "621002000",
						"countyName": "西峰区",
						"id": 621002,
						"parentId": 6210
					},
					{
						"countyCode": "621021000",
						"countyName": "庆城县",
						"id": 621021,
						"parentId": 6210
					},
					{
						"countyCode": "621022000",
						"countyName": "环县",
						"id": 621022,
						"parentId": 6210
					},
					{
						"countyCode": "621023000",
						"countyName": "华池县",
						"id": 621023,
						"parentId": 6210
					},
					{
						"countyCode": "621024000",
						"countyName": "合水县",
						"id": 621024,
						"parentId": 6210
					},
					{
						"countyCode": "621025000",
						"countyName": "正宁县",
						"id": 621025,
						"parentId": 6210
					},
					{
						"countyCode": "621026000",
						"countyName": "宁县",
						"id": 621026,
						"parentId": 6210
					},
					{
						"countyCode": "621027000",
						"countyName": "镇原县",
						"id": 621027,
						"parentId": 6210
					}
				]
			},
			{
				"cityCode": "621100000",
				"cityName": "定西市",
				"id": 6211,
				"parentId": 62,
				"children": [{
						"countyCode": "621102000",
						"countyName": "安定区",
						"id": 621102,
						"parentId": 6211
					},
					{
						"countyCode": "621121000",
						"countyName": "通渭县",
						"id": 621121,
						"parentId": 6211
					},
					{
						"countyCode": "621122000",
						"countyName": "陇西县",
						"id": 621122,
						"parentId": 6211
					},
					{
						"countyCode": "621123000",
						"countyName": "渭源县",
						"id": 621123,
						"parentId": 6211
					},
					{
						"countyCode": "621124000",
						"countyName": "临洮县",
						"id": 621124,
						"parentId": 6211
					},
					{
						"countyCode": "621125000",
						"countyName": "漳县",
						"id": 621125,
						"parentId": 6211
					},
					{
						"countyCode": "621126000",
						"countyName": "岷县",
						"id": 621126,
						"parentId": 6211
					}
				]
			},
			{
				"cityCode": "621200000",
				"cityName": "陇南市",
				"id": 6212,
				"parentId": 62,
				"children": [{
						"countyCode": "621202000",
						"countyName": "武都区",
						"id": 621202,
						"parentId": 6212
					},
					{
						"countyCode": "621221000",
						"countyName": "成县",
						"id": 621221,
						"parentId": 6212
					},
					{
						"countyCode": "621222000",
						"countyName": "文县",
						"id": 621222,
						"parentId": 6212
					},
					{
						"countyCode": "621223000",
						"countyName": "宕昌县",
						"id": 621223,
						"parentId": 6212
					},
					{
						"countyCode": "621224000",
						"countyName": "康县",
						"id": 621224,
						"parentId": 6212
					},
					{
						"countyCode": "621225000",
						"countyName": "西和县",
						"id": 621225,
						"parentId": 6212
					},
					{
						"countyCode": "621226000",
						"countyName": "礼县",
						"id": 621226,
						"parentId": 6212
					},
					{
						"countyCode": "621227000",
						"countyName": "徽县",
						"id": 621227,
						"parentId": 6212
					},
					{
						"countyCode": "621228000",
						"countyName": "两当县",
						"id": 621228,
						"parentId": 6212
					}
				]
			},
			{
				"cityCode": "622900000",
				"cityName": "临夏回族自治州",
				"id": 6229,
				"parentId": 62,
				"children": [{
						"countyCode": "622901000",
						"countyName": "临夏市",
						"id": 622901,
						"parentId": 6229
					},
					{
						"countyCode": "622921000",
						"countyName": "临夏县",
						"id": 622921,
						"parentId": 6229
					},
					{
						"countyCode": "622922000",
						"countyName": "康乐县",
						"id": 622922,
						"parentId": 6229
					},
					{
						"countyCode": "622923000",
						"countyName": "永靖县",
						"id": 622923,
						"parentId": 6229
					},
					{
						"countyCode": "622924000",
						"countyName": "广河县",
						"id": 622924,
						"parentId": 6229
					},
					{
						"countyCode": "622925000",
						"countyName": "和政县",
						"id": 622925,
						"parentId": 6229
					},
					{
						"countyCode": "622926000",
						"countyName": "东乡族自治县",
						"id": 622926,
						"parentId": 6229
					},
					{
						"countyCode": "622927000",
						"countyName": "积石山保安族东乡族撒拉族自治县",
						"id": 622927,
						"parentId": 6229
					}
				]
			},
			{
				"cityCode": "623000000",
				"cityName": "甘南藏族自治州",
				"id": 6230,
				"parentId": 62,
				"children": [{
						"countyCode": "623001000",
						"countyName": "合作市",
						"id": 623001,
						"parentId": 6230
					},
					{
						"countyCode": "623021000",
						"countyName": "临潭县",
						"id": 623021,
						"parentId": 6230
					},
					{
						"countyCode": "623022000",
						"countyName": "卓尼县",
						"id": 623022,
						"parentId": 6230
					},
					{
						"countyCode": "623023000",
						"countyName": "舟曲县",
						"id": 623023,
						"parentId": 6230
					},
					{
						"countyCode": "623024000",
						"countyName": "迭部县",
						"id": 623024,
						"parentId": 6230
					},
					{
						"countyCode": "623025000",
						"countyName": "玛曲县",
						"id": 623025,
						"parentId": 6230
					},
					{
						"countyCode": "623026000",
						"countyName": "碌曲县",
						"id": 623026,
						"parentId": 6230
					},
					{
						"countyCode": "623027000",
						"countyName": "夏河县",
						"id": 623027,
						"parentId": 6230
					}
				]
			}
		]
	},
	{
		"provinceCode": "630000000",
		"provinceName": "青海省",
		"id": 63,
		"parentId": 0,
		"children": [{
				"cityCode": "630100000",
				"cityName": "西宁市",
				"id": 6301,
				"parentId": 63,
				"children": [{
						"countyCode": "630102000",
						"countyName": "城东区",
						"id": 630102,
						"parentId": 6301
					},
					{
						"countyCode": "630103000",
						"countyName": "城中区",
						"id": 630103,
						"parentId": 6301
					},
					{
						"countyCode": "630104000",
						"countyName": "城西区",
						"id": 630104,
						"parentId": 6301
					},
					{
						"countyCode": "630105000",
						"countyName": "城北区",
						"id": 630105,
						"parentId": 6301
					},
					{
						"countyCode": "630121000",
						"countyName": "大通回族土族自治县",
						"id": 630121,
						"parentId": 6301
					},
					{
						"countyCode": "630122000",
						"countyName": "湟中县",
						"id": 630122,
						"parentId": 6301
					},
					{
						"countyCode": "630123000",
						"countyName": "湟源县",
						"id": 630123,
						"parentId": 6301
					}
				]
			},
			{
				"cityCode": "630200000",
				"cityName": "海东市",
				"id": 6302,
				"parentId": 63,
				"children": [{
						"countyCode": "630202000",
						"countyName": "乐都区",
						"id": 630202,
						"parentId": 6302
					},
					{
						"countyCode": "630203000",
						"countyName": "平安区",
						"id": 630203,
						"parentId": 6302
					},
					{
						"countyCode": "630222000",
						"countyName": "民和回族土族自治县",
						"id": 630222,
						"parentId": 6302
					},
					{
						"countyCode": "630223000",
						"countyName": "互助土族自治县",
						"id": 630223,
						"parentId": 6302
					},
					{
						"countyCode": "630224000",
						"countyName": "化隆回族自治县",
						"id": 630224,
						"parentId": 6302
					},
					{
						"countyCode": "630225000",
						"countyName": "循化撒拉族自治县",
						"id": 630225,
						"parentId": 6302
					}
				]
			},
			{
				"cityCode": "632200000",
				"cityName": "海北藏族自治州",
				"id": 6322,
				"parentId": 63,
				"children": [{
						"countyCode": "632221000",
						"countyName": "门源回族自治县",
						"id": 632221,
						"parentId": 6322
					},
					{
						"countyCode": "632222000",
						"countyName": "祁连县",
						"id": 632222,
						"parentId": 6322
					},
					{
						"countyCode": "632223000",
						"countyName": "海晏县",
						"id": 632223,
						"parentId": 6322
					},
					{
						"countyCode": "632224000",
						"countyName": "刚察县",
						"id": 632224,
						"parentId": 6322
					}
				]
			},
			{
				"cityCode": "632300000",
				"cityName": "黄南藏族自治州",
				"id": 6323,
				"parentId": 63,
				"children": [{
						"countyCode": "632321000",
						"countyName": "同仁县",
						"id": 632321,
						"parentId": 6323
					},
					{
						"countyCode": "632322000",
						"countyName": "尖扎县",
						"id": 632322,
						"parentId": 6323
					},
					{
						"countyCode": "632323000",
						"countyName": "泽库县",
						"id": 632323,
						"parentId": 6323
					},
					{
						"countyCode": "632324000",
						"countyName": "河南蒙古族自治县",
						"id": 632324,
						"parentId": 6323
					}
				]
			},
			{
				"cityCode": "632500000",
				"cityName": "海南藏族自治州",
				"id": 6325,
				"parentId": 63,
				"children": [{
						"countyCode": "632521000",
						"countyName": "共和县",
						"id": 632521,
						"parentId": 6325
					},
					{
						"countyCode": "632522000",
						"countyName": "同德县",
						"id": 632522,
						"parentId": 6325
					},
					{
						"countyCode": "632523000",
						"countyName": "贵德县",
						"id": 632523,
						"parentId": 6325
					},
					{
						"countyCode": "632524000",
						"countyName": "兴海县",
						"id": 632524,
						"parentId": 6325
					},
					{
						"countyCode": "632525000",
						"countyName": "贵南县",
						"id": 632525,
						"parentId": 6325
					}
				]
			},
			{
				"cityCode": "632600000",
				"cityName": "果洛藏族自治州",
				"id": 6326,
				"parentId": 63,
				"children": [{
						"countyCode": "632621000",
						"countyName": "玛沁县",
						"id": 632621,
						"parentId": 6326
					},
					{
						"countyCode": "632622000",
						"countyName": "班玛县",
						"id": 632622,
						"parentId": 6326
					},
					{
						"countyCode": "632623000",
						"countyName": "甘德县",
						"id": 632623,
						"parentId": 6326
					},
					{
						"countyCode": "632624000",
						"countyName": "达日县",
						"id": 632624,
						"parentId": 6326
					},
					{
						"countyCode": "632625000",
						"countyName": "久治县",
						"id": 632625,
						"parentId": 6326
					},
					{
						"countyCode": "632626000",
						"countyName": "玛多县",
						"id": 632626,
						"parentId": 6326
					}
				]
			},
			{
				"cityCode": "632700000",
				"cityName": "玉树藏族自治州",
				"id": 6327,
				"parentId": 63,
				"children": [{
						"countyCode": "632701000",
						"countyName": "玉树市",
						"id": 632701,
						"parentId": 6327
					},
					{
						"countyCode": "632722000",
						"countyName": "杂多县",
						"id": 632722,
						"parentId": 6327
					},
					{
						"countyCode": "632723000",
						"countyName": "称多县",
						"id": 632723,
						"parentId": 6327
					},
					{
						"countyCode": "632724000",
						"countyName": "治多县",
						"id": 632724,
						"parentId": 6327
					},
					{
						"countyCode": "632725000",
						"countyName": "囊谦县",
						"id": 632725,
						"parentId": 6327
					},
					{
						"countyCode": "632726000",
						"countyName": "曲麻莱县",
						"id": 632726,
						"parentId": 6327
					}
				]
			},
			{
				"cityCode": "632800000",
				"cityName": "海西蒙古族藏族自治州",
				"id": 6328,
				"parentId": 63,
				"children": [{
						"countyCode": "632801000",
						"countyName": "格尔木市",
						"id": 632801,
						"parentId": 6328
					},
					{
						"countyCode": "632802000",
						"countyName": "德令哈市",
						"id": 632802,
						"parentId": 6328
					},
					{
						"countyCode": "632803000",
						"countyName": "茫崖市",
						"id": 632803,
						"parentId": 6328
					},
					{
						"countyCode": "632821000",
						"countyName": "乌兰县",
						"id": 632821,
						"parentId": 6328
					},
					{
						"countyCode": "632822000",
						"countyName": "都兰县",
						"id": 632822,
						"parentId": 6328
					},
					{
						"countyCode": "632823000",
						"countyName": "天峻县",
						"id": 632823,
						"parentId": 6328
					},
					{
						"countyCode": "632857000",
						"countyName": "大柴旦行政委员会",
						"id": 632857,
						"parentId": 6328
					}
				]
			}
		]
	},
	{
		"provinceCode": "640000000",
		"provinceName": "宁夏回族自治区",
		"id": 64,
		"parentId": 0,
		"children": [{
				"cityCode": "640100000",
				"cityName": "银川市",
				"id": 6401,
				"parentId": 64,
				"children": [{
						"countyCode": "640104000",
						"countyName": "兴庆区",
						"id": 640104,
						"parentId": 6401
					},
					{
						"countyCode": "640105000",
						"countyName": "西夏区",
						"id": 640105,
						"parentId": 6401
					},
					{
						"countyCode": "640106000",
						"countyName": "金凤区",
						"id": 640106,
						"parentId": 6401
					},
					{
						"countyCode": "640121000",
						"countyName": "永宁县",
						"id": 640121,
						"parentId": 6401
					},
					{
						"countyCode": "640122000",
						"countyName": "贺兰县",
						"id": 640122,
						"parentId": 6401
					},
					{
						"countyCode": "640181000",
						"countyName": "灵武市",
						"id": 640181,
						"parentId": 6401
					}
				]
			},
			{
				"cityCode": "640200000",
				"cityName": "石嘴山市",
				"id": 6402,
				"parentId": 64,
				"children": [{
						"countyCode": "640202000",
						"countyName": "大武口区",
						"id": 640202,
						"parentId": 6402
					},
					{
						"countyCode": "640205000",
						"countyName": "惠农区",
						"id": 640205,
						"parentId": 6402
					},
					{
						"countyCode": "640221000",
						"countyName": "平罗县",
						"id": 640221,
						"parentId": 6402
					}
				]
			},
			{
				"cityCode": "640300000",
				"cityName": "吴忠市",
				"id": 6403,
				"parentId": 64,
				"children": [{
						"countyCode": "640302000",
						"countyName": "利通区",
						"id": 640302,
						"parentId": 6403
					},
					{
						"countyCode": "640303000",
						"countyName": "红寺堡区",
						"id": 640303,
						"parentId": 6403
					},
					{
						"countyCode": "640323000",
						"countyName": "盐池县",
						"id": 640323,
						"parentId": 6403
					},
					{
						"countyCode": "640324000",
						"countyName": "同心县",
						"id": 640324,
						"parentId": 6403
					},
					{
						"countyCode": "640381000",
						"countyName": "青铜峡市",
						"id": 640381,
						"parentId": 6403
					}
				]
			},
			{
				"cityCode": "640400000",
				"cityName": "固原市",
				"id": 6404,
				"parentId": 64,
				"children": [{
						"countyCode": "640402000",
						"countyName": "原州区",
						"id": 640402,
						"parentId": 6404
					},
					{
						"countyCode": "640422000",
						"countyName": "西吉县",
						"id": 640422,
						"parentId": 6404
					},
					{
						"countyCode": "640423000",
						"countyName": "隆德县",
						"id": 640423,
						"parentId": 6404
					},
					{
						"countyCode": "640424000",
						"countyName": "泾源县",
						"id": 640424,
						"parentId": 6404
					},
					{
						"countyCode": "640425000",
						"countyName": "彭阳县",
						"id": 640425,
						"parentId": 6404
					}
				]
			},
			{
				"cityCode": "640500000",
				"cityName": "中卫市",
				"id": 6405,
				"parentId": 64,
				"children": [{
						"countyCode": "640502000",
						"countyName": "沙坡头区",
						"id": 640502,
						"parentId": 6405
					},
					{
						"countyCode": "640521000",
						"countyName": "中宁县",
						"id": 640521,
						"parentId": 6405
					},
					{
						"countyCode": "640522000",
						"countyName": "海原县",
						"id": 640522,
						"parentId": 6405
					}
				]
			}
		]
	},
	{
		"provinceCode": "650000000",
		"provinceName": "新疆维吾尔自治区",
		"id": 65,
		"parentId": 0,
		"children": [{
				"cityCode": "650100000",
				"cityName": "乌鲁木齐市",
				"id": 6501,
				"parentId": 65,
				"children": [{
						"countyCode": "650102000",
						"countyName": "天山区",
						"id": 650102,
						"parentId": 6501
					},
					{
						"countyCode": "650103000",
						"countyName": "沙依巴克区",
						"id": 650103,
						"parentId": 6501
					},
					{
						"countyCode": "650104000",
						"countyName": "新市区",
						"id": 650104,
						"parentId": 6501
					},
					{
						"countyCode": "650105000",
						"countyName": "水磨沟区",
						"id": 650105,
						"parentId": 6501
					},
					{
						"countyCode": "650106000",
						"countyName": "头屯河区",
						"id": 650106,
						"parentId": 6501
					},
					{
						"countyCode": "650107000",
						"countyName": "达坂城区",
						"id": 650107,
						"parentId": 6501
					},
					{
						"countyCode": "650109000",
						"countyName": "米东区",
						"id": 650109,
						"parentId": 6501
					},
					{
						"countyCode": "650121000",
						"countyName": "乌鲁木齐县",
						"id": 650121,
						"parentId": 6501
					}
				]
			},
			{
				"cityCode": "650200000",
				"cityName": "克拉玛依市",
				"id": 6502,
				"parentId": 65,
				"children": [{
						"countyCode": "650202000",
						"countyName": "独山子区",
						"id": 650202,
						"parentId": 6502
					},
					{
						"countyCode": "650203000",
						"countyName": "克拉玛依区",
						"id": 650203,
						"parentId": 6502
					},
					{
						"countyCode": "650204000",
						"countyName": "白碱滩区",
						"id": 650204,
						"parentId": 6502
					},
					{
						"countyCode": "650205000",
						"countyName": "乌尔禾区",
						"id": 650205,
						"parentId": 6502
					}
				]
			},
			{
				"cityCode": "650400000",
				"cityName": "吐鲁番市",
				"id": 6504,
				"parentId": 65,
				"children": [{
						"countyCode": "650402000",
						"countyName": "高昌区",
						"id": 650402,
						"parentId": 6504
					},
					{
						"countyCode": "650421000",
						"countyName": "鄯善县",
						"id": 650421,
						"parentId": 6504
					},
					{
						"countyCode": "650422000",
						"countyName": "托克逊县",
						"id": 650422,
						"parentId": 6504
					}
				]
			},
			{
				"cityCode": "650500000",
				"cityName": "哈密市",
				"id": 6505,
				"parentId": 65,
				"children": [{
						"countyCode": "650502000",
						"countyName": "伊州区",
						"id": 650502,
						"parentId": 6505
					},
					{
						"countyCode": "650521000",
						"countyName": "巴里坤哈萨克自治县",
						"id": 650521,
						"parentId": 6505
					},
					{
						"countyCode": "650522000",
						"countyName": "伊吾县",
						"id": 650522,
						"parentId": 6505
					}
				]
			},
			{
				"cityCode": "652300000",
				"cityName": "昌吉回族自治州",
				"id": 6523,
				"parentId": 65,
				"children": [{
						"countyCode": "652301000",
						"countyName": "昌吉市",
						"id": 652301,
						"parentId": 6523
					},
					{
						"countyCode": "652302000",
						"countyName": "阜康市",
						"id": 652302,
						"parentId": 6523
					},
					{
						"countyCode": "652323000",
						"countyName": "呼图壁县",
						"id": 652323,
						"parentId": 6523
					},
					{
						"countyCode": "652324000",
						"countyName": "玛纳斯县",
						"id": 652324,
						"parentId": 6523
					},
					{
						"countyCode": "652325000",
						"countyName": "奇台县",
						"id": 652325,
						"parentId": 6523
					},
					{
						"countyCode": "652327000",
						"countyName": "吉木萨尔县",
						"id": 652327,
						"parentId": 6523
					},
					{
						"countyCode": "652328000",
						"countyName": "木垒哈萨克自治县",
						"id": 652328,
						"parentId": 6523
					}
				]
			},
			{
				"cityCode": "652700000",
				"cityName": "博尔塔拉蒙古自治州",
				"id": 6527,
				"parentId": 65,
				"children": [{
						"countyCode": "652701000",
						"countyName": "博乐市",
						"id": 652701,
						"parentId": 6527
					},
					{
						"countyCode": "652702000",
						"countyName": "阿拉山口市",
						"id": 652702,
						"parentId": 6527
					},
					{
						"countyCode": "652722000",
						"countyName": "精河县",
						"id": 652722,
						"parentId": 6527
					},
					{
						"countyCode": "652723000",
						"countyName": "温泉县",
						"id": 652723,
						"parentId": 6527
					}
				]
			},
			{
				"cityCode": "652800000",
				"cityName": "巴音郭楞蒙古自治州",
				"id": 6528,
				"parentId": 65,
				"children": [{
						"countyCode": "652801000",
						"countyName": "库尔勒市",
						"id": 652801,
						"parentId": 6528
					},
					{
						"countyCode": "652822000",
						"countyName": "轮台县",
						"id": 652822,
						"parentId": 6528
					},
					{
						"countyCode": "652823000",
						"countyName": "尉犁县",
						"id": 652823,
						"parentId": 6528
					},
					{
						"countyCode": "652824000",
						"countyName": "若羌县",
						"id": 652824,
						"parentId": 6528
					},
					{
						"countyCode": "652825000",
						"countyName": "且末县",
						"id": 652825,
						"parentId": 6528
					},
					{
						"countyCode": "652826000",
						"countyName": "焉耆回族自治县",
						"id": 652826,
						"parentId": 6528
					},
					{
						"countyCode": "652827000",
						"countyName": "和静县",
						"id": 652827,
						"parentId": 6528
					},
					{
						"countyCode": "652828000",
						"countyName": "和硕县",
						"id": 652828,
						"parentId": 6528
					},
					{
						"countyCode": "652829000",
						"countyName": "博湖县",
						"id": 652829,
						"parentId": 6528
					}
				]
			},
			{
				"cityCode": "652900000",
				"cityName": "阿克苏地区",
				"id": 6529,
				"parentId": 65,
				"children": [{
						"countyCode": "652901000",
						"countyName": "阿克苏市",
						"id": 652901,
						"parentId": 6529
					},
					{
						"countyCode": "652922000",
						"countyName": "温宿县",
						"id": 652922,
						"parentId": 6529
					},
					{
						"countyCode": "652923000",
						"countyName": "库车县",
						"id": 652923,
						"parentId": 6529
					},
					{
						"countyCode": "652924000",
						"countyName": "沙雅县",
						"id": 652924,
						"parentId": 6529
					},
					{
						"countyCode": "652925000",
						"countyName": "新和县",
						"id": 652925,
						"parentId": 6529
					},
					{
						"countyCode": "652926000",
						"countyName": "拜城县",
						"id": 652926,
						"parentId": 6529
					},
					{
						"countyCode": "652927000",
						"countyName": "乌什县",
						"id": 652927,
						"parentId": 6529
					},
					{
						"countyCode": "652928000",
						"countyName": "阿瓦提县",
						"id": 652928,
						"parentId": 6529
					},
					{
						"countyCode": "652929000",
						"countyName": "柯坪县",
						"id": 652929,
						"parentId": 6529
					}
				]
			},
			{
				"cityCode": "653000000",
				"cityName": "克孜勒苏柯尔克孜自治州",
				"id": 6530,
				"parentId": 65,
				"children": [{
						"countyCode": "653001000",
						"countyName": "阿图什市",
						"id": 653001,
						"parentId": 6530
					},
					{
						"countyCode": "653022000",
						"countyName": "阿克陶县",
						"id": 653022,
						"parentId": 6530
					},
					{
						"countyCode": "653023000",
						"countyName": "阿合奇县",
						"id": 653023,
						"parentId": 6530
					},
					{
						"countyCode": "653024000",
						"countyName": "乌恰县",
						"id": 653024,
						"parentId": 6530
					}
				]
			},
			{
				"cityCode": "653100000",
				"cityName": "喀什地区",
				"id": 6531,
				"parentId": 65,
				"children": [{
						"countyCode": "653101000",
						"countyName": "喀什市",
						"id": 653101,
						"parentId": 6531
					},
					{
						"countyCode": "653121000",
						"countyName": "疏附县",
						"id": 653121,
						"parentId": 6531
					},
					{
						"countyCode": "653122000",
						"countyName": "疏勒县",
						"id": 653122,
						"parentId": 6531
					},
					{
						"countyCode": "653123000",
						"countyName": "英吉沙县",
						"id": 653123,
						"parentId": 6531
					},
					{
						"countyCode": "653124000",
						"countyName": "泽普县",
						"id": 653124,
						"parentId": 6531
					},
					{
						"countyCode": "653125000",
						"countyName": "莎车县",
						"id": 653125,
						"parentId": 6531
					},
					{
						"countyCode": "653126000",
						"countyName": "叶城县",
						"id": 653126,
						"parentId": 6531
					},
					{
						"countyCode": "653127000",
						"countyName": "麦盖提县",
						"id": 653127,
						"parentId": 6531
					},
					{
						"countyCode": "653128000",
						"countyName": "岳普湖县",
						"id": 653128,
						"parentId": 6531
					},
					{
						"countyCode": "653129000",
						"countyName": "伽师县",
						"id": 653129,
						"parentId": 6531
					},
					{
						"countyCode": "653130000",
						"countyName": "巴楚县",
						"id": 653130,
						"parentId": 6531
					},
					{
						"countyCode": "653131000",
						"countyName": "塔什库尔干塔吉克自治县",
						"id": 653131,
						"parentId": 6531
					}
				]
			},
			{
				"cityCode": "653200000",
				"cityName": "和田地区",
				"id": 6532,
				"parentId": 65,
				"children": [{
						"countyCode": "653201000",
						"countyName": "和田市",
						"id": 653201,
						"parentId": 6532
					},
					{
						"countyCode": "653221000",
						"countyName": "和田县",
						"id": 653221,
						"parentId": 6532
					},
					{
						"countyCode": "653222000",
						"countyName": "墨玉县",
						"id": 653222,
						"parentId": 6532
					},
					{
						"countyCode": "653223000",
						"countyName": "皮山县",
						"id": 653223,
						"parentId": 6532
					},
					{
						"countyCode": "653224000",
						"countyName": "洛浦县",
						"id": 653224,
						"parentId": 6532
					},
					{
						"countyCode": "653225000",
						"countyName": "策勒县",
						"id": 653225,
						"parentId": 6532
					},
					{
						"countyCode": "653226000",
						"countyName": "于田县",
						"id": 653226,
						"parentId": 6532
					},
					{
						"countyCode": "653227000",
						"countyName": "民丰县",
						"id": 653227,
						"parentId": 6532
					}
				]
			},
			{
				"cityCode": "654000000",
				"cityName": "伊犁哈萨克自治州",
				"id": 6540,
				"parentId": 65,
				"children": [{
						"countyCode": "654002000",
						"countyName": "伊宁市",
						"id": 654002,
						"parentId": 6540
					},
					{
						"countyCode": "654003000",
						"countyName": "奎屯市",
						"id": 654003,
						"parentId": 6540
					},
					{
						"countyCode": "654004000",
						"countyName": "霍尔果斯市",
						"id": 654004,
						"parentId": 6540
					},
					{
						"countyCode": "654021000",
						"countyName": "伊宁县",
						"id": 654021,
						"parentId": 6540
					},
					{
						"countyCode": "654022000",
						"countyName": "察布查尔锡伯自治县",
						"id": 654022,
						"parentId": 6540
					},
					{
						"countyCode": "654023000",
						"countyName": "霍城县",
						"id": 654023,
						"parentId": 6540
					},
					{
						"countyCode": "654024000",
						"countyName": "巩留县",
						"id": 654024,
						"parentId": 6540
					},
					{
						"countyCode": "654025000",
						"countyName": "新源县",
						"id": 654025,
						"parentId": 6540
					},
					{
						"countyCode": "654026000",
						"countyName": "昭苏县",
						"id": 654026,
						"parentId": 6540
					},
					{
						"countyCode": "654027000",
						"countyName": "特克斯县",
						"id": 654027,
						"parentId": 6540
					},
					{
						"countyCode": "654028000",
						"countyName": "尼勒克县",
						"id": 654028,
						"parentId": 6540
					}
				]
			},
			{
				"cityCode": "654200000",
				"cityName": "塔城地区",
				"id": 6542,
				"parentId": 65,
				"children": [{
						"countyCode": "654201000",
						"countyName": "塔城市",
						"id": 654201,
						"parentId": 6542
					},
					{
						"countyCode": "654202000",
						"countyName": "乌苏市",
						"id": 654202,
						"parentId": 6542
					},
					{
						"countyCode": "654221000",
						"countyName": "额敏县",
						"id": 654221,
						"parentId": 6542
					},
					{
						"countyCode": "654223000",
						"countyName": "沙湾县",
						"id": 654223,
						"parentId": 6542
					},
					{
						"countyCode": "654224000",
						"countyName": "托里县",
						"id": 654224,
						"parentId": 6542
					},
					{
						"countyCode": "654225000",
						"countyName": "裕民县",
						"id": 654225,
						"parentId": 6542
					},
					{
						"countyCode": "654226000",
						"countyName": "和布克赛尔蒙古自治县",
						"id": 654226,
						"parentId": 6542
					}
				]
			},
			{
				"cityCode": "654300000",
				"cityName": "阿勒泰地区",
				"id": 6543,
				"parentId": 65,
				"children": [{
						"countyCode": "654301000",
						"countyName": "阿勒泰市",
						"id": 654301,
						"parentId": 6543
					},
					{
						"countyCode": "654321000",
						"countyName": "布尔津县",
						"id": 654321,
						"parentId": 6543
					},
					{
						"countyCode": "654322000",
						"countyName": "富蕴县",
						"id": 654322,
						"parentId": 6543
					},
					{
						"countyCode": "654323000",
						"countyName": "福海县",
						"id": 654323,
						"parentId": 6543
					},
					{
						"countyCode": "654324000",
						"countyName": "哈巴河县",
						"id": 654324,
						"parentId": 6543
					},
					{
						"countyCode": "654325000",
						"countyName": "青河县",
						"id": 654325,
						"parentId": 6543
					},
					{
						"countyCode": "654326000",
						"countyName": "吉木乃县",
						"id": 654326,
						"parentId": 6543
					}
				]
			},
			{
				"cityCode": "659001000",
				"cityName": "石河子市",
				"id": 659001,
				"parentId": 65,
				"children": [{
					"countyCode": "659001000",
					"countyName": "石河子市",
					"id": 659001000,
					"parentId": 659001
				}]
			},
			{
				"cityCode": "659002000",
				"cityName": "阿拉尔市",
				"id": 659002,
				"parentId": 65,
				"children": [{
					"countyCode": "659002000",
					"countyName": "阿拉尔市",
					"id": 659002000,
					"parentId": 659002
				}]
			},
			{
				"cityCode": "659003000",
				"cityName": "图木舒克市",
				"id": 659003,
				"parentId": 65,
				"children": [{
					"countyCode": "659003000",
					"countyName": "图木舒克市",
					"id": 659003000,
					"parentId": 659003
				}]
			},
			{
				"cityCode": "659004000",
				"cityName": "五家渠市",
				"id": 659004,
				"parentId": 65,
				"children": [{
					"countyCode": "659004000",
					"countyName": "五家渠市",
					"id": 659004000,
					"parentId": 659004
				}]
			},
			{
				"cityCode": "659005000",
				"cityName": "北屯市",
				"id": 659005,
				"parentId": 65,
				"children": [{
					"countyCode": "659005000",
					"countyName": "北屯市",
					"id": 659005000,
					"parentId": 659005
				}]
			},
			{
				"cityCode": "659006000",
				"cityName": "铁门关市",
				"id": 659006,
				"parentId": 65,
				"children": [{
					"countyCode": "659006000",
					"countyName": "铁门关市",
					"id": 659006000,
					"parentId": 659006
				}]
			},
			{
				"cityCode": "659007000",
				"cityName": "双河市",
				"id": 659007,
				"parentId": 65,
				"children": [{
					"countyCode": "659007000",
					"countyName": "双河市",
					"id": 659007000,
					"parentId": 659007
				}]
			},
			{
				"cityCode": "659008000",
				"cityName": "可克达拉市",
				"id": 659008,
				"parentId": 65,
				"children": [{
					"countyCode": "659008000",
					"countyName": "可克达拉市",
					"id": 659008000,
					"parentId": 659008
				}]
			},
			{
				"cityCode": "659009000",
				"cityName": "昆玉市",
				"id": 659009,
				"parentId": 65,
				"children": [{
					"countyCode": "659009000",
					"countyName": "昆玉市",
					"id": 659009000,
					"parentId": 659009
				}]
			}
		]
	},
	{
		"provinceCode": "710000000",
		"provinceName": "台湾省",
		"id": 71,
		"parentId": 0,
		"children": [{
				"cityCode": "710100000",
				"cityName": "台北市",
				"id": 7101,
				"parentId": 71,
				"children": [{
						"countyCode": "710101000",
						"countyName": "中正区",
						"id": 710101,
						"parentId": 7101
					},
					{
						"countyCode": "710102000",
						"countyName": "大同区",
						"id": 710102,
						"parentId": 7101
					},
					{
						"countyCode": "710103000",
						"countyName": "中山区",
						"id": 710103,
						"parentId": 7101
					},
					{
						"countyCode": "710104000",
						"countyName": "松山区",
						"id": 710104,
						"parentId": 7101
					},
					{
						"countyCode": "710105000",
						"countyName": "大安区",
						"id": 710105,
						"parentId": 7101
					},
					{
						"countyCode": "710106000",
						"countyName": "万华区",
						"id": 710106,
						"parentId": 7101
					},
					{
						"countyCode": "710107000",
						"countyName": "信义区",
						"id": 710107,
						"parentId": 7101
					},
					{
						"countyCode": "710108000",
						"countyName": "士林区",
						"id": 710108,
						"parentId": 7101
					},
					{
						"countyCode": "710109000",
						"countyName": "北投区",
						"id": 710109,
						"parentId": 7101
					},
					{
						"countyCode": "710110000",
						"countyName": "内湖区",
						"id": 710110,
						"parentId": 7101
					},
					{
						"countyCode": "710111000",
						"countyName": "南港区",
						"id": 710111,
						"parentId": 7101
					},
					{
						"countyCode": "710112000",
						"countyName": "文山区",
						"id": 710112,
						"parentId": 7101
					}
				]
			},
			{
				"cityCode": "710200000",
				"cityName": "高雄市",
				"id": 7102,
				"parentId": 71,
				"children": [{
						"countyCode": "710201000",
						"countyName": "新兴区",
						"id": 710201,
						"parentId": 7102
					},
					{
						"countyCode": "710202000",
						"countyName": "前金区",
						"id": 710202,
						"parentId": 7102
					},
					{
						"countyCode": "710203000",
						"countyName": "苓雅区",
						"id": 710203,
						"parentId": 7102
					},
					{
						"countyCode": "710204000",
						"countyName": "盐埕区",
						"id": 710204,
						"parentId": 7102
					},
					{
						"countyCode": "710205000",
						"countyName": "鼓山区",
						"id": 710205,
						"parentId": 7102
					},
					{
						"countyCode": "710206000",
						"countyName": "旗津区",
						"id": 710206,
						"parentId": 7102
					},
					{
						"countyCode": "710207000",
						"countyName": "前镇区",
						"id": 710207,
						"parentId": 7102
					},
					{
						"countyCode": "710208000",
						"countyName": "三民区",
						"id": 710208,
						"parentId": 7102
					},
					{
						"countyCode": "710209000",
						"countyName": "左营区",
						"id": 710209,
						"parentId": 7102
					},
					{
						"countyCode": "710210000",
						"countyName": "楠梓区",
						"id": 710210,
						"parentId": 7102
					},
					{
						"countyCode": "710211000",
						"countyName": "小港区",
						"id": 710211,
						"parentId": 7102
					},
					{
						"countyCode": "710242000",
						"countyName": "仁武区",
						"id": 710242,
						"parentId": 7102
					},
					{
						"countyCode": "710243000",
						"countyName": "大社区",
						"id": 710243,
						"parentId": 7102
					},
					{
						"countyCode": "710244000",
						"countyName": "冈山区",
						"id": 710244,
						"parentId": 7102
					},
					{
						"countyCode": "710245000",
						"countyName": "路竹区",
						"id": 710245,
						"parentId": 7102
					},
					{
						"countyCode": "710246000",
						"countyName": "阿莲区",
						"id": 710246,
						"parentId": 7102
					},
					{
						"countyCode": "710247000",
						"countyName": "田寮区",
						"id": 710247,
						"parentId": 7102
					},
					{
						"countyCode": "710248000",
						"countyName": "燕巢区",
						"id": 710248,
						"parentId": 7102
					},
					{
						"countyCode": "710249000",
						"countyName": "桥头区",
						"id": 710249,
						"parentId": 7102
					},
					{
						"countyCode": "710250000",
						"countyName": "梓官区",
						"id": 710250,
						"parentId": 7102
					},
					{
						"countyCode": "710251000",
						"countyName": "弥陀区",
						"id": 710251,
						"parentId": 7102
					},
					{
						"countyCode": "710252000",
						"countyName": "永安区",
						"id": 710252,
						"parentId": 7102
					},
					{
						"countyCode": "710253000",
						"countyName": "湖内区",
						"id": 710253,
						"parentId": 7102
					},
					{
						"countyCode": "710254000",
						"countyName": "凤山区",
						"id": 710254,
						"parentId": 7102
					},
					{
						"countyCode": "710255000",
						"countyName": "大寮区",
						"id": 710255,
						"parentId": 7102
					},
					{
						"countyCode": "710256000",
						"countyName": "林园区",
						"id": 710256,
						"parentId": 7102
					},
					{
						"countyCode": "710257000",
						"countyName": "鸟松区",
						"id": 710257,
						"parentId": 7102
					},
					{
						"countyCode": "710258000",
						"countyName": "大树区",
						"id": 710258,
						"parentId": 7102
					},
					{
						"countyCode": "710259000",
						"countyName": "旗山区",
						"id": 710259,
						"parentId": 7102
					},
					{
						"countyCode": "710260000",
						"countyName": "美浓区",
						"id": 710260,
						"parentId": 7102
					},
					{
						"countyCode": "710261000",
						"countyName": "六龟区",
						"id": 710261,
						"parentId": 7102
					},
					{
						"countyCode": "710262000",
						"countyName": "内门区",
						"id": 710262,
						"parentId": 7102
					},
					{
						"countyCode": "710263000",
						"countyName": "杉林区",
						"id": 710263,
						"parentId": 7102
					},
					{
						"countyCode": "710264000",
						"countyName": "甲仙区",
						"id": 710264,
						"parentId": 7102
					},
					{
						"countyCode": "710265000",
						"countyName": "桃源区",
						"id": 710265,
						"parentId": 7102
					},
					{
						"countyCode": "710266000",
						"countyName": "那玛夏区",
						"id": 710266,
						"parentId": 7102
					},
					{
						"countyCode": "710267000",
						"countyName": "茂林区",
						"id": 710267,
						"parentId": 7102
					},
					{
						"countyCode": "710268000",
						"countyName": "茄萣区",
						"id": 710268,
						"parentId": 7102
					}
				]
			},
			{
				"cityCode": "710300000",
				"cityName": "台南市",
				"id": 7103,
				"parentId": 71,
				"children": [{
						"countyCode": "710301000",
						"countyName": "中西区",
						"id": 710301,
						"parentId": 7103
					},
					{
						"countyCode": "710302000",
						"countyName": "东区",
						"id": 710302,
						"parentId": 7103
					},
					{
						"countyCode": "710303000",
						"countyName": "南区",
						"id": 710303,
						"parentId": 7103
					},
					{
						"countyCode": "710304000",
						"countyName": "北区",
						"id": 710304,
						"parentId": 7103
					},
					{
						"countyCode": "710305000",
						"countyName": "安平区",
						"id": 710305,
						"parentId": 7103
					},
					{
						"countyCode": "710306000",
						"countyName": "安南区",
						"id": 710306,
						"parentId": 7103
					},
					{
						"countyCode": "710339000",
						"countyName": "永康区",
						"id": 710339,
						"parentId": 7103
					},
					{
						"countyCode": "710340000",
						"countyName": "归仁区",
						"id": 710340,
						"parentId": 7103
					},
					{
						"countyCode": "710341000",
						"countyName": "新化区",
						"id": 710341,
						"parentId": 7103
					},
					{
						"countyCode": "710342000",
						"countyName": "左镇区",
						"id": 710342,
						"parentId": 7103
					},
					{
						"countyCode": "710343000",
						"countyName": "玉井区",
						"id": 710343,
						"parentId": 7103
					},
					{
						"countyCode": "710344000",
						"countyName": "楠西区",
						"id": 710344,
						"parentId": 7103
					},
					{
						"countyCode": "710345000",
						"countyName": "南化区",
						"id": 710345,
						"parentId": 7103
					},
					{
						"countyCode": "710346000",
						"countyName": "仁德区",
						"id": 710346,
						"parentId": 7103
					},
					{
						"countyCode": "710347000",
						"countyName": "关庙区",
						"id": 710347,
						"parentId": 7103
					},
					{
						"countyCode": "710348000",
						"countyName": "龙崎区",
						"id": 710348,
						"parentId": 7103
					},
					{
						"countyCode": "710349000",
						"countyName": "官田区",
						"id": 710349,
						"parentId": 7103
					},
					{
						"countyCode": "710350000",
						"countyName": "麻豆区",
						"id": 710350,
						"parentId": 7103
					},
					{
						"countyCode": "710351000",
						"countyName": "佳里区",
						"id": 710351,
						"parentId": 7103
					},
					{
						"countyCode": "710352000",
						"countyName": "西港区",
						"id": 710352,
						"parentId": 7103
					},
					{
						"countyCode": "710353000",
						"countyName": "七股区",
						"id": 710353,
						"parentId": 7103
					},
					{
						"countyCode": "710354000",
						"countyName": "将军区",
						"id": 710354,
						"parentId": 7103
					},
					{
						"countyCode": "710355000",
						"countyName": "学甲区",
						"id": 710355,
						"parentId": 7103
					},
					{
						"countyCode": "710356000",
						"countyName": "北门区",
						"id": 710356,
						"parentId": 7103
					},
					{
						"countyCode": "710357000",
						"countyName": "新营区",
						"id": 710357,
						"parentId": 7103
					},
					{
						"countyCode": "710358000",
						"countyName": "后壁区",
						"id": 710358,
						"parentId": 7103
					},
					{
						"countyCode": "710359000",
						"countyName": "白河区",
						"id": 710359,
						"parentId": 7103
					},
					{
						"countyCode": "710360000",
						"countyName": "东山区",
						"id": 710360,
						"parentId": 7103
					},
					{
						"countyCode": "710361000",
						"countyName": "六甲区",
						"id": 710361,
						"parentId": 7103
					},
					{
						"countyCode": "710362000",
						"countyName": "下营区",
						"id": 710362,
						"parentId": 7103
					},
					{
						"countyCode": "710363000",
						"countyName": "柳营区",
						"id": 710363,
						"parentId": 7103
					},
					{
						"countyCode": "710364000",
						"countyName": "盐水区",
						"id": 710364,
						"parentId": 7103
					},
					{
						"countyCode": "710365000",
						"countyName": "善化区",
						"id": 710365,
						"parentId": 7103
					},
					{
						"countyCode": "710366000",
						"countyName": "大内区",
						"id": 710366,
						"parentId": 7103
					},
					{
						"countyCode": "710367000",
						"countyName": "山上区",
						"id": 710367,
						"parentId": 7103
					},
					{
						"countyCode": "710368000",
						"countyName": "新市区",
						"id": 710368,
						"parentId": 7103
					},
					{
						"countyCode": "710369000",
						"countyName": "安定区",
						"id": 710369,
						"parentId": 7103
					}
				]
			},
			{
				"cityCode": "710400000",
				"cityName": "台中市",
				"id": 7104,
				"parentId": 71,
				"children": [{
						"countyCode": "710401000",
						"countyName": "中区",
						"id": 710401,
						"parentId": 7104
					},
					{
						"countyCode": "710402000",
						"countyName": "东区",
						"id": 710402,
						"parentId": 7104
					},
					{
						"countyCode": "710403000",
						"countyName": "南区",
						"id": 710403,
						"parentId": 7104
					},
					{
						"countyCode": "710404000",
						"countyName": "西区",
						"id": 710404,
						"parentId": 7104
					},
					{
						"countyCode": "710405000",
						"countyName": "北区",
						"id": 710405,
						"parentId": 7104
					},
					{
						"countyCode": "710406000",
						"countyName": "北屯区",
						"id": 710406,
						"parentId": 7104
					},
					{
						"countyCode": "710407000",
						"countyName": "西屯区",
						"id": 710407,
						"parentId": 7104
					},
					{
						"countyCode": "710408000",
						"countyName": "南屯区",
						"id": 710408,
						"parentId": 7104
					},
					{
						"countyCode": "710431000",
						"countyName": "太平区",
						"id": 710431,
						"parentId": 7104
					},
					{
						"countyCode": "710432000",
						"countyName": "大里区",
						"id": 710432,
						"parentId": 7104
					},
					{
						"countyCode": "710433000",
						"countyName": "雾峰区",
						"id": 710433,
						"parentId": 7104
					},
					{
						"countyCode": "710434000",
						"countyName": "乌日区",
						"id": 710434,
						"parentId": 7104
					},
					{
						"countyCode": "710435000",
						"countyName": "丰原区",
						"id": 710435,
						"parentId": 7104
					},
					{
						"countyCode": "710436000",
						"countyName": "后里区",
						"id": 710436,
						"parentId": 7104
					},
					{
						"countyCode": "710437000",
						"countyName": "石冈区",
						"id": 710437,
						"parentId": 7104
					},
					{
						"countyCode": "710438000",
						"countyName": "东势区",
						"id": 710438,
						"parentId": 7104
					},
					{
						"countyCode": "710439000",
						"countyName": "和平区",
						"id": 710439,
						"parentId": 7104
					},
					{
						"countyCode": "710440000",
						"countyName": "新社区",
						"id": 710440,
						"parentId": 7104
					},
					{
						"countyCode": "710441000",
						"countyName": "潭子区",
						"id": 710441,
						"parentId": 7104
					},
					{
						"countyCode": "710442000",
						"countyName": "大雅区",
						"id": 710442,
						"parentId": 7104
					},
					{
						"countyCode": "710443000",
						"countyName": "神冈区",
						"id": 710443,
						"parentId": 7104
					},
					{
						"countyCode": "710444000",
						"countyName": "大肚区",
						"id": 710444,
						"parentId": 7104
					},
					{
						"countyCode": "710445000",
						"countyName": "沙鹿区",
						"id": 710445,
						"parentId": 7104
					},
					{
						"countyCode": "710446000",
						"countyName": "龙井区",
						"id": 710446,
						"parentId": 7104
					},
					{
						"countyCode": "710447000",
						"countyName": "梧栖区",
						"id": 710447,
						"parentId": 7104
					},
					{
						"countyCode": "710448000",
						"countyName": "清水区",
						"id": 710448,
						"parentId": 7104
					},
					{
						"countyCode": "710449000",
						"countyName": "大甲区",
						"id": 710449,
						"parentId": 7104
					},
					{
						"countyCode": "710450000",
						"countyName": "外埔区",
						"id": 710450,
						"parentId": 7104
					},
					{
						"countyCode": "710451000",
						"countyName": "大安区",
						"id": 710451,
						"parentId": 7104
					}
				]
			},
			{
				"cityCode": "710600000",
				"cityName": "南投县",
				"id": 7106,
				"parentId": 71,
				"children": [{
						"countyCode": "710614000",
						"countyName": "南投市",
						"id": 710614,
						"parentId": 7106
					},
					{
						"countyCode": "710615000",
						"countyName": "中寮乡",
						"id": 710615,
						"parentId": 7106
					},
					{
						"countyCode": "710616000",
						"countyName": "草屯镇",
						"id": 710616,
						"parentId": 7106
					},
					{
						"countyCode": "710617000",
						"countyName": "国姓乡",
						"id": 710617,
						"parentId": 7106
					},
					{
						"countyCode": "710618000",
						"countyName": "埔里镇",
						"id": 710618,
						"parentId": 7106
					},
					{
						"countyCode": "710619000",
						"countyName": "仁爱乡",
						"id": 710619,
						"parentId": 7106
					},
					{
						"countyCode": "710620000",
						"countyName": "名间乡",
						"id": 710620,
						"parentId": 7106
					},
					{
						"countyCode": "710621000",
						"countyName": "集集镇",
						"id": 710621,
						"parentId": 7106
					},
					{
						"countyCode": "710622000",
						"countyName": "水里乡",
						"id": 710622,
						"parentId": 7106
					},
					{
						"countyCode": "710623000",
						"countyName": "鱼池乡",
						"id": 710623,
						"parentId": 7106
					},
					{
						"countyCode": "710624000",
						"countyName": "信义乡",
						"id": 710624,
						"parentId": 7106
					},
					{
						"countyCode": "710625000",
						"countyName": "竹山镇",
						"id": 710625,
						"parentId": 7106
					},
					{
						"countyCode": "710626000",
						"countyName": "鹿谷乡",
						"id": 710626,
						"parentId": 7106
					}
				]
			},
			{
				"cityCode": "710700000",
				"cityName": "基隆市",
				"id": 7107,
				"parentId": 71,
				"children": [{
						"countyCode": "710701000",
						"countyName": "仁爱区",
						"id": 710701,
						"parentId": 7107
					},
					{
						"countyCode": "710702000",
						"countyName": "信义区",
						"id": 710702,
						"parentId": 7107
					},
					{
						"countyCode": "710703000",
						"countyName": "中正区",
						"id": 710703,
						"parentId": 7107
					},
					{
						"countyCode": "710704000",
						"countyName": "中山区",
						"id": 710704,
						"parentId": 7107
					},
					{
						"countyCode": "710705000",
						"countyName": "安乐区",
						"id": 710705,
						"parentId": 7107
					},
					{
						"countyCode": "710706000",
						"countyName": "暖暖区",
						"id": 710706,
						"parentId": 7107
					},
					{
						"countyCode": "710707000",
						"countyName": "七堵区",
						"id": 710707,
						"parentId": 7107
					}
				]
			},
			{
				"cityCode": "710800000",
				"cityName": "新竹市",
				"id": 7108,
				"parentId": 71,
				"children": [{
						"countyCode": "710801000",
						"countyName": "东区",
						"id": 710801,
						"parentId": 7108
					},
					{
						"countyCode": "710802000",
						"countyName": "北区",
						"id": 710802,
						"parentId": 7108
					},
					{
						"countyCode": "710803000",
						"countyName": "香山区",
						"id": 710803,
						"parentId": 7108
					}
				]
			},
			{
				"cityCode": "710900000",
				"cityName": "嘉义市",
				"id": 7109,
				"parentId": 71,
				"children": [{
						"countyCode": "710901000",
						"countyName": "东区",
						"id": 710901,
						"parentId": 7109
					},
					{
						"countyCode": "710902000",
						"countyName": "西区",
						"id": 710902,
						"parentId": 7109
					}
				]
			},
			{
				"cityCode": "711100000",
				"cityName": "新北市",
				"id": 7111,
				"parentId": 71,
				"children": [{
						"countyCode": "711130000",
						"countyName": "万里区",
						"id": 711130,
						"parentId": 7111
					},
					{
						"countyCode": "711131000",
						"countyName": "金山区",
						"id": 711131,
						"parentId": 7111
					},
					{
						"countyCode": "711132000",
						"countyName": "板桥区",
						"id": 711132,
						"parentId": 7111
					},
					{
						"countyCode": "711133000",
						"countyName": "汐止区",
						"id": 711133,
						"parentId": 7111
					},
					{
						"countyCode": "711134000",
						"countyName": "深坑区",
						"id": 711134,
						"parentId": 7111
					},
					{
						"countyCode": "711135000",
						"countyName": "石碇区",
						"id": 711135,
						"parentId": 7111
					},
					{
						"countyCode": "711136000",
						"countyName": "瑞芳区",
						"id": 711136,
						"parentId": 7111
					},
					{
						"countyCode": "711137000",
						"countyName": "平溪区",
						"id": 711137,
						"parentId": 7111
					},
					{
						"countyCode": "711138000",
						"countyName": "双溪区",
						"id": 711138,
						"parentId": 7111
					},
					{
						"countyCode": "711139000",
						"countyName": "贡寮区",
						"id": 711139,
						"parentId": 7111
					},
					{
						"countyCode": "711140000",
						"countyName": "新店区",
						"id": 711140,
						"parentId": 7111
					},
					{
						"countyCode": "711141000",
						"countyName": "坪林区",
						"id": 711141,
						"parentId": 7111
					},
					{
						"countyCode": "711142000",
						"countyName": "乌来区",
						"id": 711142,
						"parentId": 7111
					},
					{
						"countyCode": "711143000",
						"countyName": "永和区",
						"id": 711143,
						"parentId": 7111
					},
					{
						"countyCode": "711144000",
						"countyName": "中和区",
						"id": 711144,
						"parentId": 7111
					},
					{
						"countyCode": "711145000",
						"countyName": "土城区",
						"id": 711145,
						"parentId": 7111
					},
					{
						"countyCode": "711146000",
						"countyName": "三峡区",
						"id": 711146,
						"parentId": 7111
					},
					{
						"countyCode": "711147000",
						"countyName": "树林区",
						"id": 711147,
						"parentId": 7111
					},
					{
						"countyCode": "711148000",
						"countyName": "莺歌区",
						"id": 711148,
						"parentId": 7111
					},
					{
						"countyCode": "711149000",
						"countyName": "三重区",
						"id": 711149,
						"parentId": 7111
					},
					{
						"countyCode": "711150000",
						"countyName": "新庄区",
						"id": 711150,
						"parentId": 7111
					},
					{
						"countyCode": "711151000",
						"countyName": "泰山区",
						"id": 711151,
						"parentId": 7111
					},
					{
						"countyCode": "711152000",
						"countyName": "林口区",
						"id": 711152,
						"parentId": 7111
					},
					{
						"countyCode": "711153000",
						"countyName": "芦洲区",
						"id": 711153,
						"parentId": 7111
					},
					{
						"countyCode": "711154000",
						"countyName": "五股区",
						"id": 711154,
						"parentId": 7111
					},
					{
						"countyCode": "711155000",
						"countyName": "八里区",
						"id": 711155,
						"parentId": 7111
					},
					{
						"countyCode": "711156000",
						"countyName": "淡水区",
						"id": 711156,
						"parentId": 7111
					},
					{
						"countyCode": "711157000",
						"countyName": "三芝区",
						"id": 711157,
						"parentId": 7111
					},
					{
						"countyCode": "711158000",
						"countyName": "石门区",
						"id": 711158,
						"parentId": 7111
					}
				]
			},
			{
				"cityCode": "711200000",
				"cityName": "宜兰县",
				"id": 7112,
				"parentId": 71,
				"children": [{
						"countyCode": "711214000",
						"countyName": "宜兰市",
						"id": 711214,
						"parentId": 7112
					},
					{
						"countyCode": "711215000",
						"countyName": "头城镇",
						"id": 711215,
						"parentId": 7112
					},
					{
						"countyCode": "711216000",
						"countyName": "礁溪乡",
						"id": 711216,
						"parentId": 7112
					},
					{
						"countyCode": "711217000",
						"countyName": "壮围乡",
						"id": 711217,
						"parentId": 7112
					},
					{
						"countyCode": "711218000",
						"countyName": "员山乡",
						"id": 711218,
						"parentId": 7112
					},
					{
						"countyCode": "711219000",
						"countyName": "罗东镇",
						"id": 711219,
						"parentId": 7112
					},
					{
						"countyCode": "711220000",
						"countyName": "三星乡",
						"id": 711220,
						"parentId": 7112
					},
					{
						"countyCode": "711221000",
						"countyName": "大同乡",
						"id": 711221,
						"parentId": 7112
					},
					{
						"countyCode": "711222000",
						"countyName": "五结乡",
						"id": 711222,
						"parentId": 7112
					},
					{
						"countyCode": "711223000",
						"countyName": "冬山乡",
						"id": 711223,
						"parentId": 7112
					},
					{
						"countyCode": "711224000",
						"countyName": "苏澳镇",
						"id": 711224,
						"parentId": 7112
					},
					{
						"countyCode": "711225000",
						"countyName": "南澳乡",
						"id": 711225,
						"parentId": 7112
					}
				]
			},
			{
				"cityCode": "711300000",
				"cityName": "新竹县",
				"id": 7113,
				"parentId": 71,
				"children": [{
						"countyCode": "711314000",
						"countyName": "竹北市",
						"id": 711314,
						"parentId": 7113
					},
					{
						"countyCode": "711315000",
						"countyName": "湖口乡",
						"id": 711315,
						"parentId": 7113
					},
					{
						"countyCode": "711316000",
						"countyName": "新丰乡",
						"id": 711316,
						"parentId": 7113
					},
					{
						"countyCode": "711317000",
						"countyName": "新埔镇",
						"id": 711317,
						"parentId": 7113
					},
					{
						"countyCode": "711318000",
						"countyName": "关西镇",
						"id": 711318,
						"parentId": 7113
					},
					{
						"countyCode": "711319000",
						"countyName": "芎林乡",
						"id": 711319,
						"parentId": 7113
					},
					{
						"countyCode": "711320000",
						"countyName": "宝山乡",
						"id": 711320,
						"parentId": 7113
					},
					{
						"countyCode": "711321000",
						"countyName": "竹东镇",
						"id": 711321,
						"parentId": 7113
					},
					{
						"countyCode": "711322000",
						"countyName": "五峰乡",
						"id": 711322,
						"parentId": 7113
					},
					{
						"countyCode": "711323000",
						"countyName": "横山乡",
						"id": 711323,
						"parentId": 7113
					},
					{
						"countyCode": "711324000",
						"countyName": "尖石乡",
						"id": 711324,
						"parentId": 7113
					},
					{
						"countyCode": "711325000",
						"countyName": "北埔乡",
						"id": 711325,
						"parentId": 7113
					},
					{
						"countyCode": "711326000",
						"countyName": "峨眉乡",
						"id": 711326,
						"parentId": 7113
					}
				]
			},
			{
				"cityCode": "711400000",
				"cityName": "桃园市",
				"id": 7114,
				"parentId": 71,
				"children": [{
						"countyCode": "711414000",
						"countyName": "中坜区",
						"id": 711414,
						"parentId": 7114
					},
					{
						"countyCode": "711415000",
						"countyName": "平镇区",
						"id": 711415,
						"parentId": 7114
					},
					{
						"countyCode": "711416000",
						"countyName": "龙潭区",
						"id": 711416,
						"parentId": 7114
					},
					{
						"countyCode": "711417000",
						"countyName": "杨梅区",
						"id": 711417,
						"parentId": 7114
					},
					{
						"countyCode": "711418000",
						"countyName": "新屋区",
						"id": 711418,
						"parentId": 7114
					},
					{
						"countyCode": "711419000",
						"countyName": "观音区",
						"id": 711419,
						"parentId": 7114
					},
					{
						"countyCode": "711420000",
						"countyName": "桃园区",
						"id": 711420,
						"parentId": 7114
					},
					{
						"countyCode": "711421000",
						"countyName": "龟山区",
						"id": 711421,
						"parentId": 7114
					},
					{
						"countyCode": "711422000",
						"countyName": "八德区",
						"id": 711422,
						"parentId": 7114
					},
					{
						"countyCode": "711423000",
						"countyName": "大溪区",
						"id": 711423,
						"parentId": 7114
					},
					{
						"countyCode": "711424000",
						"countyName": "复兴区",
						"id": 711424,
						"parentId": 7114
					},
					{
						"countyCode": "711425000",
						"countyName": "大园区",
						"id": 711425,
						"parentId": 7114
					},
					{
						"countyCode": "711426000",
						"countyName": "芦竹区",
						"id": 711426,
						"parentId": 7114
					}
				]
			},
			{
				"cityCode": "711500000",
				"cityName": "苗栗县",
				"id": 7115,
				"parentId": 71,
				"children": [{
						"countyCode": "711519000",
						"countyName": "竹南镇",
						"id": 711519,
						"parentId": 7115
					},
					{
						"countyCode": "711520000",
						"countyName": "头份市",
						"id": 711520,
						"parentId": 7115
					},
					{
						"countyCode": "711521000",
						"countyName": "三湾乡",
						"id": 711521,
						"parentId": 7115
					},
					{
						"countyCode": "711522000",
						"countyName": "南庄乡",
						"id": 711522,
						"parentId": 7115
					},
					{
						"countyCode": "711523000",
						"countyName": "狮潭乡",
						"id": 711523,
						"parentId": 7115
					},
					{
						"countyCode": "711524000",
						"countyName": "后龙镇",
						"id": 711524,
						"parentId": 7115
					},
					{
						"countyCode": "711525000",
						"countyName": "通霄镇",
						"id": 711525,
						"parentId": 7115
					},
					{
						"countyCode": "711526000",
						"countyName": "苑里镇",
						"id": 711526,
						"parentId": 7115
					},
					{
						"countyCode": "711527000",
						"countyName": "苗栗市",
						"id": 711527,
						"parentId": 7115
					},
					{
						"countyCode": "711528000",
						"countyName": "造桥乡",
						"id": 711528,
						"parentId": 7115
					},
					{
						"countyCode": "711529000",
						"countyName": "头屋乡",
						"id": 711529,
						"parentId": 7115
					},
					{
						"countyCode": "711530000",
						"countyName": "公馆乡",
						"id": 711530,
						"parentId": 7115
					},
					{
						"countyCode": "711531000",
						"countyName": "大湖乡",
						"id": 711531,
						"parentId": 7115
					},
					{
						"countyCode": "711532000",
						"countyName": "泰安乡",
						"id": 711532,
						"parentId": 7115
					},
					{
						"countyCode": "711533000",
						"countyName": "铜锣乡",
						"id": 711533,
						"parentId": 7115
					},
					{
						"countyCode": "711534000",
						"countyName": "三义乡",
						"id": 711534,
						"parentId": 7115
					},
					{
						"countyCode": "711535000",
						"countyName": "西湖乡",
						"id": 711535,
						"parentId": 7115
					},
					{
						"countyCode": "711536000",
						"countyName": "卓兰镇",
						"id": 711536,
						"parentId": 7115
					}
				]
			},
			{
				"cityCode": "711700000",
				"cityName": "彰化县",
				"id": 7117,
				"parentId": 71,
				"children": [{
						"countyCode": "711727000",
						"countyName": "彰化市",
						"id": 711727,
						"parentId": 7117
					},
					{
						"countyCode": "711728000",
						"countyName": "芬园乡",
						"id": 711728,
						"parentId": 7117
					},
					{
						"countyCode": "711729000",
						"countyName": "花坛乡",
						"id": 711729,
						"parentId": 7117
					},
					{
						"countyCode": "711730000",
						"countyName": "秀水乡",
						"id": 711730,
						"parentId": 7117
					},
					{
						"countyCode": "711731000",
						"countyName": "鹿港镇",
						"id": 711731,
						"parentId": 7117
					},
					{
						"countyCode": "711732000",
						"countyName": "福兴乡",
						"id": 711732,
						"parentId": 7117
					},
					{
						"countyCode": "711733000",
						"countyName": "线西乡",
						"id": 711733,
						"parentId": 7117
					},
					{
						"countyCode": "711734000",
						"countyName": "和美镇",
						"id": 711734,
						"parentId": 7117
					},
					{
						"countyCode": "711735000",
						"countyName": "伸港乡",
						"id": 711735,
						"parentId": 7117
					},
					{
						"countyCode": "711736000",
						"countyName": "员林市",
						"id": 711736,
						"parentId": 7117
					},
					{
						"countyCode": "711737000",
						"countyName": "社头乡",
						"id": 711737,
						"parentId": 7117
					},
					{
						"countyCode": "711738000",
						"countyName": "永靖乡",
						"id": 711738,
						"parentId": 7117
					},
					{
						"countyCode": "711739000",
						"countyName": "埔心乡",
						"id": 711739,
						"parentId": 7117
					},
					{
						"countyCode": "711740000",
						"countyName": "溪湖镇",
						"id": 711740,
						"parentId": 7117
					},
					{
						"countyCode": "711741000",
						"countyName": "大村乡",
						"id": 711741,
						"parentId": 7117
					},
					{
						"countyCode": "711742000",
						"countyName": "埔盐乡",
						"id": 711742,
						"parentId": 7117
					},
					{
						"countyCode": "711743000",
						"countyName": "田中镇",
						"id": 711743,
						"parentId": 7117
					},
					{
						"countyCode": "711744000",
						"countyName": "北斗镇",
						"id": 711744,
						"parentId": 7117
					},
					{
						"countyCode": "711745000",
						"countyName": "田尾乡",
						"id": 711745,
						"parentId": 7117
					},
					{
						"countyCode": "711746000",
						"countyName": "埤头乡",
						"id": 711746,
						"parentId": 7117
					},
					{
						"countyCode": "711747000",
						"countyName": "溪州乡",
						"id": 711747,
						"parentId": 7117
					},
					{
						"countyCode": "711748000",
						"countyName": "竹塘乡",
						"id": 711748,
						"parentId": 7117
					},
					{
						"countyCode": "711749000",
						"countyName": "二林镇",
						"id": 711749,
						"parentId": 7117
					},
					{
						"countyCode": "711750000",
						"countyName": "大城乡",
						"id": 711750,
						"parentId": 7117
					},
					{
						"countyCode": "711751000",
						"countyName": "芳苑乡",
						"id": 711751,
						"parentId": 7117
					},
					{
						"countyCode": "711752000",
						"countyName": "二水乡",
						"id": 711752,
						"parentId": 7117
					}
				]
			},
			{
				"cityCode": "711900000",
				"cityName": "嘉义县",
				"id": 7119,
				"parentId": 71,
				"children": [{
						"countyCode": "711919000",
						"countyName": "番路乡",
						"id": 711919,
						"parentId": 7119
					},
					{
						"countyCode": "711920000",
						"countyName": "梅山乡",
						"id": 711920,
						"parentId": 7119
					},
					{
						"countyCode": "711921000",
						"countyName": "竹崎乡",
						"id": 711921,
						"parentId": 7119
					},
					{
						"countyCode": "711922000",
						"countyName": "阿里山乡",
						"id": 711922,
						"parentId": 7119
					},
					{
						"countyCode": "711923000",
						"countyName": "中埔乡",
						"id": 711923,
						"parentId": 7119
					},
					{
						"countyCode": "711924000",
						"countyName": "大埔乡",
						"id": 711924,
						"parentId": 7119
					},
					{
						"countyCode": "711925000",
						"countyName": "水上乡",
						"id": 711925,
						"parentId": 7119
					},
					{
						"countyCode": "711926000",
						"countyName": "鹿草乡",
						"id": 711926,
						"parentId": 7119
					},
					{
						"countyCode": "711927000",
						"countyName": "太保市",
						"id": 711927,
						"parentId": 7119
					},
					{
						"countyCode": "711928000",
						"countyName": "朴子市",
						"id": 711928,
						"parentId": 7119
					},
					{
						"countyCode": "711929000",
						"countyName": "东石乡",
						"id": 711929,
						"parentId": 7119
					},
					{
						"countyCode": "711930000",
						"countyName": "六脚乡",
						"id": 711930,
						"parentId": 7119
					},
					{
						"countyCode": "711931000",
						"countyName": "新港乡",
						"id": 711931,
						"parentId": 7119
					},
					{
						"countyCode": "711932000",
						"countyName": "民雄乡",
						"id": 711932,
						"parentId": 7119
					},
					{
						"countyCode": "711933000",
						"countyName": "大林镇",
						"id": 711933,
						"parentId": 7119
					},
					{
						"countyCode": "711934000",
						"countyName": "溪口乡",
						"id": 711934,
						"parentId": 7119
					},
					{
						"countyCode": "711935000",
						"countyName": "义竹乡",
						"id": 711935,
						"parentId": 7119
					},
					{
						"countyCode": "711936000",
						"countyName": "布袋镇",
						"id": 711936,
						"parentId": 7119
					}
				]
			},
			{
				"cityCode": "712100000",
				"cityName": "云林县",
				"id": 7121,
				"parentId": 71,
				"children": [{
						"countyCode": "712121000",
						"countyName": "斗南镇",
						"id": 712121,
						"parentId": 7121
					},
					{
						"countyCode": "712122000",
						"countyName": "大埤乡",
						"id": 712122,
						"parentId": 7121
					},
					{
						"countyCode": "712123000",
						"countyName": "虎尾镇",
						"id": 712123,
						"parentId": 7121
					},
					{
						"countyCode": "712124000",
						"countyName": "土库镇",
						"id": 712124,
						"parentId": 7121
					},
					{
						"countyCode": "712125000",
						"countyName": "褒忠乡",
						"id": 712125,
						"parentId": 7121
					},
					{
						"countyCode": "712126000",
						"countyName": "东势乡",
						"id": 712126,
						"parentId": 7121
					},
					{
						"countyCode": "712127000",
						"countyName": "台西乡",
						"id": 712127,
						"parentId": 7121
					},
					{
						"countyCode": "712128000",
						"countyName": "仑背乡",
						"id": 712128,
						"parentId": 7121
					},
					{
						"countyCode": "712129000",
						"countyName": "麦寮乡",
						"id": 712129,
						"parentId": 7121
					},
					{
						"countyCode": "712130000",
						"countyName": "斗六市",
						"id": 712130,
						"parentId": 7121
					},
					{
						"countyCode": "712131000",
						"countyName": "林内乡",
						"id": 712131,
						"parentId": 7121
					},
					{
						"countyCode": "712132000",
						"countyName": "古坑乡",
						"id": 712132,
						"parentId": 7121
					},
					{
						"countyCode": "712133000",
						"countyName": "莿桐乡",
						"id": 712133,
						"parentId": 7121
					},
					{
						"countyCode": "712134000",
						"countyName": "西螺镇",
						"id": 712134,
						"parentId": 7121
					},
					{
						"countyCode": "712135000",
						"countyName": "二仑乡",
						"id": 712135,
						"parentId": 7121
					},
					{
						"countyCode": "712136000",
						"countyName": "北港镇",
						"id": 712136,
						"parentId": 7121
					},
					{
						"countyCode": "712137000",
						"countyName": "水林乡",
						"id": 712137,
						"parentId": 7121
					},
					{
						"countyCode": "712138000",
						"countyName": "口湖乡",
						"id": 712138,
						"parentId": 7121
					},
					{
						"countyCode": "712139000",
						"countyName": "四湖乡",
						"id": 712139,
						"parentId": 7121
					},
					{
						"countyCode": "712140000",
						"countyName": "元长乡",
						"id": 712140,
						"parentId": 7121
					}
				]
			},
			{
				"cityCode": "712400000",
				"cityName": "屏东县",
				"id": 7124,
				"parentId": 71,
				"children": [{
						"countyCode": "712434000",
						"countyName": "屏东市",
						"id": 712434,
						"parentId": 7124
					},
					{
						"countyCode": "712435000",
						"countyName": "三地门乡",
						"id": 712435,
						"parentId": 7124
					},
					{
						"countyCode": "712436000",
						"countyName": "雾台乡",
						"id": 712436,
						"parentId": 7124
					},
					{
						"countyCode": "712437000",
						"countyName": "玛家乡",
						"id": 712437,
						"parentId": 7124
					},
					{
						"countyCode": "712438000",
						"countyName": "九如乡",
						"id": 712438,
						"parentId": 7124
					},
					{
						"countyCode": "712439000",
						"countyName": "里港乡",
						"id": 712439,
						"parentId": 7124
					},
					{
						"countyCode": "712440000",
						"countyName": "高树乡",
						"id": 712440,
						"parentId": 7124
					},
					{
						"countyCode": "712441000",
						"countyName": "盐埔乡",
						"id": 712441,
						"parentId": 7124
					},
					{
						"countyCode": "712442000",
						"countyName": "长治乡",
						"id": 712442,
						"parentId": 7124
					},
					{
						"countyCode": "712443000",
						"countyName": "麟洛乡",
						"id": 712443,
						"parentId": 7124
					},
					{
						"countyCode": "712444000",
						"countyName": "竹田乡",
						"id": 712444,
						"parentId": 7124
					},
					{
						"countyCode": "712445000",
						"countyName": "内埔乡",
						"id": 712445,
						"parentId": 7124
					},
					{
						"countyCode": "712446000",
						"countyName": "万丹乡",
						"id": 712446,
						"parentId": 7124
					},
					{
						"countyCode": "712447000",
						"countyName": "潮州镇",
						"id": 712447,
						"parentId": 7124
					},
					{
						"countyCode": "712448000",
						"countyName": "泰武乡",
						"id": 712448,
						"parentId": 7124
					},
					{
						"countyCode": "712449000",
						"countyName": "来义乡",
						"id": 712449,
						"parentId": 7124
					},
					{
						"countyCode": "712450000",
						"countyName": "万峦乡",
						"id": 712450,
						"parentId": 7124
					},
					{
						"countyCode": "712451000",
						"countyName": "崁顶乡",
						"id": 712451,
						"parentId": 7124
					},
					{
						"countyCode": "712452000",
						"countyName": "新埤乡",
						"id": 712452,
						"parentId": 7124
					},
					{
						"countyCode": "712453000",
						"countyName": "南州乡",
						"id": 712453,
						"parentId": 7124
					},
					{
						"countyCode": "712454000",
						"countyName": "林边乡",
						"id": 712454,
						"parentId": 7124
					},
					{
						"countyCode": "712455000",
						"countyName": "东港镇",
						"id": 712455,
						"parentId": 7124
					},
					{
						"countyCode": "712456000",
						"countyName": "琉球乡",
						"id": 712456,
						"parentId": 7124
					},
					{
						"countyCode": "712457000",
						"countyName": "佳冬乡",
						"id": 712457,
						"parentId": 7124
					},
					{
						"countyCode": "712458000",
						"countyName": "新园乡",
						"id": 712458,
						"parentId": 7124
					},
					{
						"countyCode": "712459000",
						"countyName": "枋寮乡",
						"id": 712459,
						"parentId": 7124
					},
					{
						"countyCode": "712460000",
						"countyName": "枋山乡",
						"id": 712460,
						"parentId": 7124
					},
					{
						"countyCode": "712461000",
						"countyName": "春日乡",
						"id": 712461,
						"parentId": 7124
					},
					{
						"countyCode": "712462000",
						"countyName": "狮子乡",
						"id": 712462,
						"parentId": 7124
					},
					{
						"countyCode": "712463000",
						"countyName": "车城乡",
						"id": 712463,
						"parentId": 7124
					},
					{
						"countyCode": "712464000",
						"countyName": "牡丹乡",
						"id": 712464,
						"parentId": 7124
					},
					{
						"countyCode": "712465000",
						"countyName": "恒春镇",
						"id": 712465,
						"parentId": 7124
					},
					{
						"countyCode": "712466000",
						"countyName": "满州乡",
						"id": 712466,
						"parentId": 7124
					}
				]
			},
			{
				"cityCode": "712500000",
				"cityName": "台东县",
				"id": 7125,
				"parentId": 71,
				"children": [{
						"countyCode": "712517000",
						"countyName": "台东市",
						"id": 712517,
						"parentId": 7125
					},
					{
						"countyCode": "712518000",
						"countyName": "绿岛乡",
						"id": 712518,
						"parentId": 7125
					},
					{
						"countyCode": "712519000",
						"countyName": "兰屿乡",
						"id": 712519,
						"parentId": 7125
					},
					{
						"countyCode": "712520000",
						"countyName": "延平乡",
						"id": 712520,
						"parentId": 7125
					},
					{
						"countyCode": "712521000",
						"countyName": "卑南乡",
						"id": 712521,
						"parentId": 7125
					},
					{
						"countyCode": "712522000",
						"countyName": "鹿野乡",
						"id": 712522,
						"parentId": 7125
					},
					{
						"countyCode": "712523000",
						"countyName": "关山镇",
						"id": 712523,
						"parentId": 7125
					},
					{
						"countyCode": "712524000",
						"countyName": "海端乡",
						"id": 712524,
						"parentId": 7125
					},
					{
						"countyCode": "712525000",
						"countyName": "池上乡",
						"id": 712525,
						"parentId": 7125
					},
					{
						"countyCode": "712526000",
						"countyName": "东河乡",
						"id": 712526,
						"parentId": 7125
					},
					{
						"countyCode": "712527000",
						"countyName": "成功镇",
						"id": 712527,
						"parentId": 7125
					},
					{
						"countyCode": "712528000",
						"countyName": "长滨乡",
						"id": 712528,
						"parentId": 7125
					},
					{
						"countyCode": "712529000",
						"countyName": "金峰乡",
						"id": 712529,
						"parentId": 7125
					},
					{
						"countyCode": "712530000",
						"countyName": "大武乡",
						"id": 712530,
						"parentId": 7125
					},
					{
						"countyCode": "712531000",
						"countyName": "达仁乡",
						"id": 712531,
						"parentId": 7125
					},
					{
						"countyCode": "712532000",
						"countyName": "太麻里乡",
						"id": 712532,
						"parentId": 7125
					}
				]
			},
			{
				"cityCode": "712600000",
				"cityName": "花莲县",
				"id": 7126,
				"parentId": 71,
				"children": [{
						"countyCode": "712615000",
						"countyName": "花莲市",
						"id": 712615,
						"parentId": 7126
					},
					{
						"countyCode": "712616000",
						"countyName": "新城乡",
						"id": 712616,
						"parentId": 7126
					},
					{
						"countyCode": "712618000",
						"countyName": "秀林乡",
						"id": 712618,
						"parentId": 7126
					},
					{
						"countyCode": "712619000",
						"countyName": "吉安乡",
						"id": 712619,
						"parentId": 7126
					},
					{
						"countyCode": "712620000",
						"countyName": "寿丰乡",
						"id": 712620,
						"parentId": 7126
					},
					{
						"countyCode": "712621000",
						"countyName": "凤林镇",
						"id": 712621,
						"parentId": 7126
					},
					{
						"countyCode": "712622000",
						"countyName": "光复乡",
						"id": 712622,
						"parentId": 7126
					},
					{
						"countyCode": "712623000",
						"countyName": "丰滨乡",
						"id": 712623,
						"parentId": 7126
					},
					{
						"countyCode": "712624000",
						"countyName": "瑞穗乡",
						"id": 712624,
						"parentId": 7126
					},
					{
						"countyCode": "712625000",
						"countyName": "万荣乡",
						"id": 712625,
						"parentId": 7126
					},
					{
						"countyCode": "712626000",
						"countyName": "玉里镇",
						"id": 712626,
						"parentId": 7126
					},
					{
						"countyCode": "712627000",
						"countyName": "卓溪乡",
						"id": 712627,
						"parentId": 7126
					},
					{
						"countyCode": "712628000",
						"countyName": "富里乡",
						"id": 712628,
						"parentId": 7126
					}
				]
			},
			{
				"cityCode": "712700000",
				"cityName": "澎湖县",
				"id": 7127,
				"parentId": 71,
				"children": [{
						"countyCode": "712707000",
						"countyName": "马公市",
						"id": 712707,
						"parentId": 7127
					},
					{
						"countyCode": "712708000",
						"countyName": "西屿乡",
						"id": 712708,
						"parentId": 7127
					},
					{
						"countyCode": "712709000",
						"countyName": "望安乡",
						"id": 712709,
						"parentId": 7127
					},
					{
						"countyCode": "712710000",
						"countyName": "七美乡",
						"id": 712710,
						"parentId": 7127
					},
					{
						"countyCode": "712711000",
						"countyName": "白沙乡",
						"id": 712711,
						"parentId": 7127
					},
					{
						"countyCode": "712712000",
						"countyName": "湖西乡",
						"id": 712712,
						"parentId": 7127
					}
				]
			}
		]
	},
	{
		"provinceCode": "810000000",
		"provinceName": "香港特别行政区",
		"id": 81,
		"parentId": 0,
		"children": [{
			"cityCode": "810000000",
			"cityName": "香港特别行政区",
			"id": 8100,
			"parentId": 81,
			"children": [{
				"countyCode": "810000000",
				"countyName": "香港特别行政区",
				"id": 810000,
				"parentId": 8100
			}]
		}]
	},
	{
		"provinceCode": "820000000",
		"provinceName": "澳门特别行政区",
		"id": 82,
		"parentId": 0,
		"children": [{
			"cityCode": "820000000",
			"cityName": "澳门特别行政区",
			"id": 8200,
			"parentId": 82,
			"children": [{
				"countyCode": "820000000",
				"countyName": "澳门特别行政区",
				"id": 820000,
				"parentId": 8200
			}]
		}]
	}
]
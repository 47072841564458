<template>
  <span v-if="type != 'renderCell'">
    <vxe-select v-model="value" multiple :optionProps="optionProps" :placeholder="props.placeholder" :clearable="props.clearable" :options="options" @change="getTreeNode"></vxe-select>
  </span>
  <span v-else>
    {{ roleNames }}
  </span>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'VxeSelectRole',
  props: {
    renderOpts: Object,
    params: Object,
    type: String
  },
  data() {
    return {
      optionProps: {
        label: 'name',
        value: 'id'
      },
      value: '',
      options: [],
      ...this.renderOpts
    }
  },
  computed: {
    roleNames() {
      let { row } = this.params
      if (this.type == 'renderCell') {
        return row.roleNames?.length ? row.roleNames.toString() : ''
      } else {
        return ''
      }
    }
  },
  watch: {
    type: {
      immediate: true,
      handler(val) {
        if (val != 'renderCell') {
          this.getRoleLists()
        }
      }
    },
    'params.data.organizationId': {
      immediate: true,
      handler(val) {
        if (val && this.type == 'renderItemContent') {
          this.value = []
          this.getRoleLists()
        }
      }
    }
  },
  created() {
    let { row, column, data, property } = this.params
    this.value = this.type == 'renderItemContent' ? data[property] : row[column.property]
  },
  methods: {
    ...mapActions(['findByEventService']),
    // 获取数据
    getRoleLists() {
      let searchData = this.props?.searchData || {}
      let { row, data } = this.params
      let organizationId = this.type == 'renderItemContent' ? data['organizationId'] : row['organizationId']
      this.findByEventService({
        service: '/sys/sysRole',
        params: {
          organizationId: organizationId,
          ...searchData
        }
      }).then(({ code, data }) => {
        if (code == 200) {
          this.options = data
        }
      })
    },

    // 获取节点
    getTreeNode({ value }) {
      let { row, column, data, property } = this.params
      this.type == 'renderItemContent' ? (data[property] = value) : (row[column.property] = value)
      // 触发外部事件
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, value)
        })
      }
      this.$refs.xDown && this.$refs.xDown.hidePanel()
    }
  }
}
</script>

<style></style>

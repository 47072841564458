<template>
  <div class="wrapper" v-loading="loading">
    <!-- 查询功能 -->
    <div class="search" v-if="searchOptions.items.length">
      <vxe-form v-bind="searchOptions" @submit="searchEvent" @reset="searchResetEvent"></vxe-form>
    </div>
    <!-- 表格数据 -->
    <div class="table">
      <vxe-grid ref="xGrid" height="auto" v-bind="gridOptions" :pager-config="tablePage" :data="tableData" @toolbar-button-click="toolbarCustomEvent" @toolbar-tool-click="toolbarToolEvent" @menu-click="contextMenuClickEvent" @page-change="pageChange" v-on="gridEvents"></vxe-grid>
    </div>

    <!-- 新增、编辑功能 -->
    <vxe-modal ref="xModal" v-bind="formOptions" :title="formData.id ? $t('editAndSave') : $t('createAndSave')" @close="closeEvent" show-zoom>
      <template v-slot>
        <vxe-form ref="xForm" v-bind="formOptions" :data="formData" v-loading="loading" @submit="submitEvent" @reset="closeModal(false)">
        </vxe-form>
      </template>
    </vxe-modal>

    <!-- 审核功能 -->
    <vxe-modal ref="Audit" title="审核" width="800" show-zoom>
      <template v-slot>
        <Audit :dictionary="dictionary" :selectRowIds="selectRowIds"></Audit>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useRoute } from "vue-router";
export default {
  name: "VxeBasicTable",
  props: {
    service: {
      type: String,
      default: () => {
        const Route = useRoute();
        return Route.meta.service;
      },
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          value: "code",
          label: "name",
        };
      },
    },
    gridOptions: {
      type: Object,
      default: () => {
        return {
          columns: [],
          data: {},
        };
      },
    },
    formOptions: {
      type: Object,
      default: () => {
        return {
          width: "1000",
          titleWidth: "100",
          items: [],
          data: {},
        };
      },
    },
    searchOptions: {
      type: Object,
      default: () => {
        return {
          items: [],
          data: {},
        };
      },
    },
    // 是否默认加载数据 FALSE 为默认加载,TRUE 为外界触发加载
    defaultTrigger: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data() {
    return {
      loading: false,
      dictionary: {},
      tableData: [],
      originData: [],
      otherData: {},
      formData: {},
      gridEvents: {},
      currType: "edit",
      // 分页的参数配置
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      // 业务字段
      auditType: null,
      selectRowIds: [],
    };
  },
  created() {
    let { dataType, editRules, pagerConfig, mouseConfig, keyboardConfig, columns } = this.gridOptions;
    let { rules, items } = this.formOptions;

    // 分页信息
    if (pagerConfig) this.tablePage = pagerConfig;

    // 键盘和鼠标事件
    if (dataType) {
      if (!mouseConfig) this.gridOptions.mouseConfig = {};
      if (!keyboardConfig) this.gridOptions.keyboardConfig = {};
    }

    // 根据新增功能中的字段增加表格中的校验
    this.gridOptions.editRules = editRules || rules;
    // 动态拼接表单的功能按钮
    let formBtns = {
      align: "center",
      span: 24,
      itemRender: {
        name: "$buttons",
        defaultDisabled: true,
        children: [{
            props: {
              name: "submit",
              type: "submit",
              content: "保存",
              status: "primary",
            },
          },
          // {
          //   props: {
          //     name: "saveAndSubmit",
          //     type: "submit",
          //     content: "保存并提交",
          //     status: "primary",
          //   },
          // },
          {
            props: {
              name: "reset",
              type: "reset",
              content: "取消",
            },
          },
        ],
      },
    };

    if (items.length && items[items.length - 1].itemRender.name != "$buttons") {
      items.push(formBtns);
    }

    this.gridEvents = {
      "checkbox-change": () => {
        this.buttonsEvent();
      },
      "checkbox-all": () => {
        this.buttonsEvent();
      },
      ...this.gridOptions.events,
    };

    // 获取当前页面所需要的数据字典key
    if (this.defaultTrigger) {
      this.getSysDictEvent();
    }
  },
  computed:{
    ...mapGetters(['tMap'])
  },
  methods: {
    ...mapActions([
      "getSysDictService",
      "getListService",
      "removeEventService",
      "submitService",
      "submitBatchService",
      "cancelReportService",
      "batchOperateService",
      "queryEventService",
      "importAllEventService",
      "exportAllEventService",
      "getStatisticsService",
    ]),

    // 动态获取字典key
    getTypeCodeEvent(dicts, columns) {
      columns.forEach((item) => {
        let render = item.editRender || item.itemRender || item.cellRender;
        if (
          render &&
          render.optionTypeCode &&
          dicts.indexOf(render.optionTypeCode) == -1
        ) {
          dicts.push(render.optionTypeCode);
        }
        if (item.children && item.children.length) {
          this.getTypeCodeEvent(dicts, item.children);
        }
      });
      return dicts;
    },

    // 获取数据字典
    getSysDictEvent() {
      let dictTypeCodes = this.getTypeCodeEvent(
        ["auditStatus"],
        [
          ...this.gridOptions.columns,
          ...this.formOptions.items,
          ...this.searchOptions.items,
        ]
      );
      if (!dictTypeCodes.length) {
        this.getLists();
      } else {
        this.getSysDictService({
          params: dictTypeCodes,
        }).then(({ code, data }) => {
          if (code == 200) {
            this.dictionary = data;
            if (this.gridOptions.dictionaryKey) {
              this.gridOptions.data = this.$Tools.setDefaultDataEvent(data[this.gridOptions.dictionaryKey]);
            }
            this.$Tools.buildDataByDicts(this.gridOptions.columns, data, this.defaultProps);
            this.$Tools.buildDataByDicts(this.formOptions.items, data, this.defaultProps);
            this.$Tools.buildDataByDicts(this.searchOptions.items, data, this.defaultProps);
            this.getLists();
          }
        });
      }
    },

    // 获取数据
    getLists() {
      this.loading = true;
      let records = this.gridOptions.data || [];
      this.getListService({
          service: this.service,
          tablePage: this.tablePage,
          params: this.searchOptions.data || {},
        })
        .then(({ code, data, otherData }) => {
          if (code == 200) {
            this.otherData = otherData;
            if (data && data.records.length) {
              records = data.records;
            }
            this.tableData = this.originData = records;
            this.tablePage.total = (data && data.total) || 0;
            // 表格合并功能
            if (this.gridOptions.mergeCells) {
              this.gridOptions.mergeCells = [...this.gridOptions.mergeCells];
            }
            this.$refs.xGrid.clearCheckboxRow()
            this.buttonsEvent();
          }
          this.resetEvent();
        })
        .catch(() => {
          this.resetEvent();
        });
    },

    // 分页功能
    pageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.getLists();
    },

    // 按钮级权限控制
    buttonsEvent() {
      let records = this.$refs.xGrid.getCheckboxRecords();
      let statusLists = [];
      records.forEach((row) => {
        if (!this.$XEUtils.includes(statusLists, row.status)) {
          statusLists.push(row.status);
        }
      });
      let { toolbarConfig } = this.gridOptions;
      toolbarConfig.buttons = toolbarConfig.buttons || [{
          code: "create",
          name: "新增",
          status: "primary",
        },
        {
          code: "batchSubmit",
          name: "批量提交",
          status: "primary",
          disableValues: [0, 30],
        },
        {
          code: "batchCancelSubmit",
          name: "取消提交",
          status: "primary",
          disableValues: [10],
        },
        {
          code: "batchAudit",
          name: "批量审核",
          status: "primary",
          disableValues: [10, 20, 25],
        },
        {
          code: "businessBatchDelete",
          name: "批量删除",
          status: "danger",
          disableValues: [0, 30],
        },
        {
          code: "batchDelete",
          name: "批量删除",
          status: "danger",
        },
      ];

      // 按钮级权限控制
      toolbarConfig.buttons.map((btn) => {
        if (btn.eventName == "customEvent") {
          btn.visible = true;
          return;
        }
        // 特殊梳理 按钮disable
        if (btn.disableValues) {
          let disableStatus = 0;
          statusLists.forEach((statusItem) => {
            if (btn.disableValues.includes(statusItem) && disableStatus != 1) {
              disableStatus = 2;
            } else {
              disableStatus = 1;
            }
          });
          btn.disabled = disableStatus == 2 ? false : true;
        }
        let node = this.$route.meta.buttons.find((item) => item.code === `${this.$route.name}.${btn.code}`);
        if (node && (!btn.params)) {
          btn.name = node.name || btn.name;
          btn.visible = true;
        } else {
          btn.visible = false;
        }
      });
    },

    // 列表按钮组功能通用事件
    toolbarCustomEvent({ code, button }) {
      if (button.events) {
        Object.keys(button.events).forEach((key, index) => {
          if (index == 0) button.events[key](button);
        });
      } else {
        switch (code) {
          // 新增
          case "create":
            this.insertEvent();
            break;
            // 批量删除
          case "batchDelete":
            this.batchDeleteEvent();
            break;
            // 批量保存
          case "batchSave":
            this.batchSaveEvent("0");
            break;
            // 批量保存并提交
          case "batchSaveSubmit":
            this.batchSaveEvent("10");
            break;
          default:
            if (button.events) {
              Object.keys(button.events).forEach((key, index) => {
                if (index == 0) button.events[key](code);
              });
            }
            break;
        }
      }
    },
    // 列表按钮组功能通用事件
    toolbarToolEvent({ code, tool }) {
      switch (code) {
        case "downloadTemplate":
          this.downloadTemplateEvent(tool);
          break;
        case "importAll":
          this.importAllEvent();
          break;
        case "exportAll":
          this.customExportAllEvent(tool);
          break;
        default:
          if (tool.events) {
            Object.keys(tool.events).forEach((key, index) => {
              if (index == 0) tool.events[key](code);
            });
          }
          break;
      }
    },

    // 导出模板
    downloadTemplateEvent(params) {},

    // 批量导出
    customExportAllEvent(tool) {
      if (!this.fillInfoData.id) {
        this.$message.error("数据不存在，无法导出");
        return;
      }
      this.exportAllEventService({
        service: this.service,
        params: { id: this.fillInfoData.id },
      }).then(({ status, headers, data }) => {
        if (status != 200 || !data) {
          this.$message({
            type: "error",
            message: "导出失败！",
          });
        } else {
          let blob = new Blob([data], {
            type: "application/msexcel;charset=utf-8",
          });

          //设置导出的文件名)
          let downloadFilename = decodeURIComponent(
            headers["content-disposition"].split(";")[1].split("filename=")[1]
          );

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //兼容ie浏览器
            window.navigator.msSaveOrOpenBlob(blob, downloadFilename);
          } else {
            //谷歌,火狐等浏览器
            let url = window.URL.createObjectURL(blob);
            let downloadElement = document.createElement("a");
            downloadElement.style.display = "none";
            downloadElement.href = url;
            downloadElement.download = downloadFilename;
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(url);
          }
          this.$message({
            type: "success",
            message: "导出成功！",
          });
        }
      });
    },

    // 表格鼠标右键的功能
    contextMenuClickEvent({ menu, row, column }) {
      // const $xGrid = this.$refs.xGrid;
      // switch (menu.code) {
      //   case "refresh":
      //     this.getLists();
      //     break;
      //   case "exportData":
      //     this.exportAllEvent();
      //     break;
      //   default:
      //     break;
      // }
    },

    // 新增事件
    insertEvent() {
      this.formData = {
        ...this.formOptions.data,
      };
      this.$Tools.enabledAllEvent(this.formOptions.items, false);
      this.$refs.xModal.open();
    },

    // 编辑事件
    queryEvent(row, button) {
      this.loading = true;
      this.currType = button.name;
      this.queryEventService({
          service: this.service,
          params: row,
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.formData = data || {};
            if (button.name == "details") {
              this.$Tools.enabledAllEvent(this.formOptions.items, true);
            } else {
              this.$Tools.enabledAllEvent(this.formOptions.items, false);
            }
            this.$refs.xModal.open();
            if (button && button.events) {
              Object.keys(button.events).forEach((key, index) => {
                if (index == 0) button.events[key](data);
              });
            }
          }
          this.resetEvent();
        })
        .catch(() => {
          this.resetEvent();
        });
    },

    // 保存提交事件
    async submitEvent(row, button) {
      if (row.data) {
        this.$refs["xForm"].validate((valid) => {
          if (!valid) {
            this.submitData({
              ...row.data,
              status: row.$event.submitter.name == "saveAndSubmit" ? 10 : 0,
            });
          }
        });
      } else {
        const errMap = await this.$refs.xGrid
          .validate(row)
          .catch((errMap) => errMap);
        if (errMap) {
          this.$message.error("请完善数据内容");
        } else {
          this.$refs.xGrid.clearActived().then(() => {
            this.submitData(row);
          });
        }
      }
    },

    // 提交数据
    submitData(row, service) {
      if (this.loading) return;
      this.loading = true;
      this.submitService({
          service: service || this.service,
          params: row,
        })
        .then(({ code }) => {
          if (code == 200) {
            this.getLists();
            this.$message.success(row.id ? "更新成功" : "保存成功");
            this.closeModal();
          } else {
            this.$refs.xGrid.setActiveRow(row);
          }
          this.resetEvent();
        })
        .catch(() => {
          this.resetEvent();
        });
    },

    // 审核事件
    auditEvent(row, btn) {
      if (btn.code == "batchAudit" && (this.gridOptions.dataType == "single" || this.gridOptions.dataType == "batchSave")) {
        this.selectRowIds = [this.fillInfoData.id];
        this.$refs.Audit.open();
      } else if (btn.code == "batchAudit" && !this.gridOptions.dataType) {
        this.selectRowIds = row;
        this.$refs.Audit.open();
      } else if (btn.name == "audit") {
        this.selectRowIds = row;
        this.$refs.Audit.open();
      } else {
        this.$message.error("审核数据不存在或状态已改变，无法执行此操作！");
      }
      this.loading = false;
    },

    // 业务删除事件
    businessRemoveEvent(row) {
      this.$confirm(this.$t("isDelete"), this.$t("confirmTitle"), {
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("cancel"),
      }).then((type) => {
        if (type === "confirm") {
          this.loading = true;
          this.batchOperateService({
              service: this.service,
              params: {
                businessIds: [row.id],
                submitType: "delete",
                tableName: this.$route.meta.tableName,
              },
            })
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("操作成功");
                this.getLists();
                this.resetEvent();
              }
              this.resetEvent();
            })
            .catch(() => {
              this.resetEvent();
            });
        }
      });
    },

    // 取消事件
    cancelEvent() {
      this.$refs.xGrid.clearActived().then(() => {
        this.$refs.xGrid.revertData();
      });
    },

    // 搜索功能
    searchEvent() {
      this.getLists();
    },

    // 搜索重置功能
    searchResetEvent({ data }) {
      if (data.companyId && !this.gridOptions.dataType) {
        data.companyId = ''
      }
      this.getLists();
    },

    // 关闭模态对话框
    closeModal(bool) {
      if (bool) this.getLists();
      if (this.$refs.xForm) {
        this.$refs.xForm.reset();
      }
      if (this.$refs.xModal) {
        this.$refs.xModal.close();
      }
      this.tMap?.destroy()
    },

    //点击模态框关闭按钮事件
    closeEvent() {
      this.tMap?.destroy()
    },

    // 批量删除
    batchDeleteEvent() {
      let records = this.$refs.xGrid.getCheckboxRecords();
      if (!records.length) {
        this.$message.error(this.$t("pleaseSelectDataToDelete"));
      } else {
        let ids = [];
        records.forEach((item) => {
          ids.push(item.id);
        });
        this.removeEvent(ids);
      }
    },

    // 删除事件
    removeEvent(ids) {
      this.$confirm(this.$t("isDelete"), this.$t("confirmTitle"), {
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("cancel"),
      }).then((type) => {
        if (type === "confirm") {
          this.loading = true;
          this.removeEventService({
              service: this.service,
              params: ids,
            })
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(this.$t("deleteSuccess"));
                this.getLists();
                this.resetEvent();
              }
              this.resetEvent();
            })
            .catch(() => {
              this.resetEvent();
            });
        }
      });
    },

    resetEvent() {
      this.loading = false;
    },

    // 批量导入
    importAllEvent(params) {
      this.$confirm(
        "是否导入当前数据，数据覆盖后，无法撤回，请谨慎操作！",
        this.$t("confirmTitle"), {
          confirmButtonText: this.$t("ok"),
          cancelButtonText: this.$t("cancel"),
        }
      ).then((type) => {
        if (type === "confirm") {
          this.importAllEventService({
            service: this.service,
            params,
          }).then(({ code, message }) => {
            if (code == 200) {
              this.getLists();
              this.$message.success("数据导入成功");
            }
          });
        }
      });
    },
    // 前端导入功能
    // importAllEvent(service, { communityId, communityName, organizationId }) {
    //   this.$refs.xGrid
    //     .readFile({
    //       types: ["xls", "xlsx"],
    //     })
    //     .then((params) => {
    //       this.loading = true;
    //       const { file } = params;
    //       // 开始导入
    //       this.importAllEventService({
    //           service: service,
    //           communityId,
    //           communityName,
    //           organizationId,
    //           file,
    //         })
    //         .then(({ data }) => {
    //           if (data.code == 200) {
    //             // 自定义导入，将导入失败的数据进行导出
    //             this.customExportEvent(data.data || []);
    //           } else {
    //             this.$message.error(data.message);
    //           }
    //           this.loading = false;
    //         })
    //         .catch((err) => {
    //           this.loading = false;
    //           this.$message.error(err.message);
    //         });
    //     });
    // },
    // 自定义导出
    customExportEvent(params) {
      this.$refs.xGrid.exportData({
        type: "xlsx",
        filename: "导出失败的数据",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

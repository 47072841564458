<template>
  <span v-if="type != 'renderCell'">
    <vxe-select v-model="value" multiple :optionProps="optionProps" v-bind="props" :options="options" @change="getTreeNode"></vxe-select>
  </span>
  <template v-else>
    <el-tag v-for="(item, index) in tabOptions" :key="index" class="tags">{{ item.cookingStyleName }}</el-tag>
  </template>
</template>

<script>
export default {
  name: 'VxeSelectCookingStyle',
  props: {
    renderOpts: Object,
    params: Object,
    type: String
  },
  data() {
    return {
      optionProps: {
        label: 'name',
        value: 'id'
      },
      options: [],
      ...this.renderOpts
    }
  },
  computed: {
    value() {
      let { row, column, data, property } = this.params
      let options = this.type == 'renderItemContent' ? data[property] : row[column.property] || [];
      let ids = [];
      options.forEach(item => {
        ids.push(item.cookingStyleId)
      });
      return ids || []
    },
    tabOptions() {
      let { row, column, data, property } = this.params
      return this.type == 'renderItemContent' ? data[property] : row[column.property]
    }
  },
  created() {

    // let { row, column, data, property } = this.params
    // this.valueAll = this.type == 'renderItemContent' ? data[property] : row[column.property]
    // if (this.type != 'renderCell') {
    //   let value = []
    //   this.valueAll?.forEach((item) => value.push(item.cookingStyleId))
    //   this.value = value
    // }
  },
  methods: {
    getTreeNode({ value }) {
      let options = [];
      value.forEach(id => {
        options.push(this.$XEUtils.find(this.options, item => item.id == id));
      });
      // 触发外部事件
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, options)
        })
      }
      this.$refs.xDown && this.$refs.xDown.hidePanel()
    }
  }
}
</script>

<style lang="scss" scoped>
.tags {
  margin-left: 2px;

  &:first-child {
    margin-left: 0;
  }
}
</style>

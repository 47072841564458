<template>
  <!-- 审核功能 -->
  <vxe-form ref="xForm" v-bind="formOptions" @submit="confirmEvent" @reset="cancelEvent"></vxe-form>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "Audit",
  props: ["dictionary", "selectRowIds"],
  data() {
    return {
      formOptions: {
        // 新增功能字段配置
        width: 400,
        height: "80%",
        titleWidth: 100,
        items: [{
            field: "status",
            title: "审核状态",
            span: 24,
            itemRender: {
              name: "$select",
              optionTypeCode: "auditStatus",
              props: {
                placeholder: "请选择审核状态",
              },
              events: {
                change: ({ $form, data, property }) => {
                  this.formOptions.rules["approveIdea"][0].required = data[property] == 0 ? false : true;
                }
              }
            },
          },
          {
            field: "approveIdea",
            title: "审批意见",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                placeholder: "请输入审批意见",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "确定",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        data: {
          status: "",
          approveIdea:"",
          orderNo:""
        },
        //表单校验规则
        rules: {
          status: [{ required: true, message: "请选择审核状态" }],
          approveIdea: [{ required: false, message: "请输入审核意见" }],
        },
      },
    }
  },
  created() {
    this.formOptions.items = [...this.$Tools.buildDataByDicts(this.formOptions.items, this.dictionary)];
  },
  methods: {
    ...mapActions(['batchOperateService']),

    confirmEvent({ data }) {
      const { id, orderNo } = this.selectRowIds
      // const businessOutApprove = {
        
      // }
      this.batchOperateService({
          params: {
            // id: this.selectRowIds.length ? this.selectRowIds : id,
             ...data,
            orderNo,
            type1:2
          },
        })
        .then((res) => {
          if (res.code == 200) {
            if (data.auditStatus == "0") {
              this.$message.success("已驳回");
            } else {
              this.$message.success("审核成功！");
            }
            this.$parent.$parent.getLists();
            this.cancelEvent();
          }
        })
    },
    cancelEvent() {
      this.$parent.close();
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
<vxe-button :status="item.props.status" :content="$t(item.props.content)" v-for="(item, index) in defaultBtns" :key="index" @click="events(item.props)">
</vxe-button>
</template>

<script>
export default {
  name: "VxeFormButtons",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  computed: {
    defaultBtns() {
      return this.renderOpts.children || [{
          props: {
            name: "save",
            type: "submit",
            content: "save",
            status: "primary",
          },
        },
        {
          props: {
            name: "saveAndSubmit",
            type: "submit",
            content: "saveAndSubmit",
            status: "primary",
          },
        },
        {
          props: {
            name: "cancel",
            type: "reset",
            content: "cancel",
          },
        },
      ];
    },
  },
  data() {
    return {
      ...this.renderOpts
    }
  },
  created(){
    console.log(this.params)
  },
  methods: {
    events(data) {
      console.log(this.$parent.$parent.$parent)
      // this.$parent.$parent.$parent.$parent.submitEvent()
    }
  }
}
</script>

<style>

</style>

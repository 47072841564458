<template>
  <div v-if="type != 'renderCell'">
    <el-cascader size="large" class="profile-cascader" :options="options" v-model="selectedOptions" @change="handleChange" :disabled="props.disabled" :placeholder="props.placeholder" />
  </div>
  <div v-else>{{ CodeToText[selectedOptions[0]] }}{{ CodeToText[selectedOptions[1]] }}{{ CodeToText[selectedOptions[2]] }}</div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
export default {
  name: 'VxeCitySelect',
  props: {
    renderOpts: Object,
    params: Object,
    type: String
  },
  data() {
    return {
      ...this.renderOpts,
      flag: true,
      options: regionData,
      selectedOptions: [],
      CodeToText: CodeToText,
      TextToCode: TextToCode
    }
  },
  created() {
    let { row, data } = this.params
    if (this.type === 'renderItemContent') {
      this.selectedOptions = [data[this.props.provinceCode], data[this.props.cityCode], data[this.props.areaCode]]
    } else {
      this.selectedOptions = [row[this.props.provinceCode], row[this.props.cityCode], row[this.props.areaCode]]
    }
  },
  methods: {
    handleChange() {
      let { row, data } = this.params

      if (this.type === 'renderItemContent') {
        data.provinceCode = this.selectedOptions[0]
        data.cityCode = this.selectedOptions[1]
        data.areaCode = this.selectedOptions[2]
      } else {
        row.provinceCode = this.selectedOptions[0]
        row.cityCode = this.selectedOptions[1]
        row.areaCode = this.selectedOptions[2]
      }

      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index === 0) this.events[key](this.params, { code: this.selectedOptions, name: [this.CodeToText[this.selectedOptions[0]], this.CodeToText[this.selectedOptions[1]], CodeToText[this.selectedOptions[2]]] })
        })
      }
    }
  }
}
</script>

<style scoped></style>

<template>
  <el-select v-model="selectValue"
             :filterable="filterable"
             :remote="remote"
             :clearable="clearable"
             :filter-method="filterMethod"
             :remote-method="remoteMethod"
             :placeholder="placeholder"
             :loading="loading"
             @clear="onClear"
             v-if="type != 'renderCell'">
    <el-option v-for="item in list"
               :key="item[optionProps.value]"
               :value="item[optionProps.value]"
               @click="onSelect(item)">
      {{item[optionProps.label]}}
    </el-option>
  </el-select>
  <span v-else>{{selectLabel}}</span>
</template>

<script>

import { mapActions } from 'vuex'
export default {
  name: 'SearchSelect',
  props: {
    params: Object,
    type: String,
    renderOpts: {
      type: Object,
      default: () => {
        return {
          clearable: true,
          remote: false,
          service: '',
          filterable: false,
          placeholder: '',
          associatedFields: [],
          optionProps: {
            label: 'label',
            value: 'id',
          }
        }

      },
    },
  },
  data () {
    return {
      selectValue: '',
      orgList: [],
      list: [],
      loading: false,
      ...this.renderOpts,
    }
  },
  create () {
    this.initSearchSelect()
  },
  methods: {
    ...mapActions(['findByEventService']),
    initSearchSelect () {
      this.getSelectList()
      let { row, column, data, property } = this.params;
      this.selectValue = this.type == "renderItemContent" ? data[property] : row[column.property];
    },
    filterMethod (keyWords) {
      if (keyWords) {
        this.list = this.orgList.filter((item) => {
          if (!!~item[this.optionProps.label].indexOf(keyWords) || !!~item[this.optionProps.label].toUpperCase().indexOf(keyWords.toUpperCase())) {
            return true
          }
        })
      } else {
        this.list = this.orgList;
      }

    },
    remoteMethod (query) {
      if (query) {
        this.loading = true
        this.getSelectList(query)
      } else {
        this.list = []
      }
    },
    getSelectList (query = '') {
      this.findByEventService({ service: this.service, params: { keyWords: query } }).then(res => {
        if (res.code == 200) {
          this.orgList = res.data
          this.list = res.data
          this.loading = false
        }
      })
    },
    onSelect (itemData) {
      let { row, column, data, property } = this.params;
      this.type == "renderItemContent" ? data[property] = itemData[this.optionProps.value] : row[column.property] = itemData[this.optionProps.value];
      if (this.associatedFields && this.associatedFields.length > 0) {
        this.associatedFields.forEach(field => {
          this.type == "renderItemContent" ? data[field] = itemData[field] : row[column.field] = itemData[field];
        })
      }
    },
    onClear () {
      let { row, column, data, property } = this.params;
      if (this.associatedFields && this.associatedFields.length > 0) {
        this.associatedFields.forEach(field => {
          this.type == "renderItemContent" ? data[field] = '' : row[column.field] = '';
        })
      }
    }
  },
  computed: {
    selectLabel () {
      let { row } = this.params;
      if (this.type == "renderCell") {
        return row[this.optionProps.label].length ? row[this.optionProps.label].toString() : "";
      }
      return ''
    }
  },
  watch: {
    type: {
      immediate: true,
      handler (val) {
        if (val != "renderCell") {
          this.initSearchSelect();
        }
      }
    },
    renderOpts: {
      handler (val) {
        if (val && this.type == "renderItemContent") {
          this.initSearchSelect();
        }
      },
      deep: true
    }
  }
}
</script>
module.exports = {
  wellCome: "wellCome",
  confirmTitle: "confirmTitle",
  createAndSave: "createAndSave",
  editAndSave: "editAndSave",
  save: "save",
  ok: "ok",
  cancel: "cancel",
  isDelete: "isDelete?",
  pleaseSelectDataToDelete: "pleaseSelectDataToDelete",
  deleteSuccess: "deleteSuccess",
};
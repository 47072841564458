<template>
  <!-- 表格数据 -->
  <vxe-grid ref="xGrid" v-bind="gridOptions" :loading="loading" :data="tableData" @toolbar-button-click="toolbarCustomEvent"></vxe-grid>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "VxeCommonTable",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      ...this.renderOpts,
      gridOptions: {
        height: 300,
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              eventName: "customEvent",
              disabled: this.renderOpts.props.disabled || false,
              events: {
                click: () => {
                  this.insertEvent();
                }
              }
            },
            // {
            //   code: "batchDelete",
            //   name: "批量删除",
            //   status: "danger",
            //   eventName: "customEvent",
            //   disabled: this.renderOpts.props.disabled || false,
            //   events: {
            //     click: () => {
            //       let records = this.$refs.xGrid.getCheckboxRecords();
            //       if (!records.length) {
            //         this.$message.error(this.$t("pleaseSelectDataToDelete"));
            //       } else {
            //         this.removeEvent(records)
            //       }
            //     }
            //   }
            // },
          ],
          zoom: false,
          custom: false,
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "fullName",
            title: "姓名",
            minWidth: 120,
            editRender: {
              name: '$input',
              props: {
                placeholder: "请输入姓名",
              }
            }
          },
          {
            field: "gender",
            title: "性别",
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "sex",
              props: {
                placeholder: "请选择性别",
              }
            }
          },
          {
            field: "idCard",
            title: "身份证号",
            minWidth: 160,
            editRender: {
              name: '$input',
              immediate: true,
              props: {
                placeholder: "请输入身份证号",
              }
            }
          },
          {
            fixed: "right",
            title: "操作",
            width: 120,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                status: "danger",
                label: "删除",
                eventName: "customEvent",
                disabled: this.renderOpts.props.disabled || false,
                events: {
                  click: (row) => {
                    this.removeEvent(row)
                  }
                }
              }]
            },
          },
        ],
        editRules: {},
        pagerConfig: {
          enabled: false
        },
        editConfig: {}
      },
    }
  },
  created() {
    let { data } = this.params;
    if (data.records && data.records.length) {
      this.tableData = data.records;
    }
    this.getSysDictEvent();
  },
  methods: {
    ...mapActions([
      "getSysDictService",
    ]),

    // 获取数据字典
    getSysDictEvent() {
      this.getSysDictService({ params: ["sex"] }).then(({ code, data }) => {
        if (code == 200) {
          this.$Tools.buildDataByDicts(this.gridOptions.columns, data);
        }
      });
    },

    // 列表按钮组功能通用事件
    toolbarCustomEvent({ button }) {
      if (button.events) {
        Object.keys(button.events).forEach((key, index) => {
          if (index == 0) button.events[key](button);
        });
      }
    },

    insertEvent() {
      const $xGrid = this.$refs.xGrid;
      const newRow = {
        fullName: "",
        gender: '1',
        idCard: ""
      }
      $xGrid.insert(newRow).then(({ row }) => $xGrid.setActiveRow(row));
    },

    // 保存提交事件
    submitEvent(row) {
      this.tableData.push(row)
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.tableData);
        });
      }
      this.$refs.xGrid.clearActived();
    },

    // 删除
    removeEvent(row) {
      this.$XEUtils.remove(this.tableData, item => item._X_ROW_KEY === row._X_ROW_KEY)
      this.$refs.xGrid.remove(row).then(({ rows }) => {
        if (this.events) {
          Object.keys(this.events).forEach((key, index) => {
            if (index == 0) this.events[key](this.tableData);
          });
        }
      });
    },

    // 取消事件
    cancelEvent() {
      this.$refs.xGrid.clearActived().then(() => {
        this.$refs.xGrid.revertData();
      });
    },

  }
}
</script>

<style lang="scss" scoped>
</style>

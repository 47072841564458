<template>
<vxe-select v-model="value" :disabled="props.disabled" :placeholder="props.placeholder" :options="list || this.getProvince" filterable @change="change"></vxe-select>
</template>

<script>
export default {
  name: "VxeAreaSelect",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      value:"",
      ...this.renderOpts,
      provinc: require('@/utils/Province').default
    }
  },
  watch:{
    'params.data.isTransregional':{
      handler(val) {
        this.value = '';
        this.params.data['provinceProjectLocation'] = "";
      },
      immediate:true
    }
  },
  created() {
    let { row, column, data, property } = this.params;
    this.value = this.type == "renderItemContent" ? data[property] : row[column.property];
  },
  computed:{
    getProvince() {
      let list = [];
      this.provinc.forEach(item => {
        item.children.forEach(k => {
          k.children.forEach(j => {
            if (k.cityName == '市辖区') {
              list.push({
                value: j.countyCode,
                label: `${item.provinceName}${j.countyName}`
              })
            }else{
              list.push({
                value: j.countyCode,
                label: `${item.provinceName}${k.cityName}${j.countyName}`
              })
            }
          })
        })
      })
     return list
    }
  },
  methods: {
    change({value}) {
      const {row, column,data,property} = this.params;
      this.type == 'renderItemContent' ? data[property] = value : row[column.property] = value;
    }
  }
}
</script>

<style  lang='scss' scoped>
</style>

<template>
  <el-upload ref="upload" class="uploadImg" :action="action" :multiple="props.multiple" :disabled="props.disabled" :limit="limit" v-model:file-list="fileList" :headers="headers" list-type="picture-card" :class="{ 'hide': hideUpload }" :before-upload="beforeUpload" :on-success="successEvent" :on-preview="previewEvent" :on-remove="removeEvent" :on-exceed="exceedEvent" v-if="type != 'renderCell'">
    <el-icon>
      <Plus />
    </el-icon>
  </el-upload>

  <div class="smallImg" v-else>
    <img :src="imageUrl" @click="previewEvent({url:imageUrl})" />
  </div>
  <el-dialog :draggable="true" :width="props.previewModelWidth ? props.previewModelWidth : 600" v-model="dialogModal">
    <div class="bigImg">
      <img w-full :src="imageUrl" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "VxeImgUpload",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
    
  },
  computed: {
    limit() {
      return this.props.limit || 1;
    },
    imageUrl() {
      let { row, column, data, property } = this.params;
      if (this.type == "renderCell") {
        return row[column.field] ? `/sys/sysAttachment/preview/${row[column.field]}` : ""
      }
      return data[property] ? `/sys/sysAttachment/preview/${data[property]}` : ""
    }
  },
  data() {
    return {
      action: `/sys/sysAttachment/multiUpload`,
      headers: {
        Authorization: localStorage.token,
      },
      currFile: null,
      fileList: [],
      hideUpload: false,
      dialogModal: false,
      ...this.renderOpts,
    };
  },
  created() {
    const { row, column, data, property } = this.params;
    const values = this.type == "renderItemContent" ? data[property] : row[column.property]
    const fileList = values ? values.split(',') : [];
    this.fileList = fileList.map((item, index) => {
      return {
        name: index,
        url: `/sys/sysAttachment/preview/${item}`,
        attachmentId: item
      }
    })
  },
  methods: {
    // 上传之前
    beforeUpload(file) {
      this.currFile = file;
    },
    // 上传成功
    successEvent({ code, data }) {
      if (code == "200") {
        this.hideUpload = this.fileList.length >= this.limit;
        if (this.events) {
          Object.keys(this.events).forEach((key, index) => {
            if (index == 0) this.events[key](this.params, data[0]);
          });
        }
        this.$message.success("上传成功");
      } else {
        this.$refs.upload.handleRemove(this.currFile)
        this.$message.error("上传失败");
      }
    },
    // 移除
    removeEvent(file) {
      this.hideUpload = this.fileList.length >= this.limit;
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, file);
        });
      }
    },
    // 超过最大限制
    exceedEvent(files) {
      if (files.length > this.limit) {
        this.$message.error(`只能上传【${this.limit}】文件`);
      }
    },
    // 预览
    previewEvent(file) {
      this.dialogModal = true
    },
  },
}
</script>

<style lang="scss">
.uploadImg {
  .el-upload-list {
    min-width: 315px;
  }

  .hide {
    overflow: hidden;

    .el-upload--picture-card {
      display: none;
    }
  }
}

.smallImg {
  padding: 2px;
  overflow: hidden;

  img {
    max-width: 44px;
    max-height: 44px;
  }
}

.bigImg {
  padding: 2px;
  overflow: hidden;

  img {
    width: 100%;
  }
}
</style>

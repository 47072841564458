<template>
  <span v-if="type != 'renderCell'">
    <vxe-pulldown ref="xDown" transfer>
      <template #default>
        <vxe-input v-model="value" suffix-icon="fa fa-search" v-bind="props" @input="inputEvent" @focus="focusEvent" @clear="clearEvent"></vxe-input>
      </template>
      <template #dropdown>
        <div class="dropdown">
          <el-tree ref="tree" v-loading="loading" :data="options" :props='{label: "name", value: "id"}' :default-expand-all="expandAll" :filter-node-method="filterNode" :expand-on-click-node="false" @node-click="getTreeNode"></el-tree>
        </div>
      </template>
    </vxe-pulldown>
  </span>
  <span v-else>
    {{ value }}
  </span>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "VxeSelectEnterprise",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      loading: false,
      options: [],
      defaultProps: {
        id: "companyId"
      },
      ...this.renderOpts,
    }
  },
  computed: {
    value() {
      let { row, column, data, property } = this.params;
      return this.type == "renderItemContent" ? data[property] : row[column.property];
    },
  },
  watch: {
    type(val) {
      if (val == "renderEdit") {
        this.getLists();
      }
    },
    value(val) {
      let { row, column, data, property } = this.params;
      this.type == "renderItemContent" ? data[property] = val : row[column.property] = val;
      if (this.$refs.tree && this.props.filterNode) {
        this.$refs.tree.filter(val);
      }
    },
  },
  created() {
    if (this.type != 'renderCell' && this.service) {
      this.getLists();
    }
  },
  methods: {
    ...mapActions(['getTreeService']),
    // 获取数据
    getLists() {
      this.loading = true;
      this.getTreeService({
        service: this.service,
        params: this.defaultParams ||  {}
      }).then(({ code, data }) => {
        if (code == 200) {
          let arrs = ["/sys/sysAppResource"]
          if (arrs.includes(this.service) || !this.defaultParams || this.defaultParams.level == 1) {
            this.options = this.$XEUtils.toArrayTree(data, { strict: true, parentKey: 'parentId', key: 'id' })
          } else {
            this.options = data
          }
          // this.options = arrs.includes(this.service) ? this.$XEUtils.toArrayTree(data, { strict: true, parentKey: 'parentId', key: 'id' }) : data;
          if (this.options.length && this.props.defaultRender && !this.value) {
            this.getTreeNode(this.options[0])
          }
        }
        this.loading = false;
      });
    },

    focusEvent() {
      this.$refs.xDown.showPanel();
    },

    inputEvent({ value }) {
      let { row, column, data, property } = this.params;
      this.type == "renderItemContent" ? data[property] = value : row[column.property] = value;
    },

    // 节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 获取节点
    getTreeNode(node) {
      let { row, column, data, property } = this.params;
      if (this.type == "renderItemContent") {
        data[property] = node.name;
        data[this.defaultProps.id] = node.id;
      } else {
        row[column.property] = node.name;
        row[this.defaultProps.id] = node.id;
      }
      // 触发外部事件
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, node);
        });
      }
      this.$refs.xDown && this.$refs.xDown.hidePanel();
    },

    clearEvent() {
      let { row, column, data, property } = this.params;
      if (this.type == "renderItemContent") {
        data[property] = null;
        data[this.defaultProps.id] = null;
      } else {
        row[column.property] = null;
        row[this.defaultProps.id] = null;
      }
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, {});
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  width: 100%;
  padding: 5px 0;
  max-height: 200px;
  overflow: auto;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}
</style>

<template>
<el-tag></el-tag>
</template>

<script>
export default {
  name: "VxeTag"
}
</script>

<style scoped>

</style>
<template>
<vxe-radio-group v-model="value" @change="changeEvent" class="radioGroup">
  <vxe-radio-button :label="item.label" :content="item.content" v-for="(item,index) in params.children" :key="index"></vxe-radio-button>
</vxe-radio-group>
</template>

<script>
export default {
  name: "VxeRadiosGroup",
  props: ["params"],
  data() {
    return {
      value: "1"
    }
  },
  methods: {
    changeEvent() {
      this.params.events.change(this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.radioGroup {
  margin-right: 10px;
}
</style>

import Axios from "@/utils/axios";

const state = {};

const actions = {
    // 获取图形验证码服务
    getVerifyCodeService({
        state
    }, {
        params
    }) {
        return Axios.post('/sys/openApi/verifyCode', params)
    },

    // 登录服务
    LoginServe({
        state
    }, {
        params
    }) {
        return Axios.post('/sys/admin/login', params)
    },

    // 注销登录服务接口
    logOutServe({
        state
    }) {
        return Axios.post('/sys/logout')
    },
};

const mutations = {};

export default {
    state,
    actions,
    mutations
};
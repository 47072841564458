<template>
<div class="img">
  <el-image :src="imgUrl" @click="viewImgEvent" v-if="imgUrl"></el-image>

  <vxe-modal ref="xModal" title="预览" width="400" height="500" show-zoom transfer>
    <div class="preview">
      <el-image width="auto" :src="imgUrl" fit="fill"></el-image>
    </div>
  </vxe-modal>
</div>
</template>

<script>
export default {
  name: "VxePreview",
  props: {
    params: Object,
    type: String,
  },
  data() {
    return {
      action: `/sys/sysFile`,
    };
  },
  computed: {
    imgUrl() {
      let { row, column, data, property } = this.params;
      let value = this.type == "renderItemContent" ? data[property] : row[column.property];
      return value ? `${this.action}/getFile/${value}` : ""
    }
  },
  methods: {
    viewImgEvent() {
      this.$refs.xModal.open();
    },
  },
};
</script>

<style>
.img {
  width: 150px;
  overflow: hidden;
}
</style>

<template>
  <span v-if="type != 'renderCell'">
    <vxe-input v-model="value" :placeholder="props.placeholder" maxlength="18" :disabled="props.disabled" :controls="false" @input="inputEvent" @blur="inputEvent"></vxe-input>
  </span>
  <span v-else>
    {{value}}
  </span>
</template>

<script>
export default {
  name: "VxeIDCardInput",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      value: "",
      ...this.renderOpts,
    }
  },
  created() {
    let { row, column, data, property } = this.params;
    this.value = this.type == "renderItemContent" ? data[property] : row[column.property];
    this.setItemRules(this.value);
  },

  methods: {
    setItemRules(value) {
      let { $table, column, $form, item, property } = this.params;
      let itemRules = [
        { required: true, message: "请输入有效的18位身份证编号" },
        { min: 18, max: 18, message: "请输入有效的18位身份证编号" },
        {
          validator: ({ cellValue, itemValue }) => {
            let value = cellValue || itemValue
            if (value && !this.$Tools.regs.IDCard.test(value)) {
              let title = column ? column.title : item.title
              return new Error(`${title}格式不正确，请重新输入!`);
            }
          },
        }
      ]
      let props = column && column.editRender.props || item && item.itemRender.props
      if (this.type == "renderEdit") {
        if (value || props && props.required) {
          $table.props.editRules[column.property] = itemRules;
        } else {
          $table.props.editRules[column.property] = [];
        }
      }
      if (this.type == "renderItemContent") {
        if (value || props && props.required) {
          $form.props.rules[property] = itemRules;
        } else {
          $form.props.rules[property] = [];
        }
      }
    },
    inputEvent({ value }) {
      let { row, column, data, property } = this.params;
      this.type == "renderItemContent" ? data[property] = value : row[column.property] = value;
      // this.setItemRules(value);
    },
  }
};
</script>

export default {
	init:() => {
		// const AK = "O2BBZ-RIIW4-WARU3-FQ5RB-4S7NS-5JFKD";
		// const tMapUrl = `https://map.qq.com/api/gljs?v=1.exp&libraries=tools,service&key=O2BBZ-RIIW4-WARU3-FQ5RB-4S7NS-5JFKD&callback=onMapCallback`;
		return new Promise((resolve,reject) => {
			if(typeof TMap !== "undefined") {
				resolve(window?.TMap)
				return true;
			}

			window.onMapCallback = function() {
				resolve(window?.TMap)
			}

			// let scriptNode = document.createElement("script");
			// scriptNode.setAttribute('type','text/javascript');
			// scriptNode.setAttribute('src', tMapUrl);
			// document.body.appendChild(scriptNode);
		})
	},
	getLocation:() => {
		const qq = window?.qq;
		const map_key = process.env.VUE_APP_MAP_TENCENT_MAP_KEY
		const Geolocation = new qq.maps.Geolocation(map_key,'餐废回收管理系统')
		return new Promise((resolve, reject) => {
			Geolocation.getIpLocation(function(position) {
				resolve(position)
			},function(err) {
				console.log(err)
				reject('定位失败')
			},)
		})
	},
}
<template>
<div class="tree">
  <span>
    <vxe-pulldown ref="xDown" transfer>
      <template #default>
        <vxe-input v-model="orgValue" suffix-icon="fa fa-search" readonly placeholder="请选择公司" @focus="focusEvent" :clearable="false"></vxe-input>
      </template>
      <template #dropdown>
        <div class="dropdown">
          <el-tree ref="orgTree" :data="orgLists" :props="defaultProps" default-expand-all :expand-on-click-node="false" v-loading="loading" @node-click="getTreeNode"></el-tree>
        </div>
      </template>
    </vxe-pulldown>
  </span>
  <span>
    <vxe-input v-model="value" :placeholder="placeholder"></vxe-input>
  </span>
  <el-tree ref="tree" :data="communityLists" accordion :filter-node-method="filterNode" node-key="id" :highlight-current="true" :default-expand-all="expandAll" :props="defaultProps" @node-click="handleNodeClick" v-loading="loading" class="elTree" :current-node-key="currentKey">
  </el-tree>
</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "VxeOrgTree",
  props: {
    service: String, // 接口地址
    placeholder: {
      type: String,
      default: () => {
        return "请输入关键字";
      },
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: "children",
          label: "name",
        };
      },
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
    expandAll: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      loading: false,
      treeData: [],
      orgLists: [],
      orgValue: '',
      communityLists: [],
      value: "",
      currentKey: null
    };
  },
  watch: {
    value(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.getLists();
  },
  methods: {
    ...mapActions(["getTreeService"]),
    // 获取数据
    getLists() {
      this.loading = true;
      this.getTreeService({
        service: this.service,
        params: this.params || {},
      }).then(({ code, data }) => {
        if (code == 200) {
          this.treeData = this.$XEUtils.toArrayTree(data, { strict: true });
          this.orgLists = this.getOrgEvent(this.treeData);
          if (this.treeData.length) {
            let currNode = this.treeData[0];
            if (data[0].type == 1) {
              this.getTreeNode(currNode.children[0])
            } else {
              this.getTreeNode(currNode)
            }
            if (data[0].type <= 3) {
              let firstNode = currNode.children[0];
              if (firstNode.type == "1001") {
                this.handleNodeClick(firstNode)
              }
            } else {
              this.handleNodeClick(currNode)
            }
          }
        }
        this.resetEvent();
      }).catch(() => {
        this.resetEvent();
      });
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    handleNodeClick(data) {
      this.currentKey = data.id
      this.$parent.handleNodeClick(this.treeData, data);
      this.$nextTick(() => {
        if (this.$refs.tree) {
          this.$refs.tree.setCurrentKey(data.id)
        }
      })
    },

    resetEvent() {
      this.loading = false;
    },
    // 组织机构树
    getOrgEvent(tree, arr = []) {
      tree.forEach(item => {
        let node = { ...item, children: [] }
        if (item.type != '1001') arr.push(node);
        if (item.children && item.children.length && item.type != '1001') {
          this.getOrgEvent(item.children, node.children)
        }
      });
      return arr;
    },
    getCommunityEvent(tree, id, arr = []) {
      tree.forEach(item => {
        if (item.children && item.children.length && item.id != id) {
          this.getCommunityEvent(item.children, id, arr)
        } else {
          if (item.children && item.children.length) {
            item.children.forEach(item => {
              if (item.type == '1001') {
                arr.push(item)
              }
            })
          }
        }
      });
      return arr
    },

    focusEvent() {
      this.$refs.xDown.showPanel();
    },
    // 获取组织树节点
    getTreeNode(node) {
      this.orgValue = node.name;
      this.communityLists = this.getCommunityEvent(this.treeData, node.id);
      this.$refs.xDown && this.$refs.xDown.hidePanel();
    },
    styleMap(status) {
      let mapObj = {
        '0': '#000',
        '1': '#ccc',
        '2': '#122d78',
        '3': '#26d116',
        '4': '#0cb5d3',
        '5': '#e07918',
      }
      if (status || status == '0') {
        return 'color:' + mapObj[status]
      } else {
        'color: red'
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tree {
  padding-top: 10px;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  span {
    padding-bottom: 5px;
  }

  .vxe-input {
    width: 100%;
  }

  .treeNode {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;

    .iconfont {
      font-size: 14px;
      // margin-right: 3px;
    }
  }
}

.dropdown {
  width: 100%;
  padding: 5px 0;
  max-height: 200px;
  overflow: auto;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.1);
}
</style>

<template>
  <QRCode :content="props.content || props.format()" :width="props.width" :height="props.height" />
</template>

<script>
import QRCode from '@/components/Base/QRCode.vue'

export default {
  name: 'VxeQRCode',
  components: { QRCode },
  props: {
    renderOpts: Object,
    params: Object
  },
  data() {
    return {
      ...this.renderOpts
    }
  },
}
</script>

<style scoped></style>
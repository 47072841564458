<template>
<vxe-form v-bind="props" @submit="searchEvent" @reset="searchResetEvent"></vxe-form>
</template>

<script>
export default {
  name: "ToolbarSearchForm",
  props: {
    renderOpts: Object,
    params: Object,
    searchOptions: {
      type: Object,
      default: () => {
        return {
          items: [],
          data: {}
        };
      },
    },
  },
  data() {
    return {
      ...this.renderOpts,
    }
  },
  created() {
    console.log(this.renderOpts, this.params, this.searchOptions)
  },

  methods: {
    searchEvent() {

    },

    searchResetEvent() {

    },
  }
}
</script>

<style lang="scss" scoped>

</style>

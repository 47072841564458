<template>
<div></div>
</template>

<script>
export default {
  name: "VxePlaceholder",
  props: {},
  data() {
    return {}
  }
}
</script>

<style>

</style>

<template>
  <!-- 表格数据 -->
  <vxe-grid ref="xGrid" v-bind="gridOptions" :loading="loading" :data="tableData" @toolbar-button-click="toolbarCustomEvent"></vxe-grid>
</template>

<script>
export default {
  name: "VxeAttachmentTable",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      loading: false,
      action: `/sys/sysAttachment/multiUpload`,
      tableData: [],
      ...this.renderOpts,
      gridOptions: {
        height: 300,
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              buttonRender: {
                name: '$ToolbarUploadBtn',
                props: {
                  content: "附件上传",
                  status: "primary",
                  disabled: this.renderOpts.props.disabled || false,
                  multiple: this.renderOpts.props.disabled || false,
                },
                events: {
                  click: (params, files) => {
                    let { data, property } = this.params;
                    let ids = this.$XEUtils.isString(data[property]) ? JSON.parse(data[property]) : data[property]
                    files.forEach(file => {
                      ids.push(file.attachmentId)
                    });
                    data[property] = JSON.stringify(ids);
                    this.getLists();
                  }
                }
              }
            },
            {
              code: "batchDelete",
              name: "批量删除",
              status: "danger",
              eventName: "customEvent",
              disabled: this.renderOpts.props.disabled || false,
              events: {
                click: () => {
                  let records = this.$refs.xGrid.getCheckboxRecords();
                  if (!records.length) {
                    this.$message.error(this.$t("pleaseSelectDataToDelete"));
                  } else {
                    this.removeEvent(records)
                  }
                }
              }
            },
          ],
          zoom: false
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "oriFileName",
            title: "附件名称",
            minWidth: 160,
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "下载",
                  eventName: "customEvent",
                  events: {
                    click: (row) => {
                      this.downloadEvent(row)
                    }
                  }
                },
                {
                  status: "danger",
                  label: "删除",
                  eventName: "customEvent",
                  disabled: this.renderOpts.props.disabled || false,
                  events: {
                    click: (row) => {
                      this.removeEvent([row])
                    }
                  }
                }
              ]
            },
          },
        ],
        pagerConfig: {
          enabled: false
        }
      },
    }
  },
  created() {
    let { data, property } = this.params;
    if (data[property]) {
      this.getLists();
    } else {
      data[property] = [];
    }
  },
  methods: {
    // 列表按钮组功能通用事件
    toolbarCustomEvent({ button }) {
      if (button.events) {
        Object.keys(button.events).forEach((key, index) => {
          if (index == 0) button.events[key](button);
        });
      }
    },

    // 获取数据
    getLists() {
      this.loading = true;
      let { data, property } = this.params;
      let params = this.$XEUtils.isString(data[property]) ? JSON.parse(data[property]) : data[property]
      this.$Axios.post('/sys/sysAttachment/listByIds', params).then(({ code, data }) => {
        if (code === 200) {
          this.tableData = data;
        }
        this.loading = false;
      });
    },

    // 附件下载
    downloadEvent(row) {
      this.$Tools.creaElementA(`/sys/sysAttachment/download/${row.id}`)
    },

    // 附件删除
    removeEvent(records) {
      this.$refs.xGrid.remove(records).then(({ rows }) => {
        let { data, property } = this.params;
        let ids = this.$XEUtils.isString(data[property]) ? JSON.parse(data[property]) : data[property]
        rows.forEach((row) => {
          this.$XEUtils.remove(ids, item => item === row.id);
        });
        data[property] = JSON.stringify(ids);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
</style>

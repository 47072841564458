<template>
  <span v-if="type != 'renderCell'">
    <vxe-pulldown ref="xDown" transfer>
      <template #default>
        <vxe-input v-model="value" suffix-icon="fa fa-search" @focus="focusEvent" @clear="clearEvent" :placeholder="props.placeholder" :disabled="props.disabled"></vxe-input>
      </template>
    </vxe-pulldown>
    <vxe-modal ref="xModal" title="选择员工" width="800" height="80%" show-zoom resize remember>
      <template #default>
        <div class="main">
          <div class="main_right">
            <VxeBasicTable ref="xGrid" :service="service" :gridOptions="gridOptions" :searchOptions="searchOptions"> </VxeBasicTable>
          </div>
        </div>
      </template>
    </vxe-modal>
  </span>
  <span v-else>
    {{ value }}
  </span>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'VxeSelectUser',
  components: {},
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
    defaultProps: {
      type: Object,
      default: () => {
        return {
          type: 'sign'
        }
      }
    },
    searchData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      service: '/sys/sysWorkers',
      ...this.renderOpts,
      value: '',
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          zoom: false,
          custom: false
        },
        // 列表字段配置
        columns: [{
            field: 'name',
            title: '姓名',
            minWidth: 120,
            editRender: {
              name: '$input',
              props: {}
            }
          },
          {
            field: 'sex',
            title: '性别',
            width: 100,
            editRender: {
              name: '$select',
              optionTypeCode: 'sex'
            }
          },
          {
            field: 'phone',
            title: '联系电话',
            minWidth: 120,
            editRender: {
              name: '$input',
              props: {}
            }
          },
          {
            field: 'isRunning',
            title: '接单状态',
            formatter:({cellValue})=>cellValue===0?'可接单':'接单中',
            editRender: {
              name: '$input',
              props: {
              }
            }
          },
        ],
        editConfig: {
          enabled: false,
        }
      },
      // 查询功能字段配置
      searchOptions: {
        // titleWidth: 90,
        items: [{
            field: 'name',
            title: '姓名',
            itemRender: {
              name: '$input',
              props: {
                placeholder: "请输入姓名"
              }
            }
          }, {
            field: 'phone',
            title: '联系电话',
            itemRender: {
              name: '$input',
              props: {
                placeholder: "请输入联系电话"
              }
            }
          },
          {
            itemRender: {
              name: '$buttons',
              children: [{
                  props: {
                    type: 'submit',
                    content: '查询',
                    status: 'primary'
                  },
                  events: {
                    click: ({ data }) => {
                      data['enterpriseId'] = ''
                      this.$refs.xGrid.searchEvent()
                    }
                  }
                },
                {
                  props: {
                    type: 'reset',
                    content: '重置'
                  }
                }
              ]
            }
          }
        ],
        data: {}
      }
    }
  },
  created() {
    this.searchOptions.data = this.props.searchData
    if (this.defaultProps && this.defaultProps.type == 'list') {
      let { toolbarConfig, columns } = this.gridOptions
      columns.splice(1, 0, {
        type: 'checkbox',
        width: 60
      })
      toolbarConfig.buttons = [{
        code: 'customEvent',
        status: 'primary',
        name: '选择',
        eventName: 'customEvent',
        events: {
          click: () => {
            let { xGrid } = this.$refs
            let records = xGrid.$refs.xGrid.getCheckboxRecords()
            if (!records.length) {
              this.$message.error('请选择数据')
              return
            }
            this.selectListEvent(records)
          }
        }
      }]
    } else {
      let { columns } = this.gridOptions
      const isDispatch = this.props?.isDispatch || null
      columns.push({
        fixed: 'right',
        title: '操作',
        minwidth: 100,
        editRender: {
          name: '$VxeGridButtons',
          lists: [{
            status: 'primary',
            label: '选择',
            eventName: 'customEvent',
            disabledFunc:(row)=>{
              if(!isDispatch){
                return false
              }
              return row.isRunning !== 0
            },
            events: {
              click: (row) => {
                this.selectEvent(row)
              }
            }
          }]
        }
      })
    }

    if (this.props.searchItems) {
      let { items } = this.searchOptions
      this.props.searchItems.forEach((item) => {
        items.push(item)
      })
      items.push({
        itemRender: {
          name: '$buttons',
          children: [{
              props: {
                type: 'submit',
                content: '查询',
                status: 'primary'
              },
              events: {
                click: ({ data }) => {
                  data['enterpriseId'] = ''
                  this.$refs.xGrid.searchEvent()
                }
              }
            },
            {
              props: {
                type: 'reset',
                content: '重置'
              }
            }
          ]
        }
      })
    }
    let { data } = this.params;
    this.value = data[this.props.searchData.label] || ''
  },
  watch: {
    props: {
      handler(val, oldVal) {
        if (val.searchData) {
          this.searchOptions.data = val.searchData
        }
      },
      deep: true
    },
    type(val) {
      if (val == 'renderEdit') {
        this.getLists()
      }
    }
  },
  methods: {
    ...mapActions(['findByService']),
    focusEvent() {
      this.$refs.xModal.open()
    },
    selectListEvent(rows) {
      this.$confirm('是否选择这些数据!', '温馨提示', {
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel')
      }).then((type) => {
        if (type === 'confirm') {
          this.selectRow(rows)
        }
      })
    },
    selectEvent(row) {
      this.$confirm('是否选择当前数据!', '温馨提示', {
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel')
      }).then((type) => {
        if (type === 'confirm') {
          this.selectRow(row)
        }
      })
    },
    selectRow(row) {
      this.row = row
      this.value = row.name
      this.$refs.xModal.close()
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, row)
        })
      }
    },
    clearEvent() {
      this.value = ''
      this.row = {}
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, {})
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.main {
  .main_right {
    padding-left: 10px !important;
  }
}
</style>

<template>
<el-config-provider :locale="locale">
  <router-view></router-view>
</el-config-provider>
</template>

<script>
import { initRoutes } from '@/router'
export default {
  name: "App",
  computed: {
    locale() {
      const elementLangs = require.context(`@/i18n/element-langs/`, false, /\.js$/);
      let key = elementLangs.keys().find(item => item.indexOf(this.$i18n.locale) > -1);
      return elementLangs(key).default || elementLangs(key)
    }
  },
  created() {
    //调用路由的导入的函数，页面一刷新会重新调用动态添加路由的函数
    initRoutes()
  },
  beforeUnmount() {
    localStorage.clear();
  }
};
</script>

<style lang="scss">
#app {
  display: flex;
  height: 100%;
  position: relative;
}
</style>

import { createI18n } from 'vue-i18n'
// 自定义国际化---动态引入国际化资源文件
const langs = require.context(`./langs`, false, /\.js$/);
const messages = {};
langs.keys().forEach(key => {
    const name = key.replace(/(.*\/)*([^.]+).*/ig, '$2');
    messages[name] = langs(key).default || langs(key);
});

let lang = localStorage.locale || navigator.userLanguage || navigator.language.toLocaleLowerCase() || "zh-cn"
const i18n = createI18n({
    locale: lang, // 语言标识
    fallbackLocale: lang, //没有英文的时候默认中文语言
    messages,
    silentFallbackWarn: true, //抑制警告
    //this.$i18n.global.locale // 通过切换locale的值来实现语言切换
});
export default i18n;
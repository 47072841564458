module.exports = {
  wellCome: "欢迎",
  confirmTitle: "温馨提示",
  createAndSave: "新增&保存",
  editAndSave: "编辑&保存",
  save: "保存",
  saveAndSubmit: "保存并提交",
  ok: "确定",
  cancel: "取消",
  isDelete: "确定是否删除该数据?",
  pleaseSelectDataToDelete: "请选择您要删除的数据",
  delete: "删除",
  deleteSuccess: "删除成功",
};
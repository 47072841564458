<template>
  <!-- <div>
  <el-upload ref="upload" :action="action" :file-list="fileList" :multiple="true" :show-file-list="false" :headers="headers" name="file" :limit="limit" :on-error="onError" :on-success="successEvent" :disabled="renderOpts.props && renderOpts.props.disabled">
    <vxe-button status="primary" :content="renderOpts.props && renderOpts.props.content || '上传附件'"></vxe-button>
  </el-upload>
</div> -->
  <el-upload ref="upload" class="uploadBtn" :action="action" v-bind="renderOpts.props" :show-file-list="false" v-model:file-list="fileList" :headers="headers" :before-upload="beforeUpload" :on-success="successEvent" :on-preview="previewEvent" :on-remove="removeEvent" :on-exceed="exceedEvent">
    <vxe-button status="primary" v-bind="renderOpts.props" :props="this.renderOpts.props"></vxe-button>
  </el-upload>
</template>

<script>
export default {
  name: "ToolbarUploadBtn",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  computed: {
    limit() {
      return this.props.limit || null;
    }
  },
  data() {
    return {
      action: `/sys/sysAttachment/multiUpload`,
      headers: {
        Authorization: localStorage.token,
      },
      fileList: [],
      ...this.renderOpts,
    };
  },
  methods: {
    beforeUpload() {},
    handleRemove(file) {
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key]("");
        });
      }
    },

    // 上传成功
    successEvent({ code, data }) {
      if (code == "200") {
        this.$message.success("上传成功");
        if (this.events) {
          Object.keys(this.events).forEach((key, index) => {
            if (index == 0) this.events[key](this.params, data);
          });
        }
      } else {
        this.$message.error("上传失败");
      }
    },
    onError(err) {
      console.log(err);
    },
  },

};
</script>

<style lang="scss">

</style>

<template>
  <template v-if="isActiveByRow">
    <vxe-button type="text" :status="item.status" :content="$t(item.name)" @click="events(item)" v-for="(item, index) in activeBtns" :key="index"></vxe-button>
  </template>
  <template v-else>
    <vxe-button type="text" :status="item.status" :content="$t(item.label)" :disabled="item.disabled|| disabledFunc(item) || false" @click="events(item)" v-for="(item, index) in defaultBtns" :key="index"></vxe-button>
  </template>
</template>

<script>
export default {
  name: "VxeGridButtons",
  props: ["renderOpts", "params", "isActiveByRow"],
  data() {
    return {
      activeBtns: [{ name: "save", status: "primary", eventName: "submitEvent" }, { name: "cancel", eventName: "cancelEvent" }]
    }
  },
  computed: {
    defaultBtns() {
      let btnLists = this.renderOpts.lists || [{
          name: "edit",
          status: "primary",
          label: "edit",
          eventName: "queryEvent",
          params: {
            property: "status",
            values: [0],
          },
        },
        {
          name: "audit",
          status: "primary",
          label: "audit",
          eventName: "auditEvent",
          params: {
            property: "status",
            values: [0],
          },
        },
        {
          name: "apply",
          status: "primary",
          label: "apply",
          eventName: "auditEvent",
          params: {
            property: "status",
            values: [0],
          },
        },
        {
          name: "auditInfo",
          status: "primary",
          label: "auditInfo",
          eventName: "auditEvent",
          params: {
            property: "status",
            values: [90],
          },
        },
        {
          name: "delete",
          status: "danger",
          label: "delete",
          eventName: "removeEvent",
          params: {
            property: "status",
            values: [0],
          },
        },
        // {
        //   name: "businessDelete",
        //   status: "danger",
        //   label: "delete",
        //   eventName: "businessRemoveEvent",
        //   params: {
        //     property: "status",
        //     values: [0, 30],
        //   },
        // },
        {
          name: "details",
          status: "primary",
          label: "details",
          eventName: "queryEvent",
        },
      ];
      // 查找权限按钮
      let lists = [];
      btnLists.forEach((item) => {
        let { params } = item;
        // 所有按钮的eventName 为customEvent，都不需要权限控制
        if (item.eventName == "customEvent") {
          lists.push(item);
        }
        // 按钮及权限
        let node = this.$route.meta.buttons.find(btn => btn.code == `${this.$route.name}.${item.name}`);
        let values = []
        if (params) {
          values = params.values && params.values.length ? params.values : params.value
        }
        if (node && (!params || !!this.params.row[params.property] == values || this.$XEUtils.includes(values, this.$XEUtils.toNumber(this.params.row[params.property])))) {
          item.label = node ? node.name : item.label;
          lists.push(item);
        }
      });
      return lists;
    },
  },
  methods: {
    events(button) {
      let { eventName, events } = button;
      // 暂时找不到好的方法
      let { xGrid } = this.$parent.$parent.$parent.$parent.$refs;
      if (events || eventName == "customEvent") {
        Object.keys(events).forEach((key, index) => {
          if (index == 0) events[key](this.params.row, button);
        });
      } else if (eventName == "removeEvent") {
        xGrid.$parent.removeEvent([this.params.row.id]);
      } else {
        xGrid.$parent[eventName](this.params.row, button);
      }
    },
    disabledFunc(row) {
     return  row?.disabledFunc ? row.disabledFunc(this.params.row) : false
    }
  },
};
</script>

<template>
<el-select v-model="value"  
   filterable
   remote
   :disabled="props.disabled"
   :remote-method="remoteMethod"
   @change="change">
  <el-option 
   :label="item[option.label]" 
   :value="item[option.value]" 
   :value-key="item.id" 
   v-for="(item) in list" 
   :key="item.id"></el-option>
</el-select>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: "VxeStoreSelect",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      value:"",
      ...this.renderOpts,
      list:[],
      optionProps:{
        label:"name",
        value:"name",
      },
    }
  },
  watch:{
    
  },
  mounted() {
    let { row, column, data, property } = this.params;
      if (data[property]) {
      this.value = data[property]
    }
    this.value = this.type == "renderItemContent" ? data[property] : row[column.property];
    this.getDeptManage();
  },
 
  methods: {
		...mapActions(["getListService"]),
		getDeptManage() {
			this.getListService({
        service:this.service,
        tablePage:{
          currentPage:1,
          pageSize:10
        },
        params:this.requstParams
      }).then(({data,code}) => {
        if(code == 200) {
          this.list = data.records;
        }
			})
		},
    change(value,oldValue) {
      const {row, column,data,property} = this.params;
      this.type == 'renderItemContent' ? data[property] = value : row[column.property] = value;
      if(this.events) {
        const obj = this.list.filter(item => item[this.option.value] == value)[0];
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params,obj);
        });
      }
      
    },
    remoteMethod(value) {
      if(value) {
        this.requstParams.name = value;
        this.getDeptManage()
      }
      
    },
  }
}
</script>

<style  lang='scss' scoped>
</style>

<template>
<div class="card">
  <div class="card-title">
		<span>详细信息</span>
		<el-icon @click="close"><Close /></el-icon>
	</div>
  <div class="card-content">
		<div class="card-content-name">{{addressInfo.title}}</div>
		<div class="card-content-address">{{addressInfo.address}}</div>
	</div>
</div>
</template>

<script>
export default {
	props:{
		addressInfo:{
			type:Object,
			default:() => {
				return {}
			}
		}
	},
  data() {
    return {}
  },
	watch:{
		addressInfo(value) {
			console.log(value)
		},	
	},
	methods:{
		close() {
			this.$emit('close');
		},
	}
}
</script>

<style  lang='scss' scoped>
.card{
	border-radius: 10px 10px 0 0;
	background-color: #fff;
	&-title{
		background-color: #f7f6f7;
		border-bottom: 1px solid #eee;
		padding: 6px 10px;
		border-radius:10px 10px 0 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&-content{
		padding: 10px;
		&-address{
			font-size: 12px;
			color: #999;
			margin-top: 5px;
		}
		&-name{
			font-size: 15px;
			color: #333;
		}
	}
}
</style>

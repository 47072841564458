<template>
  <span v-if="type != 'renderCell'">
    <vxe-pulldown ref="xDown" transfer>
      <template #default>
        <vxe-input v-model="value" suffix-icon="fa fa-search" @focus="focusEvent" @clear="clearEvent" :placeholder="props.placeholder" :disabled="props.disabled"></vxe-input>
      </template>
    </vxe-pulldown>
    <vxe-modal ref="xModal" title="选择机器" width="1200" height="90%" show-zoom resize remember>
      <template #default>
        <div class="main">
          <div class="main_right">
            <VxeBasicTable ref="xGrid" :service="service" :gridOptions="gridOptions" :searchOptions="searchOptions"> </VxeBasicTable>
          </div>
        </div>
      </template>
    </vxe-modal>
  </span>
  <span v-else>
    {{ value }}
  </span>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'VxeSelectMachine',
  components: {},
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
    defaultProps: {
      type: Object,
      default: () => {
        return {
          type: 'sign'
        }
      }
    },
    searchData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      service: '/mealWaste/businessMachine',
      ...this.renderOpts,
      value: '',
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          zoom: false,
          custom: false
        },
        // 列表字段配置
        columns: [
          {
            field: 'diningCode',
            title: '机器编码',
            width: 100,
            editRender: {
              name: '$input',
              props: {}
            }
          },
          {
            field: 'machineName',
            title: '机器名称',
            width: 150,
            fixed: 'left',
            editRender: {
              name: '$input',
              props: {}
            }
          },
          {
            field: 'machineModelId',
            title: '机器型号',
            minWidth: 180,
            editRender: {
              name: '$select',
              optionTypeCode: 'machineModel',
              props: {}
            }
          },
          {
            field: 'sn',
            fixed: 'left',
            title: 'SN',
            width: 150,
            editRender: {
              name: '$input',
              props: {}
            }
          },
          {
            field: 'contractTypeId',
            title: '合约类型',
            width: 100,
            editRender: {
              name: '$select',
              optionTypeCode: 'machinContractType',
              props: {}
            }
          },
          {
            field: 'customName',
            title: '所属客户',
            minWidth: 200,
            editRender: {
              name: '$input',
              props: {}
            }
          },
          {
            field: 'diningName',
            title: '所属餐厅',
            minWidth: 200,
            editRender: {
              name: '$input',
              props: {}
            }
          }
        ],
        editConfig: {
          enabled: false
        }
      },
      // 查询功能字段配置
      searchOptions: {
        // titleWidth: 90,
        items: [
          {
            field: 'customId',
            title: '所属客户',
            itemRender: {
              name: '$VxeSelectCustomer',
              props: {
                placeholder: '请选择所属客户'
              },
              events: {
                change: (params, row) => {
                  params.data.customId = row.id
                }
              }
            }
          },
          {
            field: 'diningId',
            title: '所属餐厅',
            itemRender: {
              name: '$VxeSelectDining',
              props: {
                placeholder: '请选择所属餐厅'
              },
              events: {
                change: (params, row) => {
                  params.data.diningId = row.id
                }
              }
            }
          },
          {
            field: 'machineName',
            title: '机器名称',
            itemRender: {
              name: '$input',
              props: {
                placeholder: '请输入餐厅名称'
              }
            }
          },
          {
            field: 'sn',
            title: 'SN',
            itemRender: {
              name: '$input',
              props: {
                placeholder: '请输入SN'
              }
            }
          },
          {
            field: 'contractTypeId',
            title: '合约类型',
            itemRender: {
              name: '$select',
              optionTypeCode: 'machinContractType',
              props: {
                placeholder: '请选择合约类型'
              }
            }
          },
          {
            itemRender: {
              name: '$buttons',
              children: [
                {
                  props: {
                    type: 'submit',
                    content: '查询',
                    status: 'primary'
                  },
                  events: {
                    click: ({ data }) => {
                      data['enterpriseId'] = ''
                      this.$refs.xGrid.searchEvent()
                    }
                  }
                },
                {
                  props: {
                    type: 'reset',
                    content: '重置'
                  }
                }
              ]
            }
          }
        ],
        data: {}
      }
    }
  },
  created() {
    this.searchOptions.data = this.props.searchData
    if (this.defaultProps && this.defaultProps.type == 'list') {
      let { toolbarConfig, columns } = this.gridOptions
      columns.splice(1, 0, {
        type: 'checkbox',
        width: 60
      })
      toolbarConfig.buttons = [
        {
          code: 'customEvent',
          status: 'primary',
          name: '选择',
          eventName: 'customEvent',
          events: {
            click: () => {
              let { xGrid } = this.$refs
              let records = xGrid.$refs.xGrid.getCheckboxRecords()
              if (!records.length) {
                this.$message.error('请选择数据')
                return
              }
              this.selectListEvent(records)
            }
          }
        }
      ]
    } else {
      let { columns } = this.gridOptions

      columns.push({
        fixed: 'right',
        title: '操作',
        width: 100,
        editRender: {
          name: '$VxeGridButtons',
          lists: [
            {
              status: 'primary',
              label: '选择',
              eventName: 'customEvent',
              events: {
                click: (row) => {
                  this.selectEvent(row)
                }
              }
            }
          ]
        }
      })
    }

    if (this.props.searchItems) {
      let { items } = this.searchOptions
      this.props.searchItems.forEach((item) => {
        items.push(item)
      })
      items.push({
        itemRender: {
          name: '$buttons',
          children: [
            {
              props: {
                type: 'submit',
                content: '查询',
                status: 'primary'
              },
              events: {
                click: ({ data }) => {
                  data['enterpriseId'] = ''
                  this.$refs.xGrid.searchEvent()
                }
              }
            },
            {
              props: {
                type: 'reset',
                content: '重置'
              }
            }
          ]
        }
      })
    }

    this.value = this.params.data?.machineName || ''
  },
  watch: {
    props: {
      handler(val, oldVal) {
        if (val.searchData) {
          this.searchOptions.data = val.searchData
        }
      },
      deep: true
    },
    type(val) {
      if (val == 'renderEdit') {
        this.getLists()
      }
    }
  },
  methods: {
    ...mapActions(['findByService']),
    focusEvent() {
      this.$refs.xModal.open()
    },
    selectListEvent(rows) {
      this.$confirm('是否选择这些数据!', '温馨提示', {
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel')
      }).then((type) => {
        if (type === 'confirm') {
          this.selectRow(rows)
        }
      })
    },
    selectEvent(row) {
      this.$confirm('是否选择当前数据!', '温馨提示', {
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel')
      }).then((type) => {
        if (type === 'confirm') {
          this.selectRow(row)
        }
      })
    },
    selectRow(row) {
      this.row = row
      this.value = row.machineName
      this.$refs.xModal.close()
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, row)
        })
      }
    },
    clearEvent() {
      this.value = ''
      this.row = {}
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, {})
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  .main_right {
    padding-left: 10px !important;
  }
}
</style>

<template>
  <vxe-pulldown ref="xPullDown">
    <template #default>
      <vxe-input v-model="value"
                 :placeholder="props.placeholder"
                 :disabled="props.disabled"
                 @input="inputEvent"
                 @focus="focusEvent"></vxe-input>
    </template>
    <template #dropdown>
      <div class="dropdown">
        <div class="list-item"
             v-for="item in options"
             :key="item.value"
             @click="selectEvent(item)">
          <span>{{ item.label }}</span>
        </div>
      </div>
    </template>
  </vxe-pulldown>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: "VxeAutoSelect",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data () {
    return {
      options: [],
      value: '',
      ...this.renderOpts,
    }
  },
  mounted () {
    let { data, property } = this.params;
    if (data[property]) {
      this.value = data[property]
    }
    this.getPorjectInfo();
  },
  methods: {
    ...mapActions(['findByEventService']),
    focusEvent () {
      this.$refs.xPullDown.showPanel();
    },
    selectEvent (item) {
      let { data, property } = this.params;
      if (this.setFieldValue) {
        data[this.setFieldValue] = item.value
        this.value = data[property] = item.label
      } else {
        this.value = data[property] = item.value
      }
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params,item);
        });
      }
      this.$refs.xPullDown.hidePanel();
    },
    inputEvent ({ value }) {
      this.getPorjectInfo(value)

    },
    getPorjectInfo (value) {
      this.findByEventService({
        service: this.service,
        params: { keyWords: value || "" }
      }).then(({ code, data }) => {
        if (code == 200) {
          console.log(data)
          let { property } = this.params;
          this.options = data.map((item, index) => {
            return { label: property == 'projectNumber' ? item.projectName : item[property], value: item[this.setFieldValue ? this.setFieldValue : property],id:item.id}
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dropdown {
  width: 100%;
  max-height: 100px;
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  .list-item {
    padding: 8px;
  }
}
</style>
<template>
  <span v-if="type != 'renderCell'">
    <vxe-input v-model="value" :placeholder="props.placeholder" type="number" maxlength="11" :disabled="props.disabled" :controls="false" @input="inputEvent"></vxe-input>
  </span>
  <span v-else>
    {{value}}
  </span>
</template>

<script>
export default {
  name: "VxePhoneInput",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      ...this.renderOpts,
    }
  },
  computed: {
    value() {
      let { row, column, data, property } = this.params;
      return this.type == "renderItemContent" ? data[property] : row[column.property]
    },
  },
  created(){
    this.setItemRules();
  },
  methods: {
    setItemRules(value) {
      let { $table, column, $form, item, property } = this.params;
      let itemRules = [
        { required: true, message: "请输入有效的联系电话" },
        {
          validator: ({ cellValue, itemValue }) => {
            let value = cellValue || itemValue
            if (value && !this.$Tools.regs.phone.test(value)) {
              let title = column ? column.title : item.title
              return new Error(`${title}格式不正确，请重新输入!`);
            }
          },
        }
      ]
      let props = column && column.editRender.props || item && item.itemRender.props
      if (this.type == "renderEdit") {
        if (value || props && props.required) {
          $table.props.editRules[column.property] = itemRules;
        }
        // else {
        //   $table.props.editRules[column.property] = [];
        // }
      }
      if (this.type == "renderItemContent") {
        if (value || props && props.required) {
          $form.props.rules[property] = itemRules;
        }
        // else {
        //   $form.props.rules[property] = [];
        // }
      }
    },
    inputEvent({ value }) {
      let { row, column, data, property } = this.params;
      this.type == "renderItemContent" ? data[property] = value : row[column.property] = value;
      this.setItemRules(value);
    },
  }
}
</script>

<style>

</style>

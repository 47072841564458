import XEUtils from 'xe-utils'
import VXETable from 'vxe-table'
import Router from '@/router'
import Tools from '@/utils/Tools'

// import VXETablePluginElement from 'vxe-table-plugin-element'
// import 'vxe-table-plugin-element/dist/style.css'
// VXETable.use(VXETablePluginElement)

// 集成XLSX导入导出插件
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
VXETable.use(VXETablePluginExportXLSX)

VXETable.setup({
  icon: {
    // select
    SELECT_OPEN: 'vxe-icon--arrow-bottom rotate180',
    SELECT_CLOSE: 'vxe-icon--arrow-bottom'
  },
  // size: null, // 全局尺寸
  zIndex: 2000, // 全局 zIndex 起始值，如果项目的的 z-index 样式值过大时就需要跟随设置更大，避免被遮挡
  // version: 0, // 版本号，对于某些带数据缓存的功能有用到，上升版本号可以用于重置数据
  table: {
    showHeader: true,
    keepSource: true,
    delayHover: 50,
    showOverflow: true,
    showHeaderOverflow: null,
    showFooterOverflow: null,
    highlightHoverRow: true,
    highlightCurrentRow: true,
    size: null,
    resizable: true,
    autoResize: true,
    syncResize: true,
    stripe: true,
    border: true,
    round: true,
    align: 'center',
    radioConfig: {
      trigger: 'default'
    },
    columnConfig: {
      isCurrent: true,
      isHover: true,
      resizable: true
    },
    checkboxConfig: {
      strict: false,
      highlight: false,
      range: false,
      trigger: 'default'
    },
    sortConfig: {
      remote: false,
      trigger: 'default',
      orders: ['asc', 'desc', null],
      sortMethod: null
    },
    filterConfig: {
      remote: false,
      filterMethod: null
    },
    expandConfig: {
      trigger: 'default',
      showIcon: true,
      accordion: true
      // trigger : "row"
    },
    treeConfig: {
      children: 'children',
      hasChild: 'hasChild',
      indent: 20,
      showIcon: true
    },
    tooltipConfig: {
      theme: 'dark',
      enterable: false
    },
    mouseConfig: { selected: true },
    keyboardConfig: {
      isArrow: true,
      isDel: true,
      isEnter: true,
      isTab: true,
      isEdit: true,
      isChecked: true
    },
    // 右键菜单
    menuConfig: {
      header: {
        options: [
          [
            { code: 'refresh', name: '刷新' },
            { code: 'exportData', name: '导出.csv' }
            // { code: 'insert', name: '新增', record: { name: '默认名称' } }
          ]
        ]
      },
      body: {
        options: [
          [
            { code: 'refresh', name: '刷新' },
            { code: 'exportData', name: '导出.csv' }
            // { code: 'insert', name: '新增', record: { name: '默认名称' } }
          ]
        ]
      },
      visibleMethod: ({ type, options, columns }) => {
        let route = Router.currentRoute.value
        let permissButtons = Tools.getBtnPermiss(route)
        options[0].map((menu) => {
          let node = XEUtils.filterTree(permissButtons, (item) => item.code === `${route.name}.${menu.code}`)[0]
          let arrays = ['refresh', 'exportData']
          if (arrays.includes(menu.code) || node) {
            menu.visible = true
          } else {
            menu.visible = false
          }
        })
        return true
      }
    },
    editConfig: {
      trigger: 'dblclick',
      mode: 'row',
      showIcon: false, // 是否显示列编辑图标
      showStatus: true,
      autoClear: false,
      showAsterisk: true // 是否显示必填字段的红色星号
    },
    importConfig: {
      // types: ['xls', 'xlsx', 'csv'],
      types: ['xlsx'],
      mode: 'covering',
      modes: ['insert', 'covering']
    },
    exportConfig: {
      style: true,
      useStyle: true,
      isMerge: true,
      isAllExpand: true,
      // types: ['xls', 'xlsx', 'csv'],
      types: ['xlsx'],
      // modes: ["current", "selected", "all"],
      columnFilterMethod: ({ column }) => {
        if (!column.property) {
          return false
        }
        return true
      }
    },
    customConfig: {
      storage: true,
      checkMethod: ({ column }) => {
        if (!column.property || column.property == 'oper') {
          return false
        }
        return true
      }
    },
    scrollX: {
      gt: 60
    },
    scrollY: {
      gt: 100
    }
  },
  grid: {
    size: null,
    sortable: true,
    zoomConfig: {
      escRestore: true
    },
    pagerConfig: {
      size: null,
      autoHidden: false,
      border: true,
      perfect: true,
      layouts: ['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total'],
      pageSizes: [10, 30, 50, 100, 200, 500, 1000]
    },
    toolbarConfig: {
      perfect: false,
      import: false,
      export: false,
      print: false,
      zoom: true,
      custom: true
    },
    sortConfig: {
      multiple: true
    }
    // proxyConfig: {
    //   autoLoad: true,
    //   message: true,
    //   props: {
    //     list: null, // 用于列表，读取响应数据
    //     result: 'result', // 用于分页，读取响应数据
    //     total: 'page.total' // 用于分页，读取总条数
    //   },
    //   beforeItem: null,
    //   beforeColumn: null,
    //   beforeQuery: null,
    //   afterQuery: null,
    //   beforeDelete: null,
    //   afterDelete: null,
    //   beforeSave: null,
    //   afterSave: null
    // }
  },
  pager: {
    size: null,
    autoHidden: false,
    border: true,
    perfect: true,
    pageSize: 10,
    currentPage: 1,
    total: 0,
    layouts: ['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total'],
    pageSizes: [10, 30, 50, 100, 200, 500, 1000]
  },
  form: {
    titleAlign: 'right',
    titleColon: true,
    preventSubmit: false,
    validConfig: {
      autoPos: true
    },
    size: null,
    colon: false,
    titleAsterisk: true
  },
  input: {
    size: null,
    clearable: true,
    transfer: true,
    parseFormat: 'yyyy-MM-dd HH:mm:ss.SSS',
    labelFormat: 'yyyy-MM-dd',
    valueFormat: 'yyyy-MM-dd',
    startWeek: 1,
    digits: 2,
    controls: true
  },
  textarea: {
    resize: 'vertical',
    size: null,
    autosize: {
      minRows: 1,
      maxRows: 10
    }
  },
  select: {
    size: null,
    transfer: true,
    multiCharOverflow: 8
  },
  toolbar: {
    size: null,
    refresh: {
      icon: 'fa fa-refresh',
      iconLoading: 'fa fa-spinner fa-spin'
    },
    import: {
      icon: 'fa fa-upload'
    },
    export: {
      icon: 'fa fa-download'
    },
    zoom: {
      iconIn: 'fa fa-arrows-alt',
      iconOut: 'fa fa-expand'
    },
    custom: {
      isFooter: true,
      icon: 'fa fa-cog'
    },
    buttons: []
  },
  button: {
    size: null,
    transfer: false
  },
  radio: {
    size: null
  },
  checkbox: {
    size: null
  },
  switch: {
    size: null
  },
  modal: {
    // size: null,
    resize: true,
    minWidth: 300,
    minHeight: 200,
    lockView: true,
    mask: true,
    destroyOnClose: true,
    dblclickZoom: true,
    // duration: 3000,
    escClosable: true,
    transfer: true
    // marginSize: 0,
    // showTitleOverflow: true,
    // storage: false
  }
})

// 自定义封装组件自动化注册---Table
const VxeTableFiles = require.context('@/components/VxeTable', false, /.vue$/)
VxeTableFiles.keys().forEach((key) => {
  const Template = VxeTableFiles(key).default || VxeTableFiles(key)
  VXETable.renderer.add('$' + Template.name, {
    // 列表编辑模式
    renderEdit: (renderOpts, params) => {
      return [<Template renderOpts={renderOpts} params={params} isActiveByRow={true} type={'renderEdit'} />]
    },
    // 列表展示模式
    renderCell: (renderOpts, params) => {
      return [<Template renderOpts={renderOpts} params={params} isActiveByRow={false} type={'renderCell'} />]
    }
  })
})

// 自定义封装组件自动化注册---Form
const VxeFormFiles = require.context('@/components/VxeForm', false, /.vue$/)
VxeFormFiles.keys().forEach((key) => {
  const Template = VxeFormFiles(key).default || VxeFormFiles(key)
  VXETable.renderer.add('$' + Template.name, {
    // 表单模式
    renderItemContent: (renderOpts, params) => {
      return [<Template renderOpts={renderOpts} params={params} type={'renderItemContent'} />]
    },
    // 列表编辑模式
    renderEdit: (renderOpts, params) => {
      return [<Template renderOpts={renderOpts} params={params} type={'renderEdit'} />]
    },
    // 列表展示模式
    renderCell: (renderOpts, params) => {
      return [<Template renderOpts={renderOpts} params={params} type={'renderCell'} />]
    }
  })
})

// 自定义封装组件自动化注册--工具栏按钮
const ToolbarFiles = require.context('@/components/Toolbar', false, /.vue$/)
ToolbarFiles.keys().forEach((key) => {
  const Template = ToolbarFiles(key).default || ToolbarFiles(key)
  VXETable.renderer.add('$' + Template.name, {
    renderToolbarButton(renderOpts, params) {
      return [<Template renderOpts={renderOpts} params={params} />]
    }
  })
})

ToolbarFiles.keys().forEach((key) => {
  const Template = ToolbarFiles(key).default || ToolbarFiles(key)
  VXETable.renderer.add('$' + Template.name, {
    renderToolbarTool(renderOpts, params) {
      return [<Template renderOpts={renderOpts} params={params} />]
    }
  })
})

export default VXETable
